<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium">Goals of Care Plan</span>
          <div class="flex flex-col">
            <template v-for="(goal, index) in goals" :key="index">
              <span class="text-sm text-neutral-500">{{index+1}}. {{goal.text}}</span>
            </template>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium">Plan for Monitoring & Evaluation</span>
          <div class="flex flex-col">
            <template v-for="(mNe, index) in monitoringAndEvaluation" :key="index">
              <span class="text-sm text-neutral-500">{{index+1}}. {{mNe.text}}</span>
            </template>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium">Responsibilities</span>
          <div class="flex flex-col">
            <template v-for="(responsibility, index) in responsibilities" :key="index">
              <span class="text-sm text-neutral-500">{{index+1}}. ({{responsibility.responsible}}) {{responsibility.text}}</span>
            </template>
          </div>
        </div>
        <div class="grid grid-cols-2">
          <div class="flex flex-col col-span-1 gap-2">
            <span class="text-sm font-medium">Start of Care Plan</span>
            <span class="text-sm text-neutral-500">{{ startAt }}</span>
          </div>
          <div class="flex flex-col col-span-1 gap-2">
            <span class="text-sm font-medium">End of Care Plan</span>
            <span class="text-sm text-neutral-500">{{ endAt }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium">Care Plan Notes</span>
          <span class="text-sm text-neutral-500">{{text}}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import { useCarePlan } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeCarePlan } = useCarePlan();

    const hoveringGenericPreview = ref(false);
    const goals = computed(() => props.record.goals);
    const monitoringAndEvaluation = computed(() => props.record.monitoringAndEvaluation);
    const startAt = computed(() => format(new Date(props.record.startAt), 'MM/dd/yyyy'));
    const endAt = computed(() => format(new Date(props.record.endAt), 'MM/dd/yyyy'));
    const responsibilities = computed(() => props.record.responsibilities);
    const text = computed(() => {
      const text = props.record.text;
      return text.charAt(0).toUpperCase() + text.slice(1);
    });
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const recordId = computed(() => props.record.id);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      // const result = globalThis.confirm('Are you sure you want to delete this care plan?');
      // if (!result) return;
      removeCarePlan({ id: recordId.value });
    }

    return {
      hoveringGenericPreview,
      createdAt,
      goals,
      monitoringAndEvaluation,
      startAt,
      endAt,
      responsibilities,
      text,
      edit,
      remove,
    };
  },
};
</script>
