import { computed, reactive, toRefs } from 'vue';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { usePatient } from '@/composables/use-patients';
import { useEncounters } from '@/composables/use-encounters';
import { get } from 'lodash';
import { RECORD_TYPE, UPDATE_FIELDS, GROWTH_CHART_DATA_RAW } from './constants';

// function getAgeInMonths (dateOfBirth, createdAtTimestamp) {
//   const dateOfBirthDate = new Date(dateOfBirth);
//   const createdAtDate = new Date(createdAtTimestamp);
//   let ageInMonths = (createdAtDate.getFullYear() - dateOfBirthDate.getFullYear()) * 12;
//   ageInMonths += createdAtDate.getMonth() - dateOfBirthDate.getMonth();
//   return ageInMonths;
// }

function getWHOPercentiles (ageInMonths, patientSex) {
  const categories = ['height', 'weight', 'head', 'bmi'];
  const obj = {};

  categories.forEach(category => {
    for (let i = 1; i <= 7; i++) {
      const key = `${category}_point${i}`;
      const path = `${category}.${patientSex}.point${i}.${ageInMonths}`;
      obj[key] = get(GROWTH_CHART_DATA_RAW, path);
    }
  });

  return obj;
}

function formatVitalsForVue3Charts (vitals, dateOfBirth, patientSex) {
  // Initialize an array with default values for each month
  const formattedData = Array.from({ length: 64 }, (_, index) => ({
    ageInMonths: index,
    weight: null,
    height: null,
    headCircumference: null,
    bmi: null,
    ...getWHOPercentiles(index, patientSex),
  }));

  // Update the array with the actual data from the vitals array
  vitals?.forEach(item => {
    const ageInMonths = item.formattedReferenceAgeInMonths;

    // Ensure ageInMonths is within the range 0 to 63
    if (ageInMonths >= 0 && ageInMonths <= 63) {
      const heightInMeters = item.height / 100;
      const bmi = item.weight / (heightInMeters * heightInMeters);

      formattedData[ageInMonths] = {
        ageInMonths,
        weight: item.weight,
        height: item.height,
        headCircumference: item.headCircumference,
        bmi,
        ...getWHOPercentiles(ageInMonths, patientSex),
      };
    }
  });

  return formattedData;
}

function formatReferenceAgeToMonths (obj) {
  const [referenceAgeUnit] = Object.keys(obj);
  const referenceAgeValue = obj[referenceAgeUnit];

  if (referenceAgeUnit === 'years') {
    return referenceAgeValue * 12;
  }

  if (referenceAgeUnit === 'months') {
    return referenceAgeValue;
  }

  if (referenceAgeUnit === 'weeks') {
    return referenceAgeValue / 4;
  }

  if (referenceAgeUnit === 'days') {
    return referenceAgeValue / 30;
  }
}

const state = reactive({
  allVitals: [],
  vue3ChartsData: [],
});

export function useVitals () {
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
    listItems,
  } = useMedicalRecords();

  const { patient } = usePatient();
  const { encounter } = useEncounters();

  const records = computed(() => {
    return medicalRecords.value.filter((record) => record.type === RECORD_TYPE)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  const submit = async (data) => {
    const payload = Object.keys(data).reduce((acc, key) => {
      if (UPDATE_FIELDS.includes(key) && data[key] !== null && data[key] !== undefined && data[key] !== '') {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    if (data.painAssessmentScale) {
      payload.painAssessmentScale = data.painAssessmentScale;
    }

    if (data?.id) {
      const id = data.id;
      if (data.$unset) {
        await updateMedicalRecord(id, { $unset: data.$unset });
      }
      await updateMedicalRecord(id, payload);
    } else {
      payload.type = RECORD_TYPE;
      payload.encounter = encounter.value?.id;
      payload.patient = patient.value?.id;
      await createMedicalRecord(payload);
    }
  };

  const remove = async ({ id }) => {
    await removeMedicalRecord(id);
  };

  async function listAllVitals (patientId) {
    const query = {
      type: RECORD_TYPE,
      patient: patientId,
      referenceAge: { $exists: true },
    };
    const vitals = await listItems(query);

    return vitals?.map((vital) => {
      if (vital.referenceAge) {
        const formattedReferenceAgeInMonths = formatReferenceAgeToMonths(vital.referenceAge);
        return {
          ...vital,
          formattedReferenceAgeInMonths,
        };
      }
      return vital;
    });
  }

  async function getVue3ChartsVitalsData ({ patientId, patientDateOfBirth, patientSex }) {
    if (!patientId) return;
    const vitals = await listAllVitals(patientId);
    const formattedVitals = formatVitalsForVue3Charts(vitals, patientDateOfBirth, patientSex);
    state.vue3ChartsData = formattedVitals;
  }

  return {
    medicalRecords,
    records,
    ...toRefs(state),
    submit,
    remove,
    listAllVitals,
    getVue3ChartsVitalsData,
  };
}
