<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col gap-2">
          <span class="text-sm font-medium">BMI</span>
          <span class="text-sm text-neutral-500">{{bmi?.toFixed(2) || '-'}}</span>
        </div>
        <template v-for="(field, index) in formFields" :key="index">
          <div v-if="record[field.field] && field.label !== 'Age'" class="flex flex-col gap-2">
            <span class="text-sm font-medium">{{field.label}}</span>
            <span class="text-sm text-neutral-500">{{record[field.field]}} {{ field.unit }}</span>
          </div>
          <div v-if="field.label === 'Age'" class="flex flex-col gap-2">
            <span class="text-sm font-medium">Age Reference</span>
            <span class="text-sm text-neutral-500">{{referenceAgeValue}}</span>
          </div>
        </template>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { FORM_FIELDS } from './constants';
import { calcBMIUtil } from '@/utils/math';
import { useVitals } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeVitals } = useVitals();

    const text = computed(() => props.record.text);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const bmi = computed(() => calcBMIUtil(props.record.weight, props.record.height));

    const referenceAgeValue = computed(() => {
      const { referenceAge } = props.record;
      console.log('referenceAge', referenceAge);
      if (referenceAge) {
        const [referenceAgeUnit] = Object.keys(referenceAge);
        return `${referenceAge[referenceAgeUnit]} ${referenceAgeUnit}`;
      }
      return '-';
    });

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeVitals({ id: props.record.id });
    }

    return {
      createdAt,
      text,
      referenceAgeValue,
      bmi,
      formFields: FORM_FIELDS,
      edit,
      remove,
    };
  },
};
</script>
