<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <template v-for="(formField, index) in formFields" :key="index">
      <div class="form-control col-span-full">
        <div class="flex justify-between mb-2">
          <span class="label-text">
            <span v-if="showLabel">{{formField.label}} <span v-if="formField?.rules?.required" class="text-error">*</span></span>
          </span>
        </div>
        <template v-if="formField.type === 'select'">
          <select
            v-model="model[formField.field]"
            class="select select-bordered w-full"
            required
            :name="formField.label"
            :disabled="loading"
          >
            <option value="" selected disabled>{{ formField.placeholder || 'Select here' }}</option>
            <template v-for="(option, index) in formField.options" :key="index">
              <option :value="option">{{option}}</option>
            </template>
          </select>
        </template>

        <template v-if="formField.type === 'date'">
          <VDatePicker v-model.string="model[formField.field]" mode="dateTime" :masks="{ modelValue: 'YYYY-MM-DD hh:mm' }">
                <template #default="{ togglePopover }">
                  <input type="text" :placeholder="formField.placeholder" class="input input-bordered w-full focus:input-neutral"
                    v-model="model[formField.field]" @click="togglePopover" />
                </template>
              </VDatePicker>
        </template>

        <template v-if="formField.type === 'text'">
          <input
            v-model="model[formField.field]"
            type="text"
            class="input input-bordered"
            :placeholder="formField.placeholder || 'Type here'"
          />
        </template>

        <template v-if="formField.type === 'textarea'">
          <textarea
            v-model="model[formField.field]"
            :placeholder="formField.placeholder || 'Type here'"
            class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
            :disabled="loading"
          ></textarea>
        </template>
      </div>
    </template>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS } from './constants';
import { computed, reactive, ref } from 'vue';
import { useDisposition } from './composables';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitDisposition } = useDisposition();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);

    const formSubmittable = computed(() => {
      return !!model.followupAt;
    });

    const submit = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        );

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitDisposition(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      if (data.followupAt) {
        const dateAndTime = new Date(data.followupAt).toISOString().split('T');
        model.followupAt = [dateAndTime[0], dateAndTime[1].slice(0, 5)].join();
      };
      model.followupLocation = data.followupLocation;
      model.followupReason = data.followupReason;
      model.followupInstructions = data.followupInstructions;
    }

    return {
      loading,
      model,
      recordId,
      isEditing,
      formSubmittable,
      formFields: FORM_FIELDS,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
