import { ref, computed } from 'vue';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { usePatient } from '@/composables/use-patients';
import { useEncounters } from '@/composables/use-encounters';
import { RECORD_TYPE } from './constants';

export function useNutritionalPlan () {
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
  } = useMedicalRecords();

  const { patient } = usePatient();
  const { encounter } = useEncounters();

  const loading = ref(false);
  const formRef = ref(null);

  const records = computed({
    get () {
      return medicalRecords.value.filter((record) => record.type === RECORD_TYPE);
    },
    set ({ type, data }) {
      if (type === 'create') {
        records.value.unshift(data);
      }

      if (type === 'update') {
        const index = records.value.findIndex((record) => record.id === data.id);
        records.value.splice(index, 1, data);
      }

      if (type === 'remove') {
        const index = records.value.findIndex((record) => record.id === data.id);
        records.value.splice(index, 1);
      }
    },
  });

  const submit = async () => {
    try {
      loading.value = true;

      const payload = formRef.value?.submit();

      if (!payload) return;

      if (payload?.id) {
        const id = payload.id;
        delete payload.id;
        // data = await updateMedicalRecord(id, payload);
        // records.value = {
        //   type: 'update',
        //   data,
        // };
        await updateMedicalRecord(id, payload);
      } else {
        payload.type = RECORD_TYPE;
        payload.encounter = encounter.value?.id;
        payload.patient = patient.value?.id;
        // data = await createMedicalRecord(payload);
        // records.value = {
        //   type: 'create',
        //   data,
        // };
        await createMedicalRecord(payload);
      }
      resetForm();
      return true; // to let the cally know that the form was submitted successfully
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  };

  const remove = async ({ id }) => {
    try {
      loading.value = true;
      await removeMedicalRecord(id);
      // records.value = { type: 'remove', data: { id } };
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  };

  const resetForm = () => {
    formRef.value?.resetForm();
  };

  const setForm = (data) => {
    formRef.value?.setForm(data);
  };

  function getTimeFromUnixTimestamp (unixTimestamp) {
    // Create a new Date object from the Unix timestamp (in milliseconds)
    const dateTime = new Date(unixTimestamp);

    // Extract the hours and minutes components
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    // Format the time as "HH:mm" string
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  return {
    formRef,
    medicalRecords,
    records,
    loading,
    submit,
    remove,
    resetForm,
    setForm,
    getTimeFromUnixTimestamp,
  };
}
