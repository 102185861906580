<template>
  <div
    class="relative border rounded-md bg-white shadow-md p-4"
    @mouseover="onMouseover"
    @mouseout="onMouseout"
  >
    <div class="flex justify-end items-center p-0">
      <!-- <span class="text-xs mb-2 text-neutral-400">
        <span v-if="showRecordLabel">{{title}} - </span>
        {{createdAt}}
      </span> -->
      <div v-if="!hideOptions" class="flex gap-1">
        <button
          class="btn btn-sm btn-ghost btn-circle"
          :class="{ 'hover:cursor-not-allowed': !isEncounterActive }"
          :disabled="!isEncounterActive"
          @click="edit"
        >
          <i class="las la-edit text-lg"></i>
        </button>
        <button
          class="btn btn-sm btn-ghost btn-circle"
          :class="{ 'hover:cursor-not-allowed': !isEncounterActive }"
          :disabled="!isEncounterActive"
          @click="remove"
        >
          <i class="las la-trash text-lg"></i>
        </button>
      </div>
      <div v-if="!hideOptions && moreOptions.length" class="dropdown dropdown-end">
        <label tabindex="0" class="btn btn-sm btn-ghost btn-circle m-1">
          <i class="las la-ellipsis-v text-xl"></i>
        </label>
        <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-slate-100 rounded-box w-52">
          <template v-for="(option, index) in moreOptions" :key="index">
            <li>
              <nuxt-link @click="option.onClick()">
                <i :class="option.icon"></i>
                {{option.label}}
              </nuxt-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="p-2">
      <slot/>
    </div>
    <div class="flex justify-between items-center px-2 pt-2">
      <!-- <span
        class="text-xs mb-2 text-neutral-400"
        :class="{ 'text-neutral-900': createdByOthers }"
      > -->
      <span
        class="text-xs mb-2 text-neutral-400"
      >
        {{createdAt}}
      </span>
      <span
        class="text-xs mb-2"
      >
        Created by {{ record.formattedCreatedByFullName }}
      </span>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import { useEncounters } from '@/composables/use-encounters';
import { useMedicalRecords } from '@/composables/use-medical-records';

export default {
  props: {
    record: {
      type: Object,
      defafault: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const createdAt = computed(() => (props.record?.createdAt && format(new Date(props.record?.createdAt), 'MM/dd/yyyy hh:mm a')) || '');
    const createdByOthers = computed(() => props.record?.createdByOthers);

    const hovering = ref(false);

    const { isEncounterActive } = useEncounters();
    const { recordTypesMap } = useMedicalRecords();

    const recordLabel = computed(() => {
      const type = props.record?.type;
      const subtype = props.record?.subtype;
      if (subtype) return recordTypesMap?.[`${type}:${subtype}`]?.label;
      return recordTypesMap?.[type]?.label;
    });

    function onMouseover () {
      hovering.value = true;
      emit('hovering', true);
    }

    function onMouseout () {
      hovering.value = false;
      emit('hovering', false);
    }

    function edit () {
      emit('edit');
    }

    function remove () {
      if (!confirm(`Are you sure you want to delete ${recordLabel.value}?`)) {
        return;
      }
      emit('remove');
    }

    return {
      createdAt,
      hovering,
      isEncounterActive,
      createdByOthers,
      recordLabel,
      onMouseover,
      onMouseout,
      edit,
      remove,
    };
  },
};
</script>
