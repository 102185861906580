<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-full`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField?.rules?.required" class="text-error">*</span></span>
            </span>
          </div>
          <template v-if="formField.type === 'select'">
            <select v-if="formField.field === 'tests'" v-model="model[formField.field]" class="select select-bordered">
                <option value="" disabled selected>Select a test</option>
                <option
                  v-for="(option, index) in laboratoryTests"
                  :key="index"
                  :value="option"
                >
                  {{ option.name }}
                </option>
              </select>
              <select 
                v-if="formField.field === 'diagnosisCodeAndText'" 
                v-model="model[formField.field]" 
                class="select select-bordered"
              >
                <option value="" disabled selected>Select ICD 10 Code</option>
                <option
                  v-for="(option, index) in icd10codes"
                  :key="index"
                  :value="option"
                >
                  {{ option.code + ' - ' + option.text }}
                </option>
              </select>
          </template>
          <template v-if="formField.type === 'textarea'">
            <textarea
              v-model="model[formField.field]"
              placeholder="Type here"
              class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
            ></textarea>
          </template>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        :disabled="loading"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable || loading"
      >
        {{ submitText }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { computed, reactive, ref, onMounted } from 'vue';
import { useLaboratoryOrders } from './composables';
import { useDiagnosticTests } from '@/composables/use-diagnostic-tests';
import { isObject, omit, intersectionWith, toLower } from 'lodash';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const {
      icd10codes,
      submit: submitLaboratoryOrders,
      searchTests,
      searchICD,
    } = useLaboratoryOrders();
    const {
      laboratoryTests,
    } = useDiagnosticTests();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);
    const submitText = computed(() => isEditing.value ? 'Update' : 'Submit');

    const model = reactive(FORM_MODEL);
    onMounted(() => {
      searchTests();
      searchICD();
    });

    const search = async ({ event, value }) => {
      await searchICD(value);
    };

    const submit = async () => {
      try {
        const diagnosisCodeAndText = model.diagnosisCodeAndText;
        const payload = omit(Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        ), 'diagnosisCodeAndText');

        // destructure tests selected
        if (model?.tests) {
          const testsMapped = [{ name: model?.tests?.name, id: model?.tests?.id }];
          // const testsMapped = model.tests.map(i => { return { name: i?.name, id: i?.id }; });
          payload.tests = testsMapped;
        }

        // destructure icd results obtained
        if (diagnosisCodeAndText) {
          payload.diagnosisCode = diagnosisCodeAndText?.code;
          payload.diagnosisText = diagnosisCodeAndText?.text;
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitLaboratoryOrders(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error(e);
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key].required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm(data) {
      if (!data) return;
      record.value = data;
      // model.tests = intersectionWith(laboratoryTests.value, data.tests, (i, v) => i?.id === v?.id);
      const testFound = laboratoryTests.value.find(i => i?.id === data?.tests?.[0]?.id);
      model.tests = testFound;
      const icd10Found = icd10codes.value.find(i => i?.code === data?.diagnosisCode);
      model.diagnosisCodeAndText = icd10Found;
      model.reason = data.reason;
    }

    return {
      laboratoryTests,
      icd10codes,
      loading,
      model,
      recordId,
      submitText,
      isEditing,
      isObject,
      formFields: FORM_FIELDS,
      formSubmittable,
      search,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
