<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Gravidity</span>
          <span v-if="isNumber(gravidity)" class="text-sm text-neutral-500">{{ gravidity }}</span>
          <span v-else class="text-sm text-neutral-500">0</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Parity</span>
          <span v-if="isNumber(parity)" class="text-sm text-neutral-500">{{ parity }}</span>
          <span v-else class="text-sm text-neutral-500">0</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Full-term Deliveries</span>
          <span v-if="isNumber(fulltermCount)" class="text-sm text-neutral-500">{{ fulltermCount }}</span>
          <span v-else class="text-sm text-neutral-500">0</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Premature Births</span>
          <span v-if="isNumber(prematureCount)" class="text-sm text-neutral-500">{{ prematureCount }}</span>
          <span v-else class="text-sm text-neutral-500">0</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Abortions</span>
          <span v-if="isNumber(abortionCount)" class="text-sm text-neutral-500">{{ abortionCount }}</span>
          <span v-else class="text-sm text-neutral-500">0</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Live Children</span>
          <span v-if="isNumber(liveChildrenCount)" class="text-sm text-neutral-500">{{ liveChildrenCount }}</span>
          <span v-else class="text-sm text-neutral-500">0</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Stillbirths</span>
          <span v-if="isNumber(stillbornLosses)" class="text-sm text-neutral-500">{{ stillbornLosses }}</span>
          <span v-else class="text-sm text-neutral-500">0</span>
        </div>
        <div class="grid grid-cols-2 gap-2 col-span-full">
          <span class="text-sm font-medium">Pregnancy-induced Hypertension?</span>
          <span class="text-sm text-neutral-500">{{yesOrNo(withPregnancyInducedHypertension) || '-'}}</span>
        </div>
        <div class="grid grid-cols-2 gap-2 col-span-full">
          <span class="text-sm font-medium">Had family-planning counselling?</span>
          <span class="text-sm text-neutral-500">{{yesOrNo(withFamilyPlanningCounselling) || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Notes</span>
          <span class="text-sm text-neutral-500">{{notes || '-'}}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useObstetricHistory } from './composables';
import { isNumber } from 'lodash';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeObstetricHistory } = useObstetricHistory();
    const gravidity = computed(() => props.record.gravidity);
    const parity = computed(() => props.record.parity);
    const fulltermCount = computed(() => props.record.fulltermCount);
    const prematureCount = computed(() => props.record.prematureCount);
    const abortionCount = computed(() => props.record.abortionCount);
    const liveChildrenCount = computed(() => props.record.liveChildrenCount);
    const withPregnancyInducedHypertension = computed(() => props.record.withPregnancyInducedHypertension);
    const withFamilyPlanningCounselling = computed(() => props.record.withFamilyPlanningCounselling);
    const stillbornLosses = computed(() => {
      const stillbornData = props.record?.pregnancyLosses?.find(v => v?.type === 'stillborn');
      if (!stillbornData) return null;
      return stillbornData?.count;
    });
    const notes = computed(() => props.record.notes);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    const yesOrNo = (ans) => {
      switch (ans) {
        case true:
          return 'Yes';
        case false:
          return 'No';
        default:
          return 'N/A';
      }
    };

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeObstetricHistory({ id: props.record.id });
    }

    return {
      createdAt,
      gravidity,
      parity,
      fulltermCount,
      prematureCount,
      abortionCount,
      liveChildrenCount,
      withPregnancyInducedHypertension,
      withFamilyPlanningCounselling,
      stillbornLosses,
      notes,
      edit,
      remove,
      yesOrNo,
      isNumber,
    };
  },
};
</script>
