<template>
 <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <template v-for="(field, index) in formFields" :key="index">
          <div class="flex flex-col gap-2">
            <span class="text-sm font-medium">{{field.label}}</span>
            <div class="text-sm text-neutral-500" v-if="field?.field === 'lmp'">
              {{ returnDate(record[field?.field]) || '-' }}
            </div>
            <div class="text-sm text-neutral-500" v-else-if="field.field === 'fetalMovement'">
              <span v-if="record[field?.field]" class="text-sm">{{ record[field.field]?.frequency }} fetal movements {{ frequencyText }}</span>
              <span v-else class="text-sm text-neutral-500">-</span>
            </div>
            <div v-else-if="field.field === 'fundalHeight'">
              <span v-if="record[field?.field]" class="text-sm">{{ record[field.field] }} cm</span>
              <span v-else class="text-sm text-neutral-500">-</span>
            </div>
            <div v-else-if="field.field === 'gestationalAgeInWeeks'">
              <span v-if="record[field?.field]" class="text-sm">{{ record[field.field] }} weeks</span>
              <span v-else class="text-sm text-neutral-500">-</span>
            </div>
            <div v-else-if="field.field === 'fetalHeartRate'">
              <span v-if="record[field?.field]" class="text-sm">{{ record[field.field] }} bpm</span>
              <span v-else class="text-sm">-</span>
            </div>
            <span class="text-sm text-neutral-500" v-else-if="field.type === 'boolean'">
              {{ yesOrNo(record[field?.field]) }}
            </span>
            <div class="text-sm text-neutral-500" v-else>
              <span v-if="record[field?.field]" class="text-sm">
                {{record[field?.field]}}
              </span>
              <span v-else class="text-sm">-</span>
            </div>
          </div>
        </template>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { FORM_FIELDS } from './constants';
import { useObNote } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeObNote } = useObNote();
    const frequencyText = computed(() => {
      const interval = props.record?.fetalMovement?.interval;
      switch (interval) {
        case 'hourly':
          return 'in one hour';
        case 'weekly':
          return 'in one week';
        case 'daily':
          return 'in one day';
        case 'monthly':
          return 'in one month';
        default:
          return 'Unknown interval'; // You can customize this for other cases if needed
      }
    });
    const returnDate = (date) => {
      if (!date) return null;
      return format(new Date(date), 'MM/dd/yyyy');
    };
    const yesOrNo = (ans) => {
      const answer = ans;
      switch (answer) {
        case true:
          return 'Yes';
        case false:
          return 'No';
        default:
          return 'N/A';
      }
    };
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeObNote({ id: props.record.id });
    }

    function formatDate (fieldType) {
      const date = props.record[fieldType];
      if (!date) return '';
      return format(new Date(date), 'MM/dd/yyyy');
    }

    return {
      createdAt,
      frequencyText,
      formFields: FORM_FIELDS,
      returnDate,
      yesOrNo,
      edit,
      remove,
      format,
      formatDate,
    };
  },
};
</script>
