<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="form-control">
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useMedCertificate } from './composables';

const loading = ref(false);

const record = ref({});
const recordId = computed(() => record.value.id);
const isEditing = computed(() => !!recordId.value);

const model = ref({});

const formSubmittable = computed(() => {

});

function resetForm () {

}

function setForm (data) {

}
</script>
