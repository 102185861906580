// import { MEDICAL_CONDITIONS } from '../shared/constants';
import { past50Years } from '../shared/utils';

export const RECORD_TYPE = 'medical-history';

export const MEDICAL_CONDITIONS = [
  { label: 'Acid Reflux', type: '' },
  { label: 'Alcohol Addiction', type: '' },
  { label: 'Allergies', type: 'allergy' },
  { label: 'Asthma / Chronic Obstructive Pulmonary Disease', type: '' },
  { label: 'Alternative Therapies', type: '' },
  { label: 'Anemia', type: '' },
  { label: 'Anxiety', type: '' },
  { label: 'Artery/Vein Problems', type: '' },
  { label: 'Arthritis', type: '' },
  { label: 'Autoimmune Disease', type: '' },
  { label: 'Birth History', type: 'birth-history' },
  { label: 'Bladder Irritability', type: '' },
  { label: 'Bleeding Problems', type: '' },
  { label: 'Blood Clots', type: '' },
  { label: 'Cancer', type: '' },
  { label: 'Cataracts', type: '' },
  { label: 'Colitis / Crohns', type: '' },
  { label: 'Congenital Anomaly', type: '' },
  { label: 'Current Medications', type: '' },
  { label: 'Depression', type: '' },
  { label: 'Diabetes', type: '' },
  { label: 'Diet', type: '' },
  { label: 'Drug Addiction', type: '' },
  { label: 'Esophagitis, Ulcers', type: '' },
  { label: 'Family History', type: 'family-history' },
  { label: 'Fractures', type: '' },
  { label: 'Gallstones', type: '' },
  { label: 'General State of Health', type: '' },
  { label: 'Glaucoma', type: '' },
  { label: 'Gout', type: '' },
  { label: 'Growth And Development', type: '' },
  { label: 'Gynecologic History', type: '' },
  { label: 'Headaches', type: '' },
  { label: 'Hearing Impairment', type: '' },
  { label: 'Heart Attack', type: '' },
  { label: 'Heart Disease', type: '' },
  { label: 'Heart Valve Problems', type: '' },
  { label: 'Hepatitis', type: '' },
  { label: 'High Blood Pressure', type: '' },
  { label: 'High Cholesterol', type: '' },
  { label: 'HIV', type: '' },
  { label: 'Hospitalizations', type: 'hospitalization-history' },
  { label: 'Hypertension', type: '' },
  { label: 'Immunizations', type: 'vaccination' },
  { label: 'Injuries/Accidents', type: '' },
  { label: 'Irritable Bowel', type: '' },
  { label: 'Kidney Disease', type: '' },
  { label: 'Kidney Stones', type: '' },
  { label: 'Liver Disease', type: '' },
  { label: 'Lung Disease', type: '' },
  { label: 'Medication History', type: 'medication-history' },
  { label: 'Mental Status', type: '' },
  { label: 'Migraines', type: '' },
  { label: 'MRSA', type: '' },
  { label: 'Menstrual Hx', type: '' },
  { label: 'Obstetric History', type: '' },
  { label: 'Osteoporosis', type: '' },
  { label: 'PTB', type: '' },
  { label: 'PTSD', type: '' },
  { label: 'Recurrent Skin Infections', type: '' },
  { label: 'Recurrent UTI', type: '' },
  { label: 'Seizures', type: '' },
  { label: 'Sleep', type: '' },
  { label: 'Sleep Apnea', type: '' },
  { label: 'Social History', type: 'social-history' },
  { label: 'Stroke (CVA)', type: '' },
  { label: 'Substance Abuse', type: '' },
  { label: 'Surgeries', type: 'surgical-history' },
  { label: 'TB', type: '' },
  { label: 'Thyroid Problem', type: '' },
  { label: 'Travel History', type: 'travel-history' },
  { label: 'Vision Impairment', type: '' },
  { label: 'Others', type: '' },
];

export const FORM_FIELDS = [
  {
    label: 'Medical Condition',
    field: 'medicalCondition',
    update: true,
    type: 'select',
    options: MEDICAL_CONDITIONS,
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Date Occured',
    field: 'medicalConditionAt',
    update: true,
    type: 'select',
    options: past50Years(),
    rules: {
      required: true,
      type: 'number',
    },
  },
  {
    label: 'Notes',
    field: 'notes',
    update: true,
    placeholder: 'Provide pertinent medical history (e.g. Patient has history of smoking.)',
    type: 'textarea',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
