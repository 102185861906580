<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <template v-for="(field, index) in formFields" :key="index">
          <div class="flex flex-col gap-2">
            <span class="text-sm font-medium">{{ field.label }}</span>
            <span class="text-sm text-neutral-500" v-if="field.field === 'startOfVisit' || field.field === 'endOfVisit'">{{
                new Date(record[field.field]).toDateString()
              }}</span>
            <span class="text-sm text-neutral-500" v-else>{{ record[field.field] }}</span>
          </div>
        </template>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { FORM_FIELDS } from './constants';
import { useTravelHistory } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeTravelHistory } = useTravelHistory();

    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeTravelHistory({ id: props.record.id });
    }

    return {
      createdAt,
      formFields: FORM_FIELDS,
      edit,
      remove,
    };
  },
};
</script>
