<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Date Performed</span>
          <span class="text-sm text-neutral-500">{{performedAt}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Hospital Name</span>
          <span class="text-sm text-neutral-500">{{hospitalName || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Procedure</span>
          <span class="text-sm text-neutral-500">{{procedure || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Reason</span>
          <span class="text-sm text-neutral-500">{{reason || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Operation Time</span>
          <span class="text-sm text-neutral-500">{{operationTime || '-'}} hours</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Length of Stay</span>
          <span v-if="lengthOfStayInDays" class="text-sm text-neutral-500">{{lengthOfStayInDays || '-'}} days</span>
          <span v-else class="text-neutral-500">-</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Preoperative Complications</span>
          <span class="text-sm text-neutral-500">{{preComplications || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Postoperative Complications</span>
          <span class="text-sm text-neutral-500">{{postComplications || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Notes</span>
          <span class="text-sm text-neutral-500">{{notes || '-'}}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useSurgicalHistory } from './composables';
import { millisecondsToHours } from '@/utils/math';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeSurgicalHistory } = useSurgicalHistory();
    const performedAt = computed(() => {
      if (!props.record.performedAt) return;
      const date = new Date(props.record.performedAt);
      return format(date, 'MMM d, yyyy');
    });
    const procedure = computed(() => props.record.procedure);
    const hospitalName = computed(() => props.record.hospitalName);
    const reason = computed(() => props.record.reason);
    const operationTime = computed(() => millisecondsToHours(props.record.operationTimeInMillis));
    const lengthOfStayInDays = computed(() => props.record.lengthOfStayInDays);
    const preComplications = computed(() => (props.record.complications && props.record.complications?.length > 0)
      ? props.record.complications.filter(i => i.type === 'pre').map(i => i.name).join(', ')
      : null);
    const postComplications = computed(() => (props.record.complications && props.record.complications?.length > 0)
      ? props.record.complications.filter(i => i.type === 'post').map(i => i.name).join(', ')
      : null);
    const notes = computed(() => props.record.notes);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeSurgicalHistory({ id: props.record.id });
    }

    return {
      createdAt,
      performedAt,
      procedure,
      hospitalName,
      reason,
      operationTime,
      lengthOfStayInDays,
      preComplications,
      postComplications,
      notes,
      edit,
      remove,
    };
  },
};
</script>
