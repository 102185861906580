<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-full`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField?.rules?.required" class="text-error">*</span></span>
            </span>
          </div>
          
          <template v-if="formField.type === 'select'">
            <template v-if="orders.length">
              <select v-model="model[formField.field]" class="select select-bordered">
                <option value="" disabled selected>Select a test</option>
                <option
                  v-for="(option, index) in orders"
                  :key="index"
                  :value="option"
                >
                  {{ stringifyTests(option.tests) }}
                </option>
              </select>
            </template>
            <template v-else>
              <div class="flex flex-col gap-2 items-center text-center border-[1px] border-neutral-300 text-neutral-400 rounded-md p-4">
                No lab orders found. Kindly add a lab order first!
                <nuxt-link 
                  type="button" 
                  class="btn btn-sm btn-neutral w-[200px]"
                  @click="onGoToPlan"
                >
                  Create a Lab Order here
                </nuxt-link>
              </div>
            </template>
          </template>

          <template v-if="formField.field === 'results'">
            <template v-if="resultsMap.length">
              <template v-for="(testGroup, index) in resultsMap" :key="index">
                <div class="flex flex-col items-center w-full py-2">
                  <span class="text-md text-secondary">{{ testGroup?.testName || '-' }}</span>
                  <table class="table table-fixed table-xs table-bordered border-black border-[1px]">
                    <thead>
                      <tr class="border-[1px] border-black">
                        <th class="border-[1px] border-black w-[300px] p-0 pl-1 text-start font-semibold text-black">Test</th>
                        <th class="border-[1px] border-black p-0 pl-1 text-start font-semibold text-black">Result</th>
                        <th class="border-[1px] border-black w-[300px] p-0 pl-1 text-start font-semibold text-black">Reference Values / Units</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(measure, index) in testGroup?.measures" :key="index">
                        <tr>
                          <td class="py-0 border-[1px] border-black">{{ measure.name }}</td>
                          <td class="p-0 border-[1px] border-black">
                            <input v-if="measure.type !== 'posneg'" v-model="measure.result" class="input input-sm w-full focus:outline-none basis-full rounded-none bg-[#FFDA9D]"/>
                            <template v-else>
                              <select v-model="measure.result" class="select select-sm w-full focus:outline-none basis-full rounded-none bg-[#FFDA9D]">
                                <option value="Positive">Positive</option>
                                <option value="Negative">Negative</option>
                              </select>
                            </template>
                          </td>
                          <td class="py-0 border-[1px] border-black">
                            <span v-if="measure.type === 'numeric' && measure?.referenceRanges?.[0]?.min">{{ measure?.referenceRanges[0]?.min }}-{{ measure?.referenceRanges[0]?.max }} {{ measure?.unit || '' }}</span>
                            <span v-if="measure.type === 'numeric' && !measure?.referenceRanges?.[0]?.min">{{ measure?.unit || '' }}</span>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="text-center border-[1px] border-neutral-300 text-neutral-400 rounded rounded-md p-4">
                Your results will show here. Kindly select a lab order first!
              </div>
            </template>
          </template>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ submitText }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { computed, reactive, ref, watch } from 'vue';
import { isEmpty, cloneDeep, flatten, groupBy } from 'lodash';
import { useLaboratoryResults } from './composables';
import { useRouter, useRoute } from 'vue-router';
import PrimeDropdown from 'primevue/dropdown';

export default {
  props: {
    showLabel: Boolean,
  },
  components: {
    PrimeDropdown,
  },
  setup (props, { emit }) {
    const {
      // medicalRecords,
      orders,
      submit: submitLaboratoryResult,
      searchICD,
      getPopulatedTests,
    } = useLaboratoryResults();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);
    const submitText = computed(() => isEditing.value ? 'Save Changes' : 'Submit');
    const resultsMap = ref([]);
    const results = reactive({
      testResults: {},
    });
    const isObject = (option) => isObject(option);

    const model = reactive(FORM_MODEL);
    watch(model, async (newVal) => {
      // hypothetically populate order based on if the newVal selected is different
      if (!newVal?.order) return;
      const tests = newVal?.order?.tests;
      if (!tests) return;
      const mappedTests = await getPopulatedTests(tests);
      const flatMapTests = groupBy(flatten(mappedTests), 'test');
      const fullymappedOrders = model?.order?.tests.map(i => {
        if (!flatMapTests[i?.id]) return null;
        return { testName: i?.name, testId: i?.id, measures: [...flatMapTests[i?.id]] };
      });
      resultsMap.value = fullymappedOrders;
      if (!isEmpty(results.testResults)) {
        resultsMap.value.forEach((test, index) => {
          const matchingTest = results?.testResults?.find(i => i?.testId === test?.testId);
          if (!matchingTest) return;
          test.measures = test?.measures.map(measure => {
            const matchingTestResults = matchingTest?.results.find(v => v?.name === measure?.name);
            if (!matchingTestResults) return { ...measure };
            const result = matchingTestResults?.result;
            return { ...measure, result };
          });
        });
      }
    }, { deep: true });
    const populateICD10 = searchICD();
    const stringifyTests = (tests) => {
      if (!tests || !tests?.length || tests?.length === 0) return null;
      return tests?.map(i => i?.name).join(', ');
    };

    const submit = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        );
        payload.order = payload?.order?.id;
        if (!payload?.order) throw new Error('Something went wrong! Try again.');
        payload.results = resultsMap.value.map(i => ({
          testName: i?.testName,
          testId: i?.testId,
          results: i?.measures.map(v => ({ name: v?.name, result: v?.result })),
        }));
        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
          delete payload.order; // order should not be updated
        }
        // Create
        await submitLaboratoryResult(payload);
        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key].required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
      results.testResults = {};
      clearResults();
    }

    function clearResults () {
      if (!resultsMap.value.length) return;
      resultsMap.value.forEach(testGroup => {
        if (testGroup.measures && Array.isArray(testGroup.measures)) {
          testGroup.measures.forEach(measure => {
            if (measure.type !== 'posneg') {
              measure.result = ''; // Clearing the result for non 'posneg' types
            } else {
              measure.result = ''; // or any default value for 'posneg' type
            }
          });
        }
      });
      resultsMap.value = ref([]);
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      const ordersList = computed(() => {
        return orders.value.filter(i => i?.id === data.order)?.[0];
      });
      model.order = ordersList.value;
      results.testResults = cloneDeep(data.results);
    }

    const router = useRouter();
    const route = useRoute();

    function onGoToPlan () {
      router.push({ name: 'encounter-plan', params: route.parmas, query: route.query });
    }

    return {
      loading,
      model,
      orders,
      resultsMap,
      results,
      recordId,
      submitText,
      isEditing,
      isObject,
      stringifyTests,
      formFields: FORM_FIELDS,
      formSubmittable,
      populateICD10,
      resetForm,
      submit,
      setForm,
      onGoToPlan,
    };
  },
};
</script>
