<script setup>
import { computed } from 'vue';

const emit = defineEmits(['edit', 'remove']);
const props = defineProps({
  record: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  hideOptions: {
    type: Boolean,
    default: false,
  },
  showRecordLabel: {
    type: Boolean,
    default: false,
  },
});

const diagnosis = computed(() => props.record.diagnosis);
const text = computed(() => props.record.text);
const diagnosisCode = computed(() => props.record?.diagnosisCode);
const diagnosisText = computed(() => props.record?.diagnosisText);
const icd10Formatted = computed(() => {
  if (diagnosisCode.value && diagnosisText.value) {
    return `${diagnosisCode.value} - ${diagnosisText.value}`;
  }
  return '-';
});
const recommendedProcedures = computed(() => props.record.recommendedProcedures);
</script>


<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col col-span-1 gap-2">
      <span class="text-sm font-medium">Diagnosis</span>
      <span class="text-sm text-neutral-500">{{diagnosis || '-'}}</span>
    </div>

    <div class="flex flex-col col-span-1 gap-2">
      <span class="text-sm font-medium">ICD10</span>
      <span class="text-sm text-neutral-500">{{icd10Formatted || '-'}}</span>
    </div>
    
    <div class="flex flex-col col-span-1 gap-2">
      <span class="text-sm font-medium">CPT Codes</span>
      <template v-for="cpt in recommendedProcedures">
        <span class="text-sm text-neutral-500">{{cpt.coding.code}} - {{cpt.coding.text}}</span>
      </template>
    </div>

    <div class="flex flex-col col-span-1 gap-2">
      <span class="text-sm font-medium">Notes</span>
      <span class="text-sm text-neutral-500">{{text || '-'}}</span>
    </div>
  </div>
</template>

