<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-${formField.type === 'textarea' ? 'full' : '1'}`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField?.rules?.required" class="text-error">*</span></span>
            </span>
          </div>
          <template v-if="formField.type === 'select'">
            <select
              v-model="model[formField.field]"
              class="select select-bordered w-full focus:select-neutral"
              :name="formField.label"
              :disabled="loading"
              :required="formField.rules.required"
            >
              <option value="" selected disabled>{{ formField.placeholder }}</option>
              <template v-for="(option, index) in formField.options" :key="index">
                <option :value="option">{{option}}</option>
              </template>
            </select>
          </template>
          <template v-else-if="formField.type === 'textarea'">
            <textarea
              v-model="model[formField.field]"
              :placeholder="formField.placeholder"
              class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
            ></textarea>
          </template>
          <template v-else>
            <input
              v-if="formField.type !== 'date'"
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Input Text'"
              class="flex-auto input input-bordered focus:input-neutral"
              :type="formField.text"
              :disabled="loading"
            />
            <ClientOnly v-else>
              <VDatePicker v-model.string="model[formField.field]" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }">
                <template #default="{ togglePopover }">
                  <input type="text" :placeholder="formField.placeholder" class="input input-bordered w-full focus:input-neutral"
                    v-model="model[formField.field]" @click="togglePopover" />
                </template>
              </VDatePicker>
            </ClientOnly>
          </template>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        Submit
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { computed, reactive, ref } from 'vue';
import { useBirthHistory } from './composables';

export default {
  props: {
    showLabel: Boolean,
    patientId: String,
    encounterId: String,
    facilityId: String,
  },
  setup (props, { emit }) {
    const { submit: submitBirthHistory } = useBirthHistory();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);

    const submit = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        );

        // if (model.deliveredAt) {
        //   payload.deliveredAt = getUnixTimestampAtStartOfYear(model.deliveredAt);
        // }
        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }
        // Create
        await submitBirthHistory(payload, { encounterId: props.encounterId, patientId: props.patientId, facilityId: props.facilityId });
        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key].required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.methodOfDelivery = data.methodOfDelivery;
      model.deliveredAt = data.deliveredAt;
      model.attendedBy = data.attendedBy;
      model.complications = data.complications;
      model.notes = data.notes;
    }

    return {
      loading,
      model,
      recordId,
      isEditing,
      formFields: FORM_FIELDS,
      formSubmittable,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
