import _ from 'lodash';

export const subtract = (a, b) => {
  if (!_.isFinite(a)) return 0;
  if (!_.isFinite(b)) return 0;
  return +a - +b;
};

export const add = (a, b) => {
  if (!_.isFinite(a) && !_.isFinite(b)) return 0;
  if (!_.isFinite(a)) return +b;
  if (!_.isFinite(b)) return +a;
  return +a + +b;
};

/**
 * Rounds a number to two decimal places.
 *
 * Because Math.round is only capable of rounding to the nearest integer,
 * roundToTwoDecimalPlaces multiplies and divides by powers of ten to ensure
 * that the proper place value is rounded.
 *
 * Because of the inherent error in floating-point computation,
 * roundToTwoDecimalPlaces actually first rounds to three decimal places before
 * then rounding to two decimal places. This catches cases similar to e.g. 1.005
 * coming out to 100.4999..., causing it to be rounded ultimately to 1.00
 * instead of 1.01.
 *
 * @example
 * console.log(roundToTwoDecimalPlaces(1.005));
 * // 1.01
 * console.log(roundToTwoDecimalPlaces(299.792458));
 * // 299.79
 *
 * @param {number} n - Number to be rounded.
 * @return {number} - The rounded value.
 */
export function roundToTwoDecimalPlaces (n) {
  // change mantissa place value to begin at hundreds place
  // e.g. 0.3454 -> 345.4
  // so Math.round will round to three decimal places
  const mantissaToHundreds = n * 1000 % 1000;
  // round mantissaToHundreds to nearest integer
  // which represents the original mantissa rounded to three decimal places
  // e.g. 345.4 -> 345, representing 0.3454 -> 0.345
  const roundedMantissaToHundreds = Math.round(mantissaToHundreds);
  // change roundedMantissaToHundreds to begin at tens place
  // e.g. 345 -> 34.5
  // so Math.round will round to two decimal places
  const mantissaToTens = roundedMantissaToHundreds / 10;
  // round mantissaToTens to nearest integer
  // which represents the original mantissa rounded to two decimal places
  // e.g. 34.5 -> 35, representing 0.3454 -> 0.35
  const roundedMantissaToTens = Math.round(mantissaToTens);
  // change roundedMantissaToTens back to original place value
  // e.g. 35 -> 0.35
  const roundedMantissa = roundedMantissaToTens / 100;
  // add roundedMantissa to integer part of n
  return Math.floor(n) + roundedMantissa;
}
