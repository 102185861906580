import { computed } from 'vue';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { usePatient } from '@/composables/use-patients';
import { useEncounters } from '@/composables/use-encounters';
import { RECORD_TYPE } from './constants';

export function useTravelHistory () {
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
  } = useMedicalRecords();

  const { patient } = usePatient();
  const { encounter } = useEncounters();

  const records = computed(() => medicalRecords.value.filter((record) => record.type === RECORD_TYPE));

  const submit = async (data, opts) => {
    if (data?.id) {
      const id = data.id;
      delete data.id;
      await updateMedicalRecord(id, data);
    } else {
      data.type = RECORD_TYPE;
      data.encounter = opts.encounterId || encounter.value?.id;
      data.patient = opts.patientId || patient.value?.id;
      await createMedicalRecord(data, { facilityId: opts.facilityId });
    }
  };

  const remove = async ({ id }) => {
    await removeMedicalRecord(id);
  };

  return {
    medicalRecords,
    records,
    submit,
    remove,
  };
}
