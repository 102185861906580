import { computed, reactive, toRefs } from 'vue';
import { RECORD_TYPE, UPDATE_FIELDS } from './constants';
import { useEncounters } from '@/composables/use-encounters';
import { useFacilities } from '@/composables/use-facilities';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { usePatient } from '@/composables/use-patients';
import { useSdk } from '@/composables/use-mycure';

function mapAllergies (records) {
  // return unique allergy names
  const allergies = records.reduce((acc, record) => {
    const { name } = record;
    if (!acc.includes(name)) {
      acc.push(name);
    }
    return acc;
  }, []);

  return allergies;
}

const state = reactive({
  allergies: [],
});

export function useAllergyHistory () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();

  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
  } = useMedicalRecords();

  const { patient } = usePatient();
  const { encounter } = useEncounters();

  const records = computed(() => {
    return medicalRecords.value.filter((record) => record.type === RECORD_TYPE)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  async function listAllergies () {
    const patientId = patient.value?.id;
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    const query = {
      type: RECORD_TYPE,
      patient: patientId,
      facility: facilityId,
      $sort: { createdAt: -1 },
    };
    const res = await sdk?.list('medical-records', query);
    state.allergies = mapAllergies(res.data || []);
  }

  const submit = async (payload, opts) => {
    if (payload?.id) {
      const id = payload.id;
      payload = Object.keys(payload).reduce((acc, key) => {
        if (UPDATE_FIELDS.includes(key)) {
          acc[key] = payload[key];
        }
        if (key === 'reactions') {
          acc[key] = payload[key];
        }
        return acc;
      }, {});
      await updateMedicalRecord(id, payload);
    } else {
      payload.type = RECORD_TYPE;
      payload.encounter = opts.encounterId || encounter.value?.id;
      payload.patient = opts.patientId || patient.value?.id;
      await createMedicalRecord(payload, { facilityId: opts.facilityId });
    }
  };

  const remove = async ({ id }) => {
    await removeMedicalRecord(id);
  };

  return {
    medicalRecords,
    records,
    ...toRefs(state),
    listAllergies,
    submit,
    remove,
  };
}
