export const RECORD_TYPE = 'mental-status';
export const FORM_FIELDS = [
  // APPEARANCE AND BEHAVIOR
  {
    group: 'Appearance and behavior',
    label: 'Dress and grooming',
    field: 'dressAndGrooming',
    options: [
      'Clean',
      'Neat',
      'Shaven',
      'Body odor',
      'Disheveled',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Appearance and behavior',
    label: 'Posture',
    field: 'posture',
    options: [
      'Symmetrical',
      'Slouched',
      'Tense/Rigid',
      'Balanced and Steady',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Appearance and behavior',
    label: 'Eye contact',
    field: 'eyeContact',
    options: [
      'Appropriate',
      'Decreased',
      'Heightened',
      'Avoidant',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Appearance and behavior',
    label: 'Speech patterns',
    field: 'speechPatterns',
    options: [
      'Normal',
      'Tangential',
      'Pressured',
      'Impoverished',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  // MOOD AND AFFECT
  {
    group: 'Mood and affect',
    label: 'Current mood',
    field: 'currentMood',
    options: [
      'Depressed',
      'Sad',
      'Angry',
      'Irritable',
      'Good',
      'Fantastic',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Mood and affect',
    label: 'Emotional expressions',
    field: 'emotionalExpressions',
    options: [
      'Broad',
      'Restricted',
      'Flat',
      'Labile',
      'Anhedonic',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Mood and affect',
    label: 'Appropriateness of mood and affect',
    field: 'appropriatenessOfMoodAndAffect',
    options: [
      'Appropriate',
      'Incongruent',
      'Restricted',
      'Labile',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  // THOUGHT PROCESS AND CONTENT
  {
    group: 'Thought process and content',
    label: 'Attention and Concentration',
    field: 'attentionAndConcentration',
    options: [
      'Goal-directed',
      'Impoverished',
      'Rapid',
      'Illogical',
      'Incoherent',
      'Distractible',
      'Blocking',
      'Circumstantial',
      'Perseverative',
      'Tangential',
      'Loose',
      'Flight of ideas',
      'Word salad',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Thought process and content',
    label: 'Memory',
    field: 'memory',
    options: [
      'No impairment',
      'Short-term impairment',
      'Long-term impairment',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Thought process and content',
    label: 'Problem-solving skills',
    field: 'problemSolvingSkills',
    options: [
      'Good',
      'Fair',
      'Poor',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Thought process and content',
    label: 'Thought content (i.e, delusions, hallucinations)',
    field: 'thoughtContent',
    options: [
      'Normal',
      'Suicidality',
      'Delusions',
      'Homicidality',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  // JUDGMENT AND INSIGHT
  {
    group: 'Judgment and insight',
    label: 'Ability to make sound decisions',
    field: 'abilityToMakeSoundDecisions',
    options: [
      'Good',
      'Fair',
      'Poor',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Judgment and insight',
    label: 'Understanding of Diagnosis and Treatment Plan',
    field: 'understandingOfDiagnosisAndTreatmentPlan',
    options: [
      'Reliable',
      'Unreliable',
    ],
    update: true,
    model: {
      code: '',
      text: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const UPDATE_FIELDS = FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field);

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = [field.model];
  return acc;
}, {});

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
