<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex flex-col">
      <hr class="my-4">
      <div class="flex flex-col gap-6 mb-1">
        <div class="flex justify-between items-center">
          <span>All:</span>
          <div class="flex gap-2">
            <div class="join">
              <button
                type="button"
                class="btn btn-sm join-item font-medium"
                :class="{ '!font-bold text-primary': allStatus === 'not asked' }"
                @click="allStatus = 'not asked'"
              >
                Not Asked
              </button>
              <button
                type="button"
                class="btn btn-sm join-item font-medium"
                :class="{ '!font-bold text-primary': allStatus === 'none' }"
                @click="allStatus = 'none'"
              >
                None
              </button>
              <button
                type="button"
                class="btn btn-sm join-item font-medium"
                :class="{ '!font-bold text-primary': allStatus === 'positive' }"
                @click="allStatus = 'positive'"
              >
                Positive
              </button>
            </div>
            <div class="w-[200px]"></div>
          </div>
        </div>
      </div>
      <template v-for="(formField, index) in formFields" :key="index">
        <div class="flex flex-col gap-2 mb-1">
          <div class="flex justify-between items-center">
            <span>{{formField.label}}:</span>
            <div class="flex gap-2">
              <div class="join">
                <button
                  type="button"
                  class="btn btn-sm join-item font-medium"
                  :class="{ '!font-bold text-primary': model[formField.field].status === 'not asked' }"
                  @click="model[formField.field].status = 'not asked'"
                >
                  Not Asked
                </button>
                <button
                  type="button"
                  class="btn btn-sm join-item font-medium"
                  :class="{ '!font-bold text-primary': model[formField.field].status === 'none' }"
                  @click="model[formField.field].status = 'none'"
                >
                  None
                </button>
                <button
                  type="button"
                  class="btn btn-sm join-item font-medium"
                  :class="{ '!font-bold text-primary': model[formField.field].status === 'positive' }"
                  @click="model[formField.field].status = 'positive'"
                >
                  Positive
                </button>
              </div>
              <textarea
                v-model="model[formField.field].text"
                type="text"
                class="textarea textarea-sm textarea-bordered w-[400px]"
                style="height: 10px;"
                rows="1"
                :placeholder="`${formField.label} remarks`"
                :disabled="model[formField.field].status !== 'positive'"
              ></textarea>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { useToNumber } from '@vueuse/core';
import {
  UPDATE_FIELDS,
  FORM_FIELDS,
  FORM_MODEL,
  PAIN_WARNING_THRESHOLD,
  PAIN_ERROR_THRESHOLD,
} from './constants';
import { usePhysicalExam } from './composables';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitPe } = usePhysicalExam();
    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);
    const painAssessmentScale = ref(0);
    const allStatus = ref('');

    const painWarningThreshold = PAIN_WARNING_THRESHOLD;
    const painErrorThreshold = PAIN_ERROR_THRESHOLD;

    const formSubmittable = computed(() => {
      return validate(model);
    });

    watch(allStatus, (value) => {
      if (value) {
        FORM_FIELDS.forEach(field => {
          model[field.field].status = value;
        });
      }
    });

    watch(model, (value) => {
      // reset .text field if status is not positive or none
      FORM_FIELDS.forEach(field => {
        if (value[field.field].status !== 'positive') {
          value[field.field].text = '';
        }
      });
    }, { deep: true });

    function validate (obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const { text, status } = obj[key] || {};
          if (text || status) {
            return true;
          }
        }
      }
      return false;
    }

    const submit = async () => {
      try {
        const isValid = await validate(model);

        if (!isValid) return;
        const payload = { ...model, painAssessmentScale: useToNumber(painAssessmentScale).value };

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitPe(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      painAssessmentScale.value = 0;
      UPDATE_FIELDS.forEach(field => {
        model[field] = {
          text: '',
          status: '',
        };
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      painAssessmentScale.value = data.painAssessmentScale;
      const fields = Object.keys(data);
      fields.forEach(field => {
        model[field] = data[field];
      });
    }

    function getPainAssementColor (index) {
      if (index >= painErrorThreshold) {
        return 'error';
      } else if (index >= painWarningThreshold) {
        return 'warning';
      } else {
        return 'success';
      }
    }

    return {
      loading,
      model,
      recordId,
      formSubmittable,
      isEditing,
      painAssessmentScale,
      allStatus,
      // pain
      // painMin,
      // painMax,
      // painWarningThreshold,
      // painErrorThreshold,
      // painTexts,
      formFields: FORM_FIELDS,
      resetForm,
      submit,
      setForm,
      getPainAssementColor,
    };
  },
};
</script>
