<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <template v-for="(field, index) in formFields" :key="index">
          <div v-if="record[field.field] && field.field === 'foodFrequencyMap'" class="flex flex-col col-span-2 gap-2">
            <span class="text-sm font-medium">{{field.label}}</span>
            <div class="grid grid-cols-2 gap-2">
              <div class="flex flex-col col-span-1" v-for="(food, label) in record[field.field]" :key="label">
                <span class="text-sm truncate text-neutral-500">{{ capitalize(label) }} / {{ food.unit }}</span>
                <div class="flex gap-2 text-neutral-500">
                  <div class="flex flex-col">
                    <span class="text-xs">Min</span>
                    <span class="text-xs">{{ food.min }}</span>
                  </div>
                  <div class="flex flex-col">
                    <span class="text-xs">Max</span>
                    <span class="text-xs">{{ food.max }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="!['dietRX', 'nutritionalAssessment'].includes(field.field)" class="flex flex-col gap-2">
            <span class="text-sm font-medium">{{field.label}}</span>
            <span class="text-sm text-neutral-500">{{record[field.field] || '-'}}</span>
          </div>
          <div v-else class="flex flex-col col-span-full gap-2">
            <span class="text-sm font-medium">{{field.label}}</span>
            <span class="text-sm text-neutral-500">{{record[field.field] || '-'}}</span>
          </div>
        </template>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format, fromUnixTime } from 'date-fns';
import { FORM_FIELDS } from './constants';
import { capitalize } from 'lodash';
import { useNutritionalAssessment } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  props: {
    record: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  setup (props, { emit }) {
    const { remove: removeNutritionalAssessment } = useNutritionalAssessment();
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeNutritionalAssessment({ id: props.record.id });
    }

    return {
      createdAt,
      formFields: FORM_FIELDS,
      edit,
      remove,
      capitalize,
      format,
      fromUnixTime,
    };
  },
};
</script>
