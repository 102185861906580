<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8 max-h-[400px]">
    <div class="grid grid-cols-3 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-1 flex flex-col`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}}
                <span v-if="formField.rules.required" class="text-error text-sm">*</span></span>
            </span>
          </div>
          <template v-if="formField.type === 'select' && formField.field !== 'name'">
            <select v-model="model[formField.field]" class="select select-bordered w-full" :required="formField.rules.required">
              <option value="" selected disabled>{{ formField.placeholder }}</option>
              <template v-for="(option, index) in formField.options" :key="index">
                <option v-if="isObject(option)" :value="option.value">{{ option.name }}</option>
                <option v-else :value="option">{{ option }}</option>
              </template>
            </select>
          </template>
          <template v-if="formField.type === 'select' && formField.field === 'name'">
            <div class="w-full">
              <select v-model="model[formField.field]" class="select select-bordered w-full">
                <option value="" selected disabled>Select Allergy</option>
                <optgroup v-for="(group, index) in mappedAllergies" :key="index" :label="group.text">
                  <option v-for="(option, index) in group.children" :key="index" :value="option.value">{{ option.text }}</option>
                </optgroup>
              </select>
            </div>
          </template>
          <template v-if="formField.type === 'textarea'">
            <textarea
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Type here'"
              class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
            ></textarea>
          </template>
          <template v-if="formField.type === 'text'">
            <div v-if="formField.field === 'allergicReactions'">
              <div class="join">
                <select v-model="reactions.severity" class="select select-bordered w-7/12 join-item">
                  <option value="" selected disabled>e.g. Mild</option>
                  <option value="mild">Mild</option>
                  <option value="moderate">Moderate</option>
                  <option value="severe">Severe</option>
                </select>
                <input
                  v-model="reactions.type"
                  :placeholder="formField.placeholder || 'Input Text'"
                  class="input input-bordered focus:input-neutral w-full join-item"
                  :type="formField.text"
                  :disabled="loading"
                />
              </div>
            </div>
            <input
              v-else
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Input Text'"
              class="input input-bordered focus:input-neutral w-full"
              :type="formField.text"
              :disabled="loading"
            />
          </template>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
        :disabled="!formSubmittable"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ submitText }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { ALLERGY_COLLECTION } from '@/components/medical-records/shared/constants';
import { computed, reactive, ref } from 'vue';
import { getUnixTimestampAtStartOfYear } from '../shared/utils';
import { format } from 'date-fns';
import { useAllergyHistory } from './composables';
import { isObject, isEmpty, isBoolean } from 'lodash';

export default {
  props: {
    showLabel: Boolean,
    patientId: String,
    encounterId: String,
    facilityId: String,
  },
  setup (props, { emit }) {
    const { submit: submitAllergyHistory } = useAllergyHistory();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);
    const submitText = computed(() => isEditing.value ? 'Save Changes' : 'Submit');

    const model = reactive(FORM_MODEL);
    const mappedAllergies = ref(ALLERGY_COLLECTION);
    const reactions = ref({
      type: '',
      severity: '',
    });

    const submit = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value) || isBoolean(value)),
        );

        if (model.startedAt) {
          payload.startedAt = getUnixTimestampAtStartOfYear(model.startedAt);
        }

        if (reactions.value.severity && !((reactions.value.severity !== '') && isEmpty(reactions.value.type))) {
          payload.reactions = [
            reactions.value,
          ];
        } else {
          payload.reactions = [];
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitAllergyHistory(payload, { patientId: props.patientId, encounterId: props.encounterId, facilityId: props.facilityId });

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
      reactions.value = {
        type: '',
        severity: '',
      };
    }

    const formSubmittable = computed(() => {
      if ((reactions.value.severity !== '' && reactions.value.severity !== 'none') && isEmpty(reactions.value.type)) return false;
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key].required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.allergyType = data.allergyType;
      model.name = data.name;
      model.frequency = data.frequency;
      if (data.startedAt) {
        const date = new Date(data.startedAt);
        const year = format(date, 'yyyy');
        model.startedAt = Number(year);
      }
      if (data.reactions) {
        const reactionObj = data.reactions?.[0];
        reactions.value.severity = reactionObj?.severity || '';
        reactions.value.type = reactionObj?.type || '';
      } else {
        reactions.value = {
          type: '',
          severity: '',
        };
      }
      model.isWorseningOverTime = data.isWorseningOverTime;
    }

    return {
      loading,
      reactions,
      model,
      recordId,
      isObject,
      isEditing,
      submitText,
      mappedAllergies,
      formFields: FORM_FIELDS,
      formSubmittable,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: rgb(0, 0, 0);
}
option[value=""][disabled] {
  display: none;
}
option {
  color: rgb(0, 0, 0);
}
</style>
