<script setup>
import { computed, ref, } from 'vue';
import { useChiefComplaint } from './composables';
import { MINOR_AILMENTS } from '@/modules/personal-details/constants';
import EasyMultipleSelect from '@/components/commons/easy/EasyMultipleSelect.vue';

defineExpose({ resetForm, setForm });
const emit = defineEmits(['submit', 'success', 'error']);

const { submit: submitChiefComplaint } = useChiefComplaint();

const loading = ref(false);
const record = ref({});
const recordId = computed(() => record.value.id);
const isEditing = computed(() => !!recordId.value);

const painAssessmentScale = ref(0);

const chiefComplaintOptions = ref(MINOR_AILMENTS);
const chiefComplaints = ref([])

const formSubmittable = computed(() => {
  return chiefComplaints.value.length;
});

function resetForm () {
  chiefComplaints.value = [];
  // painAssessmentScale.value = 0;
}

function serialize () {
  const signsAndSymptoms = chiefComplaints.value.map((complaint) => ({ text: complaint }));
  return {
    signsAndSymptoms,
    // painLevel: Number(painAssessmentScale.value),
  };
}

function setForm (data) {
  record.value = data;
  chiefComplaints.value = data.signsAndSymptoms.map((complaint) => complaint.text);
  // painAssessmentScale.value = data.painLevel;
}

async function submit () {
  try {
    loading.value = true;
    const payload = serialize();
    if (isEditing.value) {
      payload.id = recordId.value;
    }
    await submitChiefComplaint(payload)
    resetForm();
    emit('submit');
    emit('success');
  } catch (e) {
    console.error(e.message);
  } finally {
    loading.value = false;
  }
}
</script>


<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="form-control h-[300px]">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col">
          <EasyMultipleSelect 
            v-model="chiefComplaints"
            label="Chief Complaints"
            required
            :options="chiefComplaintOptions"
          />
        </div>
      </div>
    </div>
    <!-- <template v-for="cc in chiefComplaintOptions">
      <div class="form-control">
        <label class="flex gap-2 justify-start label cursor-pointer">
          <input v-model="chiefComplaints" type="checkbox" checked="checked" class="checkbox" />
          <span class="label-text">{{ cc.label }}</span>
        </label>
      </div>
    </template> -->
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        :disabled="loading"
        :loading="loading"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable || loading"
        :loading="loading"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

