<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Medical Condition</span>
          <span class="text-sm text-neutral-500 p-0">{{medicalCondition}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Date Occured</span>
          <span class="text-sm text-neutral-500">{{medicalConditionAt || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Notes</span>
          <span class="text-sm text-neutral-500">{{notes || '-'}}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useMedicalHistory } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeMedicalHistory } = useMedicalHistory();
    const medicalCondition = computed(() => props.record.medicalCondition);
    const medicalConditionAt = computed(() => {
      if (!props.record.medicalConditionAt) return;
      const date = new Date(props.record.medicalConditionAt);
      return format(date, 'yyyy');
    });
    const notes = computed(() => props.record.notes);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeMedicalHistory({ id: props.record.id });
    }

    return {
      createdAt,
      medicalCondition,
      medicalConditionAt,
      notes,
      edit,
      remove,
    };
  },
};
</script>
