export const RECORD_TYPE = 'physical-exam';
export const FORM_FIELDS = [
  {
    label: 'General Apperance',
    field: 'general',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Eyes',
    field: 'eyes',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Ears',
    field: 'ears',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Nose',
    field: 'nose',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  // {
  //   label: 'Neck',
  //   field: 'neck',
  //   update: true,
  //   model: {
  //     text: '',
  //     status: '',
  //   },
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  {
    label: 'Throat',
    field: 'throat',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  // {
  //   label: 'Respiratory',
  //   field: 'respiratory',
  //   update: true,
  //   model: {
  //     text: '',
  //     status: '',
  //   },
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  {
    label: 'Head and Neck',
    field: 'head',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Chest and Lungs',
    field: 'chest',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Cardiovascular',
    field: 'cardiovascular',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Abdomen',
    field: 'abdomen',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Genitourinary',
    field: 'genitourinary',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Rectal',
    field: 'rectal',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Musculoskeletal',
    field: 'musculoskeletal',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Lymph nodes',
    field: 'hematologicLymphatic',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Extremities / Skin',
    field: 'skin',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Neurological',
    field: 'neurologic',
    update: true,
    model: {
      text: '',
      status: '',
    },
    rules: {
      required: false,
      type: 'string',
    },
  },
  // {
  //   label: 'Breasts',
  //   field: 'breasts',
  //   update: true,
  //   model: {
  //     text: '',
  //     status: '',
  //   },
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  // {
  //   label: 'Back',
  //   field: 'back',
  //   update: true,
  //   model: {
  //     text: '',
  //     status: '',
  //   },
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  // {
  //   label: 'Gastrointestinal',
  //   field: 'gastrointestinal',
  //   update: true,
  //   model: {
  //     text: '',
  //     status: '',
  //   },
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  // {
  //   label: 'Endocrine',
  //   field: 'endocrine',
  //   update: true,
  //   model: {
  //     text: '',
  //     status: '',
  //   },
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  // {
  //   label: 'Psychiatric',
  //   field: 'psychiatric',
  //   update: true,
  //   model: {
  //     text: '',
  //     status: '',
  //   },
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  // {
  //   label: 'Allergic / Immunologic',
  //   field: 'allergicImmunologic',
  //   update: true,
  //   model: {
  //     text: '',
  //     status: '',
  //   },
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  // {
  //   label: 'Extremities',
  //   field: 'extermities',
  //   update: true,
  //   model: {
  //     text: '',
  //     status: '',
  //   },
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  // {
  //   label: 'Genitalia',
  //   field: 'genitalia',
  //   update: true,
  //   model: {
  //     text: '',
  //     status: '',
  //   },
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  /** TODO: make a column for others */
];

export const CONSCIOUSNESS_MIN = 0;
export const CONSCIOUSNESS_WARNING_THRESHOLD = 3;
export const CONSCIOUSNESS_ERROR_THRESHOLD = 5;
export const CONSCIOUSNESS_MAX = 7;

export const PAIN_MIN = 0;
export const PAIN_WARNING_THRESHOLD = 4;
export const PAIN_ERROR_THRESHOLD = 7;
export const PAIN_MAX = 10;

export const PAIN_TEXTS = ['Pain Free', 'Very Mild', 'Discomforting', 'Tolerable', 'Distressing', 'Very Distressing', 'Intense', 'Very Intense', 'Utterly Horrible', 'Excruciatingly Unbearable', 'Unimaginable Unspeakable'];
export const SPECIAL_FIELDS = ['painAssessmentScale', 'painAssessment', 'consciousnessLevelScale', 'consciousnessLevel'];

export const UPDATE_FIELDS = FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field)
  .sort((a, b) => a.order - b.order);

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.model;
  return acc;
}, {});

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
