<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-${formField.type === 'textarea' ? 'full' : '1'}`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField?.rules?.required" class="text-error">*</span></span>
            </span>
          </div>
          <input
            v-if="formField.type === 'text'"
            v-model="model[formField.field]"
            :placeholder="formField.placeholder || 'Input Text'"
            class="flex-auto input input-bordered focus:input-neutral"
            :type="formField.text"
            :disabled="loading"
          />
          <textarea
            v-if="formField.type === 'textarea'"
            v-model="model[formField.field]"
            class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
            :placeholder="formField.placeholder || 'Input Text'"
            :disabled="loading"
          />
          <VDatePicker v-if="formField.type === 'date'" v-model.string="model[formField.field]" mode="date" :max-date="new Date()" :masks="{ modelValue: 'YYYY-MM-DD' }">
            <template #default="{ togglePopover }">
              <input type="text" :placeholder="formField.placeholder" class="input input-bordered w-full focus:input-neutral"
                v-model="model[formField.field]" @click="togglePopover" />
            </template>
          </VDatePicker>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        Submit
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { computed, reactive, ref } from 'vue';
import { useGynecologistFindings } from './composables';
import { omit } from 'lodash';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitBirthHistory } = useGynecologistFindings();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);

    const submit = async () => {
      try {
        const payload = omit(Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        ), ['prevProblemTreatmentsPelvicExam', 'prevProblemsTreatmentsOthers']);
        payload.prevProblemsTreatments = [model.prevProblemTreatmentsPelvicExam, model.prevProblemsTreatmentsOthers].join(';; ');
        if (model.prevProblemTreatmentsPelvicExam) {
          payload.metadata = {
            containsPelvicExam: true,
          };
        } else {
          payload.metadata = {
            containsPelvicExam: false,
          };
        }
        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitBirthHistory(payload);
        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key].required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      if (data.prevCervicSmearsDate) {
        const date = new Date(data.prevCervicSmearsDate).toISOString().split('T')[0]; ;
        model.prevCervicSmearsDate = date;
      }
      model.prevCervicSmearsResult = data.prevCervicSmearsResult;
      const hasPelvicExam = data.metadata?.containsPelvicExam;
      const treatments = data.prevProblemsTreatments.split(';; ');

      if (hasPelvicExam) {
        model.prevProblemTreatmentsPelvicExam = treatments[0];
        model.prevProblemsTreatmentsOthers = treatments[1];
      } else {
        model.prevProblemsTreatmentsOthers = treatments[0];
      }
      model.currentContraception = data.currentContraception;
    }

    return {
      loading,
      model,
      recordId,
      isEditing,
      formFields: FORM_FIELDS,
      formSubmittable,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
