export const RECORD_TYPE = 'ros';
export const FORM_FIELDS = [
  {
    label: 'Constitutional',
    field: 'general',
    options: [
      'Fever',
      'Weight loss or gain',
      'Fatigue',
      'Night sweats',
      'Chills',
      'Loss of appetite',
      'Malaise',
      'Pallor',
      'None',
    ],
    update: true,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Head, eyes, ears, nose, and throat (HEENT)',
    field: 'eyesAndEnt',
    options: [
      'Headache',
      'Sinus congestion',
      'Runny nose',
      'Sneezing',
      'Sore throat',
      'Ear pain',
      'Vision changes',
      'Hearing changes',
      'Nasal discharge',
      'Hoarseness',
      'Jaw pain',
      'Dry mouth',
      'Difficulty swallowing (dysphagia)',
      'Dry eyes',
      'None',
    ],
    update: false,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Respiratory',
    field: 'respiratory',
    options: [
      'Cough',
      'Shortness of breath (dyspnea)',
      'Wheezing',
      'Chest pain',
      'Sputum production',
      'Dyspnea on exertion',
      'Orthopnea (difficulty breathing when lying down)',
      'Paroxysmal nocturnal dyspnea (attacks of shortness of breath at night)',
      'Hemoptysis (coughing up blood)',
      'Pleuritic pain (sharp chest pain that worsens with breathing)',
      'Chest tightness',
      'Stridor (harsh wheezing sound)',
      'Cyanosis (bluish tint to the skin)',
      'None',
    ],
    update: true,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Cardiovascular',
    field: 'cardiovascular',
    options: [
      'Chest pain',
      'Palpitations (fast or irregular heartbeat)',
      'Shortness of breath (dyspnea)',
      'Edema (swelling) in the legs or feet',
      'High blood pressure',
      'Orthostatic hypotension (dizziness upon standing)',
      'Claudication (leg pain with exercise)',
      'Murmurs (abnormal heart sounds)',
      'Arrhythmias (irregular heart rhythms)',
      'None',
    ],
    update: true,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Gastrointestinal',
    field: 'gastrointestinal',
    options: [
      'Abdominal pain',
      'Nausea',
      'Vomiting',
      'Diarrhea',
      'Constipation',
      'Difficulty swallowing (dysphagia)',
      'Heartburn',
      'Hematemesis (vomiting blood)',
      'Melena (black, tarry stools)',
      'Jaundice (yellowing of the skin and eyes)',
      'Hepatosplenomegaly (enlarged liver and spleen)',
      'Rectal bleeding',
      'Anal pain',
      'Change in bowel habits',
      'None',
    ],
    update: true,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Genitourinary',
    field: 'genitourinary',
    options: [
      'Frequent urination',
      'Painful urination (dysuria)',
      'Blood in the urine (hematuria)',
      'Vaginal discharge',
      'Menstrual irregularities',
      'Dysmenorrhea (painful menstruation)',
      'Nocturia (urination at night)',
      'Incontinence (loss of bladder or bowel control)',
      'Flank pain (pain in the side or back)',
      'Urinary retention (inability to urinate)',
      'None',
    ],
    update: true,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Musculoskeletal',
    field: 'musculoskeletal',
    options: [
      'Joint pain (arthralgia)',
      'Muscle pain (myalgia)',
      'Back pain',
      'Stiffness',
      'Weakness',
      'Limited range of motion',
      'Swelling',
      'Redness',
      'None',
    ],
    update: true,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Neurological',
    field: 'neurologic',
    options: [
      'Headache',
      'Dizziness',
      'Seizures',
      'Fainting (syncope)',
      'Numbness or tingling in the hands or feet',
      'Weakness',
      'Paralysis',
      'Focal weakness (weakness in one area of the body)',
      'Sensory loss',
      'Ataxia (loss of coordination)',
      'Tremor',
      'Dysarthria (slurred speech)',
      'Nystagmus (rapid eye movements)',
      'None',
    ],
    update: true,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Psychiatric',
    field: 'psychiatric',
    options: [
      'Anxiety',
      'Depression',
      'Mood swings',
      'Sleep problems',
      'Stress',
      'Psychosis',
      'Mania',
      'Hallucinations',
      'Delusions',
      'Obsessions and compulsions',
      'None',
    ],
    update: true,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Skin',
    field: 'skin',
    options: [
      'Rashes',
      'Itching (pruritus)',
      'Dryness',
      'Acne',
      'Eczema',
      'Psoriasis',
      'Vitiligo (loss of pigmentation)',
      'Urticaria (hives)',
      'Angioedema (swelling of the face, lips, and tongue)',
      'Petechiae (small red spots on the skin)',
      'Purpura (large purple spots on the skin)',
      'None',
    ],
    update: true,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Endocrine',
    field: 'endocrine',
    options: [
      'Excessive thirst or urination',
      'Unexplained weight gain or loss',
      'Fatigue',
      'Heat or cold intolerance',
      'Changes in appetite',
      'Changes in skin texture or hair',
      'Increased or decreased urination',
      'Sexual dysfunction',
      'Difficulty sleeping',
      'Mood swings or irritability',
      'None',
    ],
    update: true,
    model: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Notes',
    field: 'notes',
    type: 'text',
    update: true,
    model: '',
    rules: {
      required: true,
      type: 'string',
    },
  },
];

export const UPDATE_FIELDS = FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field)
  .sort((a, b) => a.order - b.order);

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  if (field?.model) acc[field.field] = field.model;
  return acc;
}, {});

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
