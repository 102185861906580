export const RECORD_TYPE = 'surgical-history';

export const FORM_FIELDS = [
  {
    label: 'Type of Surgery',
    field: 'procedure',
    placeholder: 'e.g. Emergency surgery',
    type: 'select',
    options: [
      'Elective', 'Expedited', 'Urgent', 'Immediate',
    ],
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Date of Surgery',
    field: 'performedAt',
    placeholder: 'e.g. 2020-10-05',
    type: 'date',
    rules: {
      required: true,
      type: 'number',
    },
  },
  {
    label: 'Name of Hospital',
    field: 'hospitalName',
    placeholder: 'e.g. Great Eastern Hospital',
    type: 'text',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Reason for surgery',
    field: 'reason',
    placeholder: 'e.g. Life-threatening injury',
    type: 'text',
    rules: {
      required: false,
      type: 'string',
    },
  },
  // {
  //   label: 'Medications prescribed',
  //   field: 'medicationOrder',
  //   placeholder: 'e.g. Bioflu',
  //   type: 'text',
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  {
    label: 'Operative Time (in hours)',
    field: 'operationTimeInMillis',
    placeholder: 'e.g. 4 hours',
    type: 'text',
    rules: {
      required: true,
      type: 'number',
    },
  },
  {
    label: 'Length of Stay (in days)',
    field: 'lengthOfStayInDays',
    placeholder: 'e.g. 2 days',
    type: 'text',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    label: 'Complications (if any)',
    field: 'complications',
    placeholder: 'e.g. Wound infection',
    type: 'array',
    default: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  // {
  //   label: "Surgeon's Name",
  //   field: 'surgeon',
  //   placeholder: 'e.g. Dr. Ryan',
  //   type: 'text',
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  {
    label: 'Notes',
    field: 'notes',
    placeholder: 'Enter additional procedure notes here (e.g. Patient underwent procedure for two hours.)',
    update: true,
    type: 'textarea',
    rules: {
      required: true,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
