<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-3 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`py-2 ${formField.field === 'notes' ? 'col-span-full' : ''}`">
          <div class="flex py-2">
            <span class="label-text text-base font-semibold">
              <span>{{formField.label}} <span v-if="formField.rules.required" class="text-error text-sm">*</span></span>
            </span>
          </div>
          <div class="flex flex-col" v-if="formField.field !== 'notes'">
            <template v-for="(option, index) in formField.options" :key="index">
              <div class="col-span-1 flex flex-col justify-between">
                <div class="flex flex-row gap-2 items-center form-control">
                  <label class="label py-1 hover:cursor-pointer flex flex-row gap-2">
                    <input
                      v-model="model[formField.field]"
                      type="checkbox"
                      :name="formField.field"
                      :value="option"
                      class="checkbox checked:bg-neutral"
                      @change="fireNoneToggle($event)"
                    />
                    <span>{{ option }}</span>
                  </label>

                  <!-- <label class="label py-1 hover:cursor-pointer flex flex-row gap-2">
                    <input
                      v-model="model[formField.field]"
                      type="checkbox"
                      :name="formField.field"
                      :value="option"
                      class="checkbox checked:bg-neutral"
                    />
                    <span>{{ option }}</span>
                  </label> -->
                </div>
              </div>
            </template>
          </div>
          <div v-else class="w-full">
            <textarea
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Enter additional remarks here on evaluation'"
              class="h-[64px] w-full textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
              required
            ></textarea>
          </div>
        </div>
      </template>
    </div>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
        >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_FIELDS, FORM_MODEL } from './constants';
import { computed, reactive, ref, watch } from 'vue';
import { watchDebounced } from '@vueuse/core';
import { useRos } from './composables';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitRos } = useRos();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);
    const allStatus = ref('');

    watch(allStatus, (value) => {
      if (value) {
        FORM_FIELDS.forEach(field => {
          model[field.field].status = value;
        });
      }
    });

    /**
     * This solution aims to resolve the ff concerns:
     * - normal watchers occur after DOM events
     * - watchers can actively manipulate the model
     * - deep watchers only manipulate if the object property has been replaced
     */
    const noneToggle = ref(false);
    const fireNoneToggle = (e) => {
      const checked = e?.target?.checked;
      const noneState = e?.target?.value === 'None';
      noneToggle.value = noneState && checked;
    };
    watchDebounced(() => model.general, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.general = ['None'];
      }
      if (!noneToggle.value) {
        model.general = model.general.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });
    watchDebounced(() => model.eyesAndEnt, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.eyesAndEnt = ['None'];
      }
      if (!noneToggle.value) {
        model.eyesAndEnt = model.eyesAndEnt.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });
    watchDebounced(() => model.respiratory, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.respiratory = ['None'];
      }
      if (!noneToggle.value) {
        model.respiratory = model.respiratory.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });
    watchDebounced(() => model.cardiovascular, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.cardiovascular = ['None'];
      }
      if (!noneToggle.value) {
        model.cardiovascular = model.cardiovascular.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });
    watchDebounced(() => model.gastrointestinal, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.gastrointestinal = ['None'];
      }
      if (!noneToggle.value) {
        model.gastrointestinal = model.gastrointestinal.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });
    watchDebounced(() => model.genitourinary, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.genitourinary = ['None'];
      }
      if (!noneToggle.value) {
        model.genitourinary = model.genitourinary.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });
    watchDebounced(() => model.musculoskeletal, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.musculoskeletal = ['None'];
      }
      if (!noneToggle.value) {
        model.musculoskeletal = model.musculoskeletal.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });
    watchDebounced(() => model.neurologic, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.neurologic = ['None'];
      }
      if (!noneToggle.value) {
        model.neurologic = model.neurologic.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });
    watchDebounced(() => model.psychiatric, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.psychiatric = ['None'];
      }
      if (!noneToggle.value) {
        model.psychiatric = model.psychiatric.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });
    watchDebounced(() => model.skin, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.skin = ['None'];
      }
      if (!noneToggle.value) {
        model.skin = model.skin.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });
    watchDebounced(() => model.endocrine, (newVal, oldVal) => {
      if (newVal.includes('None') && noneToggle.value) {
        model.endocrine = ['None'];
      }
      if (!noneToggle.value) {
        model.endocrine = model.endocrine.filter(i => i !== 'None');
      }
    }, { deep: true, debounce: 5 });

    const formSubmittable = computed(() => {
      return model?.notes;
    });

    const submit = async () => {
      try {
        loading.value = true;
        const payload = {};
        for (const key in model) {
          if (/Status/gi.test(key) === true) continue; // skip status key
          if (key !== 'eyesAndEnt' && key !== 'notes') {
            payload[key] = model[key].includes('None') ? 'None' : model[key]?.join(', ');
            payload[`${key}Status`] = payload[key] !== 'None' ? 'positive' : 'none';
            continue;
          } else if (key !== 'notes' && model[key]?.length > 0) {
            payload.eyes = model[key].includes('None') ? 'None' : model[key]?.join(', ');
            payload.eyesStatus = payload.eyes !== 'None' ? 'positive' : 'none';
            payload.ent = model[key].includes('None') ? 'None' : model[key]?.join(', ');
            payload.entStatus = payload.ent !== 'None' ? 'positive' : 'none';
            continue;
          } else {
            payload.notes = model[key];
            continue;
          }
        };

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        await submitRos(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        if (field !== 'notes') model[field] = [];
        else model[field] = '';
      });
      model.eyesAndEnt = [];
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      UPDATE_FIELDS.forEach(field => {
        if (data?.[`${field}Status`] && field !== 'notes') model[`${field}Status`] = data[`${field}Status`];
        if (data[field] && field !== 'notes') model[field] = data[field].split(', ');
        if (field === 'notes') model[field] = data[field];
      });
      if (data?.eyes || data?.ent) {
        const eyesAndEnt = data?.eyes || data?.ent;
        model.eyesAndEnt = eyesAndEnt.split(', ');
      }
    }

    return {
      loading,
      model,
      recordId,
      isEditing,
      allStatus,
      formSubmittable,
      formFields: FORM_FIELDS,
      fireNoneToggle,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
