<script setup>
import { computed, ref, reactive } from 'vue';
import { useCarePlan } from './composables';

defineExpose({ setForm, resetForm });
const emit = defineEmits(['submit', 'success', 'error']);
const props = defineProps({
  record: {
    type: Object,
    default: () => ({}),
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
});

const { submit: submitCarePlan } = useCarePlan();

const loading = ref(false);
const record = ref({});
const recordId = computed(() => record.value.id);
const isEditing = computed(() => !!recordId.value);

const model = reactive({
  text: '',
});

async function submit () {
  try {
    loading.value = true;

    const payload = {
      text: model.text,
    };

    // Update
    if (isEditing.value) {
      payload.id = recordId.value;
    }

    await submitCarePlan(payload);

    resetForm();
    emit('submit');
    emit('success');
  } catch (e) {
    console.error('Something went wrong. Try again later.');
    emit('error', e);
  } finally {
    loading.value = false;
  }
};

function resetForm () {
  record.value = {};
}

// Sets value to record for editing purposes
// as well as set value model.text
function setForm (data) {
  if (!data) return;
  record.value = data;
  model.text = data.text;
}
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>


<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">      
      <div class="form-control col-span-full">
        <div class="flex justify-between py-2">
          <span class="label-text">
            <span v-if="showLabel">Care Plan Notes <span class="text-error">*</span></span>
          </span>
        </div>
        <textarea
          v-model="model.text"
          placeholder="Type here"
          class="h-[100] textarea textarea-bordered focus:textarea-neutral"
          :disabled="loading"
        ></textarea>
      </div>
    </div>

    <div class="flex gap-4 justify-end">
      <button
        v-if="model.text"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!model.text || loading"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

