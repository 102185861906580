<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex flex-col">
      <template v-for="(children, group) in mappedFormFields" :key="group">
        <div class="py-2">
          <div class="flex justify-between">
            <span class="label-text text-base font-bold">
              <span>{{group}}</span>
            </span>
          </div>
          <div class="grid grid-cols-3 gap-4 place-items-start">
            <template v-for="(form, index) in children" :key="index">
              <div class="form-control col-span-1 flex flex-col justify-between">
                <div class="flex justify-between py-2">
                  <span class="label-text">
                    <span>{{form.label}}</span>
                  </span>
                </div>
                <template v-for="(option, index) in form.options" :key="index">
                  <div class="flex flex-row gap-2 items-center">
                    <input type="radio" :id="`${form.label} - ${option}`"
                    :name="form.field" :value="option"
                    :checked="isEditing ? option === model[form.field][0].text : false"
                    class="radio checked:bg-neutral"
                    @click.stop="updateModel($event, form)"/>
                    <label :for="option" class="label">{{ option }}</label>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_FIELDS, FORM_MODEL } from './constants';
import { computed, reactive, ref } from 'vue';
import { useMse } from './composables';
import { groupBy, isEmpty } from 'lodash';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitMse } = useMse();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);

    function validate (obj) {
      return Object.values(obj).every(i => i?.[0]?.text && !isEmpty(i?.[0]?.text));
    }

    const formSubmittable = computed(() => {
      return validate(model);
    });

    const submit = async () => {
      try {
        loading.value = true;

        const payload = {
          ...model,
        };

        if (!validate(payload)) {
          throw new Error('Please fill up all fields!');
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        await submitMse(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = [{
          code: '',
          text: '',
        }];
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      UPDATE_FIELDS.forEach(field => {
        const dataObj = data[field][0];
        model[field] = [dataObj];
      });
    }

    function updateModel (event, formField) {
      const text = event.target.value;
      const code = text;
      model[formField.field] = [{ code, text }];
    }

    const mappedFormFields = ref(groupBy(FORM_FIELDS, (o) => o?.group));

    return {
      loading,
      model,
      recordId,
      isEditing,
      formSubmittable,
      formFields: FORM_FIELDS,
      mappedFormFields,
      resetForm,
      submit,
      setForm,
      updateModel,
    };
  },
};
</script>
