<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div v-if="testLength === 1">
          <div class="flex flex-col col-span-full gap-2">
            <span class="text-sm font-medium">Test Availed</span>
            <span class="text-sm text-neutral-500">{{tests || '-'}}</span>
          </div>
        </div>
        <div v-if="testLength > 1">
          <div class="flex flex-col col-span-full gap-2">
            <span class="text-sm font-medium">Tests Availed</span>
            <span class="text-sm text-neutral-500">{{tests || '-'}}</span>
          </div>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Diagnosis</span>
          <span class="text-sm text-neutral-500" v-if="diagnosisCode && diagnosisText">{{`(${diagnosisCode} - ${diagnosisText})`}}</span>
          <span class="text-sm text-neutral-500" v-else>-</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Reason</span>
          <span class="text-sm text-neutral-500">{{reason || '-'}}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useRadiologyOrders } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeRadiologyOrder } = useRadiologyOrders();
    const testLength = computed(() => props.record.tests?.length);
    const tests = computed(() => {
      if (!props?.record?.tests) return null;
      const testSpreadable = [...props.record.tests].map(i => i.name).join(', ');
      return testSpreadable;
    });
    const diagnosisCode = computed(() => props.record.diagnosisCode);
    const diagnosisText = computed(() => props.record.diagnosisText);
    const reason = computed(() => props.record.reason);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeRadiologyOrder({ id: props.record.id });
    }

    return {
      createdAt,
      testLength,
      tests,
      diagnosisCode,
      diagnosisText,
      reason,
      edit,
      remove,
    };
  },
};
</script>
