<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Allergy</span>
          <span class="text-sm text-neutral-500">{{name || '-' }}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Allergy Type</span>
          <span class="text-sm text-neutral-500">{{allergyType || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Started At</span>
          <span class="text-sm text-neutral-500">{{startedAt || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Frequency</span>
          <span class="text-sm text-neutral-500">{{capitalize(frequency) || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Worsening over time?</span>
          <span class="text-sm text-neutral-500">{{isWorseningOverTime || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Existing reactions?</span>
          <span v-if="reactions?.severity && reactions?.type" class="text-sm text-neutral-500">{{`${capitalize(reactions?.severity)} ${capitalize(reactions?.type)}`}}</span>
          <span v-else class="text-neutral-500">-</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { capitalize } from 'lodash';
import { computed } from 'vue';
import { format } from 'date-fns';
import { yesOrNo } from '@/utils/string';
import { useAllergyHistory } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeAllergyHistory } = useAllergyHistory();
    const name = computed(() => props.record.name);
    const allergyType = computed(() => props.record.allergyType);
    const startedAt = computed(() => {
      if (!props.record.startedAt) return;
      const date = new Date(props.record.startedAt);
      return format(date, 'yyyy');
    });
    const frequency = computed(() => props.record.frequency);
    const isWorseningOverTime = computed(() => yesOrNo(props.record.isWorseningOverTime));
    const reactions = computed(() => {
      const existingReaction = props.record?.reactions?.[0];
      if (!existingReaction) return null;
      return existingReaction;
    });
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeAllergyHistory({ id: props.record.id });
    }

    return {
      createdAt,
      name,
      allergyType,
      startedAt,
      frequency,
      isWorseningOverTime,
      reactions,
      edit,
      remove,
      capitalize,
    };
  },
};
</script>
