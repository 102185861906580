export const RECORD_TYPE = 'care-plan';

export const RESPONSIBLE_PARTIES = [
  'Patient',
  'Doctor',
  'Guardian',
];

export const UPDATE_FIELDS = [
  'text',
  'startAt',
  'endAt',
  'responsibilities',
  'goals',
  'monitoringAndEvaluation',
];
export const FORM_RULES = {
  text: {
    type: 'string',
    required: true,
  },
};
