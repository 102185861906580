<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-${formField.type === 'textarea' ? 'full' : (formField.type === 'complications' ? 'full' : '1')}`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField.rules?.required" class="text-error">*</span></span>
            </span>
          </div>
          <template v-if="formField.type === 'select'">
            <select
              v-model="model[formField.field]"
              class="select select-bordered w-full focus:select-neutral"
              :name="formField.label"
              :disabled="loading"
              required
            >
              <option selected disabled>Select {{formField.label}}</option>
              <template v-for="(option, index) in formField.options" :key="index">
                <option :value="option">{{option}}</option>
              </template>
            </select>
          </template>
          <template v-else-if="formField.type === 'textarea'">
            <textarea
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Type here'"
              class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
            ></textarea>
          </template>
          <template v-else-if="formField.type === 'array'">
            <Chips
              v-if="formField.field === 'proceduresPerformed'"
              v-model="model[formField.field]"
              addOnBlur
              placeholder="e.g. Reduce shortness of breath on exertion, improve exercise tolerance"
              class="input input-bordered focus:input-neutral h-[100%] py-2"
              :pt="{
                container: { class: 'w-full flex flex-row gap-2' },
                token: { class: 'flex flex-row items-center align-center gap-2 join rounded rounded-full bg-neutral-300 shadow-sm px-2' },
                label: { class: 'join-item' },
                removeTokenIcon: { class: 'hover:bg-neutral-200 rounded-full' }
              }"
            />
            <div v-else>
              <div class="flex flex-row gap-2 justify-between">
                <div class="flex flex-col gap-2 flex-1">
                  <span class="label-text">Pre-surgery</span>
                  <Chips
                    v-model="preSurgeryComplications"
                    addOnBlur
                    placeholder="Type here"
                    class="input input-bordered focus:input-neutral h-[100%] py-2"
                    :pt="{
                      container: { class: 'w-full flex flex-row gap-2' },
                      token: { class: 'flex flex-row items-center align-center gap-2 join rounded rounded-full bg-neutral-300 shadow-sm px-2' },
                      label: { class: 'join-item' },
                      removeTokenIcon: { class: 'hover:bg-neutral-200 rounded-full' }
                    }"
                  />
                </div>
                <div class="flex flex-col gap-2 flex-1">
                  <span class="label-text">Post-surgery</span>
                  <Chips
                    v-model="postSurgeryComplications"
                    addOnBlur
                    placeholder="Type here"
                    class="input input-bordered focus:input-neutral h-[100%] py-2"
                    :pt="{
                      container: { class: 'w-full flex flex-row gap-2' },
                      token: { class: 'flex flex-row items-center align-center gap-2 join rounded rounded-full bg-neutral-300 shadow-sm px-2' },
                      label: { class: 'join-item' },
                      removeTokenIcon: { class: 'hover:bg-neutral-200 rounded-full' }
                    }"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <input
              v-if="formField.type !== 'date'"
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Input Text'"
              class="flex-auto input input-bordered focus:input-neutral"
              :type="formField.rules.type"
              :disabled="loading"
            />
            <ClientOnly v-else>
              <VDatePicker v-model.string="model[formField.field]" mode="date" :max-date="new Date()" :masks="{ modelValue: 'YYYY-MM-DD' }">
                <template #default="{ togglePopover }">
                  <input type="text" :placeholder="formField.placeholder" class="input input-bordered w-full focus:input-neutral"
                    v-model="model[formField.field]" @click="togglePopover" />
                </template>
              </VDatePicker>
            </ClientOnly>
          </template>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { computed, reactive, ref } from 'vue';
import { useHospitalizationHistory } from './composables';
import { union } from 'lodash';
import Chips from 'primevue/chips';

export default {
  props: {
    showLabel: Boolean,
    patientId: String,
    encounterId: String,
    facilityId: String,
  },
  components: {
    Chips,
  },
  setup (props, { emit }) {
    const { submit: submitHospitalizationHistory } = useHospitalizationHistory();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);
    const preSurgeryComplications = ref([]);
    const postSurgeryComplications = ref([]);

    const submit = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        );
        if (model.proceduresPerformed && model.proceduresPerformed?.length > 0) {
          payload.proceduresPerformed = model.proceduresPerformed.map((v, i) => ({
            code: String(i),
            name: v,
          }));
        }
        if (preSurgeryComplications.value || postSurgeryComplications.value) payload.complications = [];
        if (preSurgeryComplications.value && preSurgeryComplications.value?.length > 0) {
          const complications = JSON.parse(JSON.stringify(preSurgeryComplications.value));
          const preComplications = complications.map((v, i) => ({
            type: 'pre',
            code: String(i),
            name: v,
          }));
          payload.complications = union(payload.complications, preComplications);
        }
        if (postSurgeryComplications.value && postSurgeryComplications.value?.length > 0) {
          const complications = JSON.parse(JSON.stringify(postSurgeryComplications.value));
          const postComplications = complications.map((v, i) => ({
            type: 'post',
            code: String(i),
            name: v,
          }));
          payload.complications = union(payload.complications, postComplications);
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitHospitalizationHistory(payload, { patientId: props.patientId, encounterId: props.encounterId, facilityId: props.facilityId });

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key].required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      if (data.hospitalizedAt) {
        const date = new Date(data.hospitalizedAt);
        model.hospitalizedAt = date.toISOString().split('T')[0];
      }
      model.reason = data.reason;
      model.hospitalName = data.hospitalName;
      model.proceduresPerformed = data.proceduresPerformed.map(i => i.name);
      preSurgeryComplications.value = data.complications.filter(i => i.type === 'pre')
        .map(v => v.name);
      postSurgeryComplications.value = data.complications.filter(i => i.type === 'post')
        .map(v => v.name);
      model.lengthOfStayInDays = data.lengthOfStayInDays;
      model.diagnosis = data.diagnosis;
      model.treatment = data.treatment;
      model.notes = data.notes;
    }

    return {
      loading,
      model,
      recordId,
      isEditing,
      formFields: FORM_FIELDS,
      resetForm,
      formSubmittable,
      submit,
      setForm,

      preSurgeryComplications,
      postSurgeryComplications,
    };
  },
};
</script>
