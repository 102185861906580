<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(field, index) in recordFields" :key="index">

        <template v-if="field.type === 'header'">
          <div class="col-span-2 flex items-center gap-4">
            <h5 class="font-medium text-black flex items-center gap-2">
              {{ field.label }}
              <!-- <i class="las la-exclamation-circle text-2xl">
                <div class="tooltip" data-tip="This is a block of text">
                </div>
              </i> -->
            </h5>
          </div>
        </template>

        <template v-if="field.inputType === 'select'">
          <div class="flex flex-col gap-2">
            <label :for="field.name" class="text-sm font-medium">{{ field.label }}</label>
            <select
              v-model="model[field.field]"
              class="select select-bordered"
              :disabled="loading"
            >
              <option v-for="(option, index) in field.options" :key="index" :value="option.value">
                {{ option.name }}
              </option>
            </select>
          </div>
        </template>

        <template v-if="field.inputType === 'input'">
          <div class="flex flex-col gap-2">
            <label :for="field.name" class="text-sm font-medium">{{ field.label }}</label>
            <input
              v-model="model[field.field]"
              :type="field.type || 'text'"
              :min="field.field === 'pain.intensity' ? 0 : ''"
              :max="field.field === 'pain.intensity' ? 10 : ''"
              class="input input-bordered"
              :placeholder="field.placeholder"
              :disabled="loading"
            />
          </div>
        </template>

        <template v-if="field.inputType === 'textarea'">
          <div class="col-span-2 flex flex-col gap-2">
            <label :for="field.name" class="text-sm font-medium">{{ field.label }}</label>
            <textarea
              v-model="model[field.field]"
              type="text"
              class="textarea textarea-bordered"
              :placeholder="field.placeholder"
              :disabled="loading"
            >
            </textarea>
          </div>
        </template>
      </template>
    </div>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { computed, ref } from 'vue';
import { useFunctionalStatus } from './composables';
import { RECORD_FIELDS } from './constants';
import { get } from 'lodash';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitFunctionalStatus } = useFunctionalStatus();

    const recordFields = computed(() => RECORD_FIELDS);
    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = ref({});

    const formSubmittable = computed(() => {
      return validate();
    });

    function validate () {
      return recordFields.value.some(field => {
        return !!model.value[field.field];
      });
    }

    const submit = async () => {
      try {
        loading.value = true;

        const validFields = recordFields.value.filter((field) => {
          return !!model.value[field.field];
        });

        function setByPath (obj, path, value) {
          const keys = path.split('.');
          let current = obj;
          for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            current[key] = current[key] || {};
            current = current[key];
          }
          current[keys[keys.length - 1]] = value;
        }

        const payload = validFields.reduce((acc, field) => {
          setByPath(acc, field.field, model.value[field.field]);
          return acc;
        }, {});

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        await submitFunctionalStatus(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      model.value = {};
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.value = recordFields.value.reduce((acc, field) => {
        if (field.type === 'header') return acc;
        if (get(data, field.field)) {
          acc[field.field] = get(data, field.field);
          return acc;
        } else {
          acc[field.field] = '';
          return acc;
        }
      }, {});
    }

    return {
      recordFields,
      loading,
      recordId,
      isEditing,
      model,
      formSubmittable,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
