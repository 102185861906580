import { FAMILY_RELATIONSHIPS, CHRONIC_DISEASES } from '@/components/medical-records/shared/constants';

export const RECORD_TYPE = 'family-history';

export const FORM_FIELDS = [
  // {
  //   label: 'Name of Family Member',
  //   field: 'nameOfFamilyMember',
  //   placeholder: 'e.g. John Doe',
  //   update: false,
  //   type: 'text',
  //   subtype: 'object',
  //   default: {
  //     firstName: '',
  //     lastName: '',
  //   },
  //   rules: {
  //     required: true,
  //     type: 'string',
  //   },
  // },
  {
    label: 'Relationship to Patient',
    field: 'relationship',
    placeholder: 'e.g. Mother',
    update: false,
    type: 'select',
    subtype: 'shallow',
    options: FAMILY_RELATIONSHIPS,
    rules: {
      required: true,
      type: 'string',
    },
  },
  // {
  //   label: 'Date of Birth',
  //   field: 'dateOfBirth',
  //   placeholder: 'e.g. 1992-04-03',
  //   update: true,
  //   type: 'date',
  //   rules: {
  //     required: true,
  //     type: 'number',
  //   },
  // },
  {
    label: 'Chronic Disease',
    field: 'medicalCondition',
    placeholder: 'e.g. Diabetes',
    update: true,
    type: 'select',
    subtype: 'shallow',
    options: CHRONIC_DISEASES,
    rules: {
      required: true,
      type: 'string',
    },
  },
  // {
  //   label: 'Living or Deceased?',
  //   field: 'deceased',
  //   placeholder: 'e.g. Living',
  //   update: true,
  //   type: 'select',
  //   subtype: 'deep',
  //   options: [
  //     { name: 'Living', value: false },
  //     { name: 'Deceased', value: true },
  //   ],
  //   rules: {
  //     required: false,
  //     type: 'boolean',
  //   },
  // },
  // {
  //   label: 'If deceased, date of death:',
  //   field: 'deceasedAt',
  //   placeholder: 'e.g. 2020-03-23',
  //   update: true,
  //   dependency: 'deceased',
  //   type: 'date',
  //   // subtype: 'number',
  //   rules: {
  //     required: false,
  //     type: 'number',
  //   },
  // },
  {
    label: 'Notes',
    field: 'notes',
    update: true,
    type: 'textarea',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
