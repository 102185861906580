import { EDUCATIONAL_LEVELS } from '../shared/constants';

export const RECORD_TYPE = 'social-history';

export const FORM_FIELDS = [
  {
    group: 'Living Situation',
    label: 'Does the patient live alone or with others?',
    field: 'livesAlone',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Lives alone',
    options: ['YES, lives alone.', 'NO, lives with others.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    group: 'Living Situation',
    label: 'Are any family members/caregivers available to assist with their care?',
    field: 'independent',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Yes',
    options: ['YES, has available caregivers.', 'NO, has no available caregivers.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  // {
  //   group: 'Occupation & Education',
  //   label: 'Current or past occupation/s',
  //   field: 'occupations',
  //   update: true,
  //   type: 'array',
  //   default: [],
  //   placeholder: 'e.g. Businessman',
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  {
    group: 'Occupation & Education',
    label: 'Educational Level',
    field: 'educationalLevel',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Post-graduate education',
    options: EDUCATIONAL_LEVELS,
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Financial Status',
    label: 'Does the patient have adequate health insurance?',
    field: 'hasInsurance',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. No',
    options: ['YES, has adequate health insurance.', 'NO, does not have adequate health insurance.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    group: 'Financial Status',
    label: 'Is able to afford their medical expenses?',
    field: 'canAffordMedicalExpenses',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. No',
    options: ['YES, can afford medical expenses.', 'NO, cannot afford their medical expenses.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    group: 'Support System',
    label: 'Does the patient have a strong support system?',
    field: 'hasStrongSupportSystem',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Yes',
    options: ['YES, has a strong support system.', 'NO, does not have a strong support system.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    group: 'Support System',
    label: 'Is involved in any community activities?',
    field: 'isInvolvedInCommunity',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Yes',
    options: ['YES, is involved in community activities.', 'NO, is not involved in community activities.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    group: 'Substance Use',
    label: 'Does the patient smoke?',
    field: 'smoking',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Yes',
    options: ['YES, patient is a smoker.', 'NO, does not smoke.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    group: 'Substance Use',
    label: 'If so, how frequent? (per day)',
    field: 'smokingSticksPerDay',
    dependency: 'smoking',
    update: true,
    type: 'text',
    placeholder: 'e.g. 4',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    group: 'Substance Use',
    label: 'Drinks alcohol?',
    field: 'drinksAlcohol',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. No',
    options: ['YES, patient drinks alcohol.', 'NO, patient does not drink alcohol.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    group: 'Substance Use',
    label: 'If so, how frequent? (per day)',
    field: 'drinksAlcoholPerDayBottlesCount',
    dependency: 'drinksAlcohol',
    update: true,
    type: 'text',
    placeholder: 'e.g. 4',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    group: 'Substance Use',
    label: 'Uses recreational/prohibited drugs?',
    field: 'usesProhibitedDrugs',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Yes',
    options: ['YES, patient uses prohibited drugs.', 'NO, patient does not use prohibited drugs.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    group: 'Substance Use',
    label: 'If so, how frequent? (per day)',
    field: 'usesProhibitedDrugsPerDay',
    dependency: 'usesProhibitedDrugs',
    update: true,
    type: 'text',
    placeholder: 'e.g. 4',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    group: 'Activities',
    label: 'Does the patient exercise?',
    field: 'exercises',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Yes',
    options: ['YES, the patient exercises.', 'NO, the patient does not exercise.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  // {
  //   group: 'Activities',
  //   label: "Patient's hobbies?",
  //   field: 'hobbies',
  //   update: true,
  //   type: 'array',
  //   default: [],
  //   placeholder: 'e.g. Writing',
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  {
    group: 'Sexual Activity',
    label: 'Is the patient sexually active?',
    field: 'sexuallyActive',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Yes',
    options: ['YES, patient is sexually active.', 'NO, patient is not sexually active.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    group: 'Mental Health',
    label: 'Does the patient have any mental health problems? (i.e depression)',
    field: 'hasMentalHealthIssues',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Yes',
    options: ['YES, patient has mental health problems.', 'NO, patient does not have mental health problems.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    group: 'Mental Health',
    label: 'Are there other stressors or challenges recently?',
    field: 'hasRecentStressors',
    update: true,
    type: 'select',
    subtype: 'shallow',
    placeholder: 'e.g. Yes',
    options: ['YES, patient has recently faced stressors/challenges.', 'NO, patient has not reecently faced stressors/challenges.'],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});

export const FORM_DEPENDENCIES = FORM_FIELDS.reduce((acc, field) => {
  if (field?.dependency) acc[field.field] = field.dependency;
  return acc;
}, {});
