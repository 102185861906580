<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Order</span>
          <span class="text-sm text-neutral-500">{{ordersList}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Result</span>
        </div>
        <template v-for="(resultData, index) in results" :key="index">
          <div class="flex flex-col col-span-full">
            <span class="text-[12px] font-medium text-neutral-700">{{ resultData?.testName }}</span>
            <span class="text-[12px] font-regular text-neutral-500 break-words">{{ resultData?.results }}</span>
          </div>
          <div v-if="index + 1 < results?.length" class="divider col-span-full py-0 my-0"></div>
        </template>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useRadiologyResults } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { orders, remove: removeLaboratoryOrder } = useRadiologyResults();
    const ordersList = computed(() => {
      return orders.value.filter(i => i?.id === props.record.order).map(g => { return g?.tests.map(i => i?.name).join(', '); })?.[0];
    });
    const results = computed(() => props.record.results);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeLaboratoryOrder({ id: props.record.id });
    }

    return {
      createdAt,
      ordersList,
      results,
      edit,
      remove,
    };
  },
};
</script>
