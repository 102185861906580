<template>
  <form @submit.prevent="submit" class="flex flex-col">
    <template v-for="(children, group) in mappedFormFields" :key="group">
      <div class="py-2">
        <div class="flex justify-between pt-2">
          <span class="label-text text-base font-bold">
            <span>{{group}}</span>
          </span>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 place-items-stretch">
          <template v-for="(form, index) in children" :key="index">
            <div :class="`form-control col-span-1 flex flex-col ${['canAffordMedicalExpenses', 'isInvolvedInCommunity', 'livesAlone'].includes(form.field) ? 'justify-between' : 'flex-start'}`">
              <div class="flex justify-between py-2">
                <span class="label-text">
                  <span>{{form.label}}</span>
                </span>
              </div>
              <template v-if="form.type === 'select'">
                <select class="select select-bordered">
                  <option value="" selected disabled>{{form.placeholder}}</option>
                  <option v-for="(option, index) in form.options" :key="index" :value="option">{{option}}</option>
                </select>
              </template>

              <template v-if="form.type === 'text'">
                <input
                v-model="model[form.field]"
                :placeholder="form.placeholder || 'Type here'"
                :type="form.rules.type"
                class="input input-bordered focus:input-neutral"
                :disabled="loading"
                />
              </template>
              <template v-if="form.type === 'array'">
                <input v-model="model[form.field]" type="text" class="input input-bordered">
              </template>
            </div>
          </template>
        </div>
      </div>
    </template>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_DEPENDENCIES } from './constants';
import { computed, reactive, ref } from 'vue';
import { useSocialHistory } from './composables';
import { groupBy, isBoolean } from 'lodash';
import Chips from 'primevue/chips';

export default {
  props: {
    showLabel: Boolean,
    patientId: String,
    encounterId: String,
    facilityId: String,
  },
  components: {
    Chips,
  },
  setup (props, { emit }) {
    const { submit: submitSocialHistory } = useSocialHistory();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);

    const formSubmittable = computed(() => {
      return Object.values(model).some(Boolean);
    });
    const submit = async () => {
      try {
        const payload = {};

        for (const key in model) {
          const value = model[key];

          if (value === '' || value === null || value === undefined || value === 'Not Asked') {
            continue; // Skip values that need to be removed
          }

          if (/yes/gi.test(value)) {
            payload[key] = true;
          } else if (/no/gi.test(value)) {
            payload[key] = false;
          } else {
            payload[key] = value;
          }

          if (payload[FORM_DEPENDENCIES[key]] === false) {
            payload[key] = 0;
          }
        }

        if (typeof model.occupations === 'object' &&  model.occupations && model.occupations?.length > 0) {
          payload.occupations = model.occupations.map(i => {
            return { company: i, position: i };
          });
        }

        if (typeof model.hobbies === 'object' && model.hobbies && model.hobbies?.length > 0) {
          payload.hobbies = model.hobbies.map((i, ind) => {
            return { code: String(ind), name: i };
          });
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitSocialHistory(payload, { patientId: props.patientId, encounterId: props.encounterId, facilityId: props.facilityId });

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error(e);
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.educationalLevel = data.educationalLevel;
      model.exercises = isBoolean(data.exercises) ? (data.exercises === true ? 'YES, the patient exercises.' : 'NO, the patient does not exercise.') : 'Not Asked';
      model.smoking = isBoolean(data.smoking) ? (data.smoking === true ? 'YES, patient is a smoker.' : 'NO, patient does not smoke.') : 'Not Asked';
      model.smokingSticksPerDay = data.smokingSticksPerDay;
      model.usesProhibitedDrugs = isBoolean(data.usesProhibitedDrugs) ? (data.usesProhibitedDrugs === true ? 'YES, patient uses prohibited drugs.' : 'NO, patient does not use prohibited drugs.') : 'Not Asked';
      model.usesProhibitedDrugsPerDay = data.usesProhibitedDrugsPerDay;
      model.sexuallyActive = isBoolean(data.sexuallyActive) ? (data.sexuallyActive === true ? 'YES, patient is sexually active.' : 'NO, patient is not sexually active.') : 'Not Asked';
      model.drinksAlcohol = isBoolean(data.drinksAlcohol) ? (data.drinksAlcohol === true ? 'YES, patient drinks alcohol.' : 'NO, patient does not drink alcohol.') : 'Not Asked';
      model.drinksAlcoholPerDayBottlesCount = data.drinksAlcoholPerDayBottlesCount;
      model.livesAlone = isBoolean(data.livesAlone) ? (data.livesAlone === true ? 'YES, lives alone.' : 'NO, lives with others.') : 'Not Asked';
      model.independent = isBoolean(data.independent) ? (data.independent === true ? 'YES, has available caregivers.' : 'NO, has no available caregivers.') : 'Not Asked';
      model.hasInsurance = isBoolean(data.hasInsurance) ? (data.hasInsurance === true ? 'YES, has adequate health insurance.' : 'NO, does not have adequate health insurance.') : 'Not Asked';
      model.canAffordMedicalExpenses = isBoolean(data.canAffordMedicalExpenses) ? (data.canAffordMedicalExpenses === true ? 'YES, can afford medical expenses.' : 'NO, cannot afford their medical expenses.') : 'Not Asked';
      model.hasStrongSupportSystem = isBoolean(data.hasStrongSupportSystem) ? (data.hasStrongSupportSystem === true ? 'YES, has a strong support system.' : 'NO, does not have a strong support system.') : 'Not Asked';
      model.isInvolvedInCommunity = isBoolean(data.isInvolvedInCommunity) ? (data.isInvolvedInCommunity === true ? 'YES, is involved in community activities.' : 'NO, is not involved in community activities.') : 'Not Asked';
      model.hasMentalHealthIssues = isBoolean(data.hasMentalHealthIssues) ? (data.hasMentalHealthIssues === true ? 'YES, patient has mental health problems.' : 'NO, patient does not have mental health problems.') : 'Not Asked';
      model.hasRecentStressors = isBoolean(data.hasRecentStressors) ? (data.hasRecentStressors === true ? 'YES, patient has recently faced stressors/challenges.' : 'NO, patient has not reecently faced stressors/challenges.') : 'Not Asked';
      // if (typeof data.hobbies === 'string') {
      //   model.hobbies = data.hobbies;
      // } else if (Array.isArray(data.hobbies)) {
      //   model.hobbies = data.hobbies.map(i => i.name);
      // } else {
      //   model.hobbies = '';
      // }
      // if (data.occupations && Array.isArray(data.occupations)) {
      //   model.occupations = data.occupations.map(i => i.position);
      // } else {
      //   model.occupations = '';
      // }
    }

    const mappedFormFields = ref(groupBy(FORM_FIELDS, (o) => o?.group));

    return {
      model,
      recordId,
      loading,
      isEditing,
      formSubmittable,
      mappedFormFields,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
