<script setup>
import { computed, toRef } from 'vue';

const props = defineProps({
  record: {
    type: Object,
    defafault: () => ({}),
  },
});

const text = computed(() => {
  if (!props.record?.text) return '';
  const text = props.record.text;
  return text.charAt(0).toUpperCase() + text.slice(1);
});
</script>

<template>
<div class="flex flex-col gap-4">
  <p class="text-sm font-medium">HPI</p>
  <p class="text-sm text-neutral-500 p-0">{{text}}</p>
</div>
</template>