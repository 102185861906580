<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
        <div class="flex flex-col gap-4">
          <p class="text-sm font-medium">HPI</p>
          <p class="text-sm text-neutral-500 p-0">{{text}}</p>
        </div>
      </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useHpi } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeHpi } = useHpi();

    const text = computed(() => {
      const text = props.record.text;
      return text.charAt(0).toUpperCase() + text.slice(1);
    });
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const signsAndSymptoms = computed(() => props.record.signsAndSymptoms);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      // const result = globalThis.confirm('Are you sure you want to delete this chief complaint?');
      // if (!result) return;
      removeHpi({ id: props.record.id });
    }

    return {
      createdAt,
      text,
      signsAndSymptoms,
      edit,
      remove,
    };
  },
};
</script>
