import { ALLERGY_COLLECTION } from '../shared/constants';
import { past50Years } from '../shared/utils';
export const RECORD_TYPE = 'allergy';

export const ALLERGY_TYPE = [
  'Swelling', 'Hives', 'Itchy skin',
  'Runny or stuffy nose', 'Sneezing', 'Watery eyes',
  'Coughing', 'Wheezing', 'Difficulty breathing',
  'Anaphylaxis',
];

export const FORM_FIELDS = [
  {
    label: 'Allergy (Food, Medication, Environmental)',
    field: 'name',
    placeholder: 'e.g. Baker\'s Yeast',
    type: 'select',
    subtype: 'deep',
    options: ALLERGY_COLLECTION,
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Type of Allergy',
    field: 'allergyType',
    placeholder: 'e.g. Swelling',
    type: 'select',
    subtype: 'shallow',
    options: ALLERGY_TYPE,
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Start of Allergy',
    field: 'startedAt',
    placeholder: 'e.g. 2005',
    type: 'select',
    subtype: 'shallow',
    options: past50Years(),
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Frequency of Allergy',
    field: 'frequency',
    placeholder: 'e.g. Intermittent',
    type: 'select',
    subtype: 'deep',
    options: [
      { name: 'Once', value: 'once' },
      { name: 'Intermittent', value: 'intermittent' },
      { name: 'Persistent', value: 'persistent' },
    ],
    rules: {
      required: false,
      type: 'text',
    },
  },
  {
    label: 'Is allergy getting worse over time?',
    field: 'isWorseningOverTime',
    placeholder: 'e.g Yes',
    type: 'select',
    subtype: 'deep',
    options: [
      { name: 'Yes', value: true },
      { name: 'No', value: false },
    ],
    rules: {
      required: false,
      type: 'boolean',
    },
  },
  {
    label: 'Any severe allergic reactions?',
    field: 'allergicReactions',
    placeholder: 'e.g. Anaphylaxis',
    type: 'text',
    rules: {
      required: false,
      type: 'object',
    },
  },
  // {
  //   label: 'Any family members with allergy?',
  //   field: 'allergyFamilyHistory',
  //   placeholder: 'e.g. Grandfather',
  //   type: 'select',
  //   options: FAMILY_RELATIONSHIPS,
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
