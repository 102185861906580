export const RECORD_TYPE = 'ent-note';
export const FORM_FIELDS = [
  {
    field: 'cpapPressure',
    label: 'CPAP Pressure (cmH2O)',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    field: 'lsatPressure',
    label: 'LSAT Pressure (%)',
    update: true,
    rules: {
      type: 'number',
      required: true,
    },
  },
  {
    field: 'bipapInspiration',
    label: 'BIPAP Inspiration (cmH2O)',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    field: 'bipapExpiration',
    label: 'BIPAP Expiration (cmH2O)',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    field: 'ahi',
    label: 'AHI (events/hr)',
    update: true,
    rules: {
      type: 'number',
      required: true,
    },
  },
  {
    field: 'rampTime',
    label: 'Ramp Time (minutes)',
    update: true,
    rules: {
      type: 'number',
      required: true,
    },
  },
  {
    field: 'psgDiagnostic',
    label: 'PSG Diagnostic',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    field: 'psgTherapeutic',
    label: 'PSG Therapeutic',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    field: 'psgSplit',
    label: 'PSG Split',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
];

export const UPDATE_FIELDS = FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field);

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = '';
  return acc;
}, {});

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
