<script setup>
import { FORM_FIELDS } from './constants';
const props = defineProps({ record: Object });
function formatStatusCasing (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
const formFields = FORM_FIELDS;
</script>

<template>
  <div class="grid grid-cols-2 gap-4">
    <template v-for="(field, index) in formFields" :key="index">
      <div v-if="record?.[field.field]" class="flex flex-col gap-2">
        <span class="text-sm font-medium">{{field.label}}</span>
        <span class="text-sm text-neutral-500">{{formatStatusCasing(record?.[field.field]?.status)}}</span>
        <span v-if="record?.[field.field]?.status === 'positive'" class="text-sm text-neutral-500">{{record?.[field.field]?.text}}</span>
      </div>
    </template>
  </div>
</template>