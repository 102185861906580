<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="flex flex-col">
        <span class="text-sm font-medium mb-4">Functional Status</span>
        <div class="grid grid-cols-2 gap-4">
          <template v-for="(field, index) in recordFields" :key="index">
            <template v-if="field.type === 'header'">
              <div class="col-span-2 gap-4">
                <span class="text-sm font-bold">{{ field.label }}</span>
              </div>
            </template>

            <template v-else>
              <div v-if="getValue(field.field)" class="flex flex-col gap-2">
                <label class="text-sm font-medium">{{ field.label }}</label>
                <span class="text-sm">{{ getValue(field.field) }}</span>
              </div>
            </template>
          </template>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import { useFunctionalStatus } from './composables';
import { INDEPENDENCE_OPTIONS, RECORD_FIELDS } from './constants';
import { get } from 'lodash';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeFunctionalStatus } = useFunctionalStatus();

    const hoveringGenericPreview = ref(false);
    const recordFields = computed(() => RECORD_FIELDS);

    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const recordId = computed(() => props.record.id);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      // const result = globalThis.confirm('Are you sure you want to delete this care plan?');
      // if (!result) return;
      removeFunctionalStatus({ id: recordId.value });
    }

    function getValue (field) {
      const fieldVal = get(props.record, field);
      const independenceValues = INDEPENDENCE_OPTIONS.map(i => i.value);
      if (independenceValues.includes(fieldVal)) {
        const index = independenceValues.findIndex(i => i === fieldVal);
        if (index === -1) return;
        const nameVal = INDEPENDENCE_OPTIONS[index]?.name;
        return nameVal;
      };
      return fieldVal;
    }

    return {
      hoveringGenericPreview,
      createdAt,
      recordFields,
      edit,
      remove,
      getValue,
    };
  },
};
</script>
