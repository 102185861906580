<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="form-control">
      <div class="flex justify-between py-2">
        <span class="label-text">
          <span v-if="showLabel">HPI <span class="text-error">*</span></span>
        </span>
      </div>
      <div class="flex flex-col gap-4">
        <textarea
          v-model="model.text"
          placeholder="Enter details regarding present illness (e.g. Patient presents for evaluation of left ankle pain. Slipped on ice yesterday.)"
          class="h-[100px] textarea textarea-bordered focus:textarea-neutral"
          required
          :disabled="loading"
        ></textarea>
      </div>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        :disabled="loading"
        :loading="loading"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable || loading"
        :loading="loading"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { computed, ref } from 'vue';
import { useHpi } from './composables';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitHpi } = useHpi();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = ref({
      text: '',
      signsAndSymptoms: [
        {
          text: '',
          duration: '',
        },
      ],
    });

    const formSubmittable = computed(() => {
      return model.value.text.trim().length > 0;
    });

    const submit = async () => {
      try {
        loading.value = true;

        const payload = {
          ...model.value,
        };

        if (payload.signsAndSymptoms?.length) {
          payload.signsAndSymptoms = payload.signsAndSymptoms.map(item => ({
            ...(item.text && { text: item.text }),
            ...(item.duration && { duration: item.duration }),
          })).filter(item => item.text !== undefined || item.duration !== undefined);
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        await submitHpi(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function addSignsAndSymptoms () {
      if (!model.value.signsAndSymptoms.length) {
        model.value.signsAndSymptoms = [{
          text: '',
          duration: '',
        }];
      }
      const lastIndex = model.value.signsAndSymptoms.length - 1;
      const item = model.value.signsAndSymptoms[lastIndex];
      if (!item.text && !item.duration) return;
      model.value.signsAndSymptoms.push({
        text: '',
        duration: '',
      });
    }

    function onRemoveSignsAndSymptoms (index) {
      if (model.value.signsAndSymptoms.length === 1) return;
      model.value.signsAndSymptoms.splice(index, 1);
    }

    function resetForm () {
      record.value = {};
      model.value.text = '';
      model.value.signsAndSymptoms = [
        {
          text: '',
          duration: '',
        },
      ];
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.value.text = data.text;
      model.value.signsAndSymptoms = data?.signsAndSymptoms || [];
    }

    return {
      loading,
      model,
      recordId,
      isEditing,
      formSubmittable,
      addSignsAndSymptoms,
      onRemoveSignsAndSymptoms,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
