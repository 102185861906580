export const RECORD_TYPE = 'discharge-note';
export const DEFAULT_DISPOSITION = 'follow-up';

export const FORM_FIELDS = [
  {
    label: 'Follow-up date and time',
    field: 'followupAt',
    update: true,
    type: 'date',
    placeholder: 'e.g. 2004-02-20 12:00',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Follow-up location',
    field: 'followupLocation',
    update: true,
    type: 'text',
    placeholder: 'e.g. Great Eastern Hospital',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Follow-up reason',
    field: 'followupReason',
    update: true,
    type: 'text',
    placeholder: 'e.g. To assess patient progress after cholecystectomy',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Follow-up Instructions',
    field: 'followupInstructions',
    update: true,
    type: 'textarea',
    placeholder: 'e.g. Please bring all of your medications to the appointment',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
