<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex flex-col gap-4">
      <div class="grid grid-cols-2 gap-4">
        <!-- 1st Column -->
        <div class="flex flex-col gap-2 mb-1">
          <!-- Respiration Type -->
          <div class="form-control">
            <label class="label">
              <span class="label-text">Respiration Type Shown</span>
            </label>
            <div class="flex gap-2 h-10">
              <input type="radio" value="CPAP" class="radio radio-primary" checked v-model="respirationType"/>
              <span class="label-text">CPAP</span>
              <input type="radio" value="BiPAP" class="radio radio-primary" v-model="respirationType"/>
              <span class="label-text">BiPAP</span>
            </div>
          </div>
          <div v-if="respirationType === 'CPAP'">
            <!-- CPAP Pressure -->
            <div class="form-control">
              <label class="label">
                <span class="label-text">CPAP Pressure (cmH2O)</span>
              </label>
              <input type="number" placeholder="e.g. 10 cmH2O" class="input input-bordered focus:input-neutral" v-model="model.cpapPressure"/>
            </div>
          </div>
          <div class="flex flex-col gap-2" v-else>
            <!-- BPAP Inspiration -->
            <div class="form-control">
              <label class="label">
                <span class="label-text">BiPAP Inspiration (cmH2O)</span>
              </label>
              <input type="number" placeholder="cmH2O" class="input input-bordered focus:input-neutral" v-model="model.bipapInspiration" />
            </div>
            <!-- BPAP Expiration -->
            <div class="form-control">
              <label class="label">
                <span class="label-text">BiPAP Expiration (cmH2O)</span>
              </label>
              <input type="number" placeholder="cmH2O" class="input input-bordered focus:input-neutral" v-model="model.bipapExpiration" />
            </div>
          </div>
        </div>
        <!-- 2nd Column -->
        <div class="flex flex-col gap-2 mb-1">
          <!-- LSAT Pressure -->
          <div class="form-control">
            <label class="label">
              <span class="label-text">LSAT Pressure (%)<span class="text-red-500">*</span></span>
            </label>
            <input type="number" placeholder="e.g. 67.5%" class="input input-bordered focus:input-neutral" v-model="model.lsatPressure" />
          </div>
          <!-- AHI -->
          <div class="form-control">
            <label class="label">
              <span class="label-text">AHI (events/hr)<span class="text-red-500">*</span></span>
            </label>
            <input type="number" placeholder="e.g. 5 events/hr" class="input input-bordered focus:input-neutral" v-model="model.ahi"/>
          </div>
          <!-- Ramp Time -->
          <div class="form-control">
            <label class="label">
              <span class="label-text">Ramp Time (minutes)<span class="text-red-500">*</span></span>
            </label>
            <input type="number" placeholder="e.g. 25 minutes" class="input input-bordered focus:input-neutral" v-model="model.rampTime" />
          </div>
        </div>
      </div>

      <span class="text-primary">More Details</span>

      <!-- PSG Diagnostic -->
      <div class="form-control">
        <label class="label">
          <span class="label-text">PSG Diagnostic Findings</span>
        </label>
        <textarea
        class="textarea textarea-bordered w-full focus:textarea-neutral"
        placeholder="Enter diagnostic findings here (e.g. Patient has normal breathing patterns)"
        v-model="model.psgDiagnostic"/>
      </div>

      <!-- PSG Therapeutic -->
      <div class="form-control">
        <label class="label">
          <span class="label-text">PSG Therapeutic Findings</span>
        </label>
        <textarea
        class="textarea textarea-bordered w-full focus:textarea-neutral"
        placeholder="Enter therapy findings here (e.g. Patient has a sleep disorder)"
        v-model="model.psgTherapeutic"/>
      </div>

      <!-- PSG Split -->
      <div class="form-control">
        <label class="label">
          <span class="label-text">PSG Split Findings</span>
        </label>
        <textarea
        class="textarea textarea-bordered w-full focus:textarea-neutral"
        placeholder="Enter split-night findings here (e.g. Patient has a mild sleep disorder)"
        v-model="model.psgSplit"/>
      </div>
    </div>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { FORM_MODEL, UPDATE_FIELDS } from './constants';
import { computed, reactive, ref } from 'vue';
import { useENT } from './composables';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitEnt } = useENT();
    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);
    const respirationType = ref('CPAP');

    const formSubmittable = computed(() => validate(model));

    function validate (obj) {
      for (const key in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, key) &&
          obj[key] !== undefined &&
          obj[key] !== null &&
          obj[key] !== ''
        ) {
          return true;
        }
      }
      return false;
    }

    const submit = async () => {
      try {
        const payload = { ...model };

        Object.keys(payload).forEach(key => {
          if (!payload[key]) {
            delete payload[key];
          }
        });

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitEnt(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      const fields = Object.keys(data);
      fields.forEach(field => {
        model[field] = data[field];
      });
    }

    return {
      respirationType,
      model,
      formSubmittable,
      recordId,
      isEditing,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
