<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-${formField.type === 'textarea' ? 'full' : '1'}`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField.rules?.required" class="text-error">*</span></span>
            </span>
          </div>

          <template v-if="formField.type === 'select'">
            <select
              v-model="model[formField.field]"
              class="select select-bordered w-full focus:select-neutral"
              :name="formField.label"
              :disabled="loading"
              required
            >
              <!-- <option value="" selected disabled>Select {{formField.label}}</option> -->
              <option value="" v-if="formField.field === 'medicalCondition'" selected disabled>e.g Diabetes</option>
              <option value="" v-if="formField.field === 'medicalConditionAt'" selected disabled>e.g. 2015</option>
              <template v-for="(option, index) in formField.options" :key="index">
                <option :value="option.label || option">{{option.label || option}}</option>
              </template>
            </select>
          </template>

          <template v-if="formField.type === 'textarea'">
            <textarea
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Type here'"
              class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
            ></textarea>
          </template>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
      v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { format } from 'date-fns';
import { getUnixTimestampAtStartOfYear } from '../shared/utils';
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS } from './constants';
import { useEncounters } from '@/composables/use-encounters';
import { useMedicalHistory } from './composables';

export default {
  props: {
    showLabel: Boolean,
    patientId: String,
    encounterId: String,
    facilityId: String,
  },
  setup (props, { emit }) {
    const { submit: submitMedicalHistory } = useMedicalHistory();

    const { isEncounterActive } = useEncounters();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);

    const formSubmittable = computed(() => {
      return !!model.medicalCondition;
    });

    const submit = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        );

        if (model.medicalConditionAt) {
          payload.medicalConditionAt = getUnixTimestampAtStartOfYear(model.medicalConditionAt);
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitMedicalHistory(payload, { patientId: props.patientId, encounterId: props.encounterId, facilityId: props.facilityId });

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.medicalCondition = data.medicalCondition;
      if (data.medicalConditionAt) {
        const date = new Date(data.medicalConditionAt);
        const year = format(date, 'yyyy');
        model.medicalConditionAt = Number(year);
      }
      model.notes = data.notes;
    }

    return {
      loading,
      formSubmittable,
      model,
      recordId,
      isEditing,
      isEncounterActive,
      formFields: FORM_FIELDS,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
