export const RECORD_TYPE = 'hospitalization-history';

export const FORM_FIELDS = [
  {
    label: 'Reason for Hospitalization',
    field: 'reason',
    type: 'text',
    placeholder: 'e.g. Emergency surgery',
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Hospitalization Date',
    field: 'hospitalizedAt',
    type: 'date',
    placeholder: 'e.g. 2020-08-23',
    rules: {
      required: true,
      type: 'number',
    },
  },
  {
    label: 'Hospital Name',
    field: 'hospitalName',
    placeholder: 'e.g. Great Eastern Hospital',
    type: 'text',
    rules: {
      required: true,
      type: 'string',
    },
  },
  // { TODO: Implement Medication Order field in future update
  //   label: 'Medications Prescribed',
  //   field: 'medicationOrder',
  //   placeholder: 'e.g. Gingkobiloban',
  //   type: 'text',
  //   rules: {
  //     required: false,
  //     type: 'object',
  //   },
  // },
  {
    label: 'Procedures Performed',
    field: 'proceduresPerformed',
    placeholder: 'e.g. Incision Surgery, Blood Test',
    type: 'array',
    default: [],
    rules: {
      required: false,
      type: 'text',
    },
  },
  {
    label: 'Complications (if any)',
    field: 'complications',
    placeholder: 'e.g. Post-op infection',
    type: 'array',
    rules: {
      required: false,
      type: 'text',
    },
  },
  {
    label: 'Length of Stay (in days)',
    field: 'lengthOfStayInDays',
    placeholder: 'e.g. 3',
    type: 'text',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    label: 'Diagnosis',
    field: 'diagnosis',
    placeholder: 'e.g. Disc hernation',
    type: 'text',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Treatment',
    field: 'treatment',
    placeholder: 'e.g. Anti-inflammatory medication',
    type: 'text',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Notes',
    field: 'notes',
    placeholder: 'Enter additional hospitalization notes here (e.g. Patient was confined for five (5) days.)',
    update: true,
    type: 'textarea',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
