<template>
  <form @submit.prevent="submit" class="flex flex-col">
    <template v-for="(children, group) in mappedFormFields" :key="group">
      <div class="py-2">
        <div class="flex flex-row justify-start align-center gap-2 pt-2">
          <span class="label-text text-base font-bold">
            <span>{{group}}</span>
          </span>
          <div v-if="group === 'Assessment'" class="form-control">
            <label class="flex flex-row items-center gap-2">
              <span class="label-text">Seminar done?</span>
              <input type="checkbox" class="checkbox" v-model="isSeminarDone"/>
            </label>
          </div>
        </div>
        <div class="grid grid-cols-3 gap-4 place-items-stretch">
          <template v-for="(form, index) in children" :key="index">
            <div :class="`form-control col-span-${ fullFields.includes(form.type) ? 'full' :
              partiallyFullFields.includes(form.field) ? '2' : '1' } flex flex-col justify-between`"
              v-if="form.field !== 'satisfaction' || (form.field === 'satisfaction' && isSeminarDone)">
              <div class="flex flex-row justify-start gap-2 py-2">
                <span class="label-text">
                  <span>{{form.label}}
                    <span v-if="form.rules.required" class="text-error text-sm">*</span>
                  </span>
                </span>
              </div>
              <template v-if="form.type === 'select'">
                <select
                  v-model="model[form.field]"
                  :class="`select select-bordered w-full focus:select-neutral`"
                  :name="form.label"
                  :disabled="loading"
                >
                  <option selected disabled>{{ form.placeholder || `Select ${form.label}` }}</option>
                  <template v-for="(option, index) in form.options" :key="index">
                    <option :value="option">{{option}}</option>
                  </template>
                </select>
              </template>

              <template v-if="form.type === 'date'">
                <VDatePicker v-model.string="model[form.field]" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }">
                  <template #default="{ togglePopover }">
                    <input type="text" :placeholder="form.placeholder" class="input input-bordered w-full focus:input-neutral"
                      v-model="model[form.field]" @click="togglePopover" />
                  </template>
                </VDatePicker>
              </template>

              <template v-if="form.type === 'text' && form.field !== 'assessments'">
                <input
                  v-model="model[form.field]"
                  :placeholder="form.placeholder || 'Type here'"
                  :type="form.rules.type"
                  class="input input-bordered focus:input-neutral"
                  :disabled="loading"
                />
              </template>
              <template v-if="form.type === 'text' && form.field === 'assessments'">
                <div class="flex flex-row gap-2 justify-between">
                  <div class="flex flex-col gap-2 flex-1">
                    <span class="label-text">Pre-event</span>
                    <textarea
                      v-model="preAssessments"
                      placeholder="e.g. Patient expressed eagerness"
                      :type="form.rules.type"
                      class="textarea textarea-bordered focus:textarea-neutral w-full"
                      :disabled="loading"
                    />
                  </div>
                  <div class="flex flex-col gap-2 flex-1" v-if="isSeminarDone">
                    <span class="label-text">Post-event</span>
                    <textarea
                      v-model="postAssessments"
                      placeholder="e.g. Patient satisfied with learning materials"
                      class="textarea textarea-bordered focus:textarea-neutral w-full"
                      :type="form.rules.type"
                      :disabled="loading || !isSeminarDone"
                    />
                  </div>
                </div>
              </template>

              <template v-if="form.type === 'array'">
                <div class="flex flex-col">
                  <Chips
                    v-model="model[form.field]"
                    addOnBlur
                    :placeholder="form.placeholder"
                    class="input input-bordered focus:input-neutral h-[100%] py-2"
                    :pt="{
                      container: { class: 'w-full flex flex-row gap-2' },
                      token: { class: 'flex flex-row items-center align-center gap-2 join rounded rounded-full bg-neutral-300 shadow-sm px-2' },
                      label: { class: 'join-item' },
                      removeTokenIcon: { class: 'hover:bg-neutral-200 rounded-full' }
                    }"
                  />
                  <span class="text-xs text-neutral-500 py-2">Press 'Enter' if you'd like to add more</span>
                </div>
              </template>

              <template v-if="form.type === 'range' && isSeminarDone">
                <div class="flex flex-col gap-2">
                  <span>Satisfaction - <span>{{ satisfactionTexts[satisfactionScale] }}</span></span>
                  <input
                    v-model="satisfactionScale"
                    step="1"
                    type="range"
                    class="range range-sm"
                    :min="satisfactionMin"
                    :max="satisfactionMax"
                    :class="{ 'range-error': satisfactionScale <= satisfactionBadThreshold, 'range-warning': satisfactionScale <= satisfactionGoodThreshold, 'range-success': satisfactionScale > satisfactionGoodThreshold, 'bg-neutral-300': !isSeminarDone }"
                    :disabled="!isSeminarDone"
                  />
                  <div class="w-full flex justify-between text-xs px-2">
                    <template v-for="(text, index) in satisfactionTexts" :key="index">
                      <span>{{index}}</span>
                    </template>
                  </div>
                </div>
              </template>

              <template v-if="form.type === 'textarea'">
                <textarea
                  v-model="model[form.field]"
                  :placeholder="form.placeholder"
                  class="h-[100px] textarea textarea-bordered focus:textarea-neutral"
                  :disabled="loading"
                ></textarea>
              </template>
            </div>
          </template>
        </div>
      </div>
    </template>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import {
  UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES,
  SATISFACTION_MIN, SATISFACTION_BAD_THRESHOLD,
  SATISFACTION_GOOD_THRESHOLD, SATISFACTION_MAX,
  SATISFACTION_TEXTS,
} from './constants';
import { computed, reactive, ref } from 'vue';
import { usePatientEducation } from './composables';
import { groupBy } from 'lodash';
// import { TagsInput } from '@flavorly/vanilla-components';
import Chips from 'primevue/chips';
// import RichSelect from '@/components/commons/rich-select';

export default {
  props: {
    showLabel: Boolean,
  },
  components: {
    Chips,
  },
  setup (props, { emit }) {
    const { submit: submitPatientEducation } = usePatientEducation();

    const satisfactionScale = ref(0);
    const satisfactionMin = SATISFACTION_MIN;
    const satisfactionMax = SATISFACTION_MAX;
    const satisfactionBadThreshold = SATISFACTION_BAD_THRESHOLD;
    const satisfactionGoodThreshold = SATISFACTION_GOOD_THRESHOLD;
    const satisfactionTexts = SATISFACTION_TEXTS;

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);
    const isSeminarDone = ref(false);
    const fullFields = ref(['textarea', 'range']);
    const partiallyFullFields = ref(['goals', 'learningMaterials', 'assessments']);

    const model = reactive(FORM_MODEL);
    const preAssessments = ref(null);
    const postAssessments = ref(null);

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key].required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    const submit = async () => {
      try {
        const payload = {};
        const assessments = [];

        for (const key in model) {
          const value = model[key];
          if (value === '' || value === null || value === undefined || value === 'Not Asked') {
            continue; // Skip values that need to be removed
          }
          if (value === 'Yes') {
            payload[key] = true;
          } else if (value === 'No') {
            payload[key] = false;
          } else {
            payload[key] = value;
          }
        }

        if (satisfactionScale.value) payload.satisfaction = satisfactionScale.value;

        if (preAssessments.value) {
          assessments.push({
            type: 'pre',
            assessment: preAssessments.value,
          });
        }

        if (postAssessments.value) {
          assessments.push({
            type: 'post',
            assessment: postAssessments.value,
          });
        }

        if (assessments && assessments.length > 0) payload.assessments = assessments;

        if (model.learningMaterials && model.learningMaterials?.length > 0) {
          payload.learningMaterials = model.learningMaterials.map(i => ({
            title: i,
          }));
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitPatientEducation(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
      isSeminarDone.value = false;
      satisfactionScale.value = 0;
      preAssessments.value = '';
      postAssessments.value = '';
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;

      record.value = data;
      model.topic = data.topic;
      model.goals = data.goals;

      if (data.presentedAt) {
        const date = new Date(data.presentedAt);
        const dateFormattted = date.toISOString().split('T')[0];
        model.presentedAt = dateFormattted;
      }

      if (data?.learningMaterials && data.learningMaterials.length > 0) {
        model.learningMaterials = data.learningMaterials.map(i => i.title);
      }

      const preAssessment = data.assessments?.find(i => i.type === 'pre');
      const postAssessment = data.assessments?.find(i => i.type === 'post');
      if (preAssessment) preAssessments.value = preAssessment?.assessment;
      if (postAssessment) postAssessments.value = postAssessment.assessment;

      satisfactionScale.value = data.satisfaction;
      isSeminarDone.value = !!data.satisfaction;

      model.notes = data.notes;
    }

    const mappedFormFields = ref(groupBy(FORM_FIELDS, (o) => o?.group));

    return {
      model,
      recordId,
      loading,
      isEditing,
      isSeminarDone,
      fullFields,
      partiallyFullFields,
      formSubmittable,
      mappedFormFields,
      resetForm,
      submit,
      setForm,

      preAssessments,
      postAssessments,
      satisfactionScale,
      satisfactionMin,
      satisfactionMax,
      satisfactionBadThreshold,
      satisfactionGoodThreshold,
      satisfactionTexts,
    };
  },
};
</script>
