<template>
  <form>
    <div class="flex flex-col gap-4">
      <span class="text-primary text-xl">Meal Plan and Sample Menu</span>
      <div class="grid grid-cols-2 gap-4">
        <div class="bg-neutral-100 rounded-md p-4" v-for="(formField, index) in formFields" :key="index">
          <span class="text-primary">{{ formField.label }}</span>
          <div class="form-control mb-4" v-for="(field, i) in model[formField.field]" :key="i">
            <label class="label">
              <span class="label-text">{{ field.label }}</span>
            </label>
            <input type="time" class="input input-bordered w-full text-center" v-model="field.value" v-if="Object.hasOwn(field, 'value')"/>
            <div class="grid grid-cols-2 gap-x-4" v-else>
              <div class="form-control">
                <label class="label">
                  <span class="label-text-alt">Amount</span>
                </label>
                <input type="number" class="input input-bordered w-full" v-model="field.amount" />
              </div>
              <div class="form-control">
                <label class="label">
                  <span class="label-text-alt">Sample</span>
                </label>
                <input type="text" class="input input-bordered w-full" v-model="field.sample" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { FORM_FIELDS, FORM_MODEL, FORM_RULES } from './constants';
import { computed, ref } from 'vue';
import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator';
import { merge } from 'lodash';
import { useCloned } from '@vueuse/core';
import { useNutritionalPlan } from '~/components/medical-records/nutritional-plan/composables';

export default {
  props: {
    showLabel: Boolean,
    loading: Boolean,
  },
  setup (props, { emit }) {
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const { cloned: model, sync } = useCloned(FORM_MODEL);

    const {
      pass, // The overall state of the validation
      errorFields, // The fields that have errors
      // execute: validate, // The function to trigger the validation manually
    } = useAsyncValidator(model, FORM_RULES, { immediate: false, manual: true });

    const { getTimeFromUnixTimestamp } = useNutritionalPlan();

    // function validate (obj) {
    //   for (const key in obj) {
    //     if (
    //       Object.prototype.hasOwnProperty.call(obj, key) &&
    //       obj[key] !== undefined &&
    //       obj[key] !== null &&
    //       obj[key] !== ''
    //     ) {
    //       return true;
    //     }
    //   }
    //   return false;
    // }

    // The API doesn't like when you pass it fields containing nothing, so we delete empty fields
    function removeProperties (inputObj) {
      // Create a deep copy of the input object
      const obj = JSON.parse(JSON.stringify(inputObj));

      // Remove the label and field properties from all objects in the hierarchy
      function removeLabelAndField (object) {
        for (const key in object) {
          if (typeof object[key] === 'object' && object[key] !== null) {
            removeLabelAndField(object[key]);
          } else if (key === 'label' || key === 'field') {
            delete object[key];
          }
        }
      }

      // Remove properties recursively
      removeLabelAndField(obj);

      // Remove the "amount" property if its value is null, "sample" property if it is an empty string,
      // and "value" property if it is an empty string recursively
      function removeNullAndEmptyValues (object) {
        for (const key in object) {
          if (typeof object[key] === 'object' && object[key] !== null) {
            removeNullAndEmptyValues(object[key]);

            // Check if the object becomes empty after removing properties
            if (Object.keys(object[key]).length === 0) {
              delete object[key];
            }
          } else if (key === 'amount' && object[key] === null) {
            delete object[key];
          } else if (key === 'sample' && object[key] === '') {
            delete object[key];
          } else if (key === 'value' && object[key] === '') {
            delete object[key];
          }
        }
      }

      // Remove null "amount," empty "sample," and empty "value" properties recursively
      removeNullAndEmptyValues(obj);

      function convertTimeToUnixTimestamp (timeString) {
        const [hours, mins] = timeString.split(':');
        const baseTime = new Date(0);
        baseTime.setHours(parseInt(hours));
        baseTime.setMinutes(parseInt(mins));

        // Convert to Unix timestamp (in milliseconds)
        return baseTime.getTime();
      }

      // Transform the "time" object to a string with "value" set to its next value recursively
      function transformTimeToString (object) {
        for (const key in object) {
          if (typeof object[key] === 'object' && object[key] !== null) {
            transformTimeToString(object[key]);

            // Check if the object is a "time" object
            if (key === 'time') {
              object[key] = convertTimeToUnixTimestamp(object[key].value); // Set "time" object as a string with "value" as its value
            }
          }
        }
      }

      // Transform "time" objects to strings with "value" set to their values recursively
      transformTimeToString(obj);

      return obj;
    }

    function transformTime (obj) {
      // recursively look for the time property and apply transformation
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          transformTime(obj[key]);
        } else if (key === 'time' && !isNaN(obj[key])) {
          obj[key] = {
            field: 'time',
            label: 'Time',
            value: getTimeFromUnixTimestamp(obj[key]),
          };
        }
      }
    }

    const submit = () => {
      // const isValid = validate(model);
      //
      // if (!isValid) return;

      // Update
      if (isEditing.value) {
        const data = removeProperties({
          id: recordId.value,
          ...model.value,
        });

        return data;
      }

      // Create
      if (!isEditing.value) {
        const data = removeProperties({
          ...model.value,
        });

        return data;
      }
    };

    function resetForm () {
      record.value = {};
      sync();
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      transformTime(data);
      record.value = data;

      function updateObject (target, source) {
        const newTarget = JSON.parse(JSON.stringify(target));
        for (const key in source) {
          if (Object.hasOwn(newTarget, key)) {
            newTarget[key] = merge(newTarget[key], source[key]);
          }
        }
        return newTarget;
      }

      model.value = updateObject(model.value, data);
    }

    return {
      model,
      pass,
      errorFields,
      formFields: FORM_FIELDS,
      recordId,
      isEditing,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
