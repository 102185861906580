<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Educational Level</span>
          <span class="text-sm text-neutral-500">{{educationalLevel || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Occupation/s</span>
          <span class="text-sm text-neutral-500 break-words">{{occupations || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Hobbies</span>
          <span class="text-sm text-neutral-500 break-words">{{hobbies || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Lives Alone?</span>
          <span class="text-sm text-neutral-500">{{livesAlone || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Has Insurance?</span>
          <span class="text-sm text-neutral-500">{{hasInsurance || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Has available caregivers?</span>
          <span class="text-sm text-neutral-500">{{independent || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Can afford their medical expenses?</span>
          <span class="text-sm text-neutral-500">{{canAffordMedicalExpenses || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Has a strong support system?</span>
          <span class="text-sm text-neutral-500">{{hasStrongSupportSystem || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Is involved in community activities?</span>
          <span class="text-sm text-neutral-500">{{isInvolvedInCommunity || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Is Exercising?</span>
          <span class="text-sm text-neutral-500">{{exercises || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium truncate">Is Smoking?</span>
          <span class="text-sm text-neutral-500">{{smoking || '-'}}
            <span v-if="/yes/gi.test(smoking) && smokingSticksPerDay">({{ smokingSticksPerDay || '-' }} sticks per day)</span>
          </span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Uses Prohibited Drugs?</span>
          <span class="text-sm text-neutral-500">{{usesProhibitedDrugs || '-'}}
            <span v-if="/yes/gi.test(usesProhibitedDrugs) && usesProhibitedDrugsPerDay">({{ usesProhibitedDrugsPerDay || '-' }} times a day)</span>
          </span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Is Sexually Active?</span>
          <span class="text-sm text-neutral-500">{{sexuallyActive || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Drinks Alcohol?</span>
          <span class="text-sm text-neutral-500">{{drinksAlcohol || '-'}}
            <span v-if="/yes/gi.test(drinksAlcohol) && drinksAlcoholPerDayBottlesCount">({{ drinksAlcoholPerDayBottlesCount || '-' }} bottles per day)</span>
          </span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Has any mental health problems?</span>
          <span class="text-sm text-neutral-500">{{hasMentalHealthIssues || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Has any recent stressors/challenges?</span>
          <span class="text-sm text-neutral-500">{{hasRecentStressors || '-'}}</span>
        </div>

      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { isBoolean } from 'lodash';
import { format } from 'date-fns';
import { useSocialHistory } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeSocialHistory } = useSocialHistory();

    const others = computed(() => props.record.others);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const educationalLevel = computed(() => props.record.educationalLevel);
    const occupations = computed(() => props.record.occupations ? props.record.occupations.map(i => i.position).join(', ') : null);
    const hobbies = computed(() => props.record.hobbies ? props.record.hobbies.map(i => i?.name?.trim()).join(', ') : null);
    const livesAlone = computed(() => isBoolean(props.record.livesAlone) ? (props.record.livesAlone === true ? 'YES, lives alone' : 'No, lives with others') : 'Not Asked');
    const hasInsurance = computed(() => isBoolean(props.record.hasInsurance) ? (props.record.hasInsurance === true ? 'YES, has adequate health insurance.' : 'NO, does not have adequate health insurance.') : 'Not Asked');
    const independent = computed(() => isBoolean(props.record.independent) ? (props.record.independent === true ? 'YES, has available caregivers.' : 'NO, has no available caregivers.') : 'Not Asked');
    const canAffordMedicalExpenses = computed(() => isBoolean(props.record.canAffordMedicalExpenses) ? (props.record.canAffordMedicalExpenses === true ? 'YES, can afford medical expenses.' : 'NO, cannot afford their medical expenses.') : 'Not Asked');
    const hasStrongSupportSystem = computed(() => isBoolean(props.record.hasStrongSupportSystem) ? (props.record.hasStrongSupportSystem === true ? 'YES, has a strong support system.' : 'NO, does not have a strong support system.') : 'Not Asked');
    const isInvolvedInCommunity = computed(() => isBoolean(props.record.isInvolvedInCommunity) ? (props.record.isInvolvedInCommunity === true ? 'YES, is involved in community activities.' : 'NO, is not involved in community activities.') : 'Not Asked');
    const hasMentalHealthIssues = computed(() => isBoolean(props.record.hasMentalHealthIssues) ? (props.record.hasMentalHealthIssues === true ? 'YES, patient has mental health problems.' : 'NO, patient does not have mental health problems.') : 'Not Asked');
    const hasRecentStressors = computed(() => isBoolean(props.record.hasRecentStressors) ? (props.record.hasRecentStressors === true ? 'YES, patient has recently faced stressors/challenges.' : 'NO, patient has not reecently faced stressors/challenges.') : 'Not Asked');
    const exercises = computed(() => isBoolean(props.record.exercises) ? (props.record.exercises === true ? 'YES, the patient exercises.' : 'NO, the patient does not exercise.') : 'Not Asked');
    const smoking = computed(() => isBoolean(props.record.smoking) ? (props.record.smoking === true ? 'YES, patient is a smoker.' : 'NO, patient does not smoke.') : 'Not Asked');
    const smokingSticksPerDay = computed(() => props.record.smokingSticksPerDay);
    const usesProhibitedDrugs = computed(() => isBoolean(props.record.usesProhibitedDrugs) ? (props.record.usesProhibitedDrugs === true ? 'YES, patient uses prohibited drugs.' : 'NO, patient does not use prohibited drugs.') : 'Not Asked');
    const usesProhibitedDrugsPerDay = computed(() => props.record.usesProhibitedDrugsPerDay);
    const sexuallyActive = computed(() => isBoolean(props.record.sexuallyActive) ? (props.record.sexuallyActive === true ? 'YES, patient is sexually active.' : 'NO, patient is not sexually active.') : 'Not Asked');
    const drinksAlcohol = computed(() => isBoolean(props.record.drinksAlcohol) ? (props.record.drinksAlcohol === true ? 'YES, patient drinks alcohol.' : 'NO, patient does not drink alcohol.') : 'Not Asked');
    const drinksAlcoholPerDayBottlesCount = computed(() => props.record.drinksAlcoholPerDayBottlesCount);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeSocialHistory({ id: props.record.id });
    }

    return {
      createdAt,
      educationalLevel,
      exercises,
      smoking,
      smokingSticksPerDay,
      usesProhibitedDrugs,
      usesProhibitedDrugsPerDay,
      sexuallyActive,
      drinksAlcohol,
      drinksAlcoholPerDayBottlesCount,
      occupations,
      hobbies,
      livesAlone,
      hasInsurance,
      independent,
      canAffordMedicalExpenses,
      hasStrongSupportSystem,
      isInvolvedInCommunity,
      hasMentalHealthIssues,
      hasRecentStressors,
      others,
      edit,
      remove,
    };
  },
};
</script>
