const teethLocations= {
  aq1: {
    label: 'Adult Quadrant 1',
    locs: [
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
    ],
  },
  aq2: {
    label: 'Adult Quadrant 2',
    locs: [
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
    ],
  },
  aq3: {
    label: 'Adult Quadrant 3',
    locs: [
      '41',
      '42',
      '43',
      '44',
      '45',
      '46',
      '47',
      '48',
    ],
  },
  aq4: {
    label: 'Adult Quadrant 4',
    locs: [
      '31',
      '32',
      '33',
      '44',
      '35',
      '36',
      '67',
      '68',
    ],
  },
  cq1: {
    label: 'Child Quadrant 1',
    locs: [
      '51',
      '52',
      '53',
      '54',
      '55',
    ],
  },
  cq2: {
    label: 'Child Quadrant 2',
    locs: [
      '61',
      '62',
      '63',
      '64',
      '65',
    ],
  },
  cq3: {
    label: 'Child Quadrant 3',
    locs: [
      '81',
      '82',
      '83',
      '84',
      '85',
    ],
  },
  cq4: {
    label: 'Child Quadrant 4',
    locs: [
      '71',
      '72',
      '73',
      '74',
      '75',
    ],
  },
};

function flattenTeethLocations (data) {
    let result = [];

    // Iterate over each property in the object
    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            let item = data[key];

            // Map each loc value to the desired format
            item.locs.forEach(loc => {
                result.push({
                    label: `${item.label} - ${loc}`,
                    value: loc
                });
            });
        }
    }

    return result;
}


const teethLocationFlat = flattenTeethLocations(teethLocations);

export default teethLocations;
export { teethLocationFlat };