<script setup>
import { computed, ref } from 'vue';
import { FORM_FIELDS, TEETH_CONDITIONS, HABITS, COMPLICATIONS } from './constants'
import { isEmpty } from 'lodash';
import { useDentalHistory } from './composables';
import { is } from 'date-fns/locale';

const props = defineProps({
  showLabel: Boolean,
  patientId: String,
  encounterId: String,
  facilityId: String,
});

const emit = defineEmits(['submit', 'error', 'showSecondModal']);

defineExpose({
  setForm,
  resetForm,
});

const { submit: submitDentalHistory } = useDentalHistory();

const loading = ref(false);
const record = ref({});
const recordId = computed(() => record.value.id);
const isEditing = computed(() => !!recordId.value);

const teethConditionsModel = ref({ ...TEETH_CONDITIONS  });
const nonCheckBoxFields = ref([ ...FORM_FIELDS ]);
const nonCheckBoxModel = ref(FORM_FIELDS.reduce((acc, field) => {
  acc[field.model] = '';
  return acc;
}, {}));
const habitsModel = ref({ ...HABITS });
const complicationsModel = ref({ ...COMPLICATIONS });
const notesModel = ref('');

const payload = computed(() => {
  const teeth = Object.entries({ ...teethConditionsModel.value })
    .filter(([key, value]) => value)
    .map(([key, value]) => key);

  const nonCheckBoxFieldsPayload = nonCheckBoxFields.value.reduce((acc, field) => {
    acc[field.model] = nonCheckBoxModel.value[field.model];
    if (!acc[field.model]) {
      delete acc[field.model];
    }
    return acc;
  }, {});

  const habits = Object.entries({ ...habitsModel.value })
    .filter(([key, value]) => value)
    .map(([key, value]) => key);

  const complications = Object.entries({ ...complicationsModel.value })
    .filter(([key, value]) => value)
    .map(([key, value]) => key);

  const payload = {
    ...nonCheckBoxFieldsPayload,
  };

  if (teeth.length) {
    payload.teeth = teeth;
  }

  if (habits.length) {
    payload.habits = habits;
  }

  if (complications.length) {
    payload.complications = complications;
  }

  if (notesModel.value) {
    payload.notes = notesModel.value;
  }

  return payload;
});

const formSubmittable = computed(() => !isEmpty(payload.value));

async function submit () {
  if (isEditing.value) {
    payload.value.id = recordId.value;
  }

  try {
    loading.value = true;
    await submitDentalHistory(payload.value, { patientId: props.patientId, encounterId: props.encounterId, facilityId: props.facilityId });

    resetForm();
    emit('submit');
    emit('success');
  } catch (e) {
    console.error('Something went wrong. Try again later.');
    emit('error', e);
  } finally {
    loading.value = false;
  }
}

function setForm (data) {
  if (!data) return;
  record.value = data;
  if (!data.teeth) data.teeth = [];
  if (!data.habits) data.habits = [];
  if (!data.complications) data.complications = [];
  teethConditionsModel.value = data.teeth.reduce((acc, tooth) => {
    acc[tooth] = true;
    return acc;
  }, { ...TEETH_CONDITIONS });
  nonCheckBoxModel.value = nonCheckBoxFields.value.reduce((acc, field) => {
    acc[field.model] = data[field.model];
    return acc;
  }, {});
  habitsModel.value = data.habits.reduce((acc, habit) => {
    acc[habit] = true;
    return acc;
  }, { ...HABITS });
  complicationsModel.value = data.complications.reduce((acc, complication) => {
    acc[complication] = true;
    return acc;
  }, { ...COMPLICATIONS });
  notesModel.value = data.notes;
}

function resetForm () {
  teethConditionsModel.value = { ...TEETH_CONDITIONS };
  nonCheckBoxModel.value = nonCheckBoxFields.value.reduce((acc, field) => {
    acc[field.model] = '';
    return acc;
  }, {});
  habitsModel.value = { ...HABITS };
  complicationsModel.value = { ...COMPLICATIONS };
  notesModel.value = '';
}
</script>


<template>
  <form @submit.prevent="submit" class="flex flex-col gap-4">
    <label class="font-medium">Teeth</label>
    <div class="flex flex-wrap gap-2">
      <template v-for="(value, key) in teethConditionsModel">
        <div class="form-control">
          <label class="flex gap-2 items-center label cursor-pointer">
            <input v-model="teethConditionsModel[key]" type="checkbox" class="checkbox" />
            <span class="label-text">{{ key }}</span> 
          </label>
        </div>
      </template>
    </div>

    <div class="grid grid-cols-3 gap-2">
      <template v-for="field in nonCheckBoxFields">
        <div class="form-control">
          <label class="label">
            {{ field.label }}
          </label>
          <template v-if="field.type === 'select'">
            <select 
              v-model="nonCheckBoxModel[field.model]" 
              class="select select-bordered w-full" 
              :placeholder="field.placeholder"
            >
              <option v-for="option in field.options" :value="option">{{ option }}</option>
            </select>
          </template>
          <template v-else-if="field.type === 'text'">
            <input 
              v-model="nonCheckBoxModel[field.model]" 
              type="text" 
              class="input input-bordered w-full" 
              :placeholder="field.placeholder"
            />
          </template>
        </div>
      </template>
    </div>

    <label class="font-medium">Habit</label>
    <div class="flex flex-wrap gap-2">
      <template v-for="(value, key) in habitsModel">
        <div class="form-control">
          <label class="flex gap-2 items-center label cursor-pointer">
            <input v-model="habitsModel[key]" type="checkbox" class="checkbox" />
            <span class="label-text">{{ key }}</span> 
          </label>
        </div>
      </template>
    </div>
    
    <label class="font-medium">Complications</label>
    <div class="flex flex-wrap gap-2">
      <template v-for="(value, key) in complicationsModel">
        <div class="form-control">
          <label class="flex gap-2 items-center label cursor-pointer">
            <input v-model="complicationsModel[key]" type="checkbox" class="checkbox" />
            <span class="label-text">{{ key }}</span> 
          </label>
        </div>
      </template>
    </div>

    <div class="form-control">
      <label class="label">
        Notes
      </label>
        <textarea 
          v-model="notesModel" 
          class="textarea textarea-bordered w-full" 
          placeholder="Type here"
        ></textarea>  
    </div>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>
