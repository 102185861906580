<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex gap-2">
      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Vaccine Name <span class="text-red-500">*</span></span>
        </label>
        <select v-model="model.vaccine" class="select select-bordered">
          <option value="" selected disabled>e.g. BCG</option>
          <!-- TODO: implement optsgroup -->
          <option v-for="(vaccine, index) in vaccinesEnum" :key="index" :value="vaccine">{{ vaccine }}</option>
        </select>
      </div>

      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Route of Administration <span class="text-red-500">*</span></span>
        </label>
        <select v-model="model.injectionSite" required class="select select-bordered w-full">
          <option value="" selected disabled>e.g. Oral</option>
          <template v-for="(route, index) in vaccineRoutesEnum" :key="index">
            <option :value="route">{{ route }}</option>
          </template>
        </select>
      </div>
    </div>

    <div class="flex gap-2">
      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Administered By</span>
        </label>
        <input
          v-model="model.administeredBy"
          type="text"
          class="input input-bordered"
          placeholder="e.g. Doe, John"
        >
      </div>

      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Date Administered</span>
        </label>
        <input 
          v-model.string="model.administeredAt" 
          type="date"
          class="input input-bordered"
          :max="today"
        />
        <!-- <ClientOnly>
          <VDatePicker v-model.string="model.administeredAt" mode="date" :max-date="new Date()" :masks="{ modelValue: 'YYYY-MM-DD' }">
            <template #default="{ togglePopover }">
              <input type="text" placeholder="e.g. 2020-10-24" class="input input-bordered w-full focus:input-neutral"
                v-model="model.administeredAt" @click="togglePopover" />
            </template>
          </VDatePicker>
        </ClientOnly> -->
      </div>
    </div>

    <div class="flex gap-2">
      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Lot No.</span>
        </label>
        <input
          v-model="model.lotNo"
          type="text"
          class="input input-bordered"
          placeholder="e.g. EH9899"
        >
      </div>

      <div class="form-control w-full">
        <label class="label">
          <span class="label-text">Expiration Date</span>
        </label>
        <input 
          v-model.string="model.expiresAt" 
          type="date"
          class="input input-bordered"
          :min="today"
        />
        <!-- <ClientOnly>
          <VDatePicker v-model.string="model.expiresAt" mode="date" :min="new Date()" :masks="{ modelValue: 'YYYY-MM-DD' }">
            <template #default="{ togglePopover }">
              <input type="text" placeholder="e.g. 2020-12-24" class="input input-bordered w-full focus:input-neutral"
                v-model="model.expiresAt" @click="togglePopover" />
            </template>
          </VDatePicker>
        </ClientOnly> -->
      </div>
    </div>

    <div class="form-control w-full">
      <label class="label">
        <span class="label-text">Remarks</span>
      </label>
      <textarea
      v-model="model.note"
      class="textarea textarea-bordered"
      placeholder="Enter additional vaccine remarks (e.g. First dose administered at recreational facility)"
      ></textarea>
    </div>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { VACCINES, ROUTES_OF_ADMINISTRATION, VACCINES_CATEGORY } from './constants';
import { computed, reactive, ref, watch } from 'vue';
import { format } from 'date-fns';
import { useVaccinations } from './composables';
import { useAuthentication } from '@/composables/use-authentication';
import { map, includes } from 'lodash';

export default {
  props: {
    showLabel: Boolean,
    administeredBy: String,
    administeredAt: String,
    patientId: String,
    encounterId: String,
    facilityId: String,
  },
  setup (props, { emit }) {
    const { records, submit: submitVaccinations } = useVaccinations();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);
    const existingVaccines = computed(() => records.value.map(i => i?.vaccine));
    const existingVaccinesRef = ref([]);
    watch(existingVaccines, (newVal) => {
      existingVaccinesRef.value = newVal;
    }, { deep: true, immediate: true });

    const model = reactive({
      vaccine: '',
      injectionSite: '',
      administeredBy: '',
      administeredAt: format(new Date(), 'yyyy-MM-dd'),
      lotNo: '',
      expiresAt: '',
      note: '',
    });

    const formSubmittable = computed(() => {
      if (!(model?.vaccine && model?.injectionSite)) return false;
      return Object.values(model).some(Boolean);
    });

    const { currentUser } = useAuthentication();

    watch(currentUser, (val) => {
      model.administeredBy = val?.formattedName;
    });

    function escapeRegExp (str) {
      return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
    const mapVaccines = (vaccines) => {
      const regexPattern = `^(${existingVaccinesRef.value.map(vaccine => escapeRegExp(vaccine)).join('|')})$`;
      const existingVaccinesPattern = new RegExp(regexPattern);
      const unusedVaccines = vaccines.filter(i => !existingVaccinesPattern.test(i));
      const mappedCategory = map(VACCINES_CATEGORY, category => {
        const updatedCategory = { ...category };
        for (const key in updatedCategory) {
          updatedCategory[key] = unusedVaccines.filter(vaccine => includes(updatedCategory[key], vaccine.split(' ')[0]));
        }
        return updatedCategory;
      });
      const fixedMappedCategory = mappedCategory.map((i) => {
        return { text: Object.keys(i)?.[0], value: Object.keys(i)?.[0], children: Object.values(i)?.[0] };
      });
      return fixedMappedCategory;
    };

    const vaccinesEnum = computed(() => VACCINES);
    const vaccineRoutesEnum = ROUTES_OF_ADMINISTRATION;

    const submit = async () => {
      try {
        if (!model.vaccine || !model.injectionSite) return;

        const payload = {
          vaccine: model.vaccine,
          injectionSite: model.injectionSite,
          administeredBy: model.administeredBy,
          administeredAt: model.administeredAt && new Date(model.administeredAt).getTime(),
          lotNo: model.lotNo,
          expiresAt: model.expiresAt && new Date(model.expiresAt).getTime(),
          note: model.note,
        };

        // remove nullish and falsy values from payload
        Object.keys(payload).forEach(key => {
          if (!payload[key]) delete payload[key];
        });

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitVaccinations(payload, { patientId: props.patientId, encounterId: props.encounterId, facilityId: props.facilityId });

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      model.vaccine = '';
      model.injectionSite = '';
      // model.administeredBy = '';
      // model.administeredAt = '';
      model.lotNo = '';
      model.expiresAt = '';
      model.note = '';
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      const dateFields = [
        'administeredAt',
        'expiresAt',
      ];
      const fields = Object.keys(model);
      fields.forEach(field => {
        if (dateFields.includes(field)) {
          model[field] = data[field] ? format(new Date(data[field]), 'yyyy-MM-dd') : '';
          return;
        }
        model[field] = data[field];
      });
    }

    const today = format(new Date(), 'yyyy-MM-dd');

    return {
      loading,
      model,
      recordId,
      isEditing,
      vaccinesEnum,
      vaccineRoutesEnum,
      formSubmittable,
      today,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: #A3A3A3;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
