export const RECORD_TYPE = 'gynecological-history';

export const FORM_FIELDS = [
  {
    label: 'Pap Smear Date',
    field: 'prevCervicSmearsDate',
    update: true,
    type: 'date',
    placeholder: 'e.g 2023-10-11',
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Pap Smear Results',
    field: 'prevCervicSmearsResult',
    update: true,
    type: 'textarea',
    placeholder: 'e.g Adequate smear. Interpretation: Negative for intraepithelial lesions or malignancy.',
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Pelvic Exam',
    field: 'prevProblemTreatmentsPelvicExam',
    update: true,
    type: 'text',
    placeholder: 'e.g. External genitalia normal',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Current Contraception Method',
    field: 'currentContraception',
    update: true,
    type: 'text',
    placeholder: 'e.g. Birth control pills',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Other Gynecological Findings',
    field: 'prevProblemsTreatmentsOthers',
    placeholder: 'e.g. Vaginal discharge present, white and thick. Patient denies any other symptoms.',
    update: true,
    type: 'textarea',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.filter((field) => field.update).map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
