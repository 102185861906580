<template>
<div
  class="relative w-full overflow-hidden" :class="{ 'max-h-[80px]': !expanded }"
  @mouseover="showExpand = true"
  @mouseleave="showExpand = false"
>
  <div :id="elementId">
    <slot name="default"/>
  </div>
</div>
<!-- Might bring back later. Ask Joff -->
<!-- <div v-if="expandEnabled" class="flex justify-center items-center mt-4">
  <button v-if="!expanded" @click="expanded = true" class="h-full w-full">
    <small>Show more</small>
  </button>
  <button v-if="expanded" @click="expanded = false" class="h-full w-full">
    <small>Show less</small>
  </button>
</div> -->
</template>

<script>
import { ref, computed, onMounted } from 'vue';
export default {
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
  },
  setup (props) {
    const expanded = ref(true); // Default to true for now
    const showExpand = ref(false);
    const expandEnabled = ref(false);
    const recordId = computed(() => props.record?.id);
    const elementId = computed(() => `preview-wrapper-${recordId.value}`);
    const containerHeight = computed(() => {
      const element = document.getElementById(elementId.value);
      return element?.clientHeight;
    });

    onMounted(() => {
      if (containerHeight.value > 80) {
        expandEnabled.value = true;
      }
    });

    return {
      expanded,
      showExpand,
      expandEnabled,
      recordId,
      elementId,
    };
  },
};
</script>
