<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex flex-col gap-4">
      <template v-for="(children, group) in mappedFormFields" :key="group">
        <div class="col-span-full">
          <div class="flex justify-between">
            <span class="label-text text-base font-bold">
              <span>{{ group }}</span>
            </span>
          </div>
        </div>
        <div class="grid grid-cols-3 col-span-full gap-4">
          <template v-for="(form, index) in children" :key="index">
            <div :class="`flex flex-col form-control col-span-${form.type === 'textarea' ? 'full' : '1'} justify-between gap-2`">
              <span class="label-text">{{ form.label }}<span v-if="form?.rules?.required" class="text-error">*</span></span>
              <template v-if="form.type === 'text'">
                <input
                  v-model="model[form.field]"
                  :placeholder="form.placeholder || 'type here'"
                  class="input input-bordered focus:input-neutral w-full"
                  :min="0"
                  :disabled="loading"
                  :type="form.rules.type"
                />
              </template>
              <template v-if="form.type === 'select'">
                <select
                  v-model="model[form.field]"
                  class="select select-bordered focus:select-neutral w-full"
                  :disabled="loading"
                >
                  <option selected disabled>e.g. Yes</option>
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </template>
              <template v-if="form.type === 'textarea'">
                <textarea
                  v-model="model[form.field]"
                  :placeholder="form.placeholder || 'type here'"
                  class="h-[78px] textarea textarea-bordered focus:textarea-neutral"
                  :disabled="loading"
                ></textarea>
              </template>
            </div>
          </template>
        </div>
        <!-- <div :class="`form-control col-span-${formField.type === 'textarea' ? 'full' : '1'}`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField?.rules?.required" class="text-error">*</span></span>
            </span>
          </div>
          <template v-if="formField.type === 'select'">
            <select
              v-model="model[formField.field]"
              class="select select-bordered w-full focus:select-neutral"
              :name="formField.label"
              :disabled="loading"
              :required="formField.rules.required"
            >
              <option value="" selected disabled>{{ formField.placeholder }}</option>
              <template v-for="(option, index) in formField.options" :key="index">
                <option :value="option">{{option}}</option>
              </template>
            </select>
          </template>
          <template v-else-if="formField.type === 'textarea'">
            <textarea
              v-model="model[formField.field]"
              :placeholder="formField.placeholder"
              class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
            ></textarea>
          </template>
          <template v-else>
            <input
              v-if="formField.type !== 'date'"
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Input Text'"
              class="flex-auto input input-bordered focus:input-neutral"
              :type="formField.text"
              :disabled="loading"
            />
            <ClientOnly v-else>
              <VDatePicker v-model.string="model[formField.field]" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }">
                <template #default="{ togglePopover }">
                  <input type="text" :placeholder="formField.placeholder" class="input input-bordered w-full focus:input-neutral"
                    v-model="model[formField.field]" @click="togglePopover" />
                </template>
              </VDatePicker>
            </ClientOnly>
          </template>
        </div> -->
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        Submit
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { computed, reactive, ref } from 'vue';
import { useObstetricHistory } from './composables';
import { groupBy, isEmpty } from 'lodash';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitObstetricHistory } = useObstetricHistory();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);
    model.fetalMovement = {};

    const submit = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries(model)
            .filter(([key, value]) => {
              // check if value is falsy, include empty object, and empty arrays
              if (typeof value === 'object') return !isEmpty(value);
              if (typeof value === 'boolean') return true;
              if (!value) return false;
              return true;
            }),
        );

        if (model?.pregnancyLossesStillbirths) {
          payload.pregnancyLosses = [{
            type: 'stillborn',
            count: model?.pregnancyLossesStillbirths,
          }];
          delete payload.pregnancyLossesStillbirths;
        }

        if (model?.gravidity) model.gravidity = String(model.gravidity);

        if (model?.parity) model.parity = String(model.parity);

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitObstetricHistory(payload);
        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key]?.required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.fetalMovement = {};
      model.gravidity = data.gravidity;
      model.parity = data.parity;
      model.fulltermCount = data.fulltermCount || 0;
      model.prematureCount = data.prematureCount || 0;
      model.abortionCount = data.abortionCount || 0;
      model.liveChildrenCount = data.liveChildrenCount || 0;
      model.withFamilyPlanningCounselling = data.withFamilyPlanningCounselling;
      model.withPregnancyInducedHypertension = data.withPregnancyInducedHypertension;
      if (data?.pregnancyLosses && data.pregnancyLosses.find(v => v?.type === 'stillborn')) {
        const stillbornData = data.pregnancyLosses.find(v => v?.type === 'stillborn');
        model.pregnancyLossesStillbirths = stillbornData?.count;
      } else {
        model.pregnancyLossesStillbirths = 0;
      }
      model.notes = data.notes;
    }

    const mappedFormFields = ref(groupBy(FORM_FIELDS, (o) => o?.group));

    return {
      loading,
      model,
      recordId,
      isEditing,
      mappedFormFields,
      formSubmittable,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option {
  color: black;
}
</style>
