import { computed } from 'vue';
import { useSdk } from '@/composables/use-mycure';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { usePatient } from '@/composables/use-patients';
import { useEncounters } from '@/composables/use-encounters';
import { RECORD_TYPE } from './constants';
import { pick } from 'lodash-es';

export function usePrescription () {
  const sdk = useSdk();
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
  } = useMedicalRecords();

  const { patient } = usePatient();
  const { encounter } = useEncounters();

  const records = computed({
    get () {
      return medicalRecords.value.filter((record) => record.type === RECORD_TYPE);
    },
    set ({ type, data }) {
      if (type === 'create') {
        records.value.unshift(data);
      }

      if (type === 'update') {
        const index = records.value.findIndex((record) => record.id === data.id);
        records.value.splice(index, 1, data);
      }

      if (type === 'remove') {
        const index = records.value.findIndex((record) => record.id === data.id);
        records.value.splice(index, 1);
      }
    },
  });

  const submit = async (data, opts) => {
    const payload = {
      ...data,
    };
    if (data?.id) {
      const id = data.id;
      await updateMedicalRecord(id, data);
    } else {
      payload.type = RECORD_TYPE;
      payload.encounter = opts.encounter || encounter.value?.id;
      payload.patient = opts.patient || patient.value?.id;
      await createMedicalRecord(payload);
    }
  };

  const remove = async ({ id }) => {
    await removeMedicalRecord(id);
  };

  const sendToPharmacy = async (record) => {
    if (!record.id) throw new Error('Record ID is required');
    if (!record.items?.length) throw new Error('Prescription items are required');
    const data = {
      status: 'finalized',
      source: 'external',
      tags: ['parmazip-telepharmacy'],
      prescriber: pick(record.createdByDetails, 'name'),
    };
    const px = await sdk.get('medical-patients', record.patient);
    data.prescribee = pick(px, ['name', 'dateOfBirth', 'sex']);
    const currentUser = await sdk.currentUser();
    const connectedmembership = await sdk.get('organization-members', {
      uid: currentUser.uid,
      roles: 'pharmacist',
      tags: 'parmazip-pharmacist',
    });
    if (!connectedmembership) throw new Error('User is not connected to any pharmacy');
    console.log('Connected membership', connectedmembership);
    data.organization = connectedmembership.organization;
    data.items = record.items.map(it => {
      const obj = {
        genericName: it.genericName,
        brandName: it.brandName,
        sig: it.dosageSig,
        formulation: it.formulation,
        notes: it.note,
      };
      if (it.dispense) {
        obj.dispense = parseInt(it.dispense);
        if (Number.isNaN(obj.dispense)) delete obj.dispense;
      }
      return obj;
    });
    await sdk.create('pharmacy-medication-orders', data);
  };

  return {
    medicalRecords,
    records,
    submit,
    remove,
    sendToPharmacy,
  };
}
