<script setup>
import { startCase } from 'lodash-es';
import { computed, toRef } from 'vue';

const props = defineProps({
  record: {
    type: Object,
    defafault: () => ({}),
  },
});

const record = toRef(props, 'record');

const signsAndSymptoms = computed(() => record.value.signsAndSymptoms);
</script>

<template>
  <div class="flex flex-col gap-4">
    
    <div v-if="signsAndSymptoms?.length > 0" class="flex flex-col">
      <span class="text-sm font-medium pb-2">Minor Ailments</span>
      <template v-for="(item, index) in signsAndSymptoms" :key="index">
        <span class="text-sm truncate md:truncate-none pr-2 text-neutral-500">{{startCase(item.text)}}</span>
      </template>
    </div>
    <!-- <p class="text-sm font-medium">Pain Level: <span class="text-sm text-neutral-500">{{painLevel}}</span></p> -->
  </div>
</template>