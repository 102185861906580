import { computed } from 'vue';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { useFixtures } from '@/composables/use-fixtures';
import { usePatient } from '@/composables/use-patients';
import { useEncounters } from '@/composables/use-encounters';
import { RECORD_TYPE, UPDATE_FIELDS, ORDER_TYPE } from './constants';
import { useFacilities } from '@/composables/use-facilities';
// import { isNil } from 'lodash';

export function useRadiologyResults () {
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
  } = useMedicalRecords();
  const {
    getICD10,
  } = useFixtures();
  // const orders = ref([]);

  const { patient } = usePatient();
  const { encounter } = useEncounters();
  const { activeFacility } = useFacilities();

  const records = computed(() => {
    return medicalRecords.value.filter((record) => record.type === RECORD_TYPE)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  const orders = computed(() => {
    return medicalRecords.value.filter((record) => record.type === ORDER_TYPE) // TODO: add filter against existing results
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  const searchICD = async (searchString) => {
    return getICD10(searchString).data;
  };

  const submit = async (payload) => {
    if (payload?.id) {
      const id = payload.id;
      payload = Object.keys(payload).reduce((acc, key) => {
        if (UPDATE_FIELDS.includes(key)) {
          acc[key] = payload[key];
        }
        return acc;
      }, {});
      await updateMedicalRecord(id, payload);
    } else {
      payload.type = RECORD_TYPE;
      payload.encounter = encounter.value?.id;
      payload.patient = patient.value?.id;
      payload.facility = activeFacility.value?.id;
      await createMedicalRecord(payload);
    }
  };

  const remove = async ({ id }) => {
    await removeMedicalRecord(id);
  };

  return {
    medicalRecords,
    records,
    orders,
    searchICD,
    // getOrders,
    submit,
    remove,
  };
}
