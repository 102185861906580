export const RECORD_TYPE = 'travel-history';
export const FORM_FIELDS = [
  {
    label: 'Destination',
    field: 'destination',
    update: true,
    model: '',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Start of Visit',
    field: 'startOfVisit',
    update: true,
    model: '',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'End of Visit',
    field: 'endOfVisit',
    update: true,
    model: '',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const UPDATE_FIELDS = FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field);

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.model;
  return acc;
}, {});

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
