import { computed } from 'vue';
import { useMedicalRecords } from '@/composables/use-medical-records';
// import { usePatient } from '@/composables/use-patients';
// import { useEncounters } from '@/composables/use-encounters';
import { RECORD_TYPE } from './constants';

export function useCarePlan () {
  const {
    medicalRecords,
    // create: createMedicalRecord,
    // update: updateMedicalRecord,
    // remove: removeMedicalRecord,
  } = useMedicalRecords();

  // const { patient } = usePatient();
  // const { encounter } = useEncounters();

  const records = computed(() => {
    return medicalRecords.value.filter((record) => record.type === RECORD_TYPE)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  const submit = async (payload) => {
    // if (payload?.id) {
    //   const id = payload.id;
    //   payload = Object.keys(payload).reduce((acc, key) => {
    //     if (UPDATE_FIELDS.includes(key)) {
    //       acc[key] = payload[key];
    //     }
    //     return acc;
    //   }, {});
    //   await updateMedicalRecord(id, payload);
    // } else {
    //   payload.type = RECORD_TYPE;
    //   payload.encounter = encounter.value?.id;
    //   payload.patient = patient.value?.id;
    //   await createMedicalRecord(payload);
    // }
  };

  const remove = async ({ id }) => {
    // await removeMedicalRecord(id);
  };

  return {
    medicalRecords,
    records,
    submit,
    remove,
  };
}
