<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useAssessment } from './composables';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import diagnostic from '~/pages/settings/diagnostic.vue';

const emit = defineEmits(['edit', 'remove']);
const props = defineProps({
  record: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  hideOptions: {
    type: Boolean,
    default: false,
  },
  showRecordLabel: {
    type: Boolean,
    default: false,
  },
});

const { remove: removeDiagnosis } = useAssessment();

const diagnosis = computed(() => props.record.diagnosis);
const text = computed(() => props.record.text);

function edit () {
  emit('edit', props.record);
}

function remove () {
  removeDiagnosis({ id: props.record.id });
}
</script>


<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Diagnosis</span>
          <span class="text-sm text-neutral-500">{{diagnosis || '-'}}</span>
        </div>

        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">ICD10</span>
          <span class="text-sm text-neutral-500">{{icd10Formatted || '-'}}</span>
        </div>
        
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">CPT Codes</span>
          <template v-for="cpt in recommendedProcedures">
            <span class="text-sm text-neutral-500">{{cpt.coding.code}} - {{cpt.coding.text}}</span>
          </template>
        </div>

        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Notes</span>
          <span class="text-sm text-neutral-500">{{text || '-'}}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

