export const RECORD_TYPE = 'obstetric-history';

export const FORM_FIELDS = [
  {
    group: 'Gravidity and Parity',
    label: 'Number of pregnancies?',
    field: 'gravidity',
    update: true,
    type: 'text',
    placeholder: 'e.g 3 pregnancies',
    rules: {
      required: true,
      type: 'number',
    },
  },
  {
    group: 'Gravidity and Parity',
    label: 'Number of live births?',
    field: 'parity',
    update: true,
    type: 'text',
    placeholder: 'e.g 3 births',
    rules: {
      required: true,
      type: 'number',
    },
  },
  {
    group: 'Gravidity and Parity',
    label: 'Number of full-term deliveries?',
    field: 'fulltermCount',
    update: true,
    type: 'text',
    placeholder: 'e.g 4 full-term deliveries',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    group: 'Gravidity and Parity',
    label: 'Number of premature births?',
    field: 'prematureCount',
    update: true,
    type: 'text',
    placeholder: 'e.g 0 premature births',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    group: 'Gravidity and Parity',
    label: 'Number of stillbirths?',
    field: 'pregnancyLossesStillbirths',
    update: true,
    type: 'text',
    placeholder: 'e.g 1 stillborn',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    group: 'Gravidity and Parity',
    label: 'Number of abortions?',
    field: 'abortionCount',
    update: true,
    type: 'text',
    placeholder: 'e.g 3 abortions',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    group: 'Gravidity and Parity',
    label: 'Number of live children?',
    field: 'liveChildrenCount',
    update: true,
    type: 'text',
    placeholder: 'e.g 2 live children',
    rules: {
      required: false,
      type: 'number',
    },
  },

  // {
  //   group: 'Pregnancy Losses',
  //   field: 'pregnancyLosses',
  //   update: true,
  //   type: 'object',
  //   default: [
  //     {
  //       type: '',
  //     },
  //   ],
  // },

  {
    group: 'Others',
    label: 'Had pregnancy-induced hypertension?',
    field: 'withPregnancyInducedHypertension',
    update: true,
    type: 'select',
    placeholder: 'e.g Yes',
    rules: {
      required: false,
      type: 'text',
    },
  },
  {
    group: 'Others',
    label: 'Has done family planning counselling?',
    field: 'withFamilyPlanningCounselling',
    update: true,
    type: 'select',
    placeholder: 'e.g Yes',
    rules: {
      required: false,
      type: 'text',
    },
  },

  {
    group: 'Others',
    label: 'Notes',
    field: 'notes',
    update: true,
    type: 'textarea',
    placeholder: 'Enter additional notes related to obstetric history (e.g. Tubal ligation completed after the birth of the third child.)',
    rules: {
      required: false,
      type: 'text',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
