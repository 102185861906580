import { computed } from 'vue';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { usePatient } from '@/composables/use-patients';
import { useEncounters } from '@/composables/use-encounters';
import { RECORD_TYPE } from './constants';

export function usePrescription () {
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
  } = useMedicalRecords();

  const { patient } = usePatient();
  const { encounter } = useEncounters();

  const records = computed({
    get () {
      return medicalRecords.value.filter((record) => record.type === RECORD_TYPE);
    },
    set ({ type, data }) {
      if (type === 'create') {
        records.value.unshift(data);
      }

      if (type === 'update') {
        const index = records.value.findIndex((record) => record.id === data.id);
        records.value.splice(index, 1, data);
      }

      if (type === 'remove') {
        const index = records.value.findIndex((record) => record.id === data.id);
        records.value.splice(index, 1);
      }
    },
  });

  const submit = async (payload, opts) => {
    if (payload?.id) {
      const id = payload.id;
      delete payload.id;
      await updateMedicalRecord(id, payload);
    } else {
      payload.type = RECORD_TYPE;
      payload.encounter = opts?.encounterId || encounter.value?.id;
      payload.patient = opts?.patientId || patient.value?.id;
      await createMedicalRecord(payload, { facilityId: opts?.facilityId });
    }
  };

  const remove = async ({ id }) => {
    await removeMedicalRecord(id);
  };

  return {
    medicalRecords,
    records,
    submit,
    remove,
  };
}
