export const RECORD_TYPE = 'care-plan';
export const UPDATE_FIELDS = [
  'text',
];
export const FORM_RULES = {
  text: {
    type: 'string',
    required: true,
  },
};
