<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-${
          ['textarea', 'text'].includes(formField.type) ? 'full' : '1'
          }`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField?.rules?.required" class="text-error">*</span></span>
            </span>
          </div>
          <template v-if="formField.type === 'select'">
            <select
              v-if="formField.field !== 'diagnosisCodeAndText'"
              v-model="model[formField.field]"
              class="select select-bordered w-full focus:select-neutral"
              :name="formField.label"
              :disabled="loading"
              :required="formField.rules.required"
            >
              <option selected disabled>Select {{formField.label}}</option>
              <template v-for="(option, index) in formField.options" :key="index">
                <option v-if="isObject(option)" :value="option">{{`${option.code} - ${option.description}`}}</option>
                <option v-else :value="option">{{option}}</option>
              </template>
            </select>
            <!-- <search-fixtures
              v-if="formField.field ==='diagnosisCodeAndText'"
              v-model="model[formField.field]"
              type="icd10"
            /> -->
            <select v-if="formField.field === 'diagnosisCodeAndText'" v-model="model[formField.field]" class="select select-bordered">
              <option value="" disabled selected>Select ICD 10 Code</option>
              <option
                v-for="(option, index) in icd10codes"
                :key="index"
                :value="option"
              >
                {{ option.code + ' - ' + option.text }}
              </option>
            </select>
          </template>
          <template v-else-if="formField.type === 'textarea'">
            <textarea
              v-model="model[formField.field]"
              placeholder="Type here"
              class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
            ></textarea>
          </template>
          <template v-else>
            <input
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Input Text'"
              class="flex-auto input input-bordered focus:input-neutral"
              :type="formField.text"
              :disabled="loading"
            />
          </template>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ submitText }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { computed, reactive, ref, onMounted } from 'vue';
import { useProcedureOrders } from './composables';
import { useFixtures } from '@/composables/use-fixtures';
import _ from 'lodash';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const {
      submit: submitProcedureOrders,
    } = useProcedureOrders();
    const { icd10codes, getICD10, findOneFixture } = useFixtures();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);
    const submitText = computed(() => isEditing.value ? 'Update' : 'Submit');
    const isObject = (option) => _.isObject(option);
    const model = reactive(FORM_MODEL);

    onMounted(() => {
      getICD10();
    });

    const submit = async () => {
      try {
        const payload = _.omit(Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        ), 'diagnosisCodeAndText');
        if (model?.diagnosisCodeAndText) {
          payload.icd10 = model?.diagnosisCodeAndText?.code;
          payload.diagnosisCode = model?.diagnosisCodeAndText?.code;
          payload.diagnosisText = model?.diagnosisCodeAndText?.text;
        }
        // destructure tests selected
        if (model.tests) {
          payload.tests = [{ name: model.tests }];
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitProcedureOrders(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key].required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = null;
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    async function setForm (data) {
      if (!data) return;
      record.value = data;
      model.name = data.name;
      // model.diagnosisCodeAndText = {
      //   code: data.diagnosisCode,
      //   description: data.diagnosisText,
      // };
      model.reason = data.reason;
      model.notes = data.notes;
      if (data?.icd10) {
        const payload = {
          code: data?.icd10,
        };
        if (data?.diagnosisText) payload.text = data?.diagnosisText;
        model.diagnosisCodeAndText = await findOneFixture(payload, 'icd10');
      }
    }

    return {
      loading,
      model,
      recordId,
      submitText,
      isEditing,
      isObject,
      formFields: FORM_FIELDS,
      formSubmittable,
      icd10codes,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
