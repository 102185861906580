<template>
  <dialog id="paperPreviewModal" :class="`modal ${paperPreviewModal ? 'modal-open' : ''}`">
    <form method="dialog" class="flex flex-col gap-4 modal-box w-6/12 max-w-3xl rounded-none">

      <div class="flex justify-end items-center">
        <button class="btn btn-sm btn-circle btn-ghost" @click="paperPreviewModal = false">
          <i class="las la-times text-2xl text-red-400"></i>
        </button>
      </div>

      <div>
        <paper-preview :record="record" :patient="patient" :doctor="currentUser"/>
      </div>
    </form>
  </dialog>

  <!-- :show-record-label="showRecordLabel" -->
  <generic-preview :record="record" :more-options="moreOptions" :hide-options="hideOptions"  @edit="edit" @remove="remove" >
    <preview-wrapper :hide-options="hideOptions" :record="record">
      <div class="flex-col flex gap-2">
        <span class="text-[24px]">Rx</span>
        <div class="flex flex-col gap-5">
          <template v-for="(medicine, index) in items" :key="index">
            <div>
              <div class="flex justify-between mb-1">
                <div>
                  <span class="font-medium">{{index + 1}}. {{medicine?.genericName}} {{medicine.formulation}}</span>
                </div>
                <span v-if="medicine?.dispense" class="text-neutral-500">#{{medicine?.dispense}}</span>
              </div>
              <div class="flex flex-col gap-4">
                <div class="flex flex-col gap-1">
                  <span v-if="medicine?.brandName" class="indent-4 text-neutral-500">
                    <span class="font-medium">Brand:</span> {{medicine?.brandName}}
                  </span>
                  <span v-if="medicine?.formattedSig?.trim().length" class="indent-4 text-neutral-500">
                    <span class="font-medium">Sig:</span> {{medicine?.formattedSig}}
                  </span>
                  <span v-if="medicine?.formulationClassification" class="indent-4 text-neutral-500">
                    <span class="font-medium">Route:</span> {{medicine?.formulationClassification}}
                  </span>
                  <span v-if="medicine?.frequencyReminderInterval" class="indent-4 text-neutral-500">
                    <span class="font-medium">Refills:</span> {{medicine?.frequencyReminderInterval}}
                  </span>
                  <span v-if="medicine?.reasonForPrescription" class="indent-4 text-neutral-500">
                    <span class="font-medium">Indication:</span> {{medicine?.reasonForPrescription}}
                  </span>
                  <span v-if="medicine?.startedAt" class="indent-4 text-neutral-500">
                    <span class="font-medium">Start Date:</span> {{formatDate(medicine?.startedAt)}}
                  </span>
                  <span v-if="medicine?.endedAt" class="indent-4 text-neutral-500">
                    <span class="font-medium">End Date:</span> {{formatDate(medicine?.endedAt)}}
                  </span>
                </div>
                <div v-if="medicine?.note" class="flex flex-col gap-2">
                  <span v-if="medicine?.note" class="indent-4 text-sm font-medium">Notes</span>
                  <span v-if="medicine?.note" class="pl-4 text-sm text-neutral-500 break-words">{{ medicine?.note }}</span>
                </div>
              </div>
            </div>
          </template>
          <p v-if="notes">Notes: {{notes}}</p>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { capitalize } from 'lodash';
import { computed, ref } from 'vue';
import { FORM_FIELDS } from './constants';
import { format, fromUnixTime } from 'date-fns';
import { usePatient } from '@/composables/use-patients';
import { useAuthentication } from '@/composables/use-authentication';
import { useRouter } from 'vue-router';
import { usePrescription } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';
import PaperPreview from './paper-preview.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
    PaperPreview,
  },
  props: {
    record: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: true,
    },
  },
  setup (props, { emit }) {
    const { remove: removePrescription } = usePrescription();

    const router = useRouter();
    const paperPreviewModal = ref(false);
    const moreOptions = [
      {
        label: 'Preview',
        icon: 'las la-eye',
        onClick: () => {
          paperPreviewModal.value = true;
        },
      },
      {
        label: 'Print',
        icon: 'las la-print',
        onClick: () => {
          onPrintPrescription();
        },
      },
    ];

    const { patient } = usePatient();
    const { currentUser } = useAuthentication();

    const createdAt = computed(() => format(new Date(props.record?.createdAt), 'MM/dd/yyyy hh:mm a'));

    const items = computed(() => {
      return props.record.items.map(item => {
        return {
          ...item,
          formattedSig: `${item.dosageSig || ''} ${item.frequency || ''}`,
        };
      });
    });

    const notes = computed(() => props.record?.notes);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removePrescription({ id: props.record.id });
    }

    function onPrintPrescription () {
      router.push({ name: 'print-prescription', query: { id: props.record?.id } });
    }

    function formatDate (date) {
      if (!date) return;
      return format(new Date(date), 'MM/dd/yyyy');
    }

    return {
      moreOptions,
      createdAt,
      paperPreviewModal,
      patient,
      currentUser,
      items,
      notes,
      formFields: FORM_FIELDS,
      formatDate,
      edit,
      remove,
      capitalize,
      format,
      fromUnixTime,
      onPrintPrescription,
    };
  },
};
</script>
