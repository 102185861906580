<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-full`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField?.rules?.required" class="text-error">*</span></span>
            </span>
          </div>
          <template v-if="formField.type === 'select'">
            <template v-if="orders.length">
              <select v-model="model[formField.field]" class="select select-bordered">
                <option value="" disabled selected>e.g. Chest PA</option>
                <option
                  v-for="(option, index) in orders"
                  :key="index"
                  :value="option"
                >
                  {{ stringifyTests(option.tests) }}
                </option>
              </select>
            </template>
            <template v-else>
              <div class="flex flex-col gap-2 items-center text-center border-[1px] border-neutral-300 text-neutral-400 rounded-md p-4">
                No radiology orders found. Kindly add a radiology order first!
                <nuxt-link 
                  type="button" 
                  class="btn btn-sm btn-neutral w-[300px]"
                  @click="onGoToPlan"
                >
                  Create a Radiology Order here
                </nuxt-link>
              </div>
            </template>
          </template>
          <template v-if="formField.field === 'results'">
            <template v-if="resultsMap">
              <template v-for="(test, index) in resultsMap" :key="index">
                <div class="flex flex-col items-start w-full py-2">
                  <span class="text-md text-secondary">{{ test?.testName || '-' }}</span>
                  <textarea
                    v-model="test.results"
                    class="h-[64px] textarea textarea-bordered focus:textarea-neutral w-full"
                    :disabled="loading"
                  ></textarea>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="text-center border-[1px] border-neutral-300 text-neutral-400 rounded rounded-md p-4">
                Your results will show here. Kindly select a radiology order first!
              </div>
            </template>
          </template>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        :disabled="!formSubmittable"
        class="btn btn-neutral btn-sm normal-case text-white"
      >
        {{ submitText }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { computed, reactive, ref, watch } from 'vue';
import { isEmpty, cloneDeep } from 'lodash';
import { useRadiologyResults } from './composables';
import { useRouter, useRoute } from 'vue-router';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const {
      // medicalRecords,
      orders,
      submit: submitRadiologyResult,
      // searchICD,
    } = useRadiologyResults();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);
    const submitText = computed(() => isEditing.value ? 'Save Changes' : 'Submit');
    const isObject = (option) => _.isObject(option);

    const resultsMap = ref(null);
    const results = reactive({
      testResults: {
        // per test measure here
      },
    });

    const model = reactive(FORM_MODEL);
    watch(model, async (newVal) => {
      if (!newVal?.order) return;
      const tests = newVal?.order.tests;
      if (!tests) return;
      const fullymappedOrders = model?.order?.tests?.map(i => {
        if (!i?.id) return null;
        return { testName: i?.name, testId: i?.id, results: '' };
      });
      resultsMap.value = fullymappedOrders;
      if (!isEmpty(results.testResults)) {
        resultsMap.value.forEach((test, index) => {
          const matchingTest = results?.testResults?.find(i => i?.testId === test?.testId);
          if (!matchingTest) return;
          test.results = matchingTest?.results;
        });
      }
    }, { deep: true });
    const stringifyTests = (tests) => {
      if (!tests || !tests?.length || tests?.length === 0) return null;
      return tests?.map(i => i?.name).join(', ');
    };

    const submit = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        );
        payload.order = payload?.order?.id;
        if (!payload?.order) throw new Error('Something went wrong! Try again.');
        // payload.results = [
        //   {
        //     text: payload?.results,
        //   },
        // ];
        payload.results = resultsMap.value.map(i => ({
          testName: i?.testName,
          testId: i?.testId,
          results: i?.results,
        }));

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
          delete payload.order; // order should not be updated
        }

        // Create
        await submitRadiologyResult(payload);
        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error(e);
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key]?.required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      const ordersList = computed(() => {
        return orders.value.filter(i => i?.id === data.order)?.[0];
      });
      model.order = ordersList.value;
      results.testResults = cloneDeep(data.results);
    }

    const router = useRouter();
    const route = useRoute();

    function onGoToPlan () {
      router.push({ name: 'encounter-plan', params: route.parmas, query: route.query });
    }

    return {
      loading,
      model,
      orders,
      resultsMap,
      results,
      recordId,
      submitText,
      isEditing,
      isObject,
      stringifyTests,
      formFields: FORM_FIELDS,
      formSubmittable,
      resetForm,
      submit,
      setForm,
      onGoToPlan,
    };
  },
};
</script>
