export const RECORD_TYPE = 'pedia-milestone';
export const FORM_FIELDS = [
  {
    field: 'name',
    label: 'Name',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    field: 'category',
    label: 'Category',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: true,
    },
  },
  {
    field: 'group',
    label: 'Group',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
];

export const UPDATE_FIELDS = FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field);

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = '';
  return acc;
}, {});

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});

export const DEVELOPMENTAL_MILESTONES = [
  {
    age: 'Newborn',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Tonic neck reflex',
        },
        {
          milestone: 'Startles (Moro reflex)',
        },
      ],
    },
  },
  {
    age: '1 month',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Raises head slightly from prone (less than 45˚)',
        },
        {
          milestone: 'Hand fisted',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Eye follows object past midline',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Throaty, gurgling sound',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Smiles',
        },
      ],
    },
  },
  {
    age: '2 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Head control 45˚ in prone',
        },
        {
          milestone: 'Hands no longer fisted',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Eye follows object past midline',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Laughs',
        },
        {
          milestone: 'Vocalizes',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Responsive, diminishes activity when talked to',
        },
      ],
    },
  },
  {
    age: '3 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Head control 90˚ in prone',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Hands together',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Squeels',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Regards hand',
        },
      ],
    },
  },
  {
    age: '4 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Rolls over',
        },
        {
          milestone: 'Chest up, arm support',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Grasps object placed in hand',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Moves head toward sound',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Plays with rattle in hand',
        },
      ],
    },
  },
  {
    age: '5 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Good head control',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Reaches for objects',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Turns to sound',
        },
      ],
    },
  },
  {
    age: '6 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'No head lag',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Chews',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Indicates likes and dislikes',
        },
      ],
    },
  },
  {
    age: '7 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Sits with support',
        },
        {
          milestone: 'Bears some weight on legs',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Rakes at small objects',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Mm when crying',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Feeds self with crackers',
        },
        {
          milestone: 'Recognizes familiar faces',
        },
      ],
    },
  },
  {
    age: '8 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Sits with support',
        },
        {
          milestone: 'Bounces',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Transfers objects from hand to hand',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Mama',
        },
        {
          milestone: 'Dada',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Plays peek a boo',
        },
        {
          milestone: 'Close open hand movement',
        },
      ],
    },
  },
  {
    age: '9 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Stands holding on',
        },
        {
          milestone: 'Creeps',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Holds bottle',
        },
        {
          milestone: 'Index finger approach',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Imitates sounds',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Shy with stranger',
        },
        {
          milestone: 'Waves bye bye',
        },
      ],
    },
  },
  {
    age: '10 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Pulls self to stand',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Thumb finger grasp',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Understands gestures',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Does nursery games (pat-a-cake)',
        },
      ],
    },
  },
  {
    age: '11 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Walks holding on',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Bangs object together',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Two words with meaning',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Helps dress, holding arm or food out',
        },
      ],
    },
  },
  {
    age: '12 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Stands alone',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Throws toys',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Obeys commands or requests',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Attempts to use a spoon',
        },
      ],
    },
  },
  {
    age: '15 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Walks well alone',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Drinks from cup',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Three words other than mama, dada',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Pats pictures',
        },
      ],
    },
  },
  {
    age: '18 months',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Walks backward',
        },
        {
          milestone: "Seats self in child's chair",
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Imitates a circular stroke',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Ten words',
        },
        {
          milestone: 'Indicates needs',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Imitates housework sweeping, washing',
        },
      ],
    },
  },
  {
    age: '2 years',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Runs well',
        },
        {
          milestone: 'Up, down stairs alone',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Imitates a circular stroke',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Combines two or three words in sentences',
        },
        {
          milestone: 'Points to one body part',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Removes garment',
        },
        {
          milestone: 'Toilet trained by day',
        },
      ],
    },
  },
  {
    age: '2.5 years',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Jumps',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Imitates a circle',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Follows direction (two three)',
        },
        {
          milestone: 'Names on picture',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Uses spoon with little spilling',
        },
        {
          milestone: 'Helps in simple house tasks',
        },
      ],
    },
  },
  {
    age: '3 years',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Throws ball',
        },
        {
          milestone: 'Rides tricycle',
        },
        {
          milestone: 'Stands momentarily on one foot',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Use plurals',
        },
        {
          milestone: 'Tells little stories about experiences',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Dry by night',
        },
        {
          milestone: 'Washes and dries hand',
        },
        {
          milestone: 'Plays interactive games',
        },
      ],
    },
  },
  {
    age: '4 years',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Downstairs on one foot per step',
        },
        {
          milestone: 'Hops on one foot',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Imitates cross',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Comprehends prepositions and words such as cold, tired, hungry',
        },
        {
          milestone: 'Recognizes a color',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Buttons up',
        },
        {
          milestone: 'Tells tell tales',
        },
      ],
    },
  },
  {
    age: '4.5 years',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Catches ball well',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Draws man with three parts',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Recognizes three or more colors',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Dresses without supervision',
        },
      ],
    },
  },
  {
    age: '5 years',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Heel to toe walk',
        },
        {
          milestone: 'Skips both feet',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Copies square',
        },
        {
          milestone: 'Writes alphabet',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Counts to ten',
        },
        {
          milestone: 'Prints first name',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Can use a knife',
        },
        {
          milestone: 'Tells store',
        },
      ],
    },
  },
  {
    age: '6 years',
    grossmotor: {
      category: 'grossmotor',
      milestones: [
        {
          milestone: 'Backward heel to toe walk',
        },
      ],
    },
    adaptivefinemotor: {
      category: 'adaptivefinemotor',
      milestones: [
        {
          milestone: 'Draws a complete person without clothing',
        },
        {
          milestone: 'Can write fairly well',
        },
      ],
    },
    language: {
      category: 'language',
      milestones: [
        {
          milestone: 'Adds and subtract',
        },
        {
          milestone: 'Distinguishes between left and right',
        },
      ],
    },
    personalandsocial: {
      category: 'personalandsocial',
      milestones: [
        {
          milestone: 'Dresses self completely',
        },
      ],
    },
  },
];
