export const RECORD_TYPE = 'chief-complaint';
export const UPDATE_FIELDS = [
  'text',
  'signsAndSymptoms',
];
export const FORM_RULES = {
  text: {
    type: 'string',
    required: true,
  },
  signsAndSymptoms: {
    type: 'array',
    required: false,
  },
};

export const CHIEF_COMPLAINTS = [
  {
    value: 'Pain',
    text: 'Pain',
    children: [
      { value: 'Headache', text: 'Headache' },
      { value: 'Abdominal pain', text: 'Abdominal pain' },
      { value: 'Joint pain', text: 'Joint pain' },
      { value: 'Back pain', text: 'Back pain' },
      { value: 'Chest pain', text: 'Chest pain' },
      { value: 'Pink eye', text: 'Pink eye' },
    ],
  },
  {
    value: 'Fatigue',
    text: 'Fatigue',
    children: [
      { value: 'General tiredness', text: 'General tiredness' },
      { value: 'Lack of energy', text: 'Lack of energy' },
    ],
  },
  {
    value: 'Shortness of Breath',
    text: 'Shortness of Breath',
    children: [
      { value: 'Difficulty breathing', text: 'Difficulty breathing' },
      { value: 'Breathlessness', text: 'Breathlessness' },
    ],
  },
  {
    value: 'Fever',
    text: 'Fever',
    children: [
      { value: 'Elevated body temperature', text: 'Elevated body temperature' },
    ],
  },
  {
    value: 'Cough',
    text: 'Cough',
    children: [
      { value: 'Dry cough', text: 'Dry cough' },
      { value: 'Productive cough', text: 'Productive cough' },
    ],
  },
  {
    value: 'Nausea and Vomiting',
    text: 'Nausea and Vomiting',
    children: [
      { value: 'Feeling nauseous', text: 'Feeling nauseous' },
      { value: 'Vomiting', text: 'Vomiting' },
    ],
  },
  {
    value: 'Dizziness',
    text: 'Dizziness',
    children: [
      { value: 'Feeling lightheaded', text: 'Feeling lightheaded' },
      { value: 'Vertigo', text: 'Vertigo' },
    ],
  },
  {
    value: 'Changes in Bowel Habits',
    text: 'Changes in Bowel Habits',
    children: [
      { value: 'Diarrhea', text: 'Diarrhea' },
      { value: 'Constipation', text: 'Constipation' },
    ],
  },
  {
    value: 'Urinary Symptoms',
    text: 'Urinary Symptoms',
    children: [
      { value: 'Frequent urination', text: 'Frequent urination' },
      { value: 'Painful urination', text: 'Painful urination' },
    ],
  },
  {
    value: 'Skin Changes',
    text: 'Skin Changes',
    children: [
      { value: 'Rashes', text: 'Rashes' },
      { value: 'Itching', text: 'Itching' },
      { value: 'Skin discoloration', text: 'Skin discoloration' },
    ],
  },
  {
    value: 'Visual Changes',
    text: 'Visual Changes',
    children: [
      { value: 'Blurred vision', text: 'Blurred vision' },
      { value: 'Double vision', text: 'Double vision' },
    ],
  },
  {
    value: 'Neurological Symptoms',
    text: 'Neurological Symptoms',
    children: [
      { value: 'Numbness or tingling', text: 'Numbness or tingling' },
      { value: 'Weakness', text: 'Weakness' },
    ],
  },
  {
    value: 'Sleep Disturbances',
    text: 'Sleep Disturbances',
    children: [
      { value: 'Insomnia', text: 'Insomnia' },
      { value: 'Excessive sleepiness', text: 'Excessive sleepiness' },
    ],
  },
  {
    value: 'Appetite Changes',
    text: 'Appetite Changes',
    children: [
      { value: 'Loss of appetite', text: 'Loss of appetite' },
      { value: 'Increased appetite', text: 'Increased appetite' },
    ],
  },
  {
    value: 'Swelling',
    text: 'Swelling',
    children: [
      { value: 'Edema in extremities', text: 'Edema in extremities' },
    ],
  },
  {
    value: 'Mood Changes',
    text: 'Mood Changes',
    children: [
      { value: 'Anxiety', text: 'Anxiety' },
      { value: 'Depression', text: 'Depression' },
    ],
  },
  {
    value: 'Weight Changes',
    text: 'Weight Changes',
    children: [
      { value: 'Unexplained weight loss or gain', text: 'Unexplained weight loss or gain' },
    ],
  },
  {
    value: 'Sore Throat',
    text: 'Sore Throat',
    children: [
      { value: 'Pain or irritation in the throat', text: 'Pain or irritation in the throat' },
    ],
  },
  {
    value: 'Palpitations',
    text: 'Palpitations',
    children: [
      { value: 'Awareness of heartbeat', text: 'Awareness of heartbeat' },
    ],
  },
  {
    value: 'Memory Problems',
    text: 'Memory Problems',
    children: [
      { value: 'Forgetfulness', text: 'Forgetfulness' },
    ],
  },
  {
    value: 'Others',
    text: 'Others',
  },
];
