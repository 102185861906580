export const RECORD_TYPE = 'imaging-test-result';
export const ORDER_TYPE = 'imaging-test-order';

export const FORM_FIELDS = [
  {
    label: 'Radiology Order',
    field: 'order',
    update: true,
    type: 'select',
    multiple: false,
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Results',
    field: 'results',
    update: true,
    type: 'textarea',
    multiple: false,
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
