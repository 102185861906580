<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">PAP Smear Date</span>
          <span class="text-sm text-neutral-500">{{prevCervicSmearDate || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">PAP Smear Findings</span>
          <span class="text-sm text-neutral-500">{{prevCervicSmearsResult || '-'}}</span>
        </div>
        <div v-if="pelvicExam" class="grid grid-cols-2 col-span-full gap-2 items-center">
          <span class="text-sm font-medium">Pelvic Exam Findings</span>
          <span class="text-sm text-neutral-500">{{pelvicExam || '-'}}</span>
        </div>
        <div class="grid grid-cols-2 col-span-full gap-2 items-center">
          <span class="text-sm font-medium">Other Findings</span>
          <span class="text-sm text-neutral-500">{{otherReadings || '-'}}</span>
        </div>
        <div class="grid grid-cols-2 col-span-full gap-2 items-center">
          <span class="text-sm font-medium">Current Contraception</span>
          <span class="text-sm text-neutral-500">{{currentContraception}}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useGynecologistFindings } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeBirthHistory } = useGynecologistFindings();
    const prevCervicSmearDate = computed(() => {
      if (!props.record.prevCervicSmearsDate) return;
      const date = new Date(props.record?.prevCervicSmearsDate);
      return format(date, 'MMM d, yyyy');
    });
    const prevCervicSmearsResult = computed(() => props.record.prevCervicSmearsResult);
    const hasPelvicExam = computed(() => props.record.metadata.containsPelvicExam);

    const treatmentReadings = computed(() => props.record.prevProblemsTreatments.split(';; '));
    const pelvicExam = computed(() => hasPelvicExam.value
      ? treatmentReadings.value[0]
      : null);
    const otherReadings = computed(() => hasPelvicExam.value
      ? treatmentReadings.value[1]
      : treatmentReadings.value[0]);
    const currentContraception = computed(() => props.record.currentContraception);
    // const notes = computed(() => props.record.notes);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeBirthHistory({ id: props.record.id });
    }

    return {
      createdAt,
      prevCervicSmearDate,
      prevCervicSmearsResult,
      pelvicExam,
      otherReadings,
      currentContraception,
      edit,
      remove,
    };
  },
};
</script>
