export const RECORD_TYPE = 'patient-education';

export const FORM_FIELDS = [
  {
    group: 'Topic',
    label: 'Topic',
    field: 'topic',
    update: true,
    type: 'text',
    placeholder: 'e.g Seminar against Dangerous Drug Use',
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    group: 'Topic',
    label: 'Date of Seminar',
    field: 'presentedAt',
    update: true,
    type: 'date',
    placeholder: 'e.g. 2017-10-23',
    rules: {
      required: true,
      type: 'number',
    },
  },
  // {
  //   group: 'Topic',
  //   label: 'Provider',
  //   field: 'presentedBy',
  //   placeholder: 'e.g. Dr. Bilbao',
  //   update: true,
  //   type: 'text',
  //   rules: {
  //     required: false,
  //     type: 'string',
  //   },
  // },
  {
    group: 'Topic',
    label: 'Materials',
    field: 'learningMaterials',
    placeholder: 'e.g. Books',
    update: true,
    type: 'array',
    default: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Topic',
    label: 'Notes',
    field: 'notes',
    update: true,
    type: 'textarea',
    placeholder: 'Enter additional pertinent seminar notes (e.g. Patient must be assessed on understanding of topic and concerns)',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Assessment',
    label: 'Patient Goals',
    field: 'goals',
    placeholder: 'e.g. Understand danger of consuming drugs',
    update: true,
    type: 'array',
    default: [],
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    group: 'Assessment',
    label: 'Patient Satisfaction',
    field: 'satisfaction',
    placeholder: '',
    update: true,
    type: 'range',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    group: 'Assessment',
    label: 'Patient Assessment',
    field: 'assessments',
    placeholder: '',
    update: true,
    type: 'text',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const SATISFACTION_MIN = 0;
export const SATISFACTION_BAD_THRESHOLD = 4;
export const SATISFACTION_GOOD_THRESHOLD = 7;
export const SATISFACTION_MAX = 10;
export const SATISFACTION_TEXTS = ['Not at all satisfied', 'Very Dissatisfied', 'Slightly Dissatisfied', 'Neutral',
  'Slightly Satisfied', 'Somewhat Satisfied', 'Moderately Satisfied', 'Quite Satisfied', 'Very Satisfied',
  'Extremely Satisfied', 'Completely Satisfied'];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.filter((field) => field.update).map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
