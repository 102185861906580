<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="flex flex-col gap-4">

        <div class="flex flex-col col-span-1">
          <span class="text-sm font-medium">ICD10</span>
          <span class="text-sm">{{icd10 || '-'}}</span>
        </div>

        <div class="flex flex-col col-span-1">
          <span class="text-sm font-medium">Diagnosis</span>
          <span class="text-sm">{{text || '-'}}</span>
        </div>

        <div class="flex flex-col gap-2 mt-4">
          <template v-for="(item, index) in extras" :key="index">
            <template v-if="item?.value?.length">
              <span class="text-sm font-bold">{{item.label}}</span>
              <template v-for="(value, valueIndex) in item.value" :key="valueIndex">
                <div class="grid grid-cols-2 gap-2">
                  <div class="flex flex-col">
                    <span class="text-sm font-medium">Code</span>
                    <span class="text-sm">{{value.code || '-'}}</span>
                  </div>
                  <div class="flex flex-col">
                    <span class="text-sm font-medium">Text</span>
                    <span class="text-sm">{{value.text || '-'}}</span>
                  </div>
                  <div class="flex flex-col col-span-2">
                    <span class="text-sm font-medium">Notes</span>
                    <span class="text-sm">{{value.text || '-'}}</span>
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useDifferentialDiagnosis } from './composables';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';
import GenericPreview from '@/components/medical-records/generic-preview.vue';

export default {
  components: {
    PreviewWrapper,
    GenericPreview,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeDiagnosis } = useDifferentialDiagnosis();

    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const icd10 = computed(() => props.record.icd10);
    const text = computed(() => props.record.text);
    const symptoms = computed(() => props.record.symptoms);
    const recommendations = computed(() => props.record.recommendations);
    const complications = computed(() => props.record.complications);
    const riskFactors = computed(() => props.record.riskFactors);

    const extras = [
      {
        label: 'Symptoms',
        value: symptoms.value,
      },
      {
        label: 'Recommendations',
        value: recommendations.value,
      },
      {
        label: 'Complications',
        value: complications.value,
      },
      {
        label: 'Risk Factors',
        value: riskFactors.value,
      },
    ];

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeDiagnosis({ id: props.record.id });
    }

    return {
      createdAt,
      icd10,
      text,
      extras,
      edit,
      remove,
    };
  },
};
</script>
