import { toRefs, reactive, computed } from 'vue';
import { useFacilities } from './use-facilities';
import { useAuthentication } from './use-authentication';
import { useSdk } from './use-mycure';
import { formatRecord } from '@/utils/formatters';
import { useRoute } from 'vue-router';
import { omit } from 'lodash';

// import PregnancyPreview from '@/components/medical-records/pregnancy/preview.vue';
// import ProcedureResultsPreview from '@/components/medical-records/procedure-results/preview.vue';
import AllergyHistoryPreview from '@/components/medical-records/allergy-history/preview.vue';
import AssessmentDiagnosisPreview from '@/components/medical-records/assessment-diagnosis/preview.vue';
import AssessmentDiagnosisView from '@/components/medical-records/assessment-diagnosis/View.vue';
import AttachmentsPreview from '@/components/medical-records/attachments/preview.vue';
import BirthHistoryPreview from '@/components/medical-records/birth-history/preview.vue';
import BlankPreview from '@/components/medical-records/blank/preview.vue';
import CarePlanPreview from '@/components/medical-records/care-plan/preview.vue';
import CarePlanView from '@/components/medical-records/care-plan/View.vue';
import ChiefComplaintPreview from '@/components/medical-records/chief-complaint/preview.vue';
import ChiefComplaintView from '@/components/medical-records/chief-complaint/View.vue';
import DentalHistoryPreview from '@/components/medical-records/dental-history/preview.vue';
import DentalNotePreview from '@/components/medical-records/dental-note/preview.vue';
import DiagnosisPreview from '@/components/medical-records/diagnosis/preview.vue';
import DiagnosisView from '@/components/medical-records/diagnosis/View.vue';
import DifferentialDiagnosisPreview from '@/components/medical-records/differential-diagnosis/preview.vue';
import DispositionPreview from '@/components/medical-records/disposition/preview.vue';
import EntNotePreview from '@/components/medical-records/ent/preview.vue';
import FamilyHistoryPreview from '@/components/medical-records/family-history/preview.vue';
import FunctionalStatusPreview from '@/components/medical-records/functional-status/preview.vue';
import GynecologistFindingsPreview from '@/components/medical-records/gynecologist-findings/preview.vue';
import HospitalizationHistoryPreview from '@/components/medical-records/hospitalization-history/preview.vue';
import HpiPreview from '@/components/medical-records/hpi/preview.vue';
import HpiView from '@/components/medical-records/hpi/View.vue';
import ImpressionPreview from '@/components/medical-records/impression/preview.vue';
import LaboratoryOrderPreview from '@/components/medical-records/lab-orders/preview.vue';
import LabTestResultsPreview from '@/components/medical-records/lab-results/preview.vue';
import MedCertPreview from '@/components/medical-records/med-certificate/preview.vue';
import MedicalHistoryPreview from '@/components/medical-records/medical-history/preview.vue';
import MedicationHistoryPreview from '@/components/medical-records/medication-history/preview.vue';
import MentalStatusExamPreview from '@/components/medical-records/mental-status/preview.vue';
import NutritionalAssessmentPreview from '@/components/medical-records/nutritional-assessment/preview.vue';
import NutritionalPlanPreview from '@/components/medical-records/nutritional-plan/preview.vue';
import ObstetricFindingsPreview from '@/components/medical-records/ob-note/preview.vue';
import ObstetricHistoryPreview from '@/components/medical-records/obstetric-history/preview.vue';
import PatientEducationPreview from '@/components/medical-records/patient-education/preview.vue';
import PediaPreview from '@/components/medical-records/pedia/preview.vue';
import PhysicalExamPreview from '@/components/medical-records/pe/preview.vue';
import PhysicalExamView from '@/components/medical-records/pe/View.vue';
import PrescriptionPreview from '@/components/medical-records/prescription/preview.vue';
import PrescriptionView from '@/components/medical-records/prescription/View.vue';
import ProcedureOrderPreview from '@/components/medical-records/procedure-order/preview.vue';
import RadiologyOrderPreview from '@/components/medical-records/radiology-orders/preview.vue';
import RadiologyTestResultsPreview from '@/components/medical-records/radiology-results/preview.vue';
import RosPreview from '@/components/medical-records/ros/preview.vue';
import SocialHistoryPreview from '@/components/medical-records/social-history/preview.vue';
import SurgicalHistoryPreview from '@/components/medical-records/surgical-history/preview.vue';
import TravelHistoryPreview from '@/components/medical-records/travel-history/preview.vue';
import VaccinationPreview from '@/components/medical-records/vaccinations/preview.vue';
import VitalsPreview from '@/components/medical-records/vitals/preview.vue';

// import PregnancyForm from '@/components/medical-records/pregnancy/form.vue';
// import ProcedureResultsForm from '@/components/medical-records/procedure-results/form.vue';
import AllergyHistoryForm from '@/components/medical-records/allergy-history/form.vue';
import AssessmentDiagnosisForm from '@/components/medical-records/assessment-diagnosis/form.vue';
import AttachmentsForm from '@/components/medical-records/attachments/form.vue';
import BirthHistoryForm from '@/components/medical-records/birth-history/form.vue';
import BlankForm from '@/components/medical-records/blank/form.vue';
import CarePlanForm from '@/components/medical-records/care-plan/form.vue';
import ChiefComplaintForm from '@/components/medical-records/chief-complaint/form.vue';
import DentalHistoryForm from '@/components/medical-records/dental-history/form.vue';
import DentalNoteForm from '@/components/medical-records/dental-note/form.vue';
import DiagnosisForm from '@/components/medical-records/diagnosis/form.vue';
import DifferentialDiagnosisForm from '@/components/medical-records/differential-diagnosis/form.vue';
import DispositionForm from '@/components/medical-records/disposition/form.vue';
import EntNoteForm from '@/components/medical-records/ent/form.vue';
import FamilyHistoryForm from '@/components/medical-records/family-history/form.vue';
import FunctionalStatusForm from '@/components/medical-records/functional-status/form.vue';
import GynecologistFindingsForm from '@/components/medical-records/gynecologist-findings/form.vue';
import HospitalizationHistoryForm from '@/components/medical-records/hospitalization-history/form.vue';
import HpiForm from '@/components/medical-records/hpi/form.vue';
import ImpressionForm from '@/components/medical-records/impression/form.vue';
import LaboratoryOrderForm from '@/components/medical-records/lab-orders/form.vue';
import LabTestResultsForm from '@/components/medical-records/lab-results/form.vue';
import MedCertForm from '@/components/medical-records/med-certificate/form.vue';
import MedicalHistoryForm from '@/components/medical-records/medical-history/form.vue';
import MedicationHistoryForm from '@/components/medical-records/medication-history/form.vue';
import MentalStatusExamForm from '@/components/medical-records/mental-status/form.vue';
import NutritionalAssessmentForm from '@/components/medical-records/nutritional-assessment/form.vue';
import NutritionalPlanForm from '@/components/medical-records/nutritional-plan/form.vue';
import ObstetricFindingsForm from '@/components/medical-records/ob-note/form.vue';
import ObstetricHistoryForm from '@/components/medical-records/obstetric-history/form.vue';
import PatientEducationForm from '@/components/medical-records/patient-education/form.vue';
import PediaForm from '@/components/medical-records/pedia/form.vue';
import PhysicalExamForm from '@/components/medical-records/pe/form.vue';
import PrescriptionForm from '@/components/medical-records/prescription/form.vue';
import ProcedureOrderForm from '@/components/medical-records/procedure-order/form.vue';
import RadiologyOrderForm from '@/components/medical-records/radiology-orders/form.vue';
import RadiologyTestResultsForm from '@/components/medical-records/radiology-results/form.vue';
import RosForm from '@/components/medical-records/ros/form.vue';
import SocialHistoryForm from '@/components/medical-records/social-history/form.vue';
import SurgicalHistoryForm from '@/components/medical-records/surgical-history/form.vue';
import TravelHistoryForm from '@/components/medical-records/travel-history/form.vue';
import VaccinationForm from '@/components/medical-records/vaccinations/form.vue';
import VitalsForm from '@/components/medical-records/vitals/form.vue';

// Second-level Modals
import DrawAttachment from '@/components/medical-records/attachments/draw-attachment.vue';

import useGlobalSnack from '@/components/commons/global-snack/composables';

const { show: showSnack } = useGlobalSnack();

function onFormatRecord (record) {
  const { currentUser } = useAuthentication();
  const formatted = formatRecord(record) || {};
  if (formatted?.createdBy !== currentUser?.value?.uid) {
    formatted.createdByOthers = true;
  }
  return formatted;
}

const state = reactive({
  medicalRecords: [],
  allMedicalRecords: [],
});

export function useMedicalRecords () {
  const sdk = useSdk();
  const { getActiveFacility } = useFacilities();
  const route = useRoute();

  const excludeRecordTypes = [
    'consent',
    'developmental-milestone',
    'summary',
  ];

  const triageGroup = [
    'chief-complaint',
    'ros',
    'vitals',
    'allergy',
    'medication-history',
    'medical-history',
    'social-history',
    'family-history',
    'travel-history',
    // 'birth-history',
    'hospitalization-history',
    'surgical-history',
    'dental-history',
  ];

  const subjectiveGroup = [
    'chief-complaint',
    'hpi',
    'medical-history',
    'social-history',
    'family-history',
    'mental-status',
    'ros',
    // 'functional-status',
    // 'psychological-status',
    // 'sociocultural-factors',
    'medication-history',
    'allergy',
    // 'birth-history',
    'hospitalization-history',
    'surgical-history',
    'obstetric-history',
    // 'dental-history',
    'vaccination',
    'pedia',
    'ent-note',
    'dental-history',
  ];

  const objectiveGroup = [
    'attachment',
    'vitals',
    'physical-exam',
    'lab-test-result',
    'imaging-test-result',
    // 'medical-procedure',
    // 'developmental-milestone',
  ];

  const assessmentGroup = [
    'assessment:diagnosis', // Syntax: <type>:<subtype>
    'assessment:differential-diagnosis',
    'assessment:impression',
  ];

  const subjectiveObjectiveGroup = [...subjectiveGroup, ...objectiveGroup];

  const planGroup = [
    // 'care-plan',
    // 'patient-education',
    'medication-order',
    'med-certificate',
    // 'referrals',
    'lab-test-order',
    'imaging-test-order',
    'medical-procedure-order',
    // 'home-care-instructions',
    // 'health-mainenance-recommendations',
    // 'discharge-note',
  ];

  const dentalGroup = [
    'dental-note:baseline',
    'dental-note:order',
    'dental-note:result',
    'dental-history',
  ];

  const recordTypesMap = {
    // summary: {
    //   label: 'Summary',
    //   preview: AllergyHistoryPreview,
    // },
    attachment: {
      label: 'Attachments',
      preview: AttachmentsPreview,
      form: AttachmentsForm,
      secondModal: DrawAttachment,
      soapGrosoapGrouping: ['subjective', 'objective'],
    },
    allergy: {
      label: 'Allergy History',
      preview: AllergyHistoryPreview,
      form: AllergyHistoryForm,
    },
    // 'assessment:diagnosis': {
    //   label: 'Diagnosis',
    //   preview: DiagnosisPreview,
    //   view: DiagnosisView,
    //   form: DiagnosisForm,
    //   limit: 1,
    // },
    'assessment:diagnosis': {
      label: 'Assessment - Diagnosis',
      preview: AssessmentDiagnosisPreview,
      view: AssessmentDiagnosisView,
      form: AssessmentDiagnosisForm,
      limit: 1,
    },
    'assessment:impression': {
      label: 'Impression',
      preview: ImpressionPreview,
      form: ImpressionForm,
      limit: 1,
    },
    'birth-history': {
      label: 'Birth History',
      preview: BirthHistoryPreview,
      form: BirthHistoryForm,
      limit: 1,
    },
    'care-plan': {
      label: 'Care Plan',
      preview: CarePlanPreview,
      view: CarePlanView,
      form: CarePlanForm,
      limit: 1,
    },
    'chief-complaint': {
      label: 'Chief Complaint',
      preview: ChiefComplaintPreview,
      view: ChiefComplaintView,
      form: ChiefComplaintForm,
      limit: 1,
    },
    'dental-history': {
      label: 'Dental History',
      preview: DentalHistoryPreview,
      form: DentalHistoryForm,
    },
    'dental-note:baseline': {
      label: 'Dental Note - Baseline',
      preview: DentalNotePreview,
      form: DentalNoteForm,
    },
    'dental-note:order': {
      label: 'Dental Note - Work Proposed',
      preview: DentalNotePreview,
      form: DentalNoteForm,
    },
    'dental-note:result': {
      label: 'Dental Note - Work Done',
      preview: DentalNotePreview,
      form: DentalNoteForm,
    },
    'discharge-note': {
      label: 'Follow-up',
      preview: DispositionPreview,
      form: DispositionForm,
      limit: 1,
    },
    'ent-note': {
      label: 'ENT Note',
      preview: EntNotePreview,
      form: EntNoteForm,
      limit: 1,
    },
    'family-history': {
      label: 'Family History',
      preview: FamilyHistoryPreview,
      form: FamilyHistoryForm,
    },
    'gynecological-history': {
      label: 'Gynecologist Findings',
      preview: GynecologistFindingsPreview,
      form: GynecologistFindingsForm,
    },
    'hospitalization-history': {
      label: 'Hospitalization History',
      preview: HospitalizationHistoryPreview,
      form: HospitalizationHistoryForm,
    },
    'imaging-test-order': {
      label: 'Radiology Orders',
      preview: RadiologyOrderPreview,
      form: RadiologyOrderForm,
    },
    'imaging-test-result': {
      label: 'Radiology Results',
      preview: RadiologyTestResultsPreview,
      form: RadiologyTestResultsForm,
    },
    'lab-test-order': {
      label: 'Laboratory Order',
      preview: LaboratoryOrderPreview,
      form: LaboratoryOrderForm,
    },
    'lab-test-result': {
      label: 'Lab Results',
      // preview: BlankPreview,
      // form: BlankForm,
      preview: LabTestResultsPreview,
      form: LabTestResultsForm,
    },
    'medical-history': {
      label: 'Medical History',
      preview: MedicalHistoryPreview,
      form: MedicalHistoryForm,
    },
    'medication-order': {
      label: 'Prescriptions',
      preview: PrescriptionPreview,
      view: PrescriptionView,
      form: PrescriptionForm,
      limit: 1,
    },
    'medication-history': {
      label: 'Medication History',
      preview: MedicationHistoryPreview,
      form: MedicationHistoryForm,
    },
    // 'medical-procedure': {
    //   label: 'Procedure Results',
    //   preview: BlankPreview,
    //   form: ProcedureResultsForm,
    // },
    'medical-procedure-order': {
      label: 'Procedure Orders',
      preview: ProcedureOrderPreview,
      form: ProcedureOrderForm,
    },
    'mental-status': {
      label: 'Mental Status Exam',
      preview: MentalStatusExamPreview,
      form: MentalStatusExamForm,
    },
    'nutritional-assessment': {
      label: 'Nutritional Status',
      preview: NutritionalAssessmentPreview,
      form: NutritionalAssessmentForm,
      limit: 1,
    },
    'obstetric-history': {
      label: 'Obstetric History',
      preview: ObstetricHistoryPreview,
      // preview: BlankPreview,
      form: ObstetricHistoryForm,
      limit: 1,
    },
    'ob-note': {
      label: 'Obstetric Findings',
      preview: ObstetricFindingsPreview,
      form: ObstetricFindingsForm,
    },
    // pregnancy: {
    //   label: 'Pregnancy',
    //   preview: BlankPreview,
    //   form: BlankForm,
    // },
    'nutritional-plan': {
      label: 'Nutritional Plan',
      preview: NutritionalPlanPreview,
      form: NutritionalPlanForm,
    },
    'patient-education': {
      label: 'Patient Education',
      preview: PatientEducationPreview,
      form: PatientEducationForm,
    },
    'physical-exam': {
      label: 'Physical Exam',
      preview: PhysicalExamPreview,
      view: PhysicalExamView,
      form: PhysicalExamForm,
      limit: 1,
      soapGrosoapGrouping: ['subjective', 'objective'],
    },
    'social-history': {
      label: 'Social History',
      preview: SocialHistoryPreview,
      form: SocialHistoryForm,
      limit: 1,
    },
    'surgical-history': {
      label: 'Surgical History',
      preview: SurgicalHistoryPreview,
      form: SurgicalHistoryForm,
    },
    'travel-history': {
      label: 'Travel History',
      preview: TravelHistoryPreview,
      form: TravelHistoryForm,
    },
    hpi: {
      label: 'History Of Present Illness',
      preview: HpiPreview,
      view: HpiView,
      form: HpiForm,
      limit: 1,
    },
    pedia: {
      label: 'Pedia',
      preview: PediaPreview,
      form: PediaForm,
      limit: 1,
    },
    ros: {
      label: 'Review of Systems',
      preview: RosPreview,
      form: RosForm,
      limit: 1,
    },
    vaccination: {
      label: 'Vaccination',
      preview: VaccinationPreview,
      form: VaccinationForm,
    },
    vitals: {
      label: 'Vitals',
      preview: VitalsPreview,
      form: VitalsForm,
      limit: 1,
    },
    // non-existing record types yet
    'functional-status': {
      label: 'Functional Status',
      preview: FunctionalStatusPreview,
      form: FunctionalStatusForm,
      limit: 1,
    },
    'pain-assessment': {
      label: 'Pain Assessment',
      preview: BlankPreview,
      form: BlankForm,
    },
    'psychological-status': {
      label: 'Psychological Status',
      preview: BlankPreview,
      form: BlankForm,
    },
    'sociocultural-factors': {
      label: 'Sociocultural Factors',
      preview: BlankPreview,
      form: BlankForm,
    },
    'primary-diagnosis': {
      label: 'Primary Diagnosis',
      preview: BlankPreview,
      form: BlankForm,
    },
    'assessment:differential-diagnosis': {
      label: 'Differential Diagnosis',
      preview: DifferentialDiagnosisPreview,
      form: DifferentialDiagnosisForm,
      limit: 1,
    },
    prognosis: {
      label: 'Prognosis',
      preview: BlankPreview,
      form: BlankForm,
    },
    recommendations: {
      label: 'Recommendations',
      preview: BlankPreview,
      form: BlankForm,
    },
    'severity-of-illness': {
      label: 'Severity of Illness',
      preview: BlankPreview,
      form: BlankForm,
    },
    'complications-or-comorbidities': {
      label: 'Complications or Comorbidities',
      preview: BlankPreview,
      form: BlankForm,
    },
    'risk-factors': {
      label: 'Risk Factors',
      preview: BlankPreview,
      form: BlankForm,
    },
    referrals: {
      label: 'Referrals',
      preview: BlankPreview,
      form: BlankForm,
    },
    'follow-up-appoinments': {
      label: 'Follow-up Appoinments',
      preview: BlankPreview,
      form: BlankForm,
    },
    counseling: {
      label: 'Counseling',
      preview: BlankPreview,
      form: BlankForm,
    },
    'home-care-instructions': {
      label: 'Home Care Instructions',
      preview: BlankPreview,
      form: BlankForm,
    },
    'health-mainenance-recommendations': {
      label: 'Health Mainenance Recommendations',
      preview: BlankPreview,
      form: BlankForm,
    },
    'med-certificate': {
      label: 'Medical Certificate',
      preview: MedCertPreview,
      form: MedCertForm,
    },
  };

  const medicalRecordsPerType = computed(() => {
    const records = {};
    state.medicalRecords.forEach((record) => {
      const type = record.type;
      const subtype = record.subtype;

      if (excludeRecordTypes.includes(type)) return;

      let groupName = type;
      if (subtype) groupName = `${type}:${subtype}`;
      const group = recordTypesMap[groupName];
      if (!records[group.label]) records[group.label] = [];
      records[group.label].push(record);
    });
    return records;
  });

  async function listItems (opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      type: {
        $in: ['medication-order', 'chief-complaint', 'hpi', 'care-plan'],
      },
      $sort: {
        createdAt: -1,
      },
      $populate: {
        relative: {
          service: 'personal-details',
          method: 'findOne',
          localKey: 'relative',
          foreignKey: 'id',
        },
        service: {
          service: 'services',
          method: 'findOne',
          localKey: 'service',
          foreignKey: 'id',
        },
        encounter: {
          service: 'medical-encounters',
          method: 'findOne',
          localKey: 'encounter',
          foreignKey: 'id',
        },
      },
      ...opts,
    };

    if (opts.facility) query.facility = opts.facility;
    if (opts.encounter) query.encounter = opts.encounter;
    if (opts.patient) query.patient = opts.patient;

    const result = await sdk?.list('medical-records', query);
    return result.data.map(onFormatRecord);
  }

  async function list (opts) {
    const items = await listItems(opts);
    state.medicalRecords = items;
  };

  async function listMedicalRecordsHistory (opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      $sort: {
        createdAt: -1,
      },
      ...opts,
    };

    if (opts.facility) query.facility = opts.facility;

    const result = await sdk?.list('medical-records', query);

    state.allMedicalRecords = result.data.map(onFormatRecord);
  };

  async function get (id) {
    const result = await sdk?.get('medical-records', id);
    return onFormatRecord(result);
  };

  async function findItem (opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
      $sort: {
        createdAt: -1,
      },
      ...opts,
    };

    const result = await sdk?.get('medical-records', query);
    return onFormatRecord(result);
  }

  async function create (data, opts = {}) {
    const activeFacility = await getActiveFacility();
    const facilityId = route.query.facility || activeFacility?.id; // ID of the shared encounter facility or the active facility

    const payload = {
      facility: opts.facilityId || facilityId,
      ...data,
    };
    const result = await sdk?.create('medical-records', payload);
    const record = onFormatRecord(result);
    state.medicalRecords.push(record);
    return record;
  };

  async function update (id, payload) {
    const result = await sdk?.update('medical-records', id, omit(payload, ['id']));
    const index = state.medicalRecords.findIndex((item) => item.id === id);
    const record = onFormatRecord(result);
    state.medicalRecords[index] = record;
    return record;
  };

  async function remove (id) {
    await sdk?.delete('medical-records', id);
    const index = state.medicalRecords.findIndex((item) => item.id === id);
    state.medicalRecords.splice(index, 1);
    showSnack('Record deleted successfully', { type: 'success' });
  };

  async function getTotalRecords (opts) {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const query = {
      facility: facilityId,
    };

    if (opts.patient) query.patient = opts.patient;

    const result = await sdk?.list('medical-records', query);
    return result?.total;
  }

  return {
    recordTypesMap,
    excludeRecordTypes,
    triageGroup,
    subjectiveGroup,
    objectiveGroup,
    assessmentGroup,
    subjectiveObjectiveGroup,
    planGroup,
    dentalGroup,
    medicalRecordsPerType,
    ...toRefs(state),
    list,
    listItems,
    get,
    create,
    update,
    findItem,
    remove,
    listMedicalRecordsHistory,
    getTotalRecords,
  };
};
