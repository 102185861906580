<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <div class="flex justify-between items-center p-0">
      <span class="text-xs mb-2 text-neutral-400">
        <span v-if="showRecordLabel">Care Plan Notes - </span>
        {{createdAt}}
      </span>
      <div v-if="!hideOptions" class="dropdown dropdown-end">
        <label tabindex="0" class="btn btn-sm btn-ghost btn-circle m-1">
          <i class="las la-ellipsis-v text-xl"></i>
        </label>
        <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-slate-100 rounded-box w-52">
          <li>
            <nuxt-link @click="edit">
              <i class="las la-edit"></i>
              Edit
            </nuxt-link>
          </li>
          <li>
            <nuxt-link @click="remove">
              <i class="las la-trash"></i>
              Delete
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
    <preview-wrapper :hide-options="hideOptions" :record="record">
      <span class="text-sm">{{text}}</span>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import { useCarePlan } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeCarePlan } = useCarePlan();

    const hoveringGenericPreview = ref(false);
    const text = computed(() => props.record.text);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const recordId = computed(() => props.record.id);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      // const result = globalThis.confirm('Are you sure you want to delete this care plan?');
      // if (!result) return;
      removeCarePlan({ id: recordId.value });
    }

    return {
      hoveringGenericPreview,
      createdAt,
      text,
      edit,
      remove,
    };
  },
};
</script>
