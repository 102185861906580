import { computed, reactive, toRefs } from 'vue';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { usePatient } from '@/composables/use-patients';
import { useFacilities } from '@/composables/use-facilities';
import { useEncounters } from '@/composables/use-encounters';
import { RECORD_TYPE, UPDATE_FIELDS } from './constants';

const state = reactive({
  allVaccinations: [],
});

export function useVaccinations () {
  const { getActiveFacility } = useFacilities();
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
    listItems,
  } = useMedicalRecords();

  const { patient } = usePatient();
  const { encounter } = useEncounters();

  const records = computed(() => {
    return medicalRecords.value.filter((record) => record.type === RECORD_TYPE)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  async function listAllVaccinations () {
    const activeFacility = await getActiveFacility();
    const facilityId = activeFacility?.id;
    if (!facilityId) return;

    const patientId = patient.value?.id;

    const result = await listItems({ type: RECORD_TYPE, patient: patientId, facility: facilityId });
    state.allVaccinations = result;
  }

  const submit = async (payload, opts) => {
    if (payload?.id) {
      const id = payload.id;
      payload = Object.keys(payload).reduce((acc, key) => {
        if (UPDATE_FIELDS.includes(key)) {
          acc[key] = payload[key];
        }
        return acc;
      }, {});
      await updateMedicalRecord(id, payload);
    } else {
      payload.type = RECORD_TYPE;
      payload.encounter = opts.encounterId || encounter.value?.id;
      payload.patient = opts.patientId || patient.value?.id;
      await createMedicalRecord(payload, { facilityId: opts.facilityId });
    }
  };

  const remove = async ({ id }) => {
    await removeMedicalRecord(id);
  };

  return {
    medicalRecords,
    records,
    ...toRefs(state),
    submit,
    remove,
    listAllVaccinations,
  };
}
