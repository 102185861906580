<script setup>
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import { usePatient } from '@/composables/use-patients';
import { useAuthentication } from '@/composables/use-authentication';
import { useRouter } from 'vue-router';
import { usePrescription } from './composables';
import PaperPreview from './paper-preview.vue';

import useSnackbar from '@/components/commons/global-snack/composables';

const { show: showSnackbar } = useSnackbar();

const emit = defineEmits(['edit']);

const props = defineProps({
  record: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  hideOptions: {
    type: Boolean,
    default: false,
  },
  showRecordLabel: {
    type: Boolean,
    default: true,
  },
});

const { remove: removePrescription, sendToPharmacy } = usePrescription();

const router = useRouter();
const paperPreviewModal = ref(false);

const { patient } = usePatient();
const { currentUser } = useAuthentication();

const items = computed(() => {
  if (!props.record?.items?.length) return [];
  return props.record.items.map(item => {
    return {
      ...item,
      formattedSig: `${item.dosageSig || ''} ${item.frequency || ''}`,
    };
  });
});

const notes = computed(() => props.record?.notes);

function edit () {
  emit('edit', props.record);
}

function remove () {
  removePrescription({ id: props.record.id });
}

function onPrintPrescription () {
  router.push({ name: 'print-prescription', query: { id: props.record?.id } });
}

function formatDate (date) {
  if (!date) return;
  return format(new Date(date), 'MM/dd/yyyy');
}

const hasItems = computed(() => items.value.length > 0);

const onSendToPharmacy = async (rec) => {
  try {
    const confirm = window.confirm('Are you sure you want to send this prescription to the pharmacy?');
    if (!confirm) return;
    await sendToPharmacy(rec);
    showSnackbar('Prescription sent to pharmacy successfully.', { type: 'success'});
  } catch (error) {
    console.error(error);
    window.alert(`Failed to send prescription to pharmacy. ${error.message}`);
  }
};
</script>

<template>
  <dialog id="paperPreviewModal" :class="`modal ${paperPreviewModal ? 'modal-open' : ''}`">
    <form method="dialog" class="flex flex-col gap-4 modal-box w-11/12 rounded-none">

      <div class="flex justify-end items-center">
        <button class="btn btn-sm btn-circle btn-ghost" @click="paperPreviewModal = false">
          <i class="las la-times text-2xl text-red-400"></i>
        </button>
      </div>

      <div>
        <paper-preview :record="record" :patient="patient" :doctor="currentUser"/>
      </div>
    </form>
  </dialog>

  <div class="flex-col flex gap-2">
    <div class="flex justify-between items-center">
      <span class="text-[24px]">Rx</span>
      <div class="flex gap-2 items-center">
        <button 
          v-if="hasItems"
          class="btn btn-sm btn-square btn-ghost tooltip tooltip-left"
          data-tip="Share to Pharmacy"
          @click="onSendToPharmacy(record)"
        >
          <i class="las la-share text-2xl"></i>
        </button>
        <button 
          v-if="hasItems" 
          class="btn btn-sm btn-square btn-ghost tooltip tooltip-left" 
          data-tip="View Prescription"
          @click="paperPreviewModal = true"
        >
          <i class="las la-eye text-2xl"></i>
        </button>
      </div>
    </div>
    <div v-if="hasItems" class="flex flex-col gap-5">
      <template v-for="(medicine, index) in items" :key="index">
        <div>
          <div class="flex justify-between mb-1">
            <div>
              <span v-if="medicine?.genericName" class="font-medium">{{index + 1}}. {{medicine?.genericName}} {{medicine.formulation}}</span>
            </div>
            <span v-if="medicine?.dispense" class="text-neutral-500">#{{medicine?.dispense}}</span>
          </div>
          <div class="flex flex-col gap-4">
            <div class="flex flex-col gap-1">
              <span v-if="medicine?.brandName" class="indent-4 text-neutral-500">
                <span class="font-medium">Brand:</span> {{medicine?.brandName}}
              </span>
              <span v-if="medicine?.formattedSig?.trim().length" class="indent-4 text-neutral-500">
                <span class="font-medium">Sig:</span> {{medicine?.formattedSig}}
              </span>
              <span v-if="medicine?.formulationClassification" class="indent-4 text-neutral-500">
                <span class="font-medium">Route:</span> {{medicine?.formulationClassification}}
              </span>
              <span v-if="medicine?.frequencyReminderInterval" class="indent-4 text-neutral-500">
                <span class="font-medium">Refills:</span> {{medicine?.frequencyReminderInterval}}
              </span>
              <span v-if="medicine?.reasonForPrescription" class="indent-4 text-neutral-500">
                <span class="font-medium">Indication:</span> {{medicine?.reasonForPrescription}}
              </span>
              <span v-if="medicine?.startedAt" class="indent-4 text-neutral-500">
                <span class="font-medium">Start Date:</span> {{formatDate(medicine?.startedAt)}}
              </span>
              <span v-if="medicine?.endedAt" class="indent-4 text-neutral-500">
                <span class="font-medium">End Date:</span> {{formatDate(medicine?.endedAt)}}
              </span>
            </div>
            <div v-if="medicine?.note" class="flex flex-col gap-2">
              <span v-if="medicine?.note" class="indent-4 text-sm font-medium">Notes</span>
              <span v-if="medicine?.note" class="pl-4 text-sm text-neutral-500 break-words">{{ medicine?.note }}</span>
            </div>
          </div>
        </div>
      </template>
      <p v-if="notes">Notes: {{notes}}</p>
    </div>
    <template v-else>
      <div class="flex justify-between mb-1">
        <div class="h-2 bg-gray-100 rounded-full w-[200px]"></div>
        <div class="h-2 bg-gray-100 rounded-full w-[100px]"></div>
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-1">
          <div class="h-2 bg-gray-100 rounded-full w-[100px]"></div>
          <div class="h-2 bg-gray-100 rounded-full w-[120px]"></div>
          <div class="h-2 bg-gray-100 rounded-full w-[200px]"></div>
          <div class="h-2 bg-gray-100 rounded-full w-[90px]"></div>
          <div class="h-2 bg-gray-100 rounded-full w-[200px]"></div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="h-2 bg-gray-100 rounded-full w-[60px]"></div>
          <div class="h-2 bg-gray-100 rounded-full w-full"></div>
          <div class="h-2 bg-gray-100 rounded-full w-full"></div>
          <div class="h-2 bg-gray-100 rounded-full w-[200px]"></div>
        </div>
      </div>
    </template>
  </div>
</template>


