<template>
  <form @submit.prevent="submit">
    <div class="flex flex-col gap-4">
      <!-- <div class="grid grid-cols-2 gap-4">
        <span class="text-primary col-span-full">Vitals</span>
        <div>"Attempt to import Vitals record here. (last task)"</div>
      </div> -->

      <div class="grid grid-cols-2">
        <div class="grid grid-cols-2 gap-x-8
         col-span-full gap-2 mb-1">
          <!-- Nutritional Supplements -->
          <div class="form-control">
            <label class="label">
              <span class="label-text">Nutritional Supplements <span class="text-error">*</span></span>
            </label>
            <Chips
              v-model="model.nutritionalSupplements"
              addOnBlur
              placeholder="e.g. Omega-3 Fish Oil, Vitamin D3"
              class="input input-bordered focus:input-neutral h-[50px] py-2"
              :pt="{
                container: { class: 'w-full flex flex-row gap-2' },
                token: { class: 'flex flex-row items-center align-center gap-2 join rounded rounded-full bg-neutral-300 shadow-sm px-2' },
                label: { class: 'join-item' },
                removeTokenIcon: { class: 'hover:bg-neutral-200 rounded-full' }
              }"
            >
            </Chips>
            <div>
              <span class="label-text-alt text-neutral-400">Enter appropriate supplements</span>
            </div>
          </div>
          <!-- Appetite -->
          <div class="form-control">
            <label class="label">
              <span class="label-text">Appetite <span class="text-error">*</span></span>
            </label>
            <input type="text" placeholder="e.g. Good" class="input input-bordered h-[50px]" v-model="model.appetite"/>
          </div>
          <!-- Bowel Function -->
          <div class="form-control">
            <label class="label">
              <span class="label-text">Bowel Function <span class="text-error">*</span></span>
            </label>
            <input
              v-model="model.bowelFunction"
              type="text"
              placeholder="e.g. Normal bowel function"
              class="input input-bordered"
            />
          </div>
        </div>
      </div>

      <span class="font-normal">Food Frequency Questionnaire <span class="text-error">*</span></span>

      <!-- Food Frequency Questionnaire -->
      <div class="grid grid-cols-2 gap-4 gap-x-7">
        <div class="form-control" v-for="(food, index) in model.foodFrequencyMap" :key="index">
          <label class="label">
            <span class="label-text text-[#171717] font-medium">{{ food.label }}</span>
            <span class="text-xs label-text-alt">e.g. {{ food.example }}</span>
          </label>
          <div class="flex flex-row gap-2">
            <input type="number" placeholder="Min" min="1" class="input input-sm input-bordered focus:input-neutral w-full" v-model="food.min">
            <input type="number" placeholder="Max" :min="food?.min || 1" class="input input-sm input-bordered focus:input-neutral w-full" v-model="food.max">
            <select v-model="food.unit" class="select select-sm select-bordered font-normal w-full" :required="!foodSubmittable(food.min, food.max, food.unit)">
              <option disabled selected>Select Frequency here</option>
              <option value="week">times a week</option>
              <option value="day">times a day</option>
            </select>
          </div>
          <label v-if="!foodSubmittable(food.min, food.max, food.unit)" class="label">
            <span v-if="!(isNumber(food?.min) && isNumber(food?.max))" class="label-text-alt text-error">Please input a complete range</span>
            <span v-if="isEmpty(food.unit)" class="label-text-alt text-error">Please input diet frequency</span>
          </label>
        </div>
      </div>

      <!-- <span class="text-primary">Food Recall (Usual Food Intake)</span>

      <div class="grid grid-cols-2 gap-4">
        <div class="form-control" v-for="(food, index) in model.foodRecallMap" :key="index">
          <label class="label">
            <span class="label-text">{{ food.label }}</span>
          </label>
          <div class="flex flex-col gap-4">
            <label class="label gap-2">
              <span class="label-text-alt">Time</span>
              <input type="time" class="input input-bordered w-full text-center" v-model="food.time">
            </label>
            <label class="label gap-2">
              <span class="label-text-alt">Foods</span>
              <input type="text" class="input input-bordered w-full" v-model="food.foods">
            </label>
          </div>
        </div>
      </div> -->

      <div class="form-control">
        <label class="label">
          <span class="label-text">Diet RX <span class="text-error">*</span></span>
        </label>
        <textarea class="textarea textarea-bordered w-full" placeholder="e.g. Iron Supplement, once a day, for treatment of iron deficiency" v-model="model.dietRX" />
      </div>

      <div class="form-control">
        <label class="label">
          <span class="label-text">Overall Nutritional Assessment <span class="text-error">*</span></span>
        </label>
        <textarea class="textarea textarea-bordered w-full" placeholder="e.g. No signs of malnutrition; nutritional intake meets recommended guidelines" v-model="model.nutritionalAssessment" />
      </div>

      <div class="flex gap-4 justify-end">
        <button
          v-if="formSubmittable"
          type="button"
          class="btn btn-outline btn-sm normal-case"
          @click="resetForm"
        >
          Clear All
        </button>
        <button
          type="submit"
          class="btn btn-neutral btn-sm normal-case text-white"
          :disabled="!formSubmittable"
          >
          {{ submitText }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { FORM_MODEL, FORM_RULES, UPDATE_FIELDS, BASE_FORM_MODEL } from './constants';
import { computed, reactive, ref } from 'vue';
import { useNutritionalAssessment } from './composables';
import { merge, isEmpty, isNumber, pick, mapValues } from 'lodash';
import Chips from 'primevue/chips';

export default {
  props: {
    showLabel: Boolean,
  },
  components: {
    Chips,
  },
  setup (props, { emit }) {
    const {
      submit: submitNutritionalAssessment,
    } = useNutritionalAssessment();
    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);
    const submitText = computed(() => isEditing.value ? 'Save Changes' : 'Submit');

    let model = reactive(FORM_MODEL);
    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key]?.required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      // check food freq input
      const completeDietInputs = Object.keys(model.foodFrequencyMap).filter(i => {
        const diet = model.foodFrequencyMap[i];
        return isNumber(diet?.min) && isNumber(diet?.max) && diet?.unit !== 'Select unit here';
      });
      if (!completeDietInputs?.length) return false;
      return true;
    });
    const foodSubmittable = (min, max, diet) => {
      return [!!min, !!max, !isEmpty(diet)].every(Boolean) ||
        [!!min, !!max, !isEmpty(diet)].every(value => value === false);
    };

    const submit = async () => {
      try {
        loading.value = true;
        const payload = Object.fromEntries(
          Object.entries(model).filter(([value, key]) => Boolean(value) || isNil(value)),
        );
        if (!isEmpty(payload.nutritionalSupplements)) {
          // payload.nutritionalSupplements = payload.nutritionalSupplements.join('; ');
        }
        const completeDietInputs = Object.keys(payload.foodFrequencyMap).filter(i => {
          const diet = payload.foodFrequencyMap[i];
          return isNumber(diet?.min) && isNumber(diet?.max) && diet?.unit !== 'Select unit here';
        });
        const completedSelectedDietInputs = pick(payload.foodFrequencyMap, completeDietInputs);
        payload.foodFrequencyMap = mapValues(completedSelectedDietInputs, i => pick(i, ['min', 'max', 'unit']));

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitNutritionalAssessment(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = BASE_FORM_MODEL[field];
      });
      model.foodFrequencyMap = {
        redMeat: {
          label: 'Red Meat',
          unit: '',
          min: '',
          max: '',
          example: '2-3 servings per week',
        },
        whiteMeat: {
          label: 'White Meat',
          unit: '',
          min: '',
          max: '',
          example: '3-4 times a week',
        },
        processedMeat: {
          label: 'Processed Meat',
          unit: '',
          min: '',
          max: '',
          example: '1-2 servings per day',
        },
        beansAndPeasOrLentils: {
          label: 'Beans and Peas or Lentils',
          unit: '',
          min: '',
          max: '',
          example: '5-7 times a week',
        },
        egg: {
          label: 'Egg',
          unit: '',
          min: '',
          max: '',
          example: '2-3 eggs per day',
        },
        milk: {
          label: 'Milk',
          unit: '',
          min: '',
          max: '',
          example: '1-2 cups per day',
        },
        cheeseAndYoghurt: {
          label: 'Cheese and Yoghurt',
          unit: '',
          min: '',
          max: '',
          example: '3-4 servings per week',
        },
        ricePastaAndBread: {
          label: 'Rice, Pasta, and Bread',
          unit: '',
          min: '',
          max: '',
          example: '4-6 servings per day',
        },
        ricePastaAndBreadWholeGrain: {
          label: 'Rice, Pasta, and Bread - Whole Grain',
          unit: '',
          min: '',
          max: '',
          example: '2-3 servings per day',
        },
        fruit: {
          label: 'Fruit',
          unit: '',
          min: '',
          max: '',
          example: '3-4 servings per day',
        },
        vegetable: {
          label: 'Vegetable',
          unit: '',
          min: '',
          max: '',
          example: '5-7 servings per day',
        },
        water: {
          label: 'Water',
          unit: '',
          min: '',
          max: '',
          example: '8-9 glasses per day',
        },
        alcoholicBeverages: {
          label: 'Alcoholic Beverages',
          unit: '',
          min: '',
          max: '',
          example: '1-2 drinks per week',
        },
        coffeeAndTea: {
          label: 'Coffee and Tea',
          unit: '',
          min: '',
          max: '',
          example: '2-3 cups per day',
        },
        fastFood: {
          label: 'Fast Food',
          unit: '',
          min: '',
          max: '',
          example: '1-2 meals per week',
        },
      };
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model = reactive(merge(model, data));
      model.nutritionalSupplements = data.nutritionalSupplements.split('; ');
    }

    return {
      loading,
      model,
      recordId,
      isEditing,
      submitText,
      formSubmittable,
      foodSubmittable,
      resetForm,
      submit,
      setForm,
      isEmpty,
      isNumber,
    };
  },
};
</script>
