<template>
<div class="flex flex-col gap-4 h-[70vh]">
  <div class="flex flex-col items-center justify-center">
    <h1 class="font-medium text-xl">Dr. {{doctor?.formattedName}}</h1>
    <h2 class="font-medium text-md">{{doctor?.formattedSpecializations}}</h2>
  </div>
  <div class="border border-1">
   <table class="table table-sm">
    <tbody>
      <tr>
        <td>Name: {{patient?.formattedName || '-'}}</td>
        <td>Date: {{record?.formattedCreatedAt || '-'}}</td>
      </tr>
      <tr>
        <td>Sex: {{patient?.formattedSex || '-'}}</td>
        <td>Age: {{patient?.formattedAge || '-'}}</td>
      </tr>
      <tr>
        <td colspan="2">
          Address: {{patient?.formattedAddress}}
        </td>
      </tr>
    </tbody>
   </table>
  </div>
  <div class="flex flex-col gap-4">
    <span class="text-5xl">℞</span>
    <template v-for="(medicine, index) in items" :key="index">
      <div>
        <div class="flex justify-between mb-1">
          <div>
            <span class="font-medium">{{index + 1}}. {{medicine?.genericName}} {{medicine.formulation}}</span>
          </div>
          <span v-if="medicine?.dispense" class="text-neutral-500">#{{medicine?.dispense}}</span>
        </div>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-1">
            <span v-if="medicine?.brandName" class="indent-4 text-neutral-500">
              <span class="font-medium">Brand:</span> {{medicine?.brandName}}
            </span>
            <span v-if="medicine?.formattedSig?.trim().length" class="indent-4 text-neutral-500">
              <span class="font-medium">Sig:</span> {{medicine?.formattedSig}}
            </span>
            <span v-if="medicine?.formulationClassification" class="indent-4 text-neutral-500">
              <span class="font-medium">Route:</span> {{medicine?.formulationClassification}}
            </span>
            <span v-if="medicine?.frequencyReminderInterval" class="indent-4 text-neutral-500">
              <span class="font-medium">Refills:</span> {{medicine?.frequencyReminderInterval}}
            </span>
            <span v-if="medicine?.reasonForPrescription" class="indent-4 text-neutral-500">
              <span class="font-medium">Indication:</span> {{medicine?.reasonForPrescription}}
            </span>
            <span v-if="medicine?.startedAt" class="indent-4 text-neutral-500">
              <span class="font-medium">Start Date:</span> {{formatDate(medicine?.startedAt)}}
            </span>
            <span v-if="medicine?.endedAt" class="indent-4 text-neutral-500">
              <span class="font-medium">End Date:</span> {{formatDate(medicine?.endedAt)}}
            </span>
          </div>
          <div v-if="medicine?.note" class="flex flex-col gap-2">
            <span v-if="medicine?.note" class="indent-4 text-sm font-medium">Notes</span>
            <span v-if="medicine?.note" class="pl-4 text-sm text-neutral-500 break-words">{{ medicine?.note }}</span>
          </div>
        </div>
      </div>
    </template>
    <p v-if="notes">Notes: {{notes}}</p>
  </div>
</div>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';

export default {
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
    doctor: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    const items = computed(() => {
      return props.record.items?.map(item => {
        return {
          ...item,
          formattedSig: `${item.dosageSig || ''} ${item.frequency || ''}`,
        };
      });
    });

    const notes = computed(() => props.record?.notes);

    function formatDate (date) {
      if (!date) return;
      return format(new Date(date), 'MM/dd/yyyy');
    }

    return {
      items,
      notes,
      formatDate,
    };
  },
};
</script>

<style scoped>
table, tr, td {
  border-collapse: collapse;
  border: 1px solid lightgrey;
}
</style>
