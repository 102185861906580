<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <template v-for="(field, index) in formFields" :key="index">
          <div v-if="field.field === 'eyesAndEnt' && eyesAndEnt" class="flex flex-col gap-2">
            <span class="text-sm font-medium truncate">HEENT</span>
            <div class="flex" v-if="eyesAndEntStatus === 'positive'">
              <span class="text-sm text-neutral-500">{{ eyesAndEnt || '-' }} +</span>
            </div>
            <div v-else>
              <span class="text-sm text-neutral-500">None</span>
            </div>
          </div>
          <div v-if="record[`${field.field}`] && field.label !== 'Notes'"
            class="flex flex-col gap-2">
            <span class="text-sm font-medium truncate">{{field.label}}</span>
            <div class="flex" v-if="record[`${field.field}Status`] === 'positive'">
              <span v-if="record[field.field]" class="text-sm text-neutral-500">{{record[field.field]}} +</span>
            </div>
            <div v-else>
              <span class="text-sm text-neutral-500">None</span>
            </div>
            <!-- <span class="text-sm text-neutral-500">Status: {{record[`${field.field}Status`]}}</span>
            <span v-if="record[field.field]" class="text-sm">Remarks: {{record[field.field]}}</span> -->
          </div>
        </template>
        <div class="flex flex-col md:gap-2 col-span-full">
          <span class="text-sm font-medium">Notes</span>
          <span class="text-sm w-full text-neutral-500">{{ record?.notes || '-' }}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { FORM_FIELDS } from './constants';
import { useRos } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeRos } = useRos();

    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const eyesAndEnt = computed(() => props.record?.eyes || props.record?.ent);
    const eyesAndEntStatus = computed(() => props.record?.eyesStatus || props.record?.entStatus);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      // const result = globalThis.confirm('Are you sure you want to delete this ros?');
      // if (!result) return;
      removeRos({ id: props.record.id });
    }

    return {
      createdAt,
      formFields: FORM_FIELDS,
      eyesAndEnt,
      eyesAndEntStatus,
      edit,
      remove,
    };
  },
};
</script>
