<script setup>
import { computed } from 'vue';
import { useDentalHistory } from './composables';
import { FORM_FIELDS } from './constants';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

const props = defineProps({
  record: {
    type: Object,
    required: true,
    defafault: () => ({}),
  },
  hideOptions: {
    type: Boolean,
    default: false,
  },
  showRecordLabel: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['edit', 'remove']);

const { remove: removeDentalHistory } = useDentalHistory();
const notes = computed(() => props.record.notes);
const teeth = computed(() => props.record.teeth);
const habits = computed(() => props.record.habits);
const complications = computed(() => props.record.complications);
const formFields = computed(() => FORM_FIELDS);
const data = computed(() => {
  return formFields.value.map((field) => {
    const value = props.record[field.model];
    if (!value) {
      return null;
    }
    const obj = {
      label: field.label,
      value: props.record[field.model],
    };
    return obj;
  }).filter(Boolean);
});

function edit () {
  emit('edit', props.record);
}

function remove () {
  removeDentalHistory({ id: props.record.id });
}
</script>

<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div v-if="teeth?.length" class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Teeth</span>
          <ul class="list-disc">
            <li v-for="tooth in teeth" :key="tooth" class="text-sm text-neutral-500">{{ tooth }}</li>
          </ul>
        </div>
        <template v-for="field in data">
          <div class="col-span-1 flex flex-col gap-2">
            <span class="text-sm font-medium">{{ field.label }}</span>
            <span class="text-sm text-neutral-500">{{ field.value }}</span>
          </div>
        </template>
        <div v-if="habits?.length" class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Habits</span>
          <ul class="list-disc">
            <li v-for="habit in habits" :key="habit" class="text-sm text-neutral-500">{{ habit }}</li>
          </ul>
        </div>
        <div v-if="complications?.length" class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Complications</span>
          <ul class="list-disc">
            <li v-for="complication in complications" :key="complication" class="text-sm text-neutral-500">{{ complication }}</li>
          </ul>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Notes</span>
          <span class="text-sm text-neutral-500">{{ notes || '-' }}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>