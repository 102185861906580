<script setup>
import { computed, onMounted } from 'vue';
import { format } from 'date-fns';
import { useDentalNote } from './composables';
import { startCase } from 'lodash';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

const props = defineProps({
  record: {
    type: Object,
    required: true,
    defafault: () => ({}),
  },
  hideOptions: {
    type: Boolean,
    default: false,
  },
  showRecordLabel: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['edit', 'remove']);

defineExpose({ edit, remove });

const { remove: removeRecord } = useDentalNote();

const teeth = computed(() => props.record.teeth?.[0]);
const status = computed(() => {
  const status = props.record.status;
  return `${status.abbreviation} - ${status.category}`
});
const surfaces = computed(() => {
  if (!props.record.surfaces) return [];
  return props.record.surfaces.map(surface => {
    const data = {
      name: startCase(surface.name),
    };
    if (surface.icdas) data.icdas = surface.icdas;
    if (surface.cast) data.cast = surface.cast;
    return data;
  });
});
const icd10 = computed(() => props.record.icd10);
const notes = computed(() => props.record.notes);
const service = computed(() => {
  const service = props.record.formattedService;
  if (!service) return;
  return `${service.name} - ${formatMoney(service.price)}`;
});

function edit() {
  emit('edit', props.record);
}

function remove () {
  removeRecord({ id: props.record.id });
}

function formatMoney(number) {
  return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
}
</script>


<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
        <!-- <div class="flex flex-col gap-4">
          <p class="text-sm font-medium">Dental Note</p>
        </div> -->
        <div class="grid grid-cols-2 gap-2">
          <div class="flex flex-col col-span-1 gap-2">
            <span class="text-sm font-medium">Teeth</span>
            <span class="text-sm text-neutral-500">{{ teeth }}</span>
          </div>
          <div class="flex flex-col col-span-1 gap-2">
            <span class="text-sm font-medium">Status</span>
            <span class="text-sm text-neutral-500">{{ status }}</span>
          </div>
          <div class="flex flex-col col-span-2 gap-2">
            <span class="text-sm font-medium">Surfaces</span>
            <div class="flex flex-col gap-2">
              <span class="text-sm text-neutral-500" v-for="surface in surfaces" :key="surface">
                {{ surface.name }} <span v-if="surface?.icdas">(ICDAS {{ surface.icdas }})</span> <span v-if="surface.cast">(CAST {{ surface.cast }})</span>
              </span>
            </div>
          </div>
          <div class="flex flex-col col-span-2 gap-2">
            <span class="text-sm font-medium">ICD10</span>
            <span class="text-sm text-neutral-500">{{ icd10 }}</span>
          </div>
          <div v-if="service" class="flex flex-col col-span-2 gap-2">
            <span class="text-sm font-medium">Procedure</span>
            <span class="text-sm text-neutral-500">{{ service }}</span>
          </div>
          <div class="flex flex-col col-span-2 gap-2">
            <span class="text-sm font-medium">Notes</span>
            <span class="text-sm text-neutral-500">{{ notes }}</span>
          </div>
        </div>
      </preview-wrapper>
  </generic-preview>
</template>

