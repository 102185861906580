import { useEncounters } from '@/composables/use-encounters';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { usePatient } from '@/composables/use-patients';
import { computed } from 'vue';
import { RECORD_TYPE, UPDATE_FIELDS } from './constants';

export function useRos () {
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
  } = useMedicalRecords();

  const { patient } = usePatient();
  const { encounter } = useEncounters();

  const records = computed(() => medicalRecords.value.filter((record) => record.type === RECORD_TYPE));

  const submit = async (data) => {
    const payload = Object.keys(data).reduce((acc, key) => {
      if (UPDATE_FIELDS.includes(key)) {
        if (data[`${key}Status`] && key !== 'notes') acc[`${key}Status`] = data[`${key}Status`];
        if (data[key]) acc[key] = data[key];
        return acc;
      }
      if (key === 'eyes' || key === 'ent' || key === 'eyesStats' || key === 'entStatus') {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    if (data?.id) {
      const id = data.id;
      await updateMedicalRecord(id, payload);
    } else {
      payload.type = RECORD_TYPE;
      payload.encounter = encounter.value?.id;
      payload.patient = patient.value?.id;
      await createMedicalRecord(payload);
    }
  };

  const remove = async ({ id }) => {
    await removeMedicalRecord(id);
  };

  return {
    medicalRecords,
    records,
    submit,
    remove,
  };
}
