import { computed } from 'vue';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { useFixtures } from '@/composables/use-fixtures';
import { usePatient } from '@/composables/use-patients';
import { useEncounters } from '@/composables/use-encounters';
import { useDiagnosticTests } from '@/composables/use-diagnostic-tests';
import { RECORD_TYPE, UPDATE_FIELDS, DIAGNOSTIC_TEST_TYPE } from './constants';
import { useFacilities } from '@/composables/use-facilities';

export function useLaboratoryOrders () {
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
  } = useMedicalRecords();
  const {
    icd10codes,
    getICD10,
  } = useFixtures();
  const { listTests } = useDiagnosticTests();

  const { patient } = usePatient();
  const { encounter } = useEncounters();
  const { activeFacility } = useFacilities();

  const records = computed(() => {
    return medicalRecords.value.filter((record) => record.type === RECORD_TYPE)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  const searchTests = async () => {
    await listTests(DIAGNOSTIC_TEST_TYPE);
  };

  const searchICD = async (searchString) => {
    return getICD10(searchString);
  };

  const submit = async (payload) => {
    if (payload?.id) {
      const id = payload.id;
      payload = Object.keys(payload).reduce((acc, key) => {
        if (UPDATE_FIELDS.includes(key)) {
          acc[key] = payload[key];
        }
        if (key === 'diagnosisCode' || key === 'diagnosisText') {
          acc[key] = payload[key];
        }
        return acc;
      }, {});
      await updateMedicalRecord(id, payload);
    } else {
      payload.type = RECORD_TYPE;
      payload.encounter = encounter.value?.id;
      payload.patient = patient.value?.id;
      payload.facility = activeFacility.value?.id;
      await createMedicalRecord(payload);
    }
  };

  const remove = async ({ id }) => {
    await removeMedicalRecord(id);
  };

  return {
    icd10codes,
    medicalRecords,
    records,
    searchTests,
    searchICD,
    submit,
    remove,
  };
}
