<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import { FORM_MODEL, FORM_FIELDS, TEETH_LOCATIONS, SURFACES_CHECKBOX_MODEL, ICDAS_DEFAULTS, CAST_DEFAULTS } from './constants';
import { ICD10_CODES } from '@/constants/commons/icd10';
import { isEmpty, startCase } from 'lodash';
import { useDentalNote } from './composables';
import { useDentalStatus } from '@/composables/use-dental-status';
import { useServices  } from '@/composables/use-services';

defineExpose({
  setForm,
  resetForm,
});

const props = defineProps({
  title: {
    type: String,
    default: 'Dental Note',
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: 'dental-note',
  },
  subtype: {
    type: String,
    default: 'baseline',
  },
});

const emit = defineEmits(['submit', 'success', 'error']);

const { listItems: listStatuses, customDentalStatuses } = useDentalStatus();
const { listItems: listDentalServices, services: dentalServices } = useServices();

onMounted(() => {
  listStatuses();
  listDentalServices('dental');
});

const teethLocations = TEETH_LOCATIONS;
const surfacesCheckboxModel = ref([...SURFACES_CHECKBOX_MODEL]);
const icd10Codes = computed(() => ICD10_CODES);
const icdasDefaults = computed(() => ICDAS_DEFAULTS);
const castDefaults = computed(() => CAST_DEFAULTS);

const { submit: submitDentalNote } = useDentalNote();

const loading = ref(false);
const record = ref({});
const recordId = computed(() => record.value.id);
const isEditing = computed(() => !!recordId.value);

const shadeAllSurfaces = ref(false);

watch(shadeAllSurfaces, (value) => {
  surfacesCheckboxModel.value.forEach(surface => {
    surface.selected = value;
  });
});

watch(surfacesCheckboxModel, (value) => {
  const selectedSurfaces = value.filter(surface => surface.selected);
  model.value.surfaces = selectedSurfaces.map(surface => {
    const data = {
      name: surface.value,
    };
    if (surface.icdas) data.icdas = surface.icdas;
    if (surface.cast) data.cast = surface.cast;
    return data;
  });
}, { deep: true });

const icd10Model = ref(null);

watch(icd10Model, (value) => {
  if (!value) return;
  model.value.icd10 = `${value.code} - ${value.display}`;
  model.value.diagnosisCode = value.code;
  model.value.diagnosisText = value.display;
});

const teethModel = ref(null);

watch(teethModel, (value) => {
  if (!value) return;
  model.value.teeth = [value];
});

const model = ref({...FORM_MODEL});

watch(() => model.value.status, (val) => {
  if (val?.forAll) shadeAllSurfaces.value = true;
});

const formSubmittable = computed(() => {
  return model.value.teeth && model.value.status;
});

async function submit () {
  try {
    loading.value = true;

    // remove empty fields
    const data = Object.fromEntries(
      Object.entries(model.value).filter(([_, v]) => !isEmpty(v))
    );

    const payload = {
      subtype: props.subtype,
      ...data,
    };

    // Update
    if (isEditing.value) {
      payload.id = recordId.value;
    }

    console.warn('payload', payload);
    
    await submitDentalNote(payload);

    resetForm();
    emit('submit');
    emit('success');
  } catch (e) {
    console.error(e);
    emit('error', e);
  } finally {
    loading.value = false;
  }
};

function resetForm () {
  model.value = FORM_MODEL;
  record.value = {};
}

function setForm (data) {
  if (!data) return;

  record.value = data;
  teethModel.value = data?.teeth?.[0];
  model.value.status = data?.status;
  model.value.notes = data?.notes;
  model.value.service = data?.service;
  model.value.subtype = data?.subtype;
  
  const icd10Found = icd10Codes.value.find(icd10 => icd10.code === data.diagnosisCode);
  icd10Model.value = icd10Found;

  surfacesCheckboxModel.value = SURFACES_CHECKBOX_MODEL.map(surface => {
    const found = data?.surfaces?.find(s => s.name === surface.value);
    const newSurface = {
      ...surface,
      ...found,
    };

    if (found) {
      newSurface.selected = true;
    } else {
      newSurface.selected = false;
    }

    return newSurface;
  });
}

function formatMoney (number) {
  return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
}

</script>

<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-6">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text font-medium">Tooth <span class="text-error">*</span></span>
          </div>
          <select
            v-model="teethModel"
            class="select select-bordered w-full"
            required
          >
            <option disabled value="">Select Teeth</option>
            <option
              v-for="location in teethLocations"
              :key="location.value"
              :value="location.value"
            >
              {{ location.label }}
            </option>
          </select>
        </label>
      </div>
      <div class="col-span-6">
        <div class="label">
          <span class="label-text font-medium">Status <span class="text-error">*</span></span>
        </div>
        <select
          v-model="model.status"
          class="select select-bordered w-full"
          required
        >
          <option disabled value="">Select Status</option>
          <option
            v-for="status in customDentalStatuses"
            :key="status.id"
            :value="status"
          >
            {{ status.abbreviation }} - {{ status.category }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="formSubmittable" class="col-span-12">
      <div class="flex flex-col gap-2">
        <div class="form-control col-span-3">
          <label class="label cursor-pointer">
            <div class="flex items-center gap-2">
              <input 
                v-model="shadeAllSurfaces" 
                type="checkbox" 
                class="checkbox checkbox-neutral" 
                :value="shadeAllSurfaces"
              />
                <!-- :disabled="model.status?.forAll"  -->
              <span class="label-text">All Surfaces</span> 
            </div>
          </label>
        </div>
        <template v-for="(surface, index) in surfacesCheckboxModel">
          <div class="form-control col-span-3">
            <label class="label cursor-pointer">
              <div class="flex items-center gap-2">
                <input 
                  v-model="surface.selected" 
                  type="checkbox" 
                  class="checkbox checkbox-neutral" 
                  :checked="surface.selected" 
                />
                  <!-- :disabled="model.status?.forAll"  -->
                <span class="label-text">{{ surface.label }}</span> 
              </div>
            </label>
          </div>
          <div v-if="model?.status?.statusType === 'icdas' && surface.selected" class="flex gap-2">
            <label class="form-control w-full">
              <div class="label">
                <span class="label-text font-medium">ICDAS Caries Index for {{ surface.label }}</span>
              </div>
              <select
                v-model="surface.icdas"
                class="select select-bordered w-full"
              >
                <option disabled value="">Select Caries Index</option>
                <option
                  v-for="(icdas, index) in icdasDefaults"
                  :key="index"
                  :value="icdas.value"
                >
                  {{ icdas.label }}
                </option>
              </select>
            </label>
            <label class="form-control w-full">
              <div class="label">
                <span class="label-text font-medium">CAST Caries Index for {{ surface.label }}</span>
              </div>
              <select
                v-model="surface.cast"
                class="select select-bordered w-full"
              >
                <option disabled value="">Select Caries Index</option>
                <option
                  v-for="(cast, index) in castDefaults"
                  :key="index"
                  :value="cast.value"
                >
                  {{ cast.label }}
                </option>
              </select>
            </label>
          </div>
        </template>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-6">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text font-medium">Diagnosis Code</span>
          </div>
          <select
            v-model="icd10Model"
            class="select select-bordered w-full"
          >
            <option disabled value="">Select Diagnosis</option>
            <option
              v-for="(icd10, index) in icd10Codes"
              :key="index"
              :value="icd10"
            >
              {{ icd10.code }} {{ icd10.display }}
            </option>
          </select>
        </label>
      </div>

      <div v-if="subtype === 'order' || subtype === 'result'" class="col-span-6">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text font-medium">Procedure</span>
          </div>
          <select
            v-model="model.service"
            class="select select-bordered w-full"
          >
            <option disabled value="">Select Procedure</option>
            <option
              v-for="(service, index) in dentalServices"
              :key="index"
              :value="service.id"
            >
              {{ service.name }} - {{ formatMoney(service.price) }}
            </option>
          </select>
        </label>
      </div>
      
      <div class="col-span-12">
        <label class="form-control w-full">
          <div class="label">
            <span class="label-text font-medium">Remarks</span>
          </div>
          <textarea v-model="model.notes" class="textarea textarea-bordered" placeholder="Type here" />
        </label>
      </div>
    </div>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

