<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col col-span-1">
          <span class="text-sm font-medium">Follow-up date and time:</span>
        </div>
        <div class="flex flex-col col-span-1">
          <span class="text-sm text-neutral-500">{{followupAt}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Follow-up location:</span>
          <span class="text-sm text-neutral-500">{{followupLocation || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Follow-up reason:</span>
          <span class="text-sm text-neutral-500">{{followupReason || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Follow-up instructions:</span>
          <span class="text-sm text-neutral-500">{{followupInstructions || '-'}}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useDisposition } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeDisposition } = useDisposition();
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    const followupAt = computed(() => {
      if (props.record?.followupAt) {
        // const dateAndTime = new Date(props.record.followupAt).toISOString().split('T');
        // return [format(new Date(dateAndTime[0]), 'MM/dd/yyyy hh:mm a'), dateAndTime[1].slice(0, 5)].join(' ');
        return format(new Date(props.record.followupAt), 'MM/dd/yyyy hh:mm a');
      };
      return '-';
    });
    const followupLocation = computed(() => props.record.followupLocation);
    const followupReason = computed(() => props.record.followupReason);
    const followupInstructions = computed(() => props.record.followupInstructions);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeDisposition({ id: props.record.id });
    }

    return {
      createdAt,
      followupAt,
      followupLocation,
      followupReason,
      followupInstructions,
      edit,
      remove,
    };
  },
};
</script>
