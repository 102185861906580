export const RECORD_TYPE = 'vitals';
export const FORM_FIELDS = [
  {
    order: 0,
    group: 'age',
    field: 'referenceAge',
    label: 'Age',
    type: 'number',
    min: 0,
    placeholder: 'Type here',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 1,
    group: 'age',
    field: 'referenceAgeUnit',
    label: 'Unit',
    type: 'select',
    placeholder: 'E.g. weeks, months, years',
    rules: {
      type: 'string',
      required: false,
    },
    options: [
      'days',
      'weeks',
      'months',
      'years',
    ],
  },
  {
    order: 0,
    id: 'weight',
    field: 'weight',
    label: 'Weight (kg)',
    type: 'number',
    min: 0,
    placeholder: 'e.g. 70 kg',
    update: true,
    unit: 'kg',
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 1,
    id: 'height',
    field: 'height',
    label: 'Height (cm)',
    placeholder: 'e.g. 170 cm',
    type: 'number',
    min: 0,
    update: true,
    unit: 'cm',
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 2,
    id: 'bmi',
    type: 'text',
    field: 'bmi',
    label: 'Bmi',
    placeholder: 'e.g. 24.2',
    readonly: true,
    update: false,
  },
  // {
  //   order: 3,
  //   field: 'weightUnitDisplayed',
  //   label: 'Weight Unit Displayed',
  //   type: 'number',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   order: 4,
  //   field: 'heightUnitDisplayed',
  //   label: 'Height Unit Displayed',
  //   type: 'number',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  {
    order: 6,
    field: 'bpSystolic',
    label: 'BP Systolic',
    type: 'number',
    min: 0,
    placeholder: 'e.g. 120',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 7,
    field: 'bpDiastolic',
    label: 'BP Diastolic',
    type: 'number',
    min: 0,
    placeholder: 'e.g. 80',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 8,
    field: 'pulse',
    label: 'Pulse Rate (bpm)',
    type: 'number',
    min: 0,
    placeholder: 'e.g. 70 bpm',
    unit: 'bpm',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 9,
    field: 'respiration',
    label: 'Respiration Rate (rpm)',
    type: 'number',
    min: 0,
    placeholder: 'e.g. 14 rpm',
    unit: 'rpm',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 10,
    field: 'temperature',
    label: 'Temperature',
    type: 'number',
    min: 0,
    placeholder: 'e.g. 37°C',
    unit: '°C',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 11,
    field: 'temperatureMethod',
    label: 'Temperature Method',
    type: 'select',
    update: true,
    optionLabel: 'e.g. Oral',
    options: ['axillary', 'oral', 'ear', 'infrared', 'rectal', 'core'],
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    order: 12,
    field: 'headCircumference',
    label: 'Head Circ (cm)',
    type: 'number',
    min: 0,
    placeholder: 'e.g. 55 cm',
    unit: 'cm',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 13,
    field: 'waistCircumference',
    label: 'Waist Circ (cm)',
    placeholder: 'e.g. 82 cm',
    unit: 'cm',
    type: 'number',
    min: 0,
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 14,
    field: 'neckCircumference',
    label: 'Neck Circ (cm)',
    placeholder: 'e.g. 40 cm',
    unit: 'cm',
    type: 'number',
    min: 0,
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 15,
    field: 'abdominalCircumference',
    label: 'Abdominal Circ (cm)',
    placeholder: 'e.g. 85 cm',
    unit: 'cm',
    type: 'number',
    min: 0,
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 16,
    field: 'chestCircumference',
    label: 'Chest Circ (cm)',
    placeholder: 'e.g. 92 cm',
    unit: 'cm',
    type: 'number',
    min: 0,
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 17,
    field: 'chestInspiration',
    label: 'Chest Inspiration (mL)',
    placeholder: 'e.g. 500 mL',
    unit: 'mL',
    type: 'number',
    min: 0,
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 18,
    field: 'chestExpiration',
    label: 'Chest Expiration (mL)',
    placeholder: 'e.g. 500 mL',
    unit: 'mL',
    type: 'number',
    min: 0,
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    order: 19,
    field: 'o2sats',
    label: 'O2 Sats',
    type: 'number',
    min: 0,
    placeholder: 'e.g. 97%',
    unit: '%',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  // {
  //   order: 20,
  //   group: 'visual',
  //   field: 'visualAcuityDsntUncorrectL',
  //   label: 'Distant Uncorrected (L)',
  //   type: 'number',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   order: 21,
  //   group: 'visual',
  //   field: 'visualAcuityDsntUncorrectR',
  //   label: 'Distant Uncorrected (R)',
  //   type: 'number',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   order: 22,
  //   group: 'visual',
  //   field: 'visualAcuityDsntCorrectL',
  //   label: 'Distant Corrected (L)',
  //   type: 'number',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   order: 23,
  //   group: 'visual',
  //   field: 'visualAcuityDsntCorrectR',
  //   label: 'Distant Corrected (R)',
  //   type: 'number',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   order: 24,
  //   group: 'visual',
  //   field: 'visualAcuityNearUncorrectL',
  //   label: 'Near Uncorrected (L)',
  //   type: 'number',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   order: 25,
  //   group: 'visual',
  //   field: 'visualAcuityNearUncorrectR',
  //   label: 'Near Uncorrected (R)',
  //   type: 'number',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   order: 26,
  //   group: 'visual',
  //   field: 'visualAcuityNearCorrectL',
  //   label: 'Near Corrected (L)',
  //   type: 'number',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   order: 27,
  //   group: 'visual',
  //   field: 'visualAcuityNearCorrectR',
  //   label: 'Near Corrected (R)',
  //   type: 'number',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   order: 28,
  //   group: 'visual',
  //   field: 'visualAcuityVisionNormal',
  //   label: 'Color Vision (Normal)',
  //   type: 'text',
  //   update: true,
  //   rules: {
  //     type: 'string',
  //     required: false,
  //   },
  // },
  // {
  //   order: 29,
  //   group: 'visual',
  //   field: 'visualAcuityVisionAbnormal',
  //   label: 'Color Vision (Abnormal)',
  //   type: 'text',
  //   update: true,
  //   rules: {
  //     type: 'string',
  //     required: false,
  //   },
  // },
  {
    order: 30,
    group: 'visual',
    field: 'visualAcuityLeft',
    label: 'Visual Acuity (L)',
    type: 'text',
    placeholder: 'e.g. Normal',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    order: 31,
    group: 'visual',
    field: 'visualAcuityRight',
    label: 'Visual Acuity (R)',
    type: 'text',
    placeholder: 'e.g. Normal',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    order: 32,
    group: 'visual',
    field: 'visualRemarks',
    label: 'Visual Remarks',
    type: 'text',
    placeholder: 'e.g. Normal',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    order: 33,
    group: 'visual',
    field: 'colorVision',
    label: 'Color Vision',
    type: 'text',
    placeholder: 'e.g. Normal',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
];

export const UPDATE_FIELDS = FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field)
  .sort((a, b) => a.order - b.order);

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = '';
  return acc;
}, {});

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});

export const GROWTH_CHART_DATA_RAW = {
  weight: {
    age: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
      60,
      61,
      62,
      63,
    ],
    female: {
      point1: [
        2.033,
        2.72,
        3.428,
        3.971,
        4.407,
        4.764,
        5.061,
        5.315,
        5.538,
        5.736,
        5.917,
        6.085,
        6.247,
        6.403,
        6.557,
        6.708,
        6.858,
        7.007,
        7.154,
        7.301,
        7.445,
        7.589,
        7.732,
        7.874,
        8.016,
        8.158,
        8.298,
        8.437,
        8.572,
        8.705,
        8.835,
        8.961,
        9.083,
        9.204,
        9.322,
        9.438,
        9.552,
        9.665,
        9.776,
        9.886,
        9.995,
        10.101,
        10.207,
        10.31,
        10.413,
        10.514,
        10.615,
        10.714,
        10.813,
        10.911,
        11.01,
        11.107,
        11.205,
        11.303,
        11.4,
        11.497,
        11.593,
        11.689,
        11.784,
        11.879,
        11.972,
        12.065,
        12.145,
      ],
      point2: [
        2.395,
        3.148,
        3.922,
        4.513,
        4.988,
        5.378,
        5.703,
        5.982,
        6.227,
        6.446,
        6.646,
        6.833,
        7.012,
        7.185,
        7.355,
        7.523,
        7.689,
        7.854,
        8.018,
        8.18,
        8.341,
        8.501,
        8.661,
        8.821,
        8.98,
        9.139,
        9.298,
        9.454,
        9.608,
        9.759,
        9.907,
        10.051,
        10.192,
        10.331,
        10.468,
        10.603,
        10.736,
        10.868,
        10.999,
        11.129,
        11.257,
        11.384,
        11.51,
        11.634,
        11.757,
        11.879,
        12,
        12.12,
        12.24,
        12.359,
        12.477,
        12.596,
        12.714,
        12.832,
        12.95,
        13.067,
        13.184,
        13.3,
        13.416,
        13.53,
        13.644,
        13.757,
        13.854,
      ],
      point3: [
        3.232,
        4.172,
        5.105,
        5.818,
        6.394,
        6.867,
        7.265,
        7.609,
        7.915,
        8.191,
        8.444,
        8.682,
        8.909,
        9.129,
        9.344,
        9.555,
        9.764,
        9.971,
        10.178,
        10.383,
        10.587,
        10.791,
        10.995,
        11.2,
        11.406,
        11.611,
        11.817,
        12.022,
        12.224,
        12.424,
        12.62,
        12.814,
        13.005,
        13.193,
        13.381,
        13.567,
        13.753,
        13.938,
        14.124,
        14.309,
        14.494,
        14.678,
        14.861,
        15.043,
        15.225,
        15.406,
        15.586,
        15.765,
        15.944,
        16.123,
        16.302,
        16.481,
        16.659,
        16.837,
        17.015,
        17.193,
        17.37,
        17.546,
        17.721,
        17.895,
        18.069,
        18.241,
        18.389,
      ],
      point4: [
        4.23,
        5.456,
        6.601,
        7.48,
        8.196,
        8.79,
        9.295,
        9.738,
        10.137,
        10.501,
        10.838,
        11.154,
        11.457,
        11.749,
        12.032,
        12.31,
        12.585,
        12.856,
        13.127,
        13.396,
        13.664,
        13.933,
        14.203,
        14.475,
        14.749,
        15.024,
        15.3,
        15.576,
        15.852,
        16.124,
        16.395,
        16.664,
        16.93,
        17.196,
        17.462,
        17.728,
        17.996,
        18.266,
        18.538,
        18.812,
        19.087,
        19.364,
        19.641,
        19.919,
        20.197,
        20.476,
        20.755,
        21.034,
        21.313,
        21.592,
        21.872,
        22.154,
        22.434,
        22.715,
        22.997,
        23.278,
        23.559,
        23.839,
        24.119,
        24.397,
        24.951,
        25.19,
      ],
      point5: [
        4.793,
        6.212,
        7.488,
        8.473,
        9.28,
        9.954,
        10.531,
        11.042,
        11.506,
        11.932,
        12.327,
        12.7,
        13.056,
        13.399,
        13.731,
        14.056,
        14.376,
        14.693,
        15.007,
        15.32,
        15.633,
        15.946,
        16.262,
        16.58,
        16.901,
        17.224,
        17.548,
        17.873,
        18.199,
        18.522,
        18.844,
        19.165,
        19.485,
        19.805,
        20.126,
        20.449,
        20.776,
        21.107,
        21.441,
        21.779,
        22.12,
        22.464,
        22.81,
        23.159,
        23.509,
        23.86,
        24.213,
        24.567,
        24.921,
        25.277,
        25.634,
        25.993,
        26.352,
        26.712,
        27.072,
        27.433,
        27.794,
        28.156,
        28.516,
        28.875,
        29.235,
        29.593,
        29.903,
      ],
    },
    male: {
      point1: [
        2.08,
        2.919,
        3.764,
        4.41,
        4.915,
        5.318,
        5.646,
        5.92,
        6.156,
        6.365,
        6.555,
        6.731,
        6.899,
        7.059,
        7.213,
        7.362,
        7.508,
        7.651,
        7.79,
        7.928,
        8.062,
        8.196,
        8.328,
        8.458,
        8.587,
        8.714,
        8.84,
        8.963,
        9.083,
        9.201,
        9.316,
        9.428,
        9.538,
        9.646,
        9.753,
        9.858,
        9.962,
        10.066,
        10.169,
        10.271,
        10.373,
        10.475,
        10.577,
        10.678,
        10.779,
        10.878,
        10.977,
        11.075,
        11.172,
        11.268,
        11.364,
        11.459,
        11.554,
        11.647,
        11.741,
        11.834,
        11.926,
        12.018,
        12.11,
        12.201,
        12.291,
        12.381,
        12.459,
      ],
      point2: [
        2.459,
        3.376,
        4.296,
        4.992,
        5.533,
        5.968,
        6.325,
        6.625,
        6.885,
        7.116,
        7.327,
        7.524,
        7.71,
        7.889,
        8.061,
        8.229,
        8.393,
        8.554,
        8.712,
        8.867,
        9.021,
        9.173,
        9.323,
        9.473,
        9.621,
        9.767,
        9.912,
        10.054,
        10.193,
        10.33,
        10.463,
        10.594,
        10.722,
        10.848,
        10.972,
        11.094,
        11.216,
        11.336,
        11.456,
        11.576,
        11.695,
        11.814,
        11.932,
        12.051,
        12.168,
        12.285,
        12.402,
        12.518,
        12.633,
        12.747,
        12.861,
        12.974,
        13.086,
        13.199,
        13.311,
        13.422,
        13.533,
        13.644,
        13.754,
        13.864,
        13.973,
        14.082,
        14.175,
      ],
      point3: [
        3.346,
        4.452,
        5.541,
        6.346,
        6.97,
        7.477,
        7.9,
        8.263,
        8.58,
        8.866,
        9.128,
        9.374,
        9.608,
        9.833,
        10.051,
        10.265,
        10.474,
        10.681,
        10.885,
        11.087,
        11.288,
        11.488,
        11.687,
        11.885,
        12.083,
        12.279,
        12.474,
        12.665,
        12.854,
        13.04,
        13.222,
        13.4,
        13.576,
        13.749,
        13.919,
        14.088,
        14.255,
        14.422,
        14.588,
        14.753,
        14.918,
        15.083,
        15.248,
        15.412,
        15.577,
        15.742,
        15.906,
        16.07,
        16.234,
        16.398,
        16.562,
        16.725,
        16.889,
        17.053,
        17.216,
        17.38,
        17.543,
        17.707,
        17.87,
        18.032,
        18.195,
        18.357,
        18.497,
      ],
      point4: [
        4.419,
        5.776,
        7.06,
        7.989,
        8.709,
        9.303,
        9.808,
        10.249,
        10.641,
        10.997,
        11.327,
        11.638,
        11.935,
        12.222,
        12.503,
        12.777,
        13.049,
        13.318,
        13.586,
        13.852,
        14.117,
        14.383,
        14.65,
        14.916,
        15.183,
        15.45,
        15.715,
        15.978,
        16.238,
        16.493,
        16.745,
        16.993,
        17.238,
        17.479,
        17.718,
        17.955,
        18.19,
        18.424,
        18.658,
        18.891,
        19.124,
        19.357,
        19.592,
        19.826,
        20.062,
        20.299,
        20.536,
        20.774,
        21.013,
        21.253,
        21.494,
        21.736,
        21.979,
        22.223,
        22.468,
        22.713,
        22.959,
        23.206,
        23.453,
        23.701,
        23.948,
        24.196,
        24.411,
      ],
      point5: [
        5.031,
        6.542,
        7.935,
        8.934,
        9.707,
        10.351,
        10.904,
        11.392,
        11.829,
        12.228,
        12.6,
        12.95,
        13.287,
        13.612,
        13.932,
        14.245,
        14.555,
        14.864,
        15.171,
        15.478,
        15.785,
        16.093,
        16.402,
        16.713,
        17.024,
        17.337,
        17.647,
        17.956,
        18.262,
        18.564,
        18.861,
        19.155,
        19.445,
        19.732,
        20.016,
        20.298,
        20.578,
        20.858,
        21.137,
        21.416,
        21.694,
        21.974,
        22.256,
        22.538,
        22.822,
        23.108,
        23.394,
        23.683,
        23.974,
        24.267,
        24.561,
        24.857,
        25.155,
        25.455,
        25.758,
        26.061,
        26.365,
        26.671,
        26.978,
        27.285,
        27.593,
        27.902,
        28.171,
      ],
    },
  },
  height: {
    age: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
      60,
      61,
      62,
      63,
    ],
    female: {
      point1: [
        43.56,
        47.774,
        50.878,
        53.39,
        55.486,
        57.26,
        58.804,
        60.205,
        61.507,
        62.74,
        63.912,
        65.033,
        66.107,
        67.137,
        68.129,
        69.087,
        70.011,
        70.906,
        71.774,
        72.615,
        73.432,
        74.225,
        74.999,
        75.75,
        76.484,
        76.499,
        77.196,
        77.878,
        78.539,
        79.186,
        79.818,
        80.435,
        81.036,
        81.627,
        82.208,
        82.775,
        83.337,
        83.887,
        84.431,
        84.969,
        85.499,
        86.021,
        86.537,
        87.043,
        87.544,
        88.04,
        88.525,
        89.007,
        89.481,
        89.949,
        90.414,
        90.87,
        91.325,
        91.774,
        92.221,
        92.66,
        93.097,
        93.526,
        93.953,
        94.376,
        94.793,
        95.206,
        95.562,
      ],
      point2: [
        45.422,
        49.727,
        52.912,
        55.491,
        57.647,
        59.473,
        61.067,
        62.515,
        63.867,
        65.149,
        66.372,
        67.545,
        68.673,
        69.757,
        70.803,
        71.814,
        72.793,
        73.742,
        74.663,
        75.558,
        76.429,
        77.276,
        78.103,
        78.907,
        79.692,
        79.759,
        80.507,
        81.239,
        81.951,
        82.647,
        83.327,
        83.992,
        84.64,
        85.277,
        85.903,
        86.516,
        87.122,
        87.716,
        88.304,
        88.884,
        89.456,
        90.02,
        90.577,
        91.125,
        91.666,
        92.202,
        92.727,
        93.248,
        93.761,
        94.268,
        94.771,
        95.266,
        95.758,
        96.244,
        96.727,
        97.204,
        97.677,
        98.143,
        98.606,
        99.065,
        99.518,
        99.967,
        100.353,
      ],
      point3: [
        47.285,
        51.68,
        54.946,
        57.593,
        59.808,
        61.687,
        63.329,
        64.825,
        66.226,
        67.558,
        68.832,
        70.058,
        71.238,
        72.376,
        73.477,
        74.542,
        75.575,
        76.578,
        77.553,
        78.501,
        79.426,
        80.327,
        81.206,
        82.063,
        82.9,
        83.019,
        83.818,
        84.6,
        85.362,
        86.107,
        86.836,
        87.548,
        88.244,
        88.927,
        89.598,
        90.257,
        90.907,
        91.546,
        92.176,
        92.799,
        93.413,
        94.019,
        94.617,
        95.206,
        95.788,
        96.363,
        96.929,
        97.489,
        98.041,
        98.587,
        99.128,
        99.661,
        100.19,
        100.714,
        101.234,
        101.748,
        102.257,
        102.76,
        103.26,
        103.754,
        104.243,
        104.727,
        105.144,
      ],
      point4: [
        49.148,
        53.633,
        56.98,
        59.695,
        61.969,
        63.9,
        65.591,
        67.136,
        68.586,
        69.967,
        71.293,
        72.57,
        73.803,
        74.996,
        76.15,
        77.27,
        78.356,
        79.413,
        80.442,
        81.445,
        82.423,
        83.378,
        84.31,
        85.22,
        86.109,
        86.278,
        87.129,
        87.96,
        88.774,
        89.568,
        90.345,
        91.105,
        91.849,
        92.578,
        93.294,
        93.998,
        94.691,
        95.375,
        96.049,
        96.714,
        97.37,
        98.018,
        98.658,
        99.288,
        99.911,
        100.525,
        101.131,
        101.73,
        102.321,
        102.906,
        103.484,
        104.056,
        104.623,
        105.185,
        105.741,
        106.292,
        106.837,
        107.378,
        107.913,
        108.443,
        108.968,
        109.488,
        109.935,
      ],
      point5: [
        51.01,
        55.585,
        59.015,
        61.797,
        64.129,
        66.113,
        67.853,
        69.446,
        70.945,
        72.376,
        73.753,
        75.083,
        76.369,
        77.616,
        78.824,
        79.997,
        81.138,
        82.249,
        83.332,
        84.388,
        85.42,
        86.428,
        87.413,
        88.376,
        89.317,
        89.538,
        90.439,
        91.321,
        92.185,
        93.029,
        93.854,
        94.662,
        95.453,
        96.228,
        96.989,
        97.739,
        98.476,
        99.204,
        99.922,
        100.629,
        101.328,
        102.017,
        102.698,
        103.37,
        104.033,
        104.687,
        105.333,
        105.971,
        106.601,
        107.225,
        107.841,
        108.452,
        109.056,
        109.655,
        110.247,
        110.835,
        111.417,
        111.995,
        112.566,
        113.132,
        113.693,
        114.249,
        114.726,
      ],
      point6: [
        52.873,
        57.538,
        61.049,
        63.899,
        66.29,
        68.327,
        70.116,
        71.756,
        73.304,
        74.785,
        76.213,
        77.595,
        78.934,
        80.235,
        81.498,
        82.725,
        83.92,
        85.085,
        86.221,
        87.332,
        88.417,
        89.479,
        90.516,
        91.533,
        92.526,
        92.797,
        93.75,
        94.682,
        95.597,
        96.49,
        97.363,
        98.218,
        99.057,
        99.878,
        100.685,
        101.48,
        102.261,
        103.034,
        103.794,
        104.544,
        105.285,
        106.016,
        106.738,
        107.452,
        108.155,
        108.848,
        109.535,
        110.212,
        110.881,
        111.544,
        112.198,
        112.847,
        113.489,
        114.125,
        114.754,
        115.379,
        115.997,
        116.612,
        117.219,
        117.821,
        118.417,
        119.009,
        119.517,
      ],
      point7: [
        54.736,
        59.491,
        63.083,
        66.001,
        68.451,
        70.54,
        72.378,
        74.066,
        75.664,
        77.193,
        78.674,
        80.107,
        81.5,
        82.855,
        84.171,
        85.452,
        86.701,
        87.921,
        89.111,
        90.275,
        91.413,
        92.53,
        93.62,
        94.689,
        95.734,
        96.057,
        97.061,
        98.043,
        99.008,
        99.951,
        100.872,
        101.775,
        102.661,
        103.529,
        104.38,
        105.221,
        106.046,
        106.863,
        107.667,
        108.459,
        109.242,
        110.016,
        110.778,
        111.533,
        112.278,
        113.01,
        113.737,
        114.453,
        115.161,
        115.863,
        116.554,
        117.243,
        117.922,
        118.596,
        119.261,
        119.923,
        120.577,
        121.229,
        121.872,
        122.51,
        123.142,
        123.77,
        124.308,
      ],
    },
    male: {
      point1: [
        44.205,
        48.828,
        52.333,
        55.185,
        57.521,
        59.441,
        61.07,
        62.512,
        63.835,
        65.079,
        66.259,
        67.376,
        68.438,
        69.452,
        70.424,
        71.356,
        72.253,
        73.118,
        73.953,
        74.759,
        75.535,
        76.289,
        77.016,
        77.724,
        78.414,
        78.383,
        79.035,
        79.67,
        80.287,
        80.884,
        81.468,
        82.035,
        82.587,
        83.127,
        83.657,
        84.179,
        84.694,
        85.204,
        85.706,
        86.205,
        86.696,
        87.185,
        87.665,
        88.14,
        88.61,
        89.073,
        89.532,
        89.983,
        90.431,
        90.879,
        91.32,
        91.762,
        92.202,
        92.641,
        93.078,
        93.514,
        93.951,
        94.384,
        94.82,
        95.25,
        95.683,
        96.114,
        96.488,
      ],
      point2: [
        46.098,
        50.773,
        54.332,
        57.227,
        59.6,
        61.551,
        63.208,
        64.68,
        66.036,
        67.318,
        68.538,
        69.698,
        70.807,
        71.869,
        72.891,
        73.874,
        74.825,
        75.744,
        76.636,
        77.498,
        78.332,
        79.144,
        79.931,
        80.698,
        81.448,
        81.478,
        82.189,
        82.882,
        83.557,
        84.211,
        84.849,
        85.47,
        86.075,
        86.665,
        87.245,
        87.815,
        88.377,
        88.932,
        89.479,
        90.021,
        90.555,
        91.085,
        91.606,
        92.12,
        92.629,
        93.13,
        93.626,
        94.115,
        94.6,
        95.084,
        95.562,
        96.04,
        96.516,
        96.991,
        97.465,
        97.937,
        98.41,
        98.879,
        99.35,
        99.818,
        100.286,
        100.753,
        101.158,
      ],
      point3: [
        47.991,
        52.719,
        56.331,
        59.269,
        61.679,
        63.66,
        65.346,
        66.847,
        68.237,
        69.556,
        70.817,
        72.021,
        73.175,
        74.286,
        75.358,
        76.393,
        77.397,
        78.371,
        79.318,
        80.237,
        81.129,
        82,
        82.846,
        83.673,
        84.482,
        84.572,
        85.343,
        86.094,
        86.826,
        87.538,
        88.23,
        88.905,
        89.562,
        90.204,
        90.833,
        91.452,
        92.06,
        92.661,
        93.252,
        93.838,
        94.414,
        94.985,
        95.546,
        96.1,
        96.647,
        97.187,
        97.72,
        98.247,
        98.769,
        99.289,
        99.804,
        100.318,
        100.83,
        101.341,
        101.851,
        102.36,
        102.868,
        103.374,
        103.881,
        104.385,
        104.889,
        105.392,
        105.827,
      ],
      point4: [
        49.884,
        54.664,
        58.33,
        61.312,
        63.758,
        65.769,
        67.484,
        69.015,
        70.438,
        71.795,
        73.096,
        74.343,
        75.543,
        76.703,
        77.825,
        78.912,
        79.969,
        80.998,
        82,
        82.976,
        83.927,
        84.855,
        85.761,
        86.648,
        87.516,
        87.667,
        88.497,
        89.307,
        90.096,
        90.864,
        91.612,
        92.34,
        93.05,
        93.743,
        94.421,
        95.088,
        95.743,
        96.389,
        97.026,
        97.654,
        98.274,
        98.885,
        99.487,
        100.08,
        100.666,
        101.244,
        101.814,
        102.378,
        102.938,
        103.494,
        104.046,
        104.596,
        105.144,
        105.692,
        106.238,
        106.782,
        107.326,
        107.869,
        108.411,
        108.952,
        109.492,
        110.031,
        110.497,
      ],
      point5: [
        51.777,
        56.61,
        60.329,
        63.354,
        65.837,
        67.878,
        69.621,
        71.183,
        72.639,
        74.033,
        75.375,
        76.666,
        77.911,
        79.12,
        80.292,
        81.431,
        82.541,
        83.625,
        84.682,
        85.715,
        86.724,
        87.71,
        88.676,
        89.622,
        90.55,
        90.761,
        91.651,
        92.519,
        93.365,
        94.191,
        94.993,
        95.775,
        96.537,
        97.282,
        98.009,
        98.724,
        99.427,
        100.117,
        100.799,
        101.47,
        102.133,
        102.785,
        103.428,
        104.061,
        104.684,
        105.3,
        105.908,
        106.51,
        107.107,
        107.699,
        108.288,
        108.874,
        109.459,
        110.042,
        110.624,
        111.205,
        111.785,
        112.364,
        112.942,
        113.519,
        114.095,
        114.67,
        115.166,
      ],
      point6: [
        53.67,
        58.556,
        62.328,
        65.396,
        67.916,
        69.987,
        71.759,
        73.351,
        74.841,
        76.272,
        77.654,
        78.988,
        80.28,
        81.537,
        82.759,
        83.95,
        85.112,
        86.251,
        87.364,
        88.454,
        89.521,
        90.566,
        91.591,
        92.597,
        93.585,
        93.856,
        94.805,
        95.731,
        96.635,
        97.517,
        98.375,
        99.21,
        100.025,
        100.82,
        101.597,
        102.36,
        103.11,
        103.846,
        104.572,
        105.287,
        105.992,
        106.685,
        107.368,
        108.041,
        108.703,
        109.357,
        110.002,
        110.642,
        111.276,
        111.903,
        112.53,
        113.152,
        113.773,
        114.392,
        115.011,
        115.628,
        116.243,
        116.859,
        117.472,
        118.087,
        118.698,
        119.309,
        119.836,
      ],
      point7: [
        55.564,
        60.501,
        64.327,
        67.438,
        69.995,
        72.097,
        73.897,
        75.519,
        77.042,
        78.51,
        79.933,
        81.311,
        82.648,
        83.954,
        85.226,
        86.469,
        87.684,
        88.878,
        90.047,
        91.193,
        92.319,
        93.421,
        94.506,
        95.572,
        96.619,
        96.951,
        97.959,
        98.944,
        99.904,
        100.844,
        101.756,
        102.645,
        103.512,
        104.359,
        105.185,
        105.996,
        106.793,
        107.574,
        108.346,
        109.103,
        109.851,
        110.585,
        111.309,
        112.021,
        112.722,
        113.414,
        114.096,
        114.774,
        115.445,
        116.108,
        116.772,
        117.43,
        118.087,
        118.743,
        119.397,
        120.051,
        120.701,
        121.354,
        122.003,
        122.654,
        123.301,
        123.948,
        124.506,
      ],
    },
  },
  head: {
    age: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
      60,
      61,
      62,
      63,
    ],
    female: {
      point1: [
        30.326,
        32.999,
        34.578,
        35.763,
        36.733,
        37.547,
        38.235,
        38.823,
        39.327,
        39.763,
        40.142,
        40.476,
        40.771,
        41.036,
        41.275,
        41.493,
        41.693,
        41.879,
        42.054,
        42.219,
        42.376,
        42.527,
        42.673,
        42.812,
        42.949,
        43.081,
        43.209,
        43.332,
        43.45,
        43.563,
        43.67,
        43.774,
        43.873,
        43.967,
        44.059,
        44.146,
        44.229,
        44.309,
        44.387,
        44.461,
        44.534,
        44.604,
        44.671,
        44.737,
        44.8,
        44.862,
        44.92,
        44.979,
        45.034,
        45.089,
        45.142,
        45.195,
        45.245,
        45.296,
        45.344,
        45.392,
        45.439,
        45.485,
        45.53,
        45.574,
        45.617,
        45.661,
        45.696,
      ],
      point2: [
        31.51,
        34.171,
        35.789,
        37.003,
        37.998,
        38.832,
        39.537,
        40.139,
        40.654,
        41.1,
        41.487,
        41.828,
        42.129,
        42.399,
        42.643,
        42.865,
        43.068,
        43.257,
        43.435,
        43.603,
        43.762,
        43.915,
        44.063,
        44.205,
        44.344,
        44.478,
        44.608,
        44.732,
        44.852,
        44.966,
        45.075,
        45.18,
        45.281,
        45.377,
        45.469,
        45.557,
        45.641,
        45.723,
        45.801,
        45.876,
        45.949,
        46.02,
        46.088,
        46.154,
        46.218,
        46.28,
        46.339,
        46.398,
        46.454,
        46.509,
        46.562,
        46.615,
        46.666,
        46.717,
        46.766,
        46.813,
        46.861,
        46.907,
        46.952,
        46.996,
        47.04,
        47.083,
        47.119,
      ],
      point3: [
        32.694,
        35.344,
        37,
        38.243,
        39.262,
        40.116,
        40.839,
        41.454,
        41.982,
        42.437,
        42.832,
        43.18,
        43.487,
        43.762,
        44.01,
        44.236,
        44.443,
        44.635,
        44.815,
        44.986,
        45.148,
        45.303,
        45.453,
        45.598,
        45.739,
        45.874,
        46.006,
        46.132,
        46.254,
        46.37,
        46.481,
        46.587,
        46.689,
        46.786,
        46.879,
        46.968,
        47.054,
        47.136,
        47.215,
        47.291,
        47.365,
        47.436,
        47.505,
        47.571,
        47.636,
        47.698,
        47.758,
        47.817,
        47.873,
        47.929,
        47.983,
        48.036,
        48.087,
        48.138,
        48.187,
        48.235,
        48.282,
        48.329,
        48.374,
        48.418,
        48.462,
        48.506,
        48.542,
      ],
      point4: [
        33.879,
        36.516,
        38.211,
        39.483,
        40.526,
        41.401,
        42.14,
        42.77,
        43.309,
        43.774,
        44.177,
        44.532,
        44.845,
        45.125,
        45.378,
        45.608,
        45.818,
        46.013,
        46.196,
        46.369,
        46.533,
        46.691,
        46.844,
        46.991,
        47.133,
        47.271,
        47.404,
        47.533,
        47.656,
        47.774,
        47.886,
        47.994,
        48.097,
        48.195,
        48.289,
        48.379,
        48.466,
        48.549,
        48.629,
        48.706,
        48.781,
        48.852,
        48.922,
        48.989,
        49.053,
        49.116,
        49.177,
        49.236,
        49.293,
        49.349,
        49.403,
        49.456,
        49.508,
        49.558,
        49.608,
        49.657,
        49.704,
        49.751,
        49.796,
        49.841,
        49.885,
        49.928,
        49.965,
      ],
      point5: [
        35.063,
        37.689,
        39.421,
        40.723,
        41.791,
        42.686,
        43.442,
        44.086,
        44.637,
        45.111,
        45.523,
        45.884,
        46.203,
        46.489,
        46.746,
        46.979,
        47.193,
        47.392,
        47.577,
        47.752,
        47.919,
        48.079,
        48.234,
        48.384,
        48.528,
        48.668,
        48.803,
        48.933,
        49.058,
        49.177,
        49.292,
        49.401,
        49.505,
        49.604,
        49.699,
        49.791,
        49.878,
        49.962,
        50.043,
        50.121,
        50.196,
        50.269,
        50.338,
        50.406,
        50.471,
        50.534,
        50.595,
        50.655,
        50.713,
        50.768,
        50.823,
        50.876,
        50.929,
        50.979,
        51.029,
        51.078,
        51.126,
        51.172,
        51.218,
        51.263,
        51.308,
        51.351,
        51.388,
      ],
      point6: [
        36.247,
        38.861,
        40.632,
        41.963,
        43.055,
        43.97,
        44.744,
        45.401,
        45.964,
        46.448,
        46.868,
        47.236,
        47.561,
        47.852,
        48.113,
        48.35,
        48.568,
        48.77,
        48.958,
        49.135,
        49.305,
        49.468,
        49.624,
        49.776,
        49.923,
        50.065,
        50.201,
        50.333,
        50.46,
        50.581,
        50.697,
        50.807,
        50.912,
        51.014,
        51.109,
        51.202,
        51.29,
        51.376,
        51.457,
        51.536,
        51.612,
        51.685,
        51.755,
        51.823,
        51.889,
        51.952,
        52.014,
        52.074,
        52.132,
        52.188,
        52.244,
        52.297,
        52.35,
        52.4,
        52.451,
        52.5,
        52.547,
        52.594,
        52.641,
        52.686,
        52.73,
        52.773,
        52.811,
      ],
      point7: [
        37.432,
        40.034,
        41.843,
        43.203,
        44.32,
        45.255,
        46.045,
        46.717,
        47.292,
        47.784,
        48.213,
        48.588,
        48.919,
        49.215,
        49.481,
        49.722,
        49.943,
        50.148,
        50.339,
        50.518,
        50.691,
        50.856,
        51.014,
        51.169,
        51.317,
        51.462,
        51.6,
        51.734,
        51.862,
        51.984,
        52.103,
        52.214,
        52.32,
        52.423,
        52.519,
        52.613,
        52.703,
        52.789,
        52.872,
        52.951,
        53.027,
        53.101,
        53.172,
        53.24,
        53.306,
        53.37,
        53.433,
        53.493,
        53.552,
        53.608,
        53.664,
        53.717,
        53.771,
        53.821,
        53.872,
        53.922,
        53.969,
        54.016,
        54.063,
        54.108,
        54.153,
        54.196,
        54.234,
      ],
    },
    male: {
      point1: [
        30.651,
        33.741,
        35.566,
        36.916,
        37.992,
        38.877,
        39.611,
        40.224,
        40.74,
        41.178,
        41.554,
        41.881,
        42.167,
        42.418,
        42.642,
        42.843,
        43.027,
        43.197,
        43.353,
        43.5,
        43.638,
        43.769,
        43.895,
        44.014,
        44.129,
        44.238,
        44.343,
        44.444,
        44.54,
        44.631,
        44.719,
        44.803,
        44.883,
        44.959,
        45.031,
        45.101,
        45.167,
        45.231,
        45.293,
        45.351,
        45.407,
        45.463,
        45.514,
        45.566,
        45.615,
        45.663,
        45.708,
        45.753,
        45.796,
        45.837,
        45.877,
        45.916,
        45.954,
        45.991,
        46.028,
        46.063,
        46.096,
        46.131,
        46.163,
        46.195,
        46.227,
        46.258,
        46.28,
      ],
      point2: [
        31.921,
        34.908,
        36.738,
        38.097,
        39.186,
        40.084,
        40.83,
        41.456,
        41.984,
        42.433,
        42.82,
        43.155,
        43.45,
        43.709,
        43.94,
        44.149,
        44.339,
        44.515,
        44.678,
        44.83,
        44.975,
        45.111,
        45.243,
        45.368,
        45.488,
        45.603,
        45.714,
        45.82,
        45.921,
        46.017,
        46.11,
        46.198,
        46.283,
        46.364,
        46.441,
        46.515,
        46.585,
        46.653,
        46.719,
        46.781,
        46.841,
        46.9,
        46.955,
        47.01,
        47.063,
        47.114,
        47.162,
        47.21,
        47.256,
        47.3,
        47.344,
        47.385,
        47.426,
        47.465,
        47.505,
        47.542,
        47.579,
        47.615,
        47.65,
        47.685,
        47.719,
        47.753,
        47.781,
      ],
      point3: [
        33.192,
        36.076,
        37.911,
        39.279,
        40.38,
        41.29,
        42.05,
        42.688,
        43.227,
        43.688,
        44.085,
        44.43,
        44.733,
        45,
        45.239,
        45.454,
        45.651,
        45.833,
        46.002,
        46.161,
        46.311,
        46.454,
        46.591,
        46.722,
        46.847,
        46.968,
        47.084,
        47.195,
        47.302,
        47.403,
        47.501,
        47.594,
        47.683,
        47.769,
        47.85,
        47.928,
        48.003,
        48.075,
        48.144,
        48.211,
        48.275,
        48.337,
        48.396,
        48.454,
        48.51,
        48.564,
        48.616,
        48.667,
        48.716,
        48.763,
        48.81,
        48.854,
        48.898,
        48.94,
        48.982,
        49.022,
        49.061,
        49.1,
        49.138,
        49.175,
        49.212,
        49.247,
        49.278,
      ],
      point4: [
        34.462,
        37.244,
        39.083,
        40.46,
        41.573,
        42.496,
        43.269,
        43.92,
        44.471,
        44.943,
        45.35,
        45.705,
        46.016,
        46.291,
        46.537,
        46.76,
        46.964,
        47.152,
        47.327,
        47.492,
        47.648,
        47.796,
        47.939,
        48.076,
        48.207,
        48.333,
        48.454,
        48.571,
        48.682,
        48.789,
        48.892,
        48.99,
        49.084,
        49.174,
        49.259,
        49.342,
        49.421,
        49.497,
        49.57,
        49.64,
        49.708,
        49.774,
        49.837,
        49.898,
        49.957,
        50.015,
        50.07,
        50.124,
        50.176,
        50.226,
        50.276,
        50.323,
        50.37,
        50.415,
        50.459,
        50.502,
        50.544,
        50.585,
        50.625,
        50.665,
        50.704,
        50.742,
        50.775,
      ],
      point5: [
        35.732,
        38.411,
        40.256,
        41.642,
        42.767,
        43.703,
        44.488,
        45.152,
        45.715,
        46.198,
        46.616,
        46.979,
        47.298,
        47.582,
        47.835,
        48.066,
        48.276,
        48.47,
        48.652,
        48.822,
        48.984,
        49.139,
        49.287,
        49.429,
        49.566,
        49.698,
        49.824,
        49.946,
        50.063,
        50.175,
        50.283,
        50.386,
        50.484,
        50.578,
        50.669,
        50.756,
        50.839,
        50.919,
        50.996,
        51.07,
        51.142,
        51.21,
        51.278,
        51.342,
        51.404,
        51.465,
        51.524,
        51.581,
        51.636,
        51.69,
        51.742,
        51.792,
        51.841,
        51.889,
        51.936,
        51.981,
        52.026,
        52.069,
        52.112,
        52.155,
        52.196,
        52.237,
        52.272,
      ],
      point6: [
        37.002,
        39.579,
        41.428,
        42.823,
        43.96,
        44.909,
        45.708,
        46.384,
        46.959,
        47.453,
        47.881,
        48.254,
        48.581,
        48.873,
        49.134,
        49.371,
        49.588,
        49.788,
        49.976,
        50.153,
        50.321,
        50.482,
        50.635,
        50.783,
        50.926,
        51.063,
        51.195,
        51.322,
        51.443,
        51.561,
        51.674,
        51.781,
        51.884,
        51.983,
        52.078,
        52.169,
        52.257,
        52.341,
        52.421,
        52.5,
        52.575,
        52.647,
        52.718,
        52.786,
        52.852,
        52.915,
        52.978,
        53.038,
        53.096,
        53.153,
        53.208,
        53.261,
        53.313,
        53.364,
        53.413,
        53.461,
        53.508,
        53.554,
        53.6,
        53.645,
        53.688,
        53.732,
        53.769,
      ],
      point7: [
        38.273,
        40.746,
        42.601,
        44.004,
        45.154,
        46.116,
        46.927,
        47.616,
        48.203,
        48.707,
        49.146,
        49.529,
        49.864,
        50.164,
        50.432,
        50.677,
        50.9,
        51.107,
        51.301,
        51.484,
        51.657,
        51.824,
        51.983,
        52.137,
        52.285,
        52.428,
        52.565,
        52.697,
        52.824,
        52.948,
        53.065,
        53.177,
        53.285,
        53.388,
        53.487,
        53.583,
        53.675,
        53.763,
        53.847,
        53.929,
        54.009,
        54.084,
        54.159,
        54.23,
        54.299,
        54.366,
        54.432,
        54.495,
        54.556,
        54.616,
        54.674,
        54.73,
        54.785,
        54.839,
        54.889,
        54.941,
        54.991,
        55.039,
        55.087,
        55.135,
        55.18,
        55.227,
        55.266,
      ],
    },
  },
  bmi: {
    age: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
      60,
      61,
      62,
      63,
    ],
    female: {
      point1: [
        10.122,
        10.75,
        11.798,
        12.344,
        12.658,
        12.848,
        12.954,
        12.995,
        12.987,
        12.945,
        12.881,
        12.806,
        12.728,
        12.651,
        12.577,
        12.509,
        12.445,
        12.387,
        12.335,
        12.288,
        12.247,
        12.212,
        12.183,
        12.16,
        12.142,
        12.364,
        12.343,
        12.323,
        12.303,
        12.283,
        12.264,
        12.244,
        12.224,
        12.203,
        12.181,
        12.159,
        12.136,
        12.112,
        12.088,
        12.063,
        12.037,
        12.011,
        11.985,
        11.959,
        11.932,
        11.906,
        11.881,
        11.857,
        11.834,
        11.812,
        11.791,
        11.771,
        11.752,
        11.735,
        11.719,
        11.704,
        11.69,
        11.678,
        11.667,
        11.657,
        11.649,
        11.641,
        11.635,
      ],
      point2: [
        11.091,
        11.932,
        13.011,
        13.561,
        13.869,
        14.049,
        14.141,
        14.166,
        14.14,
        14.08,
        13.997,
        13.904,
        13.809,
        13.717,
        13.628,
        13.546,
        13.47,
        13.4,
        13.338,
        13.281,
        13.232,
        13.189,
        13.152,
        13.123,
        13.099,
        13.334,
        13.31,
        13.288,
        13.266,
        13.245,
        13.224,
        13.203,
        13.182,
        13.16,
        13.138,
        13.116,
        13.094,
        13.071,
        13.049,
        13.026,
        13.003,
        12.981,
        12.958,
        12.935,
        12.912,
        12.891,
        12.869,
        12.849,
        12.83,
        12.812,
        12.795,
        12.779,
        12.765,
        12.751,
        12.739,
        12.728,
        12.719,
        12.71,
        12.703,
        12.697,
        12.691,
        12.686,
        12.683,
      ],
      point3: [
        12.159,
        13.195,
        14.324,
        14.89,
        15.198,
        15.371,
        15.453,
        15.464,
        15.422,
        15.344,
        15.243,
        15.132,
        15.02,
        14.911,
        14.807,
        14.711,
        14.622,
        14.541,
        14.468,
        14.401,
        14.343,
        14.292,
        14.248,
        14.212,
        14.182,
        14.428,
        14.402,
        14.378,
        14.354,
        14.331,
        14.308,
        14.286,
        14.263,
        14.241,
        14.219,
        14.197,
        14.176,
        14.156,
        14.136,
        14.117,
        14.098,
        14.079,
        14.061,
        14.043,
        14.026,
        14.009,
        13.993,
        13.978,
        13.965,
        13.952,
        13.94,
        13.93,
        13.921,
        13.913,
        13.906,
        13.901,
        13.897,
        13.893,
        13.89,
        13.889,
        13.887,
        13.887,
        13.887,
      ],
      point4: [
        13.336,
        14.542,
        15.744,
        16.339,
        16.657,
        16.83,
        16.906,
        16.906,
        16.85,
        16.755,
        16.637,
        16.508,
        16.379,
        16.254,
        16.136,
        16.026,
        15.924,
        15.831,
        15.747,
        15.671,
        15.604,
        15.544,
        15.493,
        15.451,
        15.415,
        15.67,
        15.641,
        15.614,
        15.588,
        15.563,
        15.538,
        15.514,
        15.491,
        15.468,
        15.446,
        15.425,
        15.406,
        15.388,
        15.372,
        15.357,
        15.343,
        15.331,
        15.318,
        15.307,
        15.297,
        15.287,
        15.278,
        15.271,
        15.264,
        15.259,
        15.254,
        15.252,
        15.25,
        15.25,
        15.25,
        15.252,
        15.255,
        15.258,
        15.262,
        15.266,
        15.27,
        15.275,
        15.28,
      ],
      point5: [
        14.636,
        15.976,
        17.278,
        17.919,
        18.258,
        18.44,
        18.516,
        18.51,
        18.443,
        18.335,
        18.201,
        18.057,
        17.912,
        17.772,
        17.639,
        17.516,
        17.402,
        17.298,
        17.204,
        17.119,
        17.043,
        16.976,
        16.918,
        16.869,
        16.829,
        17.087,
        17.056,
        17.026,
        16.997,
        16.969,
        16.942,
        16.917,
        16.892,
        16.869,
        16.848,
        16.829,
        16.812,
        16.798,
        16.787,
        16.778,
        16.771,
        16.766,
        16.762,
        16.76,
        16.759,
        16.758,
        16.759,
        16.761,
        16.764,
        16.768,
        16.774,
        16.781,
        16.79,
        16.799,
        16.81,
        16.822,
        16.834,
        16.846,
        16.858,
        16.87,
        16.883,
        16.895,
        16.905,
      ],
      point6: [
        16.071,
        17.499,
        18.933,
        19.64,
        20.015,
        20.217,
        20.303,
        20.298,
        20.226,
        20.109,
        19.963,
        19.805,
        19.646,
        19.493,
        19.349,
        19.214,
        19.089,
        18.975,
        18.871,
        18.779,
        18.695,
        18.622,
        18.559,
        18.505,
        18.462,
        18.718,
        18.682,
        18.649,
        18.617,
        18.586,
        18.557,
        18.529,
        18.503,
        18.48,
        18.46,
        18.443,
        18.43,
        18.421,
        18.417,
        18.416,
        18.418,
        18.423,
        18.431,
        18.44,
        18.452,
        18.465,
        18.478,
        18.493,
        18.509,
        18.526,
        18.545,
        18.566,
        18.588,
        18.611,
        18.635,
        18.66,
        18.685,
        18.709,
        18.733,
        18.756,
        18.779,
        18.801,
        18.82,
      ],
      point7: [
        17.657,
        19.114,
        20.716,
        21.514,
        21.945,
        22.182,
        22.289,
        22.295,
        22.226,
        22.105,
        21.953,
        21.787,
        21.618,
        21.455,
        21.302,
        21.158,
        21.025,
        20.904,
        20.794,
        20.695,
        20.607,
        20.529,
        20.463,
        20.407,
        20.362,
        20.605,
        20.566,
        20.528,
        20.493,
        20.458,
        20.426,
        20.396,
        20.369,
        20.346,
        20.327,
        20.314,
        20.306,
        20.305,
        20.309,
        20.319,
        20.335,
        20.353,
        20.376,
        20.401,
        20.43,
        20.46,
        20.491,
        20.523,
        20.556,
        20.591,
        20.628,
        20.667,
        20.708,
        20.749,
        20.791,
        20.834,
        20.876,
        20.917,
        20.957,
        20.995,
        21.032,
        21.067,
        21.097,
      ],
    },
    male: {
      point1: [
        10.184,
        11.253,
        12.507,
        13.078,
        13.363,
        13.529,
        13.618,
        13.65,
        13.636,
        13.59,
        13.525,
        13.451,
        13.373,
        13.293,
        13.216,
        13.141,
        13.07,
        13.003,
        12.94,
        12.883,
        12.83,
        12.784,
        12.743,
        12.706,
        12.674,
        12.852,
        12.808,
        12.765,
        12.723,
        12.682,
        12.641,
        12.601,
        12.562,
        12.524,
        12.487,
        12.452,
        12.417,
        12.383,
        12.352,
        12.321,
        12.292,
        12.265,
        12.239,
        12.215,
        12.192,
        12.171,
        12.151,
        12.131,
        12.113,
        12.096,
        12.079,
        12.063,
        12.049,
        12.035,
        12.021,
        12.009,
        11.997,
        11.986,
        11.976,
        11.966,
        11.957,
        11.949,
        11.943,
      ],
      point2: [
        11.133,
        12.391,
        13.672,
        14.241,
        14.514,
        14.667,
        14.743,
        14.76,
        14.73,
        14.667,
        14.585,
        14.494,
        14.401,
        14.308,
        14.216,
        14.129,
        14.046,
        13.968,
        13.895,
        13.828,
        13.767,
        13.712,
        13.663,
        13.62,
        13.582,
        13.781,
        13.741,
        13.701,
        13.662,
        13.624,
        13.587,
        13.55,
        13.514,
        13.479,
        13.444,
        13.411,
        13.378,
        13.346,
        13.316,
        13.286,
        13.258,
        13.231,
        13.206,
        13.182,
        13.159,
        13.138,
        13.117,
        13.097,
        13.078,
        13.06,
        13.042,
        13.025,
        13.008,
        12.993,
        12.977,
        12.963,
        12.949,
        12.936,
        12.923,
        12.91,
        12.899,
        12.888,
        12.88,
      ],
      point3: [
        12.201,
        13.61,
        14.932,
        15.506,
        15.772,
        15.916,
        15.979,
        15.982,
        15.937,
        15.858,
        15.76,
        15.653,
        15.544,
        15.436,
        15.331,
        15.231,
        15.136,
        15.046,
        14.963,
        14.886,
        14.815,
        14.752,
        14.696,
        14.646,
        14.601,
        14.822,
        14.784,
        14.746,
        14.71,
        14.673,
        14.638,
        14.603,
        14.569,
        14.536,
        14.503,
        14.471,
        14.439,
        14.409,
        14.38,
        14.351,
        14.324,
        14.298,
        14.274,
        14.251,
        14.229,
        14.208,
        14.188,
        14.168,
        14.15,
        14.132,
        14.114,
        14.098,
        14.081,
        14.066,
        14.051,
        14.036,
        14.022,
        14.008,
        13.995,
        13.983,
        13.971,
        13.959,
        13.95,
      ],
      point4: [
        13.407,
        14.914,
        16.294,
        16.883,
        17.147,
        17.285,
        17.341,
        17.333,
        17.274,
        17.18,
        17.066,
        16.944,
        16.82,
        16.697,
        16.578,
        16.465,
        16.358,
        16.257,
        16.163,
        16.076,
        15.996,
        15.924,
        15.86,
        15.803,
        15.752,
        15.994,
        15.956,
        15.918,
        15.882,
        15.846,
        15.81,
        15.776,
        15.742,
        15.709,
        15.677,
        15.645,
        15.614,
        15.585,
        15.556,
        15.529,
        15.503,
        15.478,
        15.455,
        15.434,
        15.414,
        15.395,
        15.377,
        15.36,
        15.344,
        15.328,
        15.313,
        15.299,
        15.286,
        15.273,
        15.261,
        15.249,
        15.238,
        15.227,
        15.217,
        15.208,
        15.199,
        15.191,
        15.184,
      ],
      point5: [
        14.773,
        16.306,
        17.765,
        18.38,
        18.651,
        18.79,
        18.842,
        18.826,
        18.757,
        18.651,
        18.523,
        18.386,
        18.247,
        18.111,
        17.979,
        17.853,
        17.734,
        17.622,
        17.518,
        17.421,
        17.333,
        17.253,
        17.181,
        17.117,
        17.06,
        17.322,
        17.28,
        17.239,
        17.199,
        17.16,
        17.123,
        17.086,
        17.051,
        17.016,
        16.982,
        16.95,
        16.919,
        16.889,
        16.861,
        16.834,
        16.81,
        16.787,
        16.766,
        16.747,
        16.73,
        16.715,
        16.701,
        16.689,
        16.677,
        16.667,
        16.658,
        16.65,
        16.643,
        16.637,
        16.631,
        16.627,
        16.624,
        16.621,
        16.619,
        16.618,
        16.617,
        16.618,
        16.619,
      ],
      point6: [
        16.326,
        17.791,
        19.351,
        20.008,
        20.297,
        20.445,
        20.5,
        20.482,
        20.407,
        20.291,
        20.151,
        20.002,
        19.851,
        19.703,
        19.56,
        19.422,
        19.293,
        19.17,
        19.057,
        18.951,
        18.854,
        18.767,
        18.689,
        18.619,
        18.556,
        18.835,
        18.784,
        18.735,
        18.687,
        18.642,
        18.598,
        18.555,
        18.515,
        18.476,
        18.439,
        18.404,
        18.371,
        18.34,
        18.311,
        18.285,
        18.262,
        18.242,
        18.224,
        18.209,
        18.197,
        18.188,
        18.181,
        18.176,
        18.173,
        18.172,
        18.172,
        18.175,
        18.179,
        18.185,
        18.193,
        18.202,
        18.213,
        18.225,
        18.238,
        18.253,
        18.269,
        18.288,
        18.304,
      ],
      point7: [
        18.1,
        19.371,
        21.062,
        21.78,
        22.099,
        22.268,
        22.334,
        22.322,
        22.246,
        22.125,
        21.979,
        21.821,
        21.661,
        21.504,
        21.351,
        21.205,
        21.066,
        20.936,
        20.815,
        20.702,
        20.599,
        20.506,
        20.422,
        20.348,
        20.281,
        20.571,
        20.503,
        20.439,
        20.377,
        20.319,
        20.263,
        20.21,
        20.16,
        20.113,
        20.069,
        20.028,
        19.991,
        19.957,
        19.927,
        19.901,
        19.879,
        19.862,
        19.85,
        19.841,
        19.836,
        19.836,
        19.839,
        19.845,
        19.855,
        19.868,
        19.885,
        19.904,
        19.927,
        19.952,
        19.982,
        20.013,
        20.048,
        20.085,
        20.125,
        20.168,
        20.214,
        20.263,
        20.307,
      ],
    },
  },
};

export const PAIN_MIN = 0;
export const PAIN_WARNING_THRESHOLD = 4;
export const PAIN_ERROR_THRESHOLD = 7;
export const PAIN_MAX = 10;

export const PAIN_TEXTS = ['Pain Free', 'Very Mild', 'Discomforting', 'Tolerable', 'Distressing', 'Very Distressing', 'Intense', 'Very Intense', 'Utterly Horrible', 'Excruciatingly Unbearable', 'Unimaginable Unspeakable'];

// {
//   age: number,
//   [weight|height|head|bmi]: {
//     [sex]: object.<pointkey, number>
//   }
// }
export const GROWTH_CHART_DATA = GROWTH_CHART_DATA_RAW.weight.age.map((age, ageInd) => {
  const obj = { age };
  for (const type of Object.keys(GROWTH_CHART_DATA_RAW)) {
    obj[type] = {};
    for (const sex of ['male', 'female']) {
      obj[type][sex] = {};
      for (const pkey of Object.keys(GROWTH_CHART_DATA_RAW[type][sex])) {
        const val = GROWTH_CHART_DATA_RAW[type][sex][pkey][ageInd];
        obj[type][sex][pkey] = val;
      }
    }
  }
  return obj;
});
