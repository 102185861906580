<template>
  <!-- height, weight, head circ, bmi -->
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex flex-col gap-4 form-control">
      <span class="font-bold text-neutral">Age Reference</span>

      <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
        <template v-for="(formField, index) in ageReferenceFields" :key="index">
          <div>
            <div class="flex justify-between py-2">
              <span class="label-text">
                <span v-if="showLabel">{{formField.label}}</span>
              </span>
            </div>

            <template v-if="formField.type === 'select'">
              <select v-model="model[formField.field]" class="select select-bordered w-full focus:input-neutral" :name="formField.label" :id="formField.order">
                <option selected disabled>{{formField.optionLabel}}</option>
                <template v-for="(option, index) in formField.options" :key="index">
                  <option :value="option">{{option}}</option>
                </template>
              </select>
            </template>

            <template v-if="formField.type === 'text' || formField.type === 'number'">
              <input
                v-model="model[formField.field]"
                class="input input-bordered w-full focus:input-neutral"
                :type="formField.type"
                :readonly="formField.readonly"
                :placeholder="formField.placeholder ?? 'Type here'"
                :min="formField.min"
                :step="formField.field === 'temperature' ? '0.001' : '1'"
              />
            </template>

          </div>
        </template>
      </div>

      <span class="font-bold text-neutral">Vitals</span>

      <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
        <template v-for="(formField, index) in nonVisualFormFields" :key="index">
          <div>
            <div class="flex justify-between py-2">
              <span class="label-text">
                <span v-if="showLabel">{{formField.label}}</span>
              </span>
            </div>

            <template v-if="formField.id === 'weight'">
              <input
                v-model="model.weight"
                type="number"
                min="0"
                step="0.001"
                class="input input-bordered w-full focus:input-neutral"
                placeholder="e.g. 70 kg"
              />
            </template>

            <template v-if="formField.id === 'height'">
              <input
                v-model="model.height"
                type="number"
                min="0"
                step="0.001"
                class="input input-bordered w-full focus:input-neutral"
                placeholder="e.g. 170 cm"
              />
            </template>

            <template v-if="formField.id === 'bmi'">
              <input
                v-model="model[formField.field]"
                :type="formField.type"
                :readonly="formField.readonly"
                class="input input-bordered w-full"
                placeholder="e.g. 24.2"
              />
            </template>

            <template v-if="formField.type === 'select'">
              <select v-model="model[formField.field]" class="select select-bordered w-full focus:input-neutral" :name="formField.label" :id="formField.order">
                <option selected disabled>{{formField.optionLabel}}</option>
                <template v-for="(option, index) in formField.options" :key="index">
                  <option :value="option">{{option}}</option>
                </template>
              </select>
            </template>

            <template v-if="!formField.id && (formField.type === 'text' || formField.type === 'number')">
              <input
                v-model="model[formField.field]"
                class="input input-bordered w-full focus:input-neutral"
                :type="formField.type"
                :min="formField.min"
                :readonly="formField.readonly"
                :placeholder="formField.placeholder ?? 'Type here'"
                :step="formField.field === 'temperature' ? '0.001' : '1'"
              />
            </template>

          </div>
        </template>
      </div>

      <span class="font-bold text-neutral">Visual</span>

      <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
        <template v-for="(formField, index) in visualFormFields" :key="index">
          <div>
            <div class="flex justify-between py-2">
              <span class="label-text">
                <span v-if="showLabel">{{formField.label}}</span>
              </span>
            </div>
            <input
              v-model="model[formField.field]"
              :type="formField.type"
              :readonly="formField.readonly"
              :placeholder="formField.placeholder || 'Type here'"
              class="input input-bordered w-full focus:input-neutral"
            />
          </div>
        </template>
      </div>

    </div>
    <div class="flex gap-4 justify-end">
      <button
        type="button"
        class="btn btn-outline btn-sm normal-case"
        :disabled="!formSubmittable"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { calcBMIUtil } from '@/utils/math';
import { computed, reactive, ref, watch, onMounted } from 'vue';
import { omit } from 'lodash';
import { useRoute } from 'vue-router';
import { usePatient } from '@/composables/use-patients';
import {
  UPDATE_FIELDS,
  FORM_FIELDS,
  FORM_MODEL,
  PAIN_MIN,
  PAIN_WARNING_THRESHOLD,
  PAIN_ERROR_THRESHOLD,
  PAIN_MAX,
  PAIN_TEXTS,
} from './constants';
import { useVitals } from './composables';

export default {
  props: {
    showLabel: Boolean,
    loading: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitVitals } = useVitals();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const painMin = PAIN_MIN;
    const painMax = PAIN_MAX;
    const painWarningThreshold = PAIN_WARNING_THRESHOLD;
    const painErrorThreshold = PAIN_ERROR_THRESHOLD;
    const painTexts = PAIN_TEXTS;

    const model = reactive(FORM_MODEL);
    const route = useRoute();
    const patientId = computed(() => route.query.patient);
    const { get: getPatient, patient } = usePatient();

    onMounted(async () => {
      await getPatient(patientId.value);
      console.warn('patient', patient.value);
      if (patient.value?.formattedAgeInMonths < 12) {
        model.referenceAgeUnit = 'months';
        model.referenceAge = patient.value?.formattedAgeInMonths;
      } else {
        model.referenceAgeUnit = 'years';
        model.referenceAge = patient.value?.formattedAge;
      }
    });

    watch(model, (val) => {
      if (val.weight && val.height) {
        const weight = parseFloat(val.weight);
        const height = parseFloat(val.height);
        const bmi = calcBMIUtil(weight, height);
        model.bmi = bmi.toFixed(2);
      }
    });

    function validate (obj) {
      for (const key in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, key) &&
          obj[key] !== undefined &&
          obj[key] !== null &&
          obj[key] !== ''
        ) {
          return true;
        }
      }
      return false;
    }

    const formSubmittable = computed(() => {
      return validate(model);
    });

    const submit = async () => {
      try {
        const isValid = validate(model);

        if (!isValid) return;

        loading.value = true;

        const payload = {
          ...omit(model, ['referenceAge', 'referenceAgeUnit']),
        };

        if (model.referenceAge && model.referenceAgeUnit) {
          payload.referenceAge = {
            [model.referenceAgeUnit]: model.referenceAge,
          };
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
          if (record.value.referenceAge) {
            payload.$unset = {
              referenceAge: 1,
            };
          }
        }

        // Create
        await submitVitals(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      const fields = Object.keys(data);
      fields.forEach(field => {
        model[field] = data[field];
      });

      const { referenceAge } = data;
      if (referenceAge) {
        const [referenceAgeUnit] = Object.keys(referenceAge);
        model.referenceAge = referenceAge[referenceAgeUnit];
        model.referenceAgeUnit = referenceAgeUnit;
      }
    }

    const referenceAgeInMonths = computed(() => {
      const { referenceAge, referenceAgeUnit } = model;
      if (referenceAgeUnit === 'years') {
        return referenceAge * 12;
      }
      if (referenceAgeUnit === 'months') {
        return referenceAge;
      }
      if (referenceAgeUnit === 'weeks') {
        return referenceAge / 4;
      }
      if (referenceAgeUnit === 'days') {
        return referenceAge / 30;
      }
      return referenceAge;
    });

    return {
      model,
      recordId,
      isEditing,
      formSubmittable,
      ageReferenceFields: FORM_FIELDS.filter(field => field.group === 'age'),
      visualFormFields: FORM_FIELDS.filter(field => field.group === 'visual'),
      nonVisualFormFields: FORM_FIELDS.filter(field => field.group !== 'visual' && field.group !== 'age'),

      painMin,
      painMax,
      painWarningThreshold,
      painErrorThreshold,
      painTexts,
      resetForm,
      submit,
      setForm,

      //

      referenceAgeInMonths,
    };
  },
};
</script>
