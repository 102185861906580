export const RECORD_TYPE = 'imaging-test-order';
export const DIAGNOSTIC_TEST_TYPE = 'radiology';

export const FORM_FIELDS = [
  {
    label: 'Radiology Test',
    field: 'tests',
    update: true,
    type: 'select',
    placeholder: 'e.g. Chest PA',
    multiple: true,
    rules: {
      required: true,
      type: 'string',
    },
  },
  { // combine diagnosis code and text here
    label: 'Search ICD10',
    field: 'diagnosisCodeAndText',
    update: true,
    type: 'select',
    multiple: false,
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Reason for Test Order',
    field: 'reason',
    update: true,
    type: 'textarea',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
