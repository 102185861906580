<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex flex-col">
      <div class="form-control mb-4">
        <div class="flex justify-between py-2">
          <span class="label-text">
            <span v-if="showLabel">ICD10 <span class="text-error">*</span></span>
          </span>
        </div>
        <select v-model="model.icd10" class="select select-bordered w-full" required>
          <option value="" selected disabled>e.g. A03 - Shigellosis</option>
          <option :value="`${code.code} - ${code.text}`" v-for="(code, id) in icd10" :key="id">{{ code.code }} - {{ code.text }}</option>
        </select>
      </div>

      <div class="form-control">
        <div class="flex justify-between py-2">
          <span class="label-text">
            <span v-if="showLabel">Diagnosis</span>
          </span>
        </div>
        <textarea
          v-model="model.text"
          placeholder="Enter pertinent patient diagnosis details here (e.g. Patient has watery excrement)"
          class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
          :disabled="loading"
        ></textarea>
      </div>
    </div>

    <div class="flex flex-col gap-4">
      <span class="font-medium text-base">Symptoms</span>
      <template v-for="(item, index) in model.symptoms" :key="index">
        <div class="flex flex-col gap-4">
          <div class="flex justify-between items-center">
            <span class="text-sm text-neutral-500">Symptom {{index + 1}}</span>
            <button v-if="index !== 0" class="btn btn-sm btn-circle" @click="onRemoveSymptoms(index)">
              <i class="las la-trash text-lg"></i>
            </button>
          </div>
          <div class="flex gap-4">
            <div class="flex flex-col flex-1">
              <label class="label-text mb-2">Code</label>
              <input
                v-model="item.code"
                placeholder="e.g. H91.01"
                type="text"
                class="input input-bordered focus:input-neutral"
              />
            </div>
            <div class="flex flex-col flex-1">
              <label class="label-text mb-2">Classification</label>
              <input
                v-model="item.text"
                placeholder="e.g Ototoxic hearing loss"
                type="text"
                class="input input-bordered focus:input-neutral"
              />
            </div>
          </div>
          <textarea
            v-model="item.notes"
            placeholder="Enter additional notes related to symptom (e.g. Patient regularly ingests cancer medicines)"
            type="text"
            class="flex-1 textarea textarea-bordered focus:textarea-neutral"
          >
          </textarea>
        </div>
      </template>
      <button type="button" class="btn" @click="onAddSymptoms">Add Symptom</button>
    </div>

    <div class="flex flex-col gap-4">
      <span class="font-medium text-base">Recommendations</span>
      <template v-for="(item, index) in model.recommendations" :key="index">
        <div class="flex flex-col gap-4">
          <div class="flex justify-between items-center">
            <span class="text-sm text-neutral-500">Recommendation {{index + 1}}</span>
            <button v-if="index !== 0" class="btn btn-sm btn-circle" @click="onRemoveRecommendations(index)">
              <i class="las la-trash text-lg"></i>
            </button>
          </div>
          <div class="flex gap-4">
            <div class="flex flex-col flex-1">
              <label class="label-text mb-2">Code</label>
              <input
                v-model="item.code"
                placeholder="e.g. Z02.2"
                type="text"
                class="input input-bordered focus:input-neutral"
              />
            </div>
            <div class="flex flex-col flex-1">
              <label class="label-text mb-2">Classification</label>
              <input
                v-model="item.text"
                placeholder="e.g. Encounter for examination for admission to residential institution"
                type="text"
                class="input input-bordered focus:input-neutral"
              />
            </div>
          </div>
          <textarea
            v-model="item.notes"
            placeholder="Enter additional notes related to recommendations (e.g. Patient to be confined in hospital starting June 2)"
            type="text"
            class="flex-1 textarea textarea-bordered focus:textarea-neutral"
          >
          </textarea>
        </div>
      </template>
      <button type="button" class="btn" @click="onAddRecommendations">Add Recommendation</button>
    </div>

    <div class="flex flex-col gap-4">
      <span class="font-medium text-base">Complications</span>
      <template v-for="(item, index) in model.complications" :key="index">
        <div class="flex flex-col gap-4">
          <div class="flex justify-between items-center">
            <span class="text-sm text-neutral-500">Complication {{index + 1}}</span>
            <button v-if="index !== 0" class="btn btn-sm btn-circle" @click="onRemoveComplications(index)">
              <i class="las la-trash text-lg"></i>
            </button>
          </div>
          <div class="flex gap-4">
            <div class="flex flex-col flex-1">
              <label class="label-text mb-2">Code</label>
              <input
                v-model="item.code"
                placeholder="e.g. B05.9"
                type="text"
                class="input input-bordered focus:input-neutral"
              />
            </div>
            <div class="flex flex-col flex-1">
              <label class="label-text mb-2">Classification</label>
              <input
                v-model="item.text"
                placeholder="e.g. Measles without complication"
                type="text"
                class="input input-bordered focus:input-neutral"
              />
            </div>
          </div>
          <textarea
            v-model="item.notes"
            placeholder="Enter additional notes related to complications (e.g. Patient rashes originated from neck)"
            type="text"
            class="flex-1 textarea textarea-bordered focus:textarea-neutral"
          >
          </textarea>
        </div>
      </template>
      <button type="button" class="btn" @click="onAddComplications">Add Complication</button>
    </div>

    <div class="flex flex-col gap-4">
      <span class="font-medium text-base">Risk Factors</span>
      <template v-for="(item, index) in model.riskFactors" :key="index">
        <div class="flex flex-col gap-4">
          <div class="flex justify-between items-center">
            <span class="text-sm text-neutral-500">Risk Factor {{index + 1}}</span>
            <button v-if="index !== 0" class="btn btn-sm btn-circle" @click="onRemoveRiskFactors(index)">
              <i class="las la-trash text-lg"></i>
            </button>
          </div>
          <div class="flex gap-4">
            <div class="flex flex-col flex-1">
              <label class="label-text mb-2">Code</label>
              <input
                v-model="item.code"
                placeholder="e.g. Z91.010"
                type="text"
                class="input input-bordered focus:input-neutral"
              />
            </div>
            <div class="flex flex-col flex-1">
              <label class="label-text mb-2">Classification</label>
              <input
                v-model="item.text"
                placeholder="e.g. Allergy to peanuts"
                type="text"
                class="input input-bordered focus:input-neutral"
              />
            </div>
          </div>
          <textarea
            v-model="item.notes"
            placeholder="Enter additional notes related to risk factors (e.g. Patient has peanut-free diet)"
            type="text"
            class="flex-1 textarea textarea-bordered focus:textarea-neutral"
          >
          </textarea>
        </div>
      </template>
      <button type="button" class="btn" @click="onAddRiskFactors">Add Risk Factor</button>
    </div>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { ICD10_CODES } from './constants';
import { computed, reactive, ref } from 'vue';
import { useDifferentialDiagnosis } from './composables';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submiDiagnosis } = useDifferentialDiagnosis();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive({
      text: '',
      icd10: '',
      symptoms: [
        {
          code: '',
          text: '',
          // duration: '',
          notes: '',
        },
      ],
      recommendations: [
        {
          code: '',
          text: '',
          // duration: '',
          notes: '',
        },
      ],
      complications: [
        {
          code: '',
          text: '',
          // duration: '',
          // description: '',
          // occuredAt: '',
          // serverity: '',
          notes: '',
        },
      ],
      riskFactors: [
        {
          code: '',
          text: '',
          notes: '',
        },
      ],
    });

    const formSubmittable = computed(() => {
      return !!model.icd10;
    });

    const submit = async () => {
      try {
        loading.value = true;

        const payload = {
          text: model.text,
          icd10: model.icd10,
        };

        if (model.symptoms.length) {
          // Remove empty items and remove the empty fields per item
          payload.symptoms = model.symptoms.map(item => {
            const newObj = { ...item };
            for (const key in newObj) {
              if (newObj[key] === '') {
                delete newObj[key]; // Delete properties with empty strings
              }
            }
            return newObj;
          }).filter(item => Object.keys(item).length);
        }

        if (model.recommendations.length) {
          // Remove empty items and remove the empty fields per item
          payload.recommendations = model.recommendations.map(item => {
            const newObj = { ...item };
            for (const key in newObj) {
              if (newObj[key] === '') {
                delete newObj[key]; // Delete properties with empty strings
              }
            }
            return newObj;
          }).filter(item => Object.keys(item).length);
        }

        if (model.complications.length) {
          // Remove empty items and remove the empty fields per item
          payload.complications = model.complications.map(item => {
            const newObj = { ...item };
            for (const key in newObj) {
              if (newObj[key] === '') {
                delete newObj[key]; // Delete properties with empty strings
              }
            }
            return newObj;
          }).filter(item => Object.keys(item).length);
        }

        if (model.riskFactors.length) {
          // Remove empty items and remove the empty fields per item
          payload.riskFactors = model.riskFactors.map(item => {
            const newObj = { ...item };
            for (const key in newObj) {
              if (newObj[key] === '') {
                delete newObj[key]; // Delete properties with empty strings
              }
            }
            return newObj;
          }).filter(item => Object.keys(item).length);
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submiDiagnosis(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function onAddSymptoms () {
      if (!model.symptoms.length) {
        model.symptoms = [{
          text: '',
          duration: '',
        }];
      }
      const lastIndex = model.symptoms.length - 1;
      const item = model.symptoms[lastIndex];
      if (!item.text && !item.duration) return;
      model.symptoms.push({
        text: '',
        duration: '',
      });
    }

    function onRemoveSymptoms (index) {
      if (model.symptoms.length === 1) return;
      model.symptoms.splice(index, 1);
    }

    function onAddRecommendations () {
      if (!model.recommendations.length) {
        model.recommendations = [{
          code: '',
          text: '',
          // duration: '',
          notes: '',
        }];
      }
      const lastIndex = model.recommendations.length - 1;
      const item = model.recommendations[lastIndex];
      if (!item.code && !item.text && !item.notes) return;
      model.recommendations.push({
        code: '',
        text: '',
        // duration: '',
        notes: '',
      });
    }

    function onRemoveRecommendations (index) {
      if (model.recommendations.length === 1) return;
      model.recommendations.splice(index, 1);
    }

    function onAddComplications () {
      if (!model.complications.length) {
        model.complications = [{
          code: '',
          text: '',
          // duration: '',
          // description: '',
          // occuredAt: '',
          // serverity: '',
          notes: '',
        }];
      }
      const lastIndex = model.complications.length - 1;
      const item = model.complications[lastIndex];
      if (!item.code && !item.text && !item.notes) return;
      model.complications.push({
        code: '',
        text: '',
        // duration: '',
        // description: '',
        // occuredAt: '',
        // serverity: '',
        notes: '',
      });
    }

    function onRemoveComplications (index) {
      if (model.complications.length === 1) return;
      model.complications.splice(index, 1);
    }

    function onAddRiskFactors () {
      if (!model.riskFactors.length) {
        model.riskFactors = [{
          code: '',
          text: '',
          notes: '',
        }];
      }
      const lastIndex = model.riskFactors.length - 1;
      const item = model.riskFactors[lastIndex];
      if (!item.code && !item.text && !item.notes) return;
      model.riskFactors.push({
        code: '',
        text: '',
        notes: '',
      });
    }

    function onRemoveRiskFactors (index) {
      if (model.riskFactors.length === 1) return;
      model.riskFactors.splice(index, 1);
    }

    function resetForm () {
      record.value = {};
      model.text = '';
      model.icd10 = '';
      model.symptoms = [
        {
          code: '',
          text: '',
          // duration: '',
          notes: '',
        },
      ];
      model.recommendations = [
        {
          code: '',
          text: '',
          // duration: '',
          notes: '',
        },
      ];
      model.complications = [
        {
          code: '',
          text: '',
          // duration: '',
          // description: '',
          // occuredAt: '',
          // serverity: '',
          notes: '',
        },
      ];
      model.riskFactors = [
        {
          code: '',
          text: '',
          notes: '',
        },
      ];
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.text = data.text;
      model.icd10 = data.icd10;
      model.symptoms = data?.symptoms || [];
      model.recommendations = data?.recommendations || [];
      model.complications = data?.complications || [];
      model.riskFactors = data?.riskFactors || [];
    }

    return {
      loading,
      model,
      recordId,
      formSubmittable,
      isEditing,
      icd10: ICD10_CODES,
      onAddSymptoms,
      onRemoveSymptoms,
      onAddRecommendations,
      onRemoveRecommendations,
      onAddComplications,
      onRemoveComplications,
      onAddRiskFactors,
      onRemoveRiskFactors,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
