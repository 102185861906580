import { ref, computed } from 'vue';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { usePatient } from '@/composables/use-patients';
import { useEncounters } from '@/composables/use-encounters';
import { RECORD_TYPE, UPDATE_FIELDS, SPECIAL_FIELDS } from './constants';

export function usePhysicalExam () {
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
  } = useMedicalRecords();

  const { patient } = usePatient();
  const { encounter } = useEncounters();

  const loading = ref(false);
  const formRef = ref(null);

  const records = computed(() => {
    return medicalRecords.value.filter((record) => record.type === RECORD_TYPE)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  const submit = async (data) => {
    const payload = Object.keys(data).reduce((acc, key) => {
      if (UPDATE_FIELDS.includes(key)) {
        const { text, status } = data[key];
        if (text || status) {
          acc[key] = {};
          if (text) acc[key].text = text;
          if (status) acc[key].status = status;
        }
      } else if (SPECIAL_FIELDS.includes(key)) {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    if (data?.id) {
      const id = data.id;
      await updateMedicalRecord(id, payload);
    } else {
      payload.type = RECORD_TYPE;
      payload.encounter = encounter.value?.id;
      payload.patient = patient.value?.id;
      await createMedicalRecord(payload);
    }
  };

  const remove = async ({ id }) => {
    try {
      loading.value = true;
      await removeMedicalRecord(id);
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  };

  const resetForm = () => {
    formRef.value?.resetForm();
  };

  const setForm = (data) => {
    formRef.value?.setForm(data);
  };

  return {
    formRef,
    medicalRecords,
    records,
    loading,
    submit,
    remove,
    resetForm,
    setForm,
  };
}
