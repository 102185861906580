<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex flex-col gap-4 form-control">
      <div class="grid grid-cols-2 gap-2">
        <div class="form-control w-full col-span-2">
          <label class="label">
            <span class="label-text">Destination <span class="text-error">*</span></span>
          </label>
          <input
            v-model="model.destination"
            type="text"
            placeholder="Type here"
            class="input input-bordered w-full focus:input-neutral"
            required
          />
        </div>
        <div class="form-control w-full">
          <label class="label">
            <span class="label-text">Start of Visit <span class="text-error">*</span></span>
          </label>
          <input
            v-model="model.startOfVisit"
            type="date"
            placeholder="Enter date here"
            class="input input-bordered w-full focus:input-neutral"
            required
          />
          <!-- <ClientOnly>
            <VDatePicker v-model.string="model.startOfVisit" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }">
              <template #default="{ togglePopover }">

              </template>
            </VDatePicker>
          </ClientOnly> -->
        </div>
        <div class="form-control w-full">
          <label class="label">
            <span class="label-text">End of Visit <span class="text-error">*</span></span>
          </label>
          <input
            v-model="model.endOfVisit"
            type="date"
            placeholder="Enter date here"
            class="input input-bordered w-full focus:input-neutral"
            required
          />
          <!-- <ClientOnly>
            <VDatePicker v-model.string="model.endOfVisit" mode="date" :masks="{ modelValue: 'YYYY-MM-DD' }">
              <template #default="{ togglePopover }">
                <input type="text" placeholder="Enter date here" class="input input-bordered w-full focus:input-neutral" v-model="model.endOfVisit" @click="togglePopover"/>
              </template>
            </VDatePicker>
          </ClientOnly> -->
        </div>
      </div>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_FIELDS, FORM_MODEL } from './constants';
import { computed, ref } from 'vue';
import { useCloned } from '@vueuse/core';
import { format } from 'date-fns';
import { useTravelHistory } from './composables';

export default {
  props: {
    showLabel: Boolean,
    loading: Boolean,
    patientId: String,
    encounterId: String,
    facilityId: String,
  },
  setup (props, { emit }) {
    const { submit: submitTravelHistory } = useTravelHistory();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);
    const { cloned: model, sync } = useCloned(FORM_MODEL);

    const formSubmittable = computed(() => {
      return !!model.value.destination && !!model.value.startOfVisit && !!model.value.endOfVisit;
    });

    const submit = async () => {
      try {
        loading.value = true;
        const payload = { ...model.value };

        if (isEditing.value) {
          payload.id = recordId.value;
        }
        // Create
        await submitTravelHistory(payload, {
          patientId: props.patientId,
          encounterId: props.encounterId,
          facilityId: props.facilityId,
        });

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      sync();
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      console.log('data', data);
      UPDATE_FIELDS.forEach(field => {
        if (field === 'startOfVisit' || field === 'endOfVisit') {
          model.value[field] = format(new Date(data[field]), 'yyyy-MM-dd');
        } else {
          model.value[field] = data[field];
        }
      });
    }

    return {
      model,
      recordId,
      isEditing,
      formSubmittable,
      formFields: FORM_FIELDS,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
