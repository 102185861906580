export const RECORD_TYPE = 'nutritional-plan';

export const FORM_FIELDS = [
  {
    field: 'breakfast',
    label: 'Breakfast',
    update: true,
    model: {
      time: {
        label: 'Time',
        field: 'time',
        value: '',
      },
      rice: {
        label: 'Rice',
        field: 'rice',
        amount: null,
        sample: '',
      },
      meat: {
        label: 'Meat',
        field: 'meat',
        amount: null,
        sample: '',
      },
      vegetables: {
        label: 'Vegetables',
        field: 'vegetables',
        amount: null,
        sample: '',
      },
      fat: {
        label: 'Fats',
        field: 'fat',
        amount: null,
        sample: '',
      },
      fruit: {
        label: 'Fruits',
        field: 'fruit',
        amount: null,
        sample: '',
      },
      milk: {
        label: 'Milk',
        field: 'milk',
        amount: null,
        sample: '',
      },
      sugar: {
        label: 'Sugar',
        field: 'sugar',
        amount: null,
        sample: '',
      },
    },
    rules: {
      required: false,
      type: 'object',
    },
  },
  {
    field: 'snackAM',
    label: 'Snack AM',
    update: true,
    model: {
      time: {
        label: 'Time',
        field: 'time',
        value: '',
      },
      rice: {
        label: 'Rice',
        field: 'rice',
        amount: null,
        sample: '',
      },
      meat: {
        label: 'Meat',
        field: 'meat',
        amount: null,
        sample: '',
      },
      vegetables: {
        label: 'Vegetables',
        field: 'vegetables',
        amount: null,
        sample: '',
      },
      fat: {
        label: 'Fats',
        field: 'fat',
        amount: null,
        sample: '',
      },
      fruit: {
        label: 'Fruits',
        field: 'fruit',
        amount: null,
        sample: '',
      },
      milk: {
        label: 'Milk',
        field: 'milk',
        amount: null,
        sample: '',
      },
      sugar: {
        label: 'Sugar',
        field: 'sugar',
        amount: null,
        sample: '',
      },
    },
    rules: {
      required: false,
      type: 'object',
    },
  },
  {
    field: 'lunch',
    label: 'Lunch',
    update: true,
    model: {
      time: {
        label: 'Time',
        field: 'time',
        value: '',
      },
      rice: {
        label: 'Rice',
        field: 'rice',
        amount: null,
        sample: '',
      },
      meat: {
        label: 'Meat',
        field: 'meat',
        amount: null,
        sample: '',
      },
      vegetables: {
        label: 'Vegetables',
        field: 'vegetables',
        amount: null,
        sample: '',
      },
      fat: {
        label: 'Fats',
        field: 'fat',
        amount: null,
        sample: '',
      },
      fruit: {
        label: 'Fruits',
        field: 'fruit',
        amount: null,
        sample: '',
      },
      milk: {
        label: 'Milk',
        field: 'milk',
        amount: null,
        sample: '',
      },
      sugar: {
        label: 'Sugar',
        field: 'sugar',
        amount: null,
        sample: '',
      },
    },
    rules: {
      required: false,
      type: 'object',
    },
  },
  {
    field: 'snackPM',
    label: 'Snack PM',
    update: true,
    model: {
      time: {
        label: 'Time',
        field: 'time',
        value: '',
      },
      rice: {
        label: 'Rice',
        field: 'rice',
        amount: null,
        sample: '',
      },
      meat: {
        label: 'Meat',
        field: 'meat',
        amount: null,
        sample: '',
      },
      vegetables: {
        label: 'Vegetables',
        field: 'vegetables',
        amount: null,
        sample: '',
      },
      fat: {
        label: 'Fats',
        field: 'fat',
        amount: null,
        sample: '',
      },
      fruit: {
        label: 'Fruits',
        field: 'fruit',
        amount: null,
        sample: '',
      },
      milk: {
        label: 'Milk',
        field: 'milk',
        amount: null,
        sample: '',
      },
      sugar: {
        label: 'Sugar',
        field: 'sugar',
        amount: null,
        sample: '',
      },
    },
    rules: {
      required: false,
      type: 'object',
    },
  },
  {
    field: 'dinner',
    label: 'Dinner',
    update: true,
    model: {
      time: {
        label: 'Time',
        field: 'time',
        value: '',
      },
      rice: {
        label: 'Rice',
        field: 'rice',
        amount: null,
        sample: '',
      },
      meat: {
        label: 'Meat',
        field: 'meat',
        amount: null,
        sample: '',
      },
      vegetables: {
        label: 'Vegetables',
        field: 'vegetables',
        amount: null,
        sample: '',
      },
      fat: {
        label: 'Fats',
        field: 'fat',
        amount: null,
        sample: '',
      },
      fruit: {
        label: 'Fruits',
        field: 'fruit',
        amount: null,
        sample: '',
      },
      milk: {
        label: 'Milk',
        field: 'milk',
        amount: null,
        sample: '',
      },
      sugar: {
        label: 'Sugar',
        field: 'sugar',
        amount: null,
        sample: '',
      },
    },
    rules: {
      required: false,
      type: 'object',
    },
  },
  {
    field: 'snackBeforeBed',
    label: 'Snack Before Bed',
    update: true,
    model: {
      time: {
        label: 'Time',
        field: 'time',
        value: '',
      },
      rice: {
        label: 'Rice',
        field: 'rice',
        amount: null,
        sample: '',
      },
      meat: {
        label: 'Meat',
        field: 'meat',
        amount: null,
        sample: '',
      },
      vegetables: {
        label: 'Vegetables',
        field: 'vegetables',
        amount: null,
        sample: '',
      },
      fat: {
        label: 'Fats',
        field: 'fat',
        amount: null,
        sample: '',
      },
      fruit: {
        label: 'Fruits',
        field: 'fruit',
        amount: null,
        sample: '',
      },
      milk: {
        label: 'Milk',
        field: 'milk',
        amount: null,
        sample: '',
      },
      sugar: {
        label: 'Sugar',
        field: 'sugar',
        amount: null,
        sample: '',
      },
    },
    rules: {
      required: false,
      type: 'object',
    },
  },
];
export const UPDATE_FIELDS = FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field);

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.model;
  return acc;
}, {});

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
