import { initial, last } from 'lodash';

export const reverseString = (string) => {
  const rawString = string.split('');
  const reverseRaw = rawString.reverse();
  const joinedReverse = reverseRaw.join('');
  return joinedReverse;
};

export const convertToFirstAndLastName = (nameString) => {
  if (!nameString) return;
  const nameArray = nameString.split(' ');
  if (nameArray?.length === 1) return { firstName: nameArray[0], lastName: null };
  const firstName = initial(nameArray).join(' ');
  const lastName = last(nameArray);
  return { firstName, lastName };
};

export const retrieveName = (nameObj, asObj = false) => {
  if (!nameObj) return;
  if (!asObj) return `${nameObj?.firstName} ${nameObj?.lastName}`;
  else return { firstName: nameObj?.firstName, lastName: nameObj?.lastName };
};

export const yesOrNo = (ans) => {
  const answer = ans;
  switch (answer) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      return 'N/A';
  }
};
