export const RECORD_TYPE = 'medical-procedure-order';

// TODO: replace with generic fixtures once EJ API is populated
const TEMP_LAB_TESTS = [
  'Appendectomy',
  'Cesarean Section (C-Section)',
  'Colonoscopy',
  'Endoscopy',
  'Hysterectomy',
  'Laparoscopic Surgery',
  'Mastectomy',
  'Cardiac Catheterization',
  'Angioplasty',
  'Coronary Artery Bypass Graft (CABG) Surgery',
  'Knee Replacement (Knee Arthroplasty)',
  'Hip Replacement (Hip Arthroplasty)',
  'Gastric Bypass Surgery',
  'Cholecystectomy (Gallbladder Removal)',
  'Tonsillectomy',
  'Vasectomy',
  'Vasectomy Reversal',
  'Cataract Surgery',
  'LASIK Eye Surgery',
  'Root Canal',
  'Dental Crown Placement',
  'Tonsil and Adenoid Removal (Tonsillectomy and Adenoidectomy)',
  'Bronchoscopy',
  'Dialysis',
  'Bone Marrow Transplant',
  'Liver Transplant',
  'Kidney Transplant',
  'Colon Resection',
  'Lumbar Puncture (Spinal Tap)',
  'Pacemaker Implantation',
];

export const FORM_FIELDS = [
  {
    label: 'Procedure Order/s',
    field: 'name',
    update: true,
    type: 'select',
    multiple: true,
    options: TEMP_LAB_TESTS,
    rules: {
      required: true,
      type: 'string',
    },
  },
  { // combine diagnosis code and text here
    label: 'Search Diagnosis Code',
    field: 'diagnosisCodeAndText',
    update: true,
    type: 'select',
    multiple: false,
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Reason for Procedure Order',
    field: 'reason',
    placeholder: 'Indicate test purpose',
    type: 'text',
    multiple: false,
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Notes',
    field: 'notes',
    update: true,
    type: 'textarea',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
