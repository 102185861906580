export const MEDICAL_CONDITIONS = [
  'Acid Reflux',
  'Alcohol Addiction',
  'Allergies',
  'Asthma / Chronic Obstructive Pulmonary Disease',
  'Alternative therapies',
  'Anemia',
  'Anxiety',
  'Artery/Vein Problems',
  'Arthritis',
  'Autoimmune Disease',
  'Birth history',
  'Bladder Irritability',
  'Bleeding Problems',
  'Blood Clots',
  'Cancer',
  'Cataracts',
  'Colitis / Crohns',
  'Congenital Anomaly',
  'Current Medications',
  'Depression',
  'Diabetes',
  'Diet',
  'Drug Addiction',
  'Esophagitis, ulcers',
  'Fractures',
  'Gallstones',
  'General state of health',
  'Glaucoma',
  'Gout',
  'Growth and development',
  'Gynecologic history',
  'Headaches',
  'Hearing Impairment',
  'Heart Attack',
  'Heart Disease',
  'Heart Valve Problems',
  'Hepatitis',
  'High Blood Pressure',
  'High Cholesterol',
  'HIV',
  'Hospitalizations',
  'Hypertension',
  'Immunizations',
  'Injuries/Accidents',
  'Irritable Bowel',
  'Kidney Disease',
  'Kidney Stones',
  'Liver Disease',
  'Lung Disease',
  'Mental Status',
  'Migraines',
  'MRSA',
  'Menstrual Hx',
  'Obstetric history',
  'Osteoporosis',
  'PTB',
  'PTSD',
  'Recurrent Skin Infections',
  'Recurrent UTI',
  'Seizures',
  'Sleep',
  'Sleep Apnea',
  'Stroke (CVA)',
  'Substance abuse',
  'Surgeries',
  'TB',
  'Thyroid problem',
  'Vision Impairment',
  'Others',
];

export const FAMILY_RELATIONSHIPS = [
  'Mother',
  'Father',
  'Child',
  'Aunt',
  'Uncle',
  'Nephew',
  'Cousin',
  'Sibling',
  'Grandparents',
];

export const EDUCATIONAL_LEVELS = [
  'Pre-school education',
  'Elementary school or stage 2 basic education',
  'High school or stage 3 basic education',
  'Non-tertiary/technical-vocational education',
  'Baccalaureate/college education',
  'Post-graduate education',
  'Didn\'t attend school',
];

export const CHRONIC_DISEASES = [
  {
    value: 'Cancer',
    text: 'Cancer',
    children: [
      { value: 'Breast cancer', text: 'Breast cancer' },
      { value: 'Colon cancer', text: 'Colon cancer' },
      { value: 'Lung cancer', text: 'Lung cancer' },
      { value: 'Prostate cancer', text: 'Prostate cancer' },
      { value: 'Ovarian cancer', text: 'Ovarian cancer' },
      { value: 'Leukemia', text: 'Leukemia' },
      { value: 'Lymphoma', text: 'Lymphoma' },
      { value: 'Melanoma', text: 'Melanoma' },
      { value: 'Pancreatic cancer', text: 'Pancreatic cancer' },
      { value: 'Kidney cancer', text: 'Kidney cancer' },
      { value: 'Bladder cancer', text: 'Bladder cancer' },
      { value: 'Stomach cancer', text: 'Stomach cancer' },
      { value: 'Esophageal cancer', text: 'Esophageal cancer' },
      { value: 'Thyroid cancer', text: 'Thyroid cancer' },
      { value: 'Brain cancer', text: 'Brain cancer' },
    ],
  },
  {
    value: 'Cardiovascular disease',
    text: 'Cardiovascular disease',
    children: [
      { value: 'Heart disease', text: 'Heart disease' },
      { value: 'Stroke', text: 'Stroke' },
      { value: 'High blood pressure', text: 'High blood pressure' },
      { value: 'High cholesterol', text: 'High cholesterol' },
      { value: 'Arrhythmia', text: 'Arrhythmia' },
      { value: 'Congestive heart failure', text: 'Congestive heart failure' },
    ],
  },
  {
    value: 'Neurological disorders',
    text: 'Neurological disorders',
    children: [
      { value: "Alzheimer's disease", text: "Alzheimer's disease" },
      { value: "Parkinson's disease", text: "Parkinson's disease" },
      { value: 'Multiple sclerosis', text: 'Multiple sclerosis' },
      { value: 'Epilepsy', text: 'Epilepsy' },
      { value: "Huntington's disease", text: "Huntington's disease" },
      { value: 'Muscular dystrophy', text: 'Muscular dystrophy' },
      { value: 'Amyotrophic lateral sclerosis', text: 'Amyotrophic lateral sclerosis (ALS)' },
      { value: 'Cerebral palsy', text: 'Cerebral palsy' },
      { value: 'Spina bifida', text: 'Spina bifida' },
      { value: 'Hydrocephalus', text: 'Hydrocephalus' },
      { value: 'Autism spectrum disorder', text: 'Autism spectrum disorder' },
    ],
  },
  {
    value: 'Mental health disorders',
    text: 'Mental health disorders',
    children: [
      { value: 'Depression', text: 'Depression' },
      { value: 'Anxiety', text: 'Anxiety' },
      { value: 'Bipolar disorder', text: 'Bipolar disorder' },
      { value: 'Schizophrenia', text: 'Schizophrenia' },
      { value: 'Post-traumatic stress disorder (PTSD)', text: 'Post-traumatic stress disorder (PTSD)' },
      { value: 'Obsessive-compulsive disorder (OCD)', text: 'Obsessive-compulsive disorder (OCD)' },
      { value: 'Attention-deficit hyperactivity disorder (ADHD)', text: 'Attention-deficit hyperactivity disorder (ADHD)' },
      { value: 'Eating disorders', text: 'Eating disorders' },
      { value: 'Substance abuse disorders', text: 'Substance abuse disorders' },
    ],
  },
  {
    value: 'Genetic disorders',
    text: 'Genetic disorders',
    children: [
      { value: 'Cystic fibrosis', text: 'Cystic fibrosis' },
      { value: 'Sickle cell disease', text: 'Sickle cell disease' },
      { value: 'Down syndrome', text: 'Down syndrome' },
      { value: 'Hemophilia', text: 'Hemophilia' },
      { value: 'Fragile X syndrome', text: 'Fragile X syndrome' },
      { value: 'Turner syndrome', text: 'Turner syndrome' },
      { value: 'Klinefelter syndrome', text: 'Klinefelter syndrome' },
      { value: 'Tay-Sachs disease', text: 'Tay-Sachs disease' },
      { value: 'Phenylketonuria (PKU)', text: 'Phenylketonuria (PKU)' },
      { value: 'Neurofibromatosis', text: 'Neurofibromatosis' },
      { value: 'Marfan syndrome', text: 'Marfan syndrome' },
      { value: 'Ehlers-Danlos syndrome', text: 'Ehlers-Danlos syndrome' },
    ],
  },
  {
    value: 'Infectious diseases',
    text: 'Infectious diseases',
    children: [
      { value: 'HIV/AIDS', text: 'HIV/AIDS' },
      { value: 'Tuberculosis', text: 'Tuberculosis' },
      { value: 'Malaria', text: 'Malaria' },
      { value: 'Hepatitis', text: 'Hepatitis' },
      { value: 'Zika virus', text: 'Zika virus' },
      { value: 'COVID-19', text: 'COVID-19' },
      { value: 'Other infectious diseases', text: 'Other infectious diseases' },
    ],
  },
  {
    value: 'Autoimmune diseases',
    text: 'Autoimmune diseases',
    children: [
      { value: 'Lupus', text: 'Lupus' },
      { value: 'Rheumatoid arthritis', text: 'Rheumatoid arthritis' },
      { value: 'Type 1 diabetes', text: 'Type 1 diabetes' },
      { value: "Crohn's disease", text: "Crohn's disease" },
      { value: 'Ulcerative colitis', text: 'Ulcerative colitis' },
      { value: 'Multiple sclerosis', text: 'Multiple sclerosis' },
      { value: 'Celiac disease', text: 'Celiac disease' },
      { value: 'Psoriasis', text: 'Psoriasis' },
      { value: "Graves' disease", text: "Graves' disease" },
      { value: "Hashimoto's thyroiditis", text: "Hashimoto's thyroiditis" },
      { value: 'Myasthenia gravis', text: 'Myasthenia gravis' },
    ],
  },
  {
    value: 'Rare diseases',
    text: 'Rare diseases',
    children: [
      { value: 'Tay-Sachs disease', text: 'Tay-Sachs disease' },
      { value: "Huntington's disease", text: "Huntington's disease" },
      { value: 'Phenylketonuria (PKU)', text: 'Phenylketonuria (PKU)' },
      { value: 'Gaucher disease', text: 'Gaucher disease' },
      { value: 'Pompe disease', text: 'Pompe disease' },
      { value: 'Fabry disease', text: 'Fabry disease' },
      { value: 'Krabbe disease', text: 'Krabbe disease' },
      { value: 'Alexander disease', text: 'Alexander disease' },
      { value: 'Canavan disease', text: 'Canavan disease' },
      { value: 'Metachromatic leukodystrophy', text: 'Metachromatic leukodystrophy' },
      { value: 'Adrenoleukodystrophy', text: 'Adrenoleukodystrophy' },
    ],
  },
  {
    value: 'Others',
    text: 'Others',
    children: [
      { value: 'Others', text: 'Others' },
    ],
  },
];

export const ICD_10_CODES = [
  {
    type: 'icd10',
    code: 'A00',
    text: 'Cholera',
  },
  {
    type: 'icd10',
    code: 'A00.0',
    text: 'Cholera due to Vibrio cholerae 01, biovar cholerae',
  },
  {
    type: 'icd10',
    code: 'A00.1',
    text: 'Cholera due to Vibrio cholerae 01, biovar eltor',
  },
  {
    type: 'icd10',
    code: 'A00.9',
    text: 'Cholera, unspecified',
  },
  {
    type: 'icd10',
    code: 'A01',
    text: 'Typhoid and paratyphoid fevers',
  },
  {
    type: 'icd10',
    code: 'A01.0',
    text: 'Typhoid fever',
  },
  {
    type: 'icd10',
    code: 'A01.1',
    text: 'Paratyphoid fever A',
  },
  {
    type: 'icd10',
    code: 'A01.2',
    text: 'Paratyphoid fever B',
  },
  {
    type: 'icd10',
    code: 'A01.3',
    text: 'Paratyphoid fever C',
  },
  {
    type: 'icd10',
    code: 'A01.4',
    text: 'Paratyphoid fever, unspecified',
  },
  {
    type: 'icd10',
    code: 'A02',
    text: 'Other salmonella infections',
  },
  {
    type: 'icd10',
    code: 'A02.0',
    text: 'Salmonella enteritis',
  },
  {
    type: 'icd10',
    code: 'A02.1',
    text: 'Salmonella sepsis',
  },
  {
    type: 'icd10',
    code: 'A02.2',
    text: 'Localized salmonella infections',
  },
  {
    type: 'icd10',
    code: 'A02.8',
    text: 'Other specified salmonella infections',
  },
  {
    type: 'icd10',
    code: 'A02.9',
    text: 'Salmonella infection, unspecified',
  },
  {
    type: 'icd10',
    code: 'A03',
    text: 'Shigellosis',
  },
  {
    type: 'icd10',
    code: 'A03.0',
    text: 'Shigellosis due to Shigella dysenteriae',
  },
  {
    type: 'icd10',
    code: 'A03.1',
    text: 'Shigellosis due to Shigella flexneri',
  },
  {
    type: 'icd10',
    code: 'A03.2',
    text: 'Shigellosis due to Shigella boydii',
  },
  {
    type: 'icd10',
    code: 'A03.3',
    text: 'Shigellosis due to Shigella sonnei',
  },
  {
    type: 'icd10',
    code: 'A03.8',
    text: 'Other shigellosis',
  },
  {
    type: 'icd10',
    code: 'A03.9',
    text: 'Shigellosis, unspecified',
  },
  {
    type: 'icd10',
    code: 'A04',
    text: 'Other bacterial intestinal infections',
  },
  {
    type: 'icd10',
    code: 'A04.0',
    text: 'Enteropathogenic Escherichia coli infection',
  },
  {
    type: 'icd10',
    code: 'A04.1',
    text: 'Enterotoxigenic Escherichia coli infection',
  },
  {
    type: 'icd10',
    code: 'A04.2',
    text: 'Enteroinvasive Escherichia coli infection',
  },
  {
    type: 'icd10',
    code: 'A04.3',
    text: 'Enterohaemorrhagic Escherichia coli infection',
  },
  {
    type: 'icd10',
    code: 'A04.4',
    text: 'Other intestinal Escherichia coli infections',
  },
  {
    type: 'icd10',
    code: 'A04.5',
    text: 'Campylobacter enteritis',
  },
  {
    type: 'icd10',
    code: 'A04.6',
    text: 'Enteritis due to Yersinia enterocolitica',
  },
  {
    type: 'icd10',
    code: 'A04.7',
    text: 'Enterocolitis due to Clostridium difficile',
  },
  {
    type: 'icd10',
    code: 'A04.8',
    text: 'Other specified bacterial intestinal infections',
  },
  {
    type: 'icd10',
    code: 'A04.9',
    text: 'Bacterial intestinal infection, unspecified',
  },
  {
    type: 'icd10',
    code: 'A05',
    text: 'Other bacterial foodborne intoxications, not elsewhere classified',
  },
  {
    type: 'icd10',
    code: 'A05.0',
    text: 'Foodborne staphylococcal intoxication',
  },
  {
    type: 'icd10',
    code: 'A05.1',
    text: 'Botulism',
  },
  {
    type: 'icd10',
    code: 'A05.2',
    text: 'Foodborne Clostridium perfringens [Clostridium welchii] intoxication',
  },
  {
    type: 'icd10',
    code: 'A05.3',
    text: 'Foodborne Vibrio parahaemolyticus intoxication',
  },
  {
    type: 'icd10',
    code: 'A05.4',
    text: 'Foodborne Bacillus cereus intoxication',
  },
  {
    type: 'icd10',
    code: 'A05.8',
    text: 'Other specified bacterial foodborne intoxications',
  },
  {
    type: 'icd10',
    code: 'A05.9',
    text: 'Bacterial foodborne intoxication, unspecified',
  },
  {
    type: 'icd10',
    code: 'A06',
    text: 'Amoebiasis',
  },
  {
    type: 'icd10',
    code: 'A06.0',
    text: 'Acute amoebic dysentery',
  },
  {
    type: 'icd10',
    code: 'A06.1',
    text: 'Chronic intestinal amoebiasis',
  },
  {
    type: 'icd10',
    code: 'A06.2',
    text: 'Amoebic nondysenteric colitis',
  },
  {
    type: 'icd10',
    code: 'A06.3',
    text: 'Amoeboma of intestine',
  },
  {
    type: 'icd10',
    code: 'A06.4',
    text: 'Amoebic liver abscess',
  },
  {
    type: 'icd10',
    code: 'A06.5',
    text: 'Amoebic lung abscess',
  },
  {
    type: 'icd10',
    code: 'A06.6',
    text: 'Amoebic brain abscess',
  },
  {
    type: 'icd10',
    code: 'A06.7',
    text: 'Cutaneous amoebiasis',
  },
  {
    type: 'icd10',
    code: 'A06.8',
    text: 'Amoebic infection of other sites',
  },
  {
    type: 'icd10',
    code: 'A06.9',
    text: 'Amoebiasis, unspecified',
  },
  {
    type: 'icd10',
    code: 'A07',
    text: 'Other protozoal intestinal diseases',
  },
  {
    type: 'icd10',
    code: 'A07.0',
    text: 'Balantidiasis',
  },
  {
    type: 'icd10',
    code: 'A07.1',
    text: 'Giardiasis [lambliasis]',
  },
  {
    type: 'icd10',
    code: 'A07.2',
    text: 'Cryptosporidiosis',
  },
  {
    type: 'icd10',
    code: 'A07.3',
    text: 'Isosporiasis',
  },
  {
    type: 'icd10',
    code: 'A07.8',
    text: 'Other specified protozoal intestinal diseases',
  },
  {
    type: 'icd10',
    code: 'A07.9',
    text: 'Protozoal intestinal disease, unspecified',
  },
  {
    type: 'icd10',
    code: 'A08',
    text: 'Viral and other specified intestinal infections',
  },
  {
    type: 'icd10',
    code: 'A08.0',
    text: 'Rotaviral enteritis',
  },
  {
    type: 'icd10',
    code: 'A08.1',
    text: 'Acute gastroenteropathy due to Norwalk agent',
  },
  {
    type: 'icd10',
    code: 'A08.2',
    text: 'Adenoviral enteritis',
  },
  {
    type: 'icd10',
    code: 'A08.3',
    text: 'Other viral enteritis',
  },
  {
    type: 'icd10',
    code: 'A08.4',
    text: 'Viral intestinal infection, unspecified',
  },
  {
    type: 'icd10',
    code: 'A08.5',
    text: 'Other specified intestinal infections',
  },
  {
    type: 'icd10',
    code: 'A09',
    text: 'Other gastroenteritis and colitis of infectious and unspecified origin',
  },
  {
    type: 'icd10',
    code: 'A09.0',
    text: 'Other and unspecified gastroenteritis and colitis of infectious origin',
  },
  {
    type: 'icd10',
    code: 'A09.9',
    text: 'Gastroenteritis and colitis of unspecified origin',
  },
  {
    type: 'icd10',
    code: 'A15',
    text: 'Respiratory tuberculosis, bacteriologically and histologically confirmed',
  },
  {
    type: 'icd10',
    code: 'A15.0',
    text: 'Tuberculosis of lung, confirmed by sputum microscopy with or without culture',
  },
  {
    type: 'icd10',
    code: 'A15.1',
    text: 'Tuberculosis of lung, confirmed by culture only',
  },
  {
    type: 'icd10',
    code: 'A15.2',
    text: 'Tuberculosis of lung, confirmed histologically',
  },
  {
    type: 'icd10',
    code: 'A15.3',
    text: 'Tuberculosis of lung, confirmed by unspecified means',
  },
  {
    type: 'icd10',
    code: 'A15.4',
    text: 'Tuberculosis of intrathoracic lymph nodes, confirmed bacteriologically and histologically',
  },
  {
    type: 'icd10',
    code: 'A15.5',
    text: 'Tuberculosis of larynx, trachea and bronchus, confirmed bacteriologically and histologically',
  },
  {
    type: 'icd10',
    code: 'A15.6',
    text: 'Tuberculous pleurisy, confirmed bacteriologically and histologically',
  },
  {
    type: 'icd10',
    code: 'A15.7',
    text: 'Primary respiratory tuberculosis, confirmed bacteriologically and histologically',
  },
  {
    type: 'icd10',
    code: 'A15.8',
    text: 'Other respiratory tuberculosis, confirmed bacteriologically and histologically',
  },
  {
    type: 'icd10',
    code: 'A15.9',
    text: 'Respiratory tuberculosis unspecified, confirmed bacteriologically and histologically',
  },
  {
    type: 'icd10',
    code: 'A16',
    text: 'Respiratory tuberculosis, not confirmed bacteriologically or histologically',
  },
  {
    type: 'icd10',
    code: 'A16.0',
    text: 'Tuberculosis of lung, bacteriologically and histologically negative',
  },
  {
    type: 'icd10',
    code: 'A16.1',
    text: 'Tuberculosis of lung, bacteriological and histological examination not done',
  },
  {
    type: 'icd10',
    code: 'A16.2',
    text: 'Tuberculosis of lung, without mention of bacteriological or histological confirmation',
  },
  {
    type: 'icd10',
    code: 'A16.3',
    text: 'Tuberculosis of intrathoracic lymph nodes, without mention of bacteriological or histological confirmation',
  },
  {
    type: 'icd10',
    code: 'A16.4',
    text: 'Tuberculosis of larynx, trachea and bronchus, without mention of bacteriological or histological confirmation',
  },
  {
    type: 'icd10',
    code: 'A16.5',
    text: 'Tuberculous pleurisy, without mention of bacteriological or histological confirmation',
  },
  {
    type: 'icd10',
    code: 'A16.7',
    text: 'Primary respiratory tuberculosis without mention of bacteriological or histological confirmation',
  },
  {
    type: 'icd10',
    code: 'A16.8',
    text: 'Other respiratory tuberculosis, without mention of bacteriological or histological confirmation',
  },
  {
    type: 'icd10',
    code: 'A16.9',
    text: 'Respiratory tuberculosis unspecified, without mention of bacteriological or histological confirmation',
  },
  {
    type: 'icd10',
    code: 'A17',
    text: 'Tuberculosis of nervous system',
  },
  {
    type: 'icd10',
    code: 'A17.0',
    text: 'Tuberculous meningitis',
  },
  {
    type: 'icd10',
    code: 'A17.1',
    text: 'Meningeal tuberculoma',
  },
  {
    type: 'icd10',
    code: 'A17.8',
    text: 'Other tuberculosis of nervous system',
  },
  {
    type: 'icd10',
    code: 'A17.9',
    text: 'Tuberculosis of nervous system, unspecified',
  },
  {
    type: 'icd10',
    code: 'A18',
    text: 'Tuberculosis of other organs',
  },
  {
    type: 'icd10',
    code: 'A18.0',
    text: 'Tuberculosis of bones and joints',
  },
  {
    type: 'icd10',
    code: 'A18.1',
    text: 'Tuberculosis of genitourinary system',
  },
  {
    type: 'icd10',
    code: 'A18.2',
    text: 'Tuberculous peripheral lymphadenopathy',
  },
];

export const ALLERGY_COLLECTION = [
  {
    value: 'food',
    text: 'Food',
    children: [
      { value: 'Food - Almonds', text: 'Almonds' },
      { value: 'Food - Apple', text: 'Apple' },
      { value: 'Food - Avocado', text: 'Avocado' },
      { value: 'Food - Baker\'s yeast', text: 'Baker\'s yeast' },
      { value: 'Food - Banana', text: 'Banana' },
      { value: 'Food - Beef', text: 'Beef' },
      { value: 'Food - Brewer\'s yeast', text: 'Brewer\'s yeast' },
      { value: 'Food - Cabbage', text: 'Cabbage' },
      { value: 'Food - Cantaloupe', text: 'Cantaloupe' },
      { value: 'Food - Carrots', text: 'Carrots' },
      { value: 'Food - Celery', text: 'Celery' },
      { value: 'Food - Cherry', text: 'Cherry' },
      { value: 'Food - Chicken', text: 'Chicken' },
      { value: 'Food - Chocolate', text: 'Chocolate' },
      { value: 'Food - Clams', text: 'Clams' },
      { value: 'Food - Coffee', text: 'Coffee' },
      { value: 'Food - Corn', text: 'Corn' },
      { value: 'Food - Cow\'s milk', text: 'Cow\'s milk' },
      { value: 'Food - Crab', text: 'Crab' },
      { value: 'Food - Cucumber', text: 'Cucumber' },
      { value: 'Food - Egg', text: 'Egg' },
      { value: 'Food - Fish', text: 'Fish' },
      { value: 'Food - Garlic', text: 'Garlic' },
      { value: 'Food - Gluten', text: 'Gluten' },
      { value: 'Food - Goat\'s Milk', text: 'Goat\'s Milk' },
      { value: 'Food - Hazelnut', text: 'Hazelnut' },
      { value: 'Food - Hot peppers', text: 'Hot peppers' },
      { value: 'Food - Kiwi', text: 'Kiwi' },
      { value: 'Food - Lemon', text: 'Lemon' },
      { value: 'Food - Mango', text: 'Mango' },
      { value: 'Food - Meat', text: 'Meat' },
      { value: 'Food - Milk', text: 'Milk' },
      { value: 'Food - Mixed Fish', text: 'Mixed Fish' },
      { value: 'Food - Mustard', text: 'Mustard' },
      { value: 'Food - Oats', text: 'Oats' },
      { value: 'Food - Onion', text: 'Onion' },
      { value: 'Food - Orange', text: 'Orange' },
      { value: 'Food - Orris root', text: 'Orris root' },
      { value: 'Food - Peach', text: 'Peach' },
      { value: 'Food - Peanut', text: 'Peanut' },
      { value: 'Food - Pepper mix', text: 'Pepper mix' },
      { value: 'Food - Pineapple', text: 'Pineapple' },
      { value: 'Food - Pork', text: 'Pork' },
      { value: 'Food - Potato', text: 'Potato' },
      { value: 'Food - Rice grain', text: 'Rice grain' },
      { value: 'Food - Sesame', text: 'Sesame' },
      { value: 'Food - Shellfish', text: 'Shellfish' },
      { value: 'Food - Shrimp', text: 'Shrimp' },
      { value: 'Food - Soy', text: 'Soy' },
      { value: 'Food - Strawberry', text: 'Strawberry' },
      { value: 'Food - Sulfites', text: 'Sulfites' },
      { value: 'Food - Sunflower Seeds', text: 'Sunflower Seeds' },
      { value: 'Food - Tomato', text: 'Tomato' },
      { value: 'Food - Tree nut', text: 'Tree nut' },
      { value: 'Food - Tuna', text: 'Tuna' },
      { value: 'Food - Watermelon', text: 'Watermelon' },
    ],
  },
  {
    value: 'medication',
    text: 'Medication',
    children: [
      { value: 'Medication - Acetaminophen', text: 'Acetaminophen' },
      { value: 'Medication - Adalimumab', text: 'Adalimumab' },
      { value: 'Medication - Aspirin', text: 'Aspirin' },
      { value: 'Medication - Atenolol', text: 'Atenolol' },
      { value: 'Medication - Captopril', text: 'Captopril' },
      { value: 'Medication - Carboplatin', text: 'Carboplatin' },
      { value: 'Medication - Carvedilol', text: 'Carvedilol' },
      { value: 'Medication - Cephalosporins', text: 'Cephalosporins' },
      { value: 'Medication - Cetirizine', text: 'Cetirizine' },
      { value: 'Medication - Cisplatin', text: 'Cisplatin' },
      { value: 'Medication - Cromolyn Sodium', text: 'Cromolyn Sodium' },
      { value: 'Medication - Dilantin (phenytoin)', text: 'Dilantin (phenytoin)' },
      { value: 'Medication - Diphenhydramine', text: 'Diphenhydramine' },
      { value: 'Medication - Docetaxel', text: 'Docetaxel' },
      { value: 'Medication - Enalapril', text: 'Enalapril' },
      { value: 'Medication - Fexofenadine', text: 'Fexofenadine' },
      { value: 'Medication - Ibuprofen', text: 'Ibuprofen' },
      { value: 'Medication - Infliximab', text: 'Infliximab' },
      { value: 'Medication - Insulin', text: 'Insulin' },
      { value: 'Medication - Lidocaine', text: 'Lidocaine' },
      { value: 'Medication - Lisinopril', text: 'Lisinopril' },
      { value: 'Medication - Loratadine', text: 'Loratadine' },
      { value: 'Medication - Losartan', text: 'Losartan' },
      { value: 'Medication - Metoprolol', text: 'Metoprolol' },
      { value: 'Medication - Naproxen', text: 'Naproxen' },
      { value: 'Medication - Nedocromil Sodium', text: 'Nedocromil Sodium' },
      { value: 'Medication - Paclitaxel', text: 'Paclitaxel' },
      { value: 'Medication - Penicillin', text: 'Penicillin' },
      { value: 'Medication - Procaine', text: 'Procaine' },
      { value: 'Medication - Propranolol', text: 'Propranolol' },
      { value: 'Medication - Quinolones', text: 'Quinolones' },
      { value: 'Medication - Rituximab', text: 'Rituximab' },
      { value: 'Medication - Sulfonamides', text: 'Sulfonamides' },
      { value: 'Medication - Telmisartan', text: 'Telmisartan' },
      { value: 'Medication - Tetracycline', text: 'Tetracycline' },
      { value: 'Medication - Valsartan', text: 'Valsartan' },
    ],
  },
  {
    value: 'environmental',
    text: 'Environmental',
    children: [
      { value: 'Environmental - Acacia', text: 'Acacia' },
      { value: 'Environmental - Animal Dander', text: 'Animal Dander' },
      { value: 'Environmental - Balsam of Peru', text: 'Balsam of Peru' },
      { value: 'Environmental - Cat Hair', text: 'Cat Hair' },
      { value: 'Environmental - Chromium', text: 'Chromium' },
      { value: 'Environmental - Cobalt chloride', text: 'Cobalt chloride' },
      { value: 'Environmental - Cockroach', text: 'Cockroach' },
      { value: 'Environmental - Cosmetics', text: 'Cosmetics' },
      { value: 'Environmental - Cotton lint', text: 'Cotton lint' },
      { value: 'Environmental - Dog hair', text: 'Dog hair' },
      { value: 'Environmental - Dust mite', text: 'Dust mite' },
      { value: 'Environmental - Feathers', text: 'Feathers' },
      { value: 'Environmental - Fire Ant', text: 'Fire Ant' },
      { value: 'Environmental - Formaldehyde', text: 'Formaldehyde' },
      { value: 'Environmental - Fungicide', text: 'Fungicide' },
      { value: 'Environmental - Gold', text: 'Gold' },
      { value: 'Environmental - Grass Mix', text: 'Grass Mix' },
      { value: 'Environmental - Horse Hair', text: 'Horse Hair' },
      { value: 'Environmental - House dust', text: 'House dust' },
      { value: 'Environmental - Insect bites and stings', text: 'Insect bites and stings' },
      { value: 'Environmental - Iodine', text: 'Iodine' },
      { value: 'Environmental - Java cotton (Kapok)', text: 'Java cotton (Kapok)' },
      { value: 'Environmental - Latex', text: 'Latex' },
      { value: 'Environmental - Mold', text: 'Mold' },
      { value: 'Environmental - Mosquito', text: 'Mosquito' },
      { value: 'Environmental - Nickel', text: 'Nickel' },
      { value: 'Environmental - Perfume & cologne', text: 'Perfume & cologne' },
      { value: 'Environmental - Photographic developers', text: 'Photographic developers' },
      { value: 'Environmental - Pigweed grass', text: 'Pigweed grass' },
      { value: 'Environmental - Pine mix', text: 'Pine mix' },
      { value: 'Environmental - Pollen', text: 'Pollen' },
      { value: 'Environmental - Pyrethrum', text: 'Pyrethrum' },
      { value: 'Environmental - Smoke', text: 'Smoke' },
      { value: 'Environmental - Strong cleaning chemicals', text: 'Strong cleaning chemicals' },
    ],
  },
  {
    value: 'Others',
    text: 'Others',
    children: [
      { value: 'Others', text: 'Others' },
    ],
  },
];
export const ALLERGY_COLLECTION_SIMPLE = ALLERGY_COLLECTION.map(category => ({
  value: category.value,
  text: category.text,
  children: category.children.map(child => child.text),
}));
