<script setup>
import { FORM_MODEL, FORM_FIELDS, PRESCRIPTION } from './constants';
import { computed, ref, onMounted, watch } from 'vue';
import { useCloned } from '@vueuse/core';
import { useMedicines } from '@/modules/medicines/composable';
// useMedicineConfigurations, 
import { usePrescription } from './composables';
import { pickBy, identity } from 'lodash';
import EasySelect from '@/components/commons/easy/EasySelect.vue';

const props = defineProps({
  showLabel: Boolean,
  encounter: String,
  patient: String,
  hideActions: Boolean,
  modalOpen: Boolean,
  tags: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['changed', 'submit', 'success', 'error']);

const { submit: submitPrescription } = usePrescription();

const formFields = FORM_FIELDS;
const loading = ref(false);
const searchingMedicine = ref(false);
const record = ref({});
const recordId = computed(() => record.value.id);
const isEditing = computed(() => !!recordId.value);

const { cloned: model, sync } = useCloned(FORM_MODEL);
const { listItems: listMedicines } = useMedicines();
// const { listItems: listMedicineConfigurations } = useMedicineConfigurations();
// const favoritePrescriptions = ref([]);
const medicines = ref([]);
const allMedicineFixtures = ref([]);

watch(() => props.modalOpen, (val) => {
  val && init();
});

async function init (searchString) {
  try {
    const tags = ['parmazip-minor-ailment'];
    if (props.tags.length) {
      tags.push(...props.tags);
    }

    const query = { tags };

    if (searchString) {
      query.searchString = searchString;
    }

    searchingMedicine.value = true;

    // Fetch medicines based on the query
    medicines.value = await listMedicines(query);

    let allMedsRaw = [...medicines.value];

    // Include medicinesFromModel only if searchString is falsy
    if (!searchString) {
      const medicinesFromModel = model.value.map((med) => ({
        ...med,
        favorite: false,
      }));
      allMedsRaw = [...allMedsRaw, ...medicinesFromModel];
    }

    // Prepare allMedicineFixtures
    allMedicineFixtures.value = allMedsRaw
      .map((med) => ({
        label: med.genericName,
        value: med.genericName,
      }))
      .filter((med) => med.label && med.value);

  } catch (e) {
    console.error('Something went wrong. Try again later.');
  } finally {
    searchingMedicine.value = false;
  }
}

// The API doesn't like when you pass it fields containing nothing, so we delete empty fields
function removeEmptyProperties (arr) {
  // Create a new array with objects that have empty string properties removed
  return arr.map((obj) => {
    const newObj = JSON.parse(JSON.stringify(obj)); // Create a copy of the original object

    if (obj.startedAt) newObj.startedAt = new Date(obj.startedAt).getTime();
    if (obj.endedAt) newObj.endedAt = new Date(obj.endedAt).getTime();

    for (const key in newObj) {
      if (Object.hasOwn(newObj, key) && newObj[key] === '') {
        delete newObj[key]; // Delete properties with empty strings
      }
    }

    return newObj;
  });
}

const submit = async () => {
  try {
    const payload = {
      items: removeEmptyProperties(toSave.value),
    };

    // Update
    if (isEditing.value) {
      payload.id = recordId.value;
    }

    // Create
    await submitPrescription(payload, { patient: props.patient, encounter: props.encounter });

    resetForm();
    emit('submit');
    emit('success');
  } catch (e) {
    console.error('Something went wrong. Try again later.');
    emit('error', e);
  } finally {
    loading.value = false;
  }
};

function resetForm () {
  record.value = {};
  sync();
}

// Sets value to record for editing purposes
// as well as set value model.text
function setForm (data) {
  if (!data) return;
  record.value = data;
  model.value = data.items.map(item => {
    const newItem = {
      ...item,
      startedAt: item.startedAt ? new Date(item.startedAt).toISOString().split('T')[0] : undefined,
      endedAt: item.endedAt ? new Date(item.endedAt).toISOString().split('T')[0] : undefined,
    };
    return pickBy(newItem, identity);
  });
}

function addNewPrescription () {
  const newPrescription = JSON.parse(JSON.stringify(PRESCRIPTION));
  model.value.push(newPrescription);
}

function removeMedicine (index) {
  model.value.splice(index, 1);
}

const toSave = ref([]);
watch(model, (val) => {
  const items = val.map((medicine) => {
    return {
      ...medicine,
      genericName: medicine.genericName || '',
      ...(medicine.favorite && {
        brandName: medicine.brandName,
        formulation: medicine.formulation,
        dispense: medicine.dispense,
        frequency: medicine.frequency,
        dosageSig: medicine.dosageSig,
        note: medicine.note,
      }),
    };
  });

  toSave.value = items;
  emit('changed', items);
}, { deep: true });

defineExpose({
  resetForm,
  setForm,
  submit,
});
</script>

<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex flex-col gap-4 min-h-[400px]">
      <div v-for="(fields, index) in model" :key="index">
        <div class="flex justify-between items-center mb-4">
          <span class="text-primary font-bold text-md">Medicine {{ index + 1 }}</span>
          <div class="flex gap-2">
            <button type="button" class="btn btn-sm btn-circle" @click="removeMedicine(index)">
              <i class="las la-trash text-2xl text-error"></i>
            </button>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 p-4 rounded-xl bg-neutral-50 border border-gray-300 shadow-sm">
          <div
            v-for="(formField, i) in formFields" :key="i"
            class="form-control w-full"
            :class="{ 'col-span-2': formField.field === 'note' || formField.field === 'genericName', 'hidden': formField.field !== 'genericName' && fields['genericName']?.length === 0 }"
          >
            <label class="label">
              <span class="label-text">{{ formField.label }}</span>
            </label>

            <template v-if="formField.field === 'genericName'">
              <EasySelect
                v-model="fields[formField.field]"
                item-value="genericName"
                async-search
                :options="allMedicineFixtures"
                :loading="searchingMedicine"
                @search="v => init(v)"
              />
            </template>

            <template v-if="formField.type === 'text'">
              <input
                v-model="fields[formField.field]"
                type="text"
                class="input input-bordered w-full focus:input-neutral"
                :placeholder="formField.placeholder"
              />
            </template>

            <template v-if="formField.type === 'number'">
              <input
                v-model="fields[formField.field]"
                type="number"
                min="0"
                class="input input-bordered w-full focus:input-neutral"
                :placeholder="formField.placeholder"
              />
            </template>

            <template v-if="formField.type === 'select' && formField.field !== 'genericName'">
              <select
                v-model="fields[formField.field]"
                class="select select-bordered w-full focus:select-neutral"
              >
                <option value="" selected disabled>{{ formField.placeholder }}</option>
                <option :value="form.value" v-for="(form, i) in formField.options" :key="i">{{ form.text }}</option>
              </select>
            </template>

            <template v-if="formField.type === 'date'">
              <input
                v-model="fields[formField.field]"
                type="date"
                class="input input-bordered w-full focus:input-neutral"
                :placeholder="formField.placeholder"
              />
            </template>

            <template v-if="formField.type === 'textarea'">
              <textarea
                v-model="fields[formField.field]"
                class="textarea textarea-bordered col-span-2 focus:textarea-neutral"
                :placeholder="formField.placeholder"
              />
            </template>
          </div>
        </div>
      </div>
      <button type="button" class="btn bg-white border-gray-300 font-medium" @click="addNewPrescription">
        <i class="las la-plus text-xl"></i>
        Add Medicine
      </button>
   </div>
   <div v-if="!hideActions" class="flex gap-4 justify-end">
      <button
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
      >
        Submit
      </button>
    </div>
</form>
</template>

<style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
