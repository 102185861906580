<script setup>
import { computed, reactive, ref, onMounted } from 'vue';
import { useAssessment } from './composables';
import { useFixtures } from '@/composables/use-fixtures';

const props = defineProps({
  showLabel: Boolean,
});
const emit = defineEmits(['submit', 'success', 'error']);
defineExpose({ resetForm, setForm });


const { submit: submitRecord } = useAssessment();
const { findOneFixture, icd10codes, getICD10, cptCodes, getCPTCodes  } = useFixtures();

const loading = ref(false);
const record = ref({});
const recordId = computed(() => record.value.id);
const isEditing = computed(() => !!recordId.value);

const model = reactive({
  diagnosis: '',
  icd10Code: {},
  cptCode: {},
  text: '',
});

const formSubmittable = computed(() => {
  return !!model.diagnosis;
});

onMounted(() => {
  getICD10();
  getCPTCodes();
});

const submit = async () => {
  try {
    loading.value = true;

    const payload = {
      text: model.text,
      diagnosis: model.diagnosis,
    };

    if (model?.icd10Code) {
      console.warn(model?.icd10Code);
      payload.icd10 = model?.icd10Code?.code;
      payload.diagnosisCode = model?.icd10Code?.code;
      payload.diagnosisText = model?.icd10Code?.text;
    }

    if (model?.cptCode) {
      payload.recommendedProcedures = [{
        id: model?.cptCode.id,
        text: model?.cptCode.name,
        coding: {
          system: 'cpt',
          code: model?.cptCode.code,
          text: model?.cptCode.name,
        },
      }];
    }

    // Update
    if (isEditing.value) {
      payload.id = recordId.value;
    }

    // Create
    await submitRecord(payload);

    resetForm();
    emit('submit');
    emit('success');
  } catch (e) {
    console.error(e);
    emit('error', e);
  } finally {
    loading.value = false;
  }
};

function resetForm () {
  record.value = {};
  model.diagnosis = '';
  model.text = '';
  model.icd10Code = {};
  model.cptCode = {};
}

// Sets value to record for editing purposes
// as well as set value model.text
async function setForm (data, diffDiagData) {
  if (!data) return;
  record.value = data;
  model.text = data.text;
  model.diagnosis = data.diagnosis;
  
  if (data?.icd10) {
    const payload = {
      code: data?.icd10,
    };
    if (data?.diagnosisText) payload.text = data?.diagnosisText;
    model.icd10Code = await findOneFixture(payload, 'icd10');
  }

  if (data?.recommendedProcedures?.length) {
    const cptCode = data?.recommendedProcedures[0].coding;
    if (cptCode) {
      const payload = {
        code: cptCode.code,
      };
      model.cptCode = await findOneFixture(payload, 'cpt');
    }
  }
}
</script>

<template>
  <form @submit.prevent="submit" class="flex flex-col gap-6">

    <label class="form-control">
      <div class="label">
        <span class="label-text">Diagnosis <span class="text-error">*</span></span>
      </div>
      <textarea v-model="model.diagnosis" class="textarea textarea-bordered h-24" placeholder="Write here"></textarea>
    </label>

    <label class="form-control">
      <div class="label">
        <span class="label-text">ICD-10 Codes</span>
      </div>
      <select v-model="model.icd10Code" class="select select-bordered w-full">
        <option value="" disabled selected>Select ICD-10 Code</option>
        <option v-for="code in icd10codes" :key="code.code" :value="code">{{ code.code }} - {{ code.text }}</option>
      </select>
    </label>

    <label class="form-control">
      <div class="label">
        <span class="label-text">CPT Codes</span>
      </div>
      <select v-model="model.cptCode" class="select select-bordered w-full">
        <option value="" disabled selected>Select CPT Code</option>
        <option v-for="code in cptCodes" :key="code.code" :value="code">{{ code.code }} - {{ code.name }}</option>
      </select>
    </label>

    <label class="form-control">
      <div class="label">
        <span class="label-text">Notes</span>
      </div>
      <textarea v-model="model.text" class="textarea textarea-bordered h-24" placeholder="Write here"></textarea>
    </label>

    <div class="flex items-center justify-end gap-4">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>



<!-- <style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style> -->
