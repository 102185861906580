import { ref } from 'vue';
import { useSdk } from './use-mycure';

export function useMedicines () {
  try {
    const sdk = useSdk();
    const medicines = ref([]);

    const init = async () => {
      medicines.value = await sdk?.list('medicines');
      medicines.value.data = medicines.value.data.sort((a, b) => a.genericName > b.genericName);
    };

    return {
      medicines,
      init,
    };
  } catch (e) {
    console.error(e);
  }
}
