<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :hide-options="hideOptions" :record="record">
      <div class="flex justify-between items-center p-0">
        <span class="text-[12px] text-[#a3a3a3]">Created at {{createdAt}}</span>
        <div class="dropdown dropdown-end">
          <label tabindex="0" class="btn btn-sm btn-ghost btn-circle m-1">
            <i class="las la-ellipsis-v text-xl"></i>
          </label>
          <ul tabindex="0" class="dropdown-content z-[1] menu p-2 shadow bg-slate-100 rounded-box w-52">
            <li>
              <nuxt-link @click="edit">
                <i class="las la-edit"></i>
                Edit
              </nuxt-link>
            </li>
            <li>
              <nuxt-link @click="remove">
                <i class="las la-trash"></i>
                Delete
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <template v-for="(field, index) in formFields" :key="index">
          <div v-if="record[field.field]" class="flex flex-col">
            <span class="text-sm">{{field.label}}</span>
            <span class="text-sm">{{record[field.field]}}</span>
          </div>
        </template>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { FORM_FIELDS } from './constants';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      emit('remove', props.record);
    }

    return {
      createdAt,
      formFields: FORM_FIELDS,
      edit,
      remove,
    };
  },
};
</script>
