<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-full`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField?.rules?.required" class="text-error">*</span></span>
            </span>
          </div>

          <template v-if="formField.type === 'input'">
            <div class="flex flex-col gap-2">
              <div class="flex gap-4 flex-col card">
                <div class="flex flex-row gap-2">
                  <button class="btn" @click.prevent.stop="openDialog">
                    <i class="las la-upload text-xl"></i>
                    Upload
                  </button>
                  <button class="btn" @click.prevent.stop="openDrawDialog">
                    <i class="las la-paint-brush text-xl"></i>
                    Draw
                  </button>
                </div>
              </div>
              <div v-if="model[formField.field] && model[formField.field]?.length > 0" class="flex flex-row gap-2 w-full">
                <Viewer :images="model[formField.field]" @inited="inited" class="viewer flex flex-row gap-2 flex-wrap" ref="viewer">
                  <template #default="scope">
                    <div v-for="(src, index) in scope.images" :key="src" class="flex flex-col join join-vertical">
                      <img :src="src" :key="src" class="max-w-[200px] max-h-[200px] join-item hover:cursor-pointer">
                      <button :key="src" class="btn btn-xs btn-outline hover:btn-error join-item h-[10px]"
                        @click.prevent="openConfirmDialog(model[formField.field], index)"
                      >
                        <i class="las la-times text-xl"></i>
                      </button>
                    </div>
                    {{scope.options}}
                  </template>
                </Viewer>
              </div>
              <div v-else class="border-[1px] border-neutral-200 p-4 w-full rounded-lg">No attachments available.</div>
            </div>
          </template>

          <template v-if="formField.type === 'textarea'">
            <textarea
              v-model="model[formField.field]"
              :placeholder="formField.placeholder"
              class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
            ></textarea>
          </template>
          <template v-if="formField.type === 'text'">
            <input
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Input Text'"
              class="flex-auto input input-bordered focus:input-neutral"
              :type="formField.text"
              :disabled="loading"
            />
          </template>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        Submit
      </button>
    </div>
  </form>

  <ClientOnly>
    <teleport to="body">
      <div v-if="isRevealed" class="modal modal-open">
        <div class="modal-box w-full">
          <div class="modal-card">
            <div class="card-title">Confirm Delete</div>
            <div class="card-body px-0">
              <span>
                Are you sure you want to delete this attachment?
              </span>
            </div>
            <div class="card-actions flex flex-row justify-end">
              <button class="btn btn-md btn-[#424242]" @click="cancel">Cancel</button>
              <button class="btn btn-md btn-error text-white hover:bg-[#ef4444]" @click="confirm">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </teleport>
  </ClientOnly>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { computed, reactive, ref, inject, watch } from 'vue';
import { useFileDialog, useConfirmDialog } from '@vueuse/core';
import { useAuthentication } from '@/composables/use-authentication';
import { useStorage } from '@/composables/use-storage';
import { union, clone, isEmpty } from 'lodash';
import { useAttachments } from './composables';
import 'viewerjs/dist/viewer.css';
import { component as Viewer } from 'v-viewer';

export default {
  props: {
    showLabel: Boolean,
    type: String,
    subtype: String,
    title: String,
  },
  components: {
    Viewer,
  },
  emits: ['showSecondModal', 'success', 'error'],
  setup (props, { emit }) {
    const {
      currentUser,
    } = useAuthentication();
    const { submit: submitAttachments } = useAttachments();
    const {
      upload: uploadFile,
      // locateFile
    } = useStorage();
    const {
      // files,
      open: openDialog,
      // reset: resetDialog,
      onChange,
    } = useFileDialog({
      accept: 'image/*',
    });
    const {
      isRevealed,
      reveal,
      confirm,
      cancel,
    } = useConfirmDialog();

    const openConfirmDialog = async (attachments, index) => {
      const {
        data, // eslint-disable-line
        isCanceled,
      } = await reveal();
      if (!isCanceled) {
        // User confirmed, now you can perform the delete action
        await confirmDeleteAttachment(attachments, index);
      }
    };

    const confirmDeleteAttachment = async (attachments, index) => {
      if (!attachments?.length) return;
      const attachmentList = clone(attachments);
      attachmentList.splice(index, 1);
      model.attachmentURLs = attachmentList;
    };

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const mountState = ref(false);
    const savedImage = inject('secondModalData');
    watch(savedImage, async (newVal) => {
      const img = [newVal];
      model.attachmentURLs = union(model.attachmentURLs, img);
    }, { deep: true });

    const model = reactive(FORM_MODEL);
    onChange(async (files) => {
      const rawFilesUrl = await Promise.all(Array.from(files).map(file => {
        return new Promise((resolve, reject) => {
          const reader = new window.FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = (error) => {
            reject(new Error(error));
          };
          reader.readAsDataURL(file);
        });
      }));
      model.attachmentURLs = union(model.attachmentURLs, rawFilesUrl);
    });

    const onChangeFile = (callback) => {
      callback();
    };

    const viewer = ref(null);
    const inited = (viewer) => {
      viewer.value = viewer;
    };

    const submit = async () => {
      try {
        loading.value = true;

        // SAVE FILES
        const existingAttachments = model.attachmentURLs.filter(attachment => attachment?.startsWith('https://'));
        const rawAttachments = model.attachmentURLs.filter(attachment => !attachment?.startsWith('https://'));
        const filesUrl = await Promise.all(rawAttachments.map(async (url) => {
          const link = await uploadFile({ dataURI: url, owner: currentUser.id });
          return link.url;
        }));
        model.attachmentURLs = union(existingAttachments, filesUrl);

        const payload = Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        );
        if (isEditing.value) {
          payload.id = recordId.value;
        }
        // Create
        await submitAttachments(payload);
        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    const openDrawDialog = () => {
      mountState.value = !mountState.value;
      emit('showSecondModal', {
        title: 'Draw Image',
        // subtitle: 'Upload an image of at most 1000x1000 for best quality',
        autoMount: mountState.value,
      });
    };
    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key].required && (val === '' || val === null || val === undefined || isEmpty(val))) return false;
        continue;
      }
      return true;
    });

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.attachmentURLs = data.attachmentURLs;
      model.title = data.title;
      model.description = data.description;
    }

    return {
      loading,
      model,
      recordId,
      isEditing,
      formFields: FORM_FIELDS,
      formSubmittable,
      resetForm,
      submit,
      setForm,

      openDialog,
      openConfirmDialog,
      openDrawDialog,
      confirmDeleteAttachment,
      onChangeFile,

      viewer,
      inited,

      isRevealed,
      reveal,
      confirm,
      cancel,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
