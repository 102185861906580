import { computed } from 'vue';
import { useMedicalRecords } from '@/composables/use-medical-records';
import { usePatient } from '@/composables/use-patients';
import { useEncounters } from '@/composables/use-encounters';
import { RECORD_TYPE } from './constants';
import { omit, startCase } from 'lodash';
import { format } from 'date-fns';

function formatMoney (number) {
  return new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(number);
}

export function mapDentalNotes (record) {
  const formattedTeeth = record.teeth?.join(', ');
  const formattedStatus = `${record.status?.abbreviation} - ${record.status?.category}`;
  const formattedStatusColorCoding = record.status?.colorCoding;
  const formattedService = record.formattedService ? `${record.formattedService?.name} - ${formatMoney(record.formattedService?.price)}` : '-';
  const formattedSubtype = startCase(record.subtype);
  const formattedSurfaces = record.surfaces?.map((surface) => startCase(surface.name)).join(', ');

  const dentalNote = {
    ...record,
    formattedTeeth,
    formattedSurfaces,
    formattedStatus,
    formattedStatusColorCoding,
    formattedService,
    formattedSubtype,
  };

  return dentalNote;
}

export function useDentalNote () {
  const {
    medicalRecords,
    create: createMedicalRecord,
    update: updateMedicalRecord,
    remove: removeMedicalRecord,
    listItems: listMedicalRecords,
  } = useMedicalRecords();

  const { patient } = usePatient();
  const { encounter } = useEncounters();

  const records = computed(() => {
    return medicalRecords.value
      .filter((record) => record.type === RECORD_TYPE)
      .map(mapDentalNotes)
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  });

  async function listItems (opts) {
    const query = {
      type: RECORD_TYPE,
      ...opts,
    };
    const items = await listMedicalRecords(query);
    return items.map((item) => mapDentalNotes(item, { previousEncounter: true }));
  }

  const submit = async (data) => {
    if (data?.id) {
      const id = data.id;
      const payload = { ...omit(data, ['id', 'subtype']) };
      await updateMedicalRecord(id, payload);
    } else {
      const payload = data;
      payload.type = RECORD_TYPE;
      payload.encounter = encounter.value?.id;
      payload.patient = patient.value?.id;
      await createMedicalRecord(payload);
    }
  };

  const remove = async ({ id }) => {
    await removeMedicalRecord(id);
  };

  return {
    medicalRecords,
    records,
    submit,
    remove,
    listItems,
  };
}
