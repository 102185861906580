export const RECORD_TYPE = 'lab-test-result';
export const ORDER_TYPE = 'lab-test-order';
export const TEST_TYPE = 'laboratory';

export const FORM_FIELDS = [
  {
    label: 'Laboratory Order',
    field: 'order',
    update: true,
    type: 'select',
    multiple: false,
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Results',
    field: 'results',
    update: true,
    type: 'textarea',
    multiple: false,
    rules: {
      required: false,
      type: 'string',
    },
  },
  // {
  //   label: 'Attachments',
  //   field: 'attachmentsURL',
  //   update: true,
  //   type: 'input',
  //   rules: {
  //     required: false,
  //     type: 'input',
  //   },
  // },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
