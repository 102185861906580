<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div>
        <div class="grid grid-cols-2 gap-4">
          <!-- <div class="flex flex-col col-span-1 gap-2">
            <span class="text-sm font-medium">Family Member</span>
            <span class="text-sm text-neutral-500">{{familyMember || '-'}}</span>
          </div> -->
          <!-- <div class="flex flex-col col-span-1 gap-2">
            <span class="text-sm font-medium">Deceased?</span>
            <span class="text-sm text-neutral-500">{{deceased || '-'}} <span v-if="relativeDeceasedAt"> - {{ relativeDeceasedAt }}</span></span>
          </div> -->
          <div class="flex flex-col col-span-1 gap-2">
            <span class="text-sm font-medium">Relationship</span>
            <span class="text-sm text-neutral-500">{{relationship || '-'}}</span>
          </div>
          <!-- <div class="flex flex-col col-span-1 gap-2">
            <span class="text-sm font-medium">Date of Birth</span>
            <span class="text-sm text-neutral-500">{{relativeDateOfBirth || '-'}}</span>
          </div> -->
          <div class="flex flex-col col-span-1 gap-2">
            <span class="text-sm font-medium">Medical Condition</span>
            <span class="text-sm text-neutral-500">{{medicalCondition}}</span>
          </div>
          <!-- <div v-if="ageAtDeath" class="flex flex-col col-span-1 gap-2">
            <span class="text-sm font-medium">Age at Death</span>
            <span class="text-sm text-neutral-500">{{ageAtDeath || '-'}} y/o</span>
          </div> -->
          <div class="flex flex-col col-span-full gap-2">
            <span class="text-sm font-medium">Notes</span>
            <span class="text-sm text-neutral-500">{{notes || '-'}}</span>
          </div>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import { useFamilyHistory } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeFamilyHistory } = useFamilyHistory();
    const relative = computed(() => props.record.relativeData);
    const ageAtDeath = ref(null);
    const familyMember = ref(null);
    const medicalCondition = computed(() => props.record.medicalCondition);
    const relationship = computed(() => props.record.relationship);
    const deceased = computed(() => relative.value?.deceasedAt ? 'Yes' : 'No');
    const notes = computed(() => props.record.notes);
    const createdAt = computed(() => format(new Date(props.record?.createdAt), 'MM/dd/yyyy hh:mm a'));
    const relativeDeceasedAt = computed(() => props.record.formattedRelativeDeceasedAt);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeFamilyHistory({ id: props.record.id });
    }

    return {
      createdAt,
      familyMember,
      medicalCondition,
      relationship,
      deceased,
      ageAtDeath,
      notes,
      relativeDeceasedAt,
      edit,
      remove,
    };
  },
};
</script>
