<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex flex-col">
      <div class="grid grid-cols-2 gap-4">
        <!-- 1st Column -->
        <div class="flex flex-col gap-x-2 mb-1">
          <!-- Group -->
          <div class="form-control">
            <label class="label">
              <span class="label-text">Age Group</span>
            </label>
            <!-- TODO: I think group should be a select here -->
            <input type="text" placeholder="e.g. Children" class="input input-bordered" v-model="model.group" />
          </div>
        </div>
        <!-- 2nd Column -->
        <div class="flex flex-col gap-2 mb-1">
          <!-- Category -->
          <div class="form-control">
            <label class="label">
              <span class="label-text">Category</span>
            </label>
            <input type="text" placeholder="e.g. 1 to 12 years old" class="input input-bordered" v-model="model.category" />
          </div>
        </div>
      </div>

      <!-- Name -->
      <div class="form-control">
        <label class="label">
          <span class="label-text">Name</span>
        </label>
        <input type="text" placeholder="Enter patient name here (e.g. Doe, John)" class="input input-bordered w-full" v-model="model.name" />
      </div>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { FORM_MODEL, UPDATE_FIELDS } from './constants';
import { computed, reactive, ref } from 'vue';
import { usePedia } from './composables';

export default {
  props: {
    showLabel: Boolean,

  },
  setup (props, { emit }) {
    const { submit: submitPe } = usePedia();
    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);
    const respirationType = ref('CPAP');

    const formSubmittable = computed(() => validate(model));

    function validate (obj) {
      for (const key in obj) {
        if (
          Object.prototype.hasOwnProperty.call(obj, key) &&
          obj[key] !== undefined &&
          obj[key] !== null &&
          obj[key] !== ''
        ) {
          return true;
        }
      }
      return false;
    }

    const submit = async () => {
      try {
        const payload = { ...model };

        // The API doesn't like when you pass it fields containing nothing, so we delete empty fields
        Object.keys(payload).forEach(key => {
          if (!payload[key]) {
            delete payload[key];
          }
        });

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitPe(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      const fields = Object.keys(data);
      fields.forEach(field => {
        model[field] = data[field];
      });
    }

    return {
      respirationType,
      model,
      formSubmittable,
      recordId,
      isEditing,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
