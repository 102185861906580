// import { past50Years } from '../shared/utils';

export const RECORD_TYPE = 'birth-history';

const METHODS_OF_DELIVERY = [
  'Unknown',
  'Vaginal Delivery',
  'Caesarian Section',
  'Implant',
  'Vaginal Birth After Cesarian',
  'Vacuum Extraction',
  'Forceps Delivery',
  'Others',
];

export const FORM_FIELDS = [
  {
    label: 'Method of Delivery',
    field: 'methodOfDelivery',
    update: true,
    type: 'select',
    placeholder: 'e.g Vacuum Extraction',
    options: METHODS_OF_DELIVERY,
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Delivery Date',
    field: 'deliveredAt',
    update: true,
    type: 'date',
    placeholder: 'e.g. 2017-10-23',
    rules: {
      required: false,
      type: 'number',
    },
  },
  {
    label: 'Attended By',
    field: 'attendedBy',
    placeholder: 'e.g. Midwife',
    update: true,
    type: 'text',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Complications',
    field: 'complications',
    placeholder: 'e.g. Urinary Tract Infection',
    update: true,
    type: 'text',
    rules: {
      required: false,
      type: 'string',
    },
  },
  {
    label: 'Notes',
    field: 'notes',
    update: true,
    type: 'textarea',
    placeholder: 'Enter additional pertinent birth history (e.g. Patient was underweight on birth)',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
