<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :hide-options="hideOptions" :record="record">
      <div class="grid grid-cols-2 gap-4">
        <div v-if="record.vaccine" class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Vaccine</span>
          <span class="text-sm text-neutral-500">{{record.vaccine}}</span>
        </div>
        <div v-if="record.injectionSite" class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Route of Administration</span>
          <span class="text-sm text-neutral-500">{{record.injectionSite}}</span>
        </div>
        <div v-if="record.administeredBy" class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Administered By</span>
          <span class="text-sm text-neutral-500">{{record.administeredBy}}</span>
        </div>
        <div v-if="administeredAt" class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Administered On</span>
          <span class="text-sm text-neutral-500">{{administeredAt}}</span>
        </div>
        <div v-if="record.lotNo" class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Lot No</span>
          <span class="text-sm text-neutral-500">{{record.lotNo}}</span>
        </div>
        <div v-if="expiresAt" class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Expiration Date</span>
          <span class="text-sm text-neutral-500">{{expiresAt}}</span>
        </div>
        <div v-if="record.note" class="col-span-2 flex flex-col gap-2">
          <span class="text-sm font-medium">Remarks</span>
          <span class="text-sm text-neutral-500">{{record.note}}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useVaccinations } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: true,
    },
  },
  setup (props, { emit }) {
    const { remove: removeVaccinations } = useVaccinations();

    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const expiresAt = computed(() => {
      if (!props.record.expiresAt) return;
      return format(new Date(props.record.expiresAt), 'MM/dd/yyyy');
    });
    const administeredAt = computed(() => {
      if (!props.record.administeredAt) return;
      return format(new Date(props.record.administeredAt), 'MM/dd/yyyy');
    });

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeVaccinations({ id: props.record.id });
    }

    return {
      createdAt,
      expiresAt,
      administeredAt,
      edit,
      remove,
    };
  },
};
</script>
