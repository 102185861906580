<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-2 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-${formField.type === 'textarea' ? 'full' : '1'}`">

          <div class="flex items-center justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField.rules?.required" class="text-error">*</span></span>
            </span>
          </div>

          <template v-if="formField.type === 'select' && formField.field !== 'medicalCondition'">
            <select
              v-if="formField.subtype === 'shallow'"
              v-model="model[formField.field]"
              class="select select-bordered w-full"
            >
              <option value="" disabled selected>{{ formField.placeholder }}</option>
              <template v-for="(option, index) in formField.options" :key="index">
                <option :value="option">{{option}}</option>
              </template>
            </select>

            <select
              v-if="formField.subtype === 'deep'"
              v-model="model[formField.field]"
              class="select select-bordered w-full"
            >
              <option value="" disabled selected>{{ formField.placeholder }}</option>
              <template v-for="(label, index) in formField.options" :key="index">
                <option :value="label.value">{{label.name}}</option>
              </template>
            </select>
          </template>

          <template v-if="formField.type === 'text'">
            <input
              v-model="model[formField.field]"
              class="flex-auto input input-bordered focus:input-neutral"
              min="0"
              max="120"
              :placeholder="formField.placeholder || 'Input Text'"
              :type="formField.subtype"
            />
          </template>

          <template v-if="formField.type === 'date'">
            <input
              v-model="model[formField.field]"
              type="date"
              class="input input-bordered w-full focus:input-neutral"
              :placeholder="formField.placeholder"
              :required="model.deceased"
              :disabled="!model.deceased"
            />
          </template>

          <template v-if="formField.type === 'textarea'">
            <textarea
              v-model="model[formField.field]"
              placeholder="Provide pertinent family history (e.g. Patient's relative has a history of diabetes.)"
              class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
            ></textarea>
          </template>

          <template v-if="formField.type === 'select' && formField.field === 'medicalCondition'">
            <div class="w-full">
              <select v-model="model[formField.field]" class="select select-bordered w-full">
                <option value="" disabled selected>{{ formField.placeholder }}</option>
                <template v-for="(option, index) in formField.options" :key="index">
                  <option :value="option.value">{{option.text}}</option>
                </template>
              </select>
            </div>
          </template>

          </div>
          </template>
    </div>

    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ editState }}
      </button>
    </div>
  </form>
</template>

<script>
import { computed, reactive, ref, watch } from 'vue';
import { format } from 'date-fns';
import { pick, isEmpty } from 'lodash';
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS } from './constants';
import { useFamilyHistory } from './composables';
import { usePersonalDetails } from '@/composables/use-personal-details';

export default {
  props: {
    showLabel: Boolean,
    patientId: String,
    encounterId: String,
    facilityId: String,
  },
  setup (props, { emit }) {
    const { create: createPersonalDetails, updateWithPatientId } = usePersonalDetails();

    const { submit: submitFamilyHistory } = useFamilyHistory();
    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);
    const editState = computed(() => isEditing.value ? 'Save Changes' : 'Submit');
    const model = reactive(FORM_MODEL);

    const formSubmittable = computed(() => {
      const requiredFields = ['relationship', 'medicalCondition'];
      if (model.deceased === true) requiredFields.push('deceasedAt');
      const hasRequiredFields = requiredFields.every(field => !isEmpty(model[field]));
      return hasRequiredFields;
    });

    watch(model, (val) => {
      if (val.deceased === false) {
        model.deceasedAt = '';
      }
    }, { deep: true });

    async function submit () {
      try {
        const payload = Object.fromEntries(Object.entries(model).filter(([key, value]) => Boolean(value)));

        if (model.deceased === false) {
          payload.$unset = {
            deceasedAt: 1,
          };
        }

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        const familyHistoryPayload = pick(payload,
          ['id', 'relative', 'deceased', 'type', 'medicalCondition',
            'relationship', 'deathCause', 'source', 'notes']);

        const personalDetailsPayload = pick(payload,
          ['relationship', 'deceasedAt', 'facility']);

        if (personalDetailsPayload?.relationship) {
          personalDetailsPayload.relatedPersonRelationship = personalDetailsPayload?.relationship;
          delete personalDetailsPayload.relationship;
        }
        // Create
        if (!isEditing.value) {
          const personalDetails = await createPersonalDetails(personalDetailsPayload);
          familyHistoryPayload.relative = personalDetails?.id;
        } else {
          const relativeId = record.value?.relative;
          await updateWithPatientId(personalDetailsPayload, relativeId);
        }

        await submitFamilyHistory(familyHistoryPayload, { patientId: props.patientId, encounterId: props.encounterId, facilityId: props.facilityId });

        resetForm();

        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        if (field === 'nameOfFamilyMember') model[field] = {};
        else model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    async function setForm (data) {
      if (!data) return;

      record.value = data;

      model.deceased = !!data.relativeData?.deceasedAt;
      model.deceasedAt = data.relativeData?.deceasedAt && format(new Date(data.relativeData?.deceasedAt), 'yyyy-MM-dd');

      model.relationship = data.relationship;
      model.medicalCondition = data.medicalCondition;
      model.notes = data.notes;
    }

    return {
      loading,
      model,
      recordId,
      isEditing,
      editState,
      formSubmittable,
      formFields: FORM_FIELDS,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>

<style scoped>
option[value=""]:disabled {
  color: gray;
}
</style>
