export const RECORD_TYPE = 'dental-history';

export const FORM_FIELDS = [
  {
    label: 'Gingivae',
    model: 'gingivae',
    type: 'select',
    placeholder: 'Select one',
    options: [
      'Healthy',
      'Midly inflamed',
      'Severely inflamed',
    ],
  },
  {
    label: 'Palate',
    model: 'palate',
    type: 'text',
    placeholder: 'Type here',
  },
  {
    label: 'Frenum',
    model: 'frenum',
    type: 'text',
    placeholder: 'Type here',
  },
  {
    label: 'Profile',
    model: 'profile',
    type: 'select',
    placeholder: 'Select one',
    options: [
      'Class I',
      'Class II',
      'Class III',
    ],
  },
  {
    label: 'Hygiene',
    model: 'hygiene',
    type: 'select',
    placeholder: 'Select one',
    options: [
      'Good',
      'Fair',
      'Poor',
    ],
  },
  {
    label: 'Abraisions',
    model: 'abrasions',
    type: 'text',
    placeholder: 'Type here',
  },
  {
    label: 'Receded Gums',
    model: 'recededGums',
    type: 'text',
    placeholder: 'Type here',
  },
  {
    label: 'Mouth Opening',
    model: 'mouthOpening',
    type: 'text',
    placeholder: 'Type here',
  },
  {
    label: 'Oropharynx',
    model: 'oropharynx',
    type: 'text',
    placeholder: 'Type here',
  },
  {
    label: 'Teeth Discoloration',
    model: 'teethDiscoloration',
    type: 'select',
    placeholder: 'Select one',
    options: [
      'Intrinsic',
      'Extrinsic',
    ],
  },
  {
    label: 'Calculus',
    model: 'calculus',
    type: 'select',
    placeholder: 'Select one',
    options: [
      'Slight',
      'Moderate',
      'Heavy',
    ],
  },
  {
    label: 'Tongue',
    model: 'tongue',
    type: 'select',
    placeholder: 'Select one',
    options: [
      'Small',
      'Normal',
      'Large',
      'Red',
      'Pink',
    ],
  },
  {
    label: 'Gag Flex',
    model: 'gagFlex',
    type: 'text',
    placeholder: 'Type here',
  },
  {
    label: 'Previous Dentist',
    model: 'prevDentist',
    type: 'text',
    placeholder: 'Type here',
  },
  {
    label: 'Previous Dentist Contanct No.',
    model: 'prevDentistContactNo',
    type: 'text',
    placeholder: 'Type here',
  },
];

export const TEETH_CONDITIONS = {
  Cracked: false,
  'Sensitive to sweets': false,
  'Sensitive to sour foods': false,
  'Sensitive to percussions': false,
  'Sensitive to hot or cold food or drinks': false,
};

export const HABITS = {
  'Drinks tea, coffee, or wine': false,
  'Smokes / uses tobacco products': false,
};

export const COMPLICATIONS = {
  'Bleeds when brusing or flossing': false,
  'Bleeds profusely after tooth extraction': false,
  'Delayed healing after tooth extraction': false,
};