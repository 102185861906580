<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="flex flex-col gap-4">
        <!-- <div class="flex flex-col gap-2">
          <p class="text-sm font-medium">Complaint</p>
          <p class="text-sm text-neutral-500 p-0">{{text}}</p>
        </div> -->
        <p class="text-sm font-medium">Severity: <span class="text-sm text-neutral-500">{{painLevel}}</span></p>
        <div v-if="signsAndSymptoms?.length > 0" class="grid grid-cols-2">
          <span class="text-sm font-medium pb-2">Signs</span>
          <!-- <span class="text-sm font-medium pb-2">Duration</span> -->
          <template v-for="(item, index) in signsAndSymptoms" :key="index">
            <span class="text-sm truncate md:truncate-none pr-2 text-neutral-500">{{item.text}}</span>
            <!-- <span class="text-sm text-neutral-500">{{item.duration}}</span> -->
          </template>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useChiefComplaint } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeChiefComplaint } = useChiefComplaint();

    const text = computed(() => {
      const text = props.record.text;
      return text.charAt(0).toUpperCase() + text.slice(1);
    });
    const painLevel = computed(() => {
      const level = props.record.painLevel;
      return level;
    });
    const events = computed(() => props.record.events);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const signsAndSymptoms = computed(() => props.record.signsAndSymptoms);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeChiefComplaint({ id: props.record.id });
    }

    return {
      createdAt,
      text,
      painLevel,
      signsAndSymptoms,
      events,
      edit,
      remove,
    };
  },
};
</script>
