import WholeBodyFront from '@/assets/images/drawings/body-man-right.png';
import WholeBodyBack from '@/assets/images/drawings/body-man-left.png';
import WholeBodyKid from '@/assets/images/drawings/body-kid.png';
import Head from '@/assets/images/drawings/head.png';
import Face from '@/assets/images/drawings/face.png';
import HandsArms from '@/assets/images/drawings/arms.png';
import TorsoFront from '@/assets/images/drawings/front-torso.png';
import TorsoBack from '@/assets/images/drawings/back-torso.png';
import PainQuadrant from '@/assets/images/drawings/pain-quadrant.png';
import LegsFeet from '@/assets/images/drawings/legs.png';
import Sinus from '@/assets/images/drawings/sinus.png';
import EarRight from '@/assets/images/drawings/ear-right.png';
import EarLeft from '@/assets/images/drawings/ear-left.png';
import ThroatSide from '@/assets/images/drawings/throat-left.png';
import OralCavity from '@/assets/images/drawings/friedman.png';
import NasalCavity from '@/assets/images/drawings/nasal.png';
import Neck from '@/assets/images/drawings/neck.png';
import NeckFront from '@/assets/images/drawings/neck-front.png';
import Nose from '@/assets/images/drawings/nose.png';
import Otology from '@/assets/images/drawings/otology.png';
import TonsillarHypertology from '@/assets/images/drawings/tonsillar.png';
import VocalChords from '@/assets/images/drawings/vocal-chords.png';
import FemaleReproductive from '@/assets/images/drawings/uterus.png';
import MaleReproductive from '@/assets/images/drawings/male-rep.png';

export const RECORD_TYPE = 'attachment';
export const FORM_FIELDS = [
  {
    label: 'Attachment',
    field: 'attachmentURLs',
    update: true,
    type: 'input',
    rules: {
      required: true,
      type: 'string',
    },
  },
  {
    label: 'Attachment Title',
    field: 'title',
    update: true,
    type: 'text',
    placeholder: 'e.g. Gash images',
    rules: {
      required: true,
      type: 'number',
    },
  },
  {
    label: 'Description',
    field: 'description',
    update: true,
    type: 'textarea',
    placeholder: 'e.g. Gash spots',
    rules: {
      required: false,
      type: 'string',
    },
  },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.default || '';
  return acc;
}, {});

export const UPDATE_FIELDS = FORM_FIELDS.map((field) => field.field);

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});

export const TEMPLATE_LINKS = [
  { header: 'General', title: 'Whole Body (Front)', src: WholeBodyFront },
  { header: 'General', title: 'Whole Body (Back)', src: WholeBodyBack },
  { header: 'General', title: 'Whole Body (Kid)', src: WholeBodyKid },
  { header: 'Derma', title: 'Head', src: Head },
  { header: 'Derma', title: 'Face', src: Face },
  { header: 'Derma', title: 'Hands/Arms', src: HandsArms },
  { header: 'Derma', title: 'Torso (Front)', src: TorsoFront },
  { header: 'Derma', title: 'Torso (Back)', src: TorsoBack },
  { header: 'Derma', title: 'Pain Quadrant', src: PainQuadrant },
  { header: 'Derma', title: 'Legs/Feet', src: LegsFeet },
  { header: 'ENT', title: 'Sinus', src: Sinus },
  { header: 'ENT', title: 'Ear (Right)', src: EarRight },
  { header: 'ENT', title: 'Ear (Left)', src: EarLeft },
  { header: 'ENT', title: 'Throat (Side)', src: ThroatSide },
  { header: 'ENT', title: 'Oral Cavity', src: OralCavity },
  { header: 'ENT', title: 'Nasal Cavity', src: NasalCavity },
  { header: 'ENT', title: 'Neck', src: Neck },
  { header: 'ENT', title: 'Neck Front', src: NeckFront },
  { header: 'ENT', title: 'Nose', src: Nose },
  { header: 'ENT', title: 'Otology', src: Otology },
  { header: 'ENT', title: 'Tonsillar Hypertology', src: TonsillarHypertology },
  { header: 'ENT', title: 'Vocal Chords', src: VocalChords },
  { header: 'Obgyne', title: 'Female Reproductive', src: FemaleReproductive },
  { header: 'Urology', title: 'Male Reproductive', src: MaleReproductive },
];
