<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="grid grid-cols-2 gap-4">
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Hospitalization Date</span>
          <span class="text-sm text-neutral-500">{{hospitalizedAt}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Reason for Hospitalization</span>
          <span class="text-sm text-neutral-500">{{record?.reason || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Hospital Name</span>
          <span class="text-sm text-neutral-500">{{hospitalName || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium basis-2/5">Procedures Performed</span>
          <span class="text-sm basis-full text-neutral-500">{{procedures || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Length of Stay (in days)</span>
          <span v-if="lengthOfStayInDays" class="text-sm text-neutral-500">{{lengthOfStayInDays || '-'}} days</span>
          <span v-else class="text-neutral-500">-</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium basis-2/5">Preoperative Complications</span>
          <span class="text-sm basis-full text-neutral-500">{{preComplications || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium basis-2/5">Postoperative Complications</span>
          <span class="text-sm basis-full text-neutral-500">{{postComplications || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Diagnosis</span>
          <span class="text-sm text-neutral-500">{{diagnosis || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-1 gap-2">
          <span class="text-sm font-medium">Treatment</span>
          <span class="text-sm text-neutral-500">{{treatment || '-'}}</span>
        </div>
        <div class="flex flex-col col-span-full gap-2">
          <span class="text-sm font-medium">Notes</span>
          <span class="text-sm text-neutral-500">{{notes || '-'}}</span>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useHospitalizationHistory } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeHospitalizationHistory } = useHospitalizationHistory();
    const hospitalizedAt = computed(() => {
      if (!props.record.hospitalizedAt) return;
      const date = new Date(props.record.hospitalizedAt);
      return format(date, 'MMM d, yyyy');
    });
    const hospitalName = computed(() => props.record.hospitalName);
    const procedures = computed(() => (props.record.proceduresPerformed && props.record.proceduresPerformed?.length > 0)
      ? props.record.proceduresPerformed.map(i => i.name).join(', ')
      : null);
    const lengthOfStayInDays = computed(() => props.record.lengthOfStayInDays);
    const preComplications = computed(() => (props.record.proceduresPerformed && props.record.proceduresPerformed?.length > 0)
      ? props.record.complications.filter(i => i.type === 'pre').map(i => i.name).join(', ')
      : null);
    const postComplications = computed(() => (props.record.proceduresPerformed && props.record.proceduresPerformed?.length > 0)
      ? props.record.complications.filter(i => i.type === 'post').map(i => i.name).join(', ')
      : null);
    const diagnosis = computed(() => props.record.diagnosis);
    const treatment = computed(() => props.record.treatment);
    const notes = computed(() => props.record.notes);
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeHospitalizationHistory({ id: props.record.id });
    }

    return {
      createdAt,
      hospitalizedAt,
      hospitalName,
      procedures,
      lengthOfStayInDays,
      preComplications,
      postComplications,
      diagnosis,
      treatment,
      notes,
      edit,
      remove,
    };
  },
};
</script>
