<template>
  <div class="flex flex-col items-center">
    <div class="flex flex-row justify-between place-items-stretch w-full">
      <div class="flex flex-row gap-2 justify-self-start">
        <button class="btn btn-sm tooltip hover:tooltip-open" data-tip="Undo" @click.stop="undo">
          <i class="las la-undo"></i>
        </button>
        <button class="btn btn-sm tooltip hover:tooltip-open" data-tip="Redo" @click.stop="redo">
          <i class="las la-redo"></i>
        </button>
      </div>
      <div class="flex flex-row items-center gap-2">
        <div class="tooltip hover:tooltip-open" data-tip="Pencil Size">
          <select v-model="stylusSize" class="select select-bordered focus:select-neutral select-sm w-[70px] h-[32px]">
            <template v-for="(number, index) in [1,2,3,4,5,6,7,8,9,10]" :key="index">
              <option :value="number">{{number}}</option>
            </template>
          </select>
        </div>
        <div class="join">
          <button :class="`btn ${eraser ? '' : 'btn-secondary'} btn-sm join-item tooltip hover:tooltip-open`"
          data-tip="Pen"
          @click.stop="eraser = false">
            <i class="las la-paint-brush text-white"></i>
          </button>
          <button :class="`btn ${eraser ? 'btn-secondary' : ''} btn-sm join-item tooltip hover:tooltip-open`"
          data-tip="Eraser"
          @click.stop="eraser = true">
            <i class="las la-eraser text-white"></i>
          </button>
        </div>
        <div class="flex flex-row align-center justify-center items-center gap-1 rounded-lg
        hover:bg-primary hover:text-white hover:cursor-pointer">
          <div class="tooltip hover:tooltip-open" data-tip="Color">
            <input v-model="stylusColor" type="color" class="input input-bordered input-sm">
          </div>
        </div>
      </div>
    </div>
    <div class="my-2 bg-neutral-100 border-[1px] w-full flex justify-center">
      <VueDrawingCanvas
        v-model:image="model"
        ref="VueCanvasDrawing"
        class="border-[1px] border-neutral-300 my-4 object-contain"
        saveAs="jpeg"
        :backgroundImage="canvasImg"
        :color="stylusColor"
        :backgroundColor="'#ffffff'"
        :eraser="eraser"
        :lineWidth="stylusSize"
        :height="innerHeight/2"
        :width="innerHeight/2"
      />
    </div>
    <div class="flex flex-row gap-2 w-full justify-between">
      <div class="flex flex-row gap-2 justify-start w-full">
        <button class="btn btn-secondary" @click.prevent="selectTemplate = true">
          <i class="las la-upload text-white"></i>
          <span class="text-white">
            Select from templates
          </span>
        </button>
        <!-- <button class="btn btn-secondary" @click.prevent="openDialog">
          <i class="las la-upload text-white"></i>
          <span class="text-white">
            Upload Image
          </span>
        </button> -->
      </div>
      <div class="flex flex-row gap-2 w-full justify-end">
        <button class="btn hover:btn-neutral-300" @click.prevent="reset">Clear Canvas</button>
        <button class="btn btn-neutral" @click.prevent="save">Save</button>
      </div>
    </div>
  </div>

  <ClientOnly>
    <Teleport to="body">
      <dialog v-if="selectTemplate" :class="`modal ${selectTemplate ? 'modal-open' : ''}`">
        <div class="modal-box">
          <div class="flex justify-between items-center">
            <span class="text-xl font-medium">Select Medical Illustration Templates</span>
            <button class="btn btn-sm btn-circle btn-ghost" @click="selectTemplate = false;">
              <i class="las la-times text-2xl text-red-400"></i>
            </button>
          </div>
          <div class="modal-card w-full">
            <div class="py-2">
              <div class="flex flex-row items-center gap-9 h-[90px] hover:bg-neutral-300 hover:cursor-pointer"
                @click.stop="openDialog"
              >
                <i class="las la-file-upload text-6xl"></i>
                <span>Upload your own template</span>
              </div>
              <template v-for="(children, group) in mappedTemplateLinks" :key="group">
                <div class="flex flex-col gap-2 py-2">
                  <span class="text-lg font-weight-600">{{ group }}</span>
                  <template v-for="(child, index) in children" :key="index">
                    <div class="flex flex-row items-center gap-2 hover:bg-neutral-300 hover:cursor-pointer" @click.stop="selectImg(child)">
                      <img :src="child.src" height="100" width="100"/>
                      <span>{{ child.title }}</span>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </dialog>
    </Teleport>
  </ClientOnly>
</template>

<script>
import VueDrawingCanvas from 'vue-drawing-canvas';
import { fetchImage } from '@/services/fetch-image';
import { useFileDialog, until } from '@vueuse/core';
import { TEMPLATE_LINKS } from './constants';
import { ref, watch, computed, toRef, nextTick } from 'vue';
import { groupBy } from 'lodash';
import useSnackbar from '@/components/commons/global-snack/composables';

export default {
  components: {
    VueDrawingCanvas,
  },
  props: {
    autoMount: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['finish'],
  setup (props, { emit }) {
    const {
      open: openDialog,
      // reset: resetDialog,
      onChange,
    } = useFileDialog({
      accept: 'image/*',
      multiple: false,
    });
    const { show } = useSnackbar();

    // const loading = ref(null);
    const model = ref(null);
    const stylusColor = ref('#000000');
    const selectTemplate = ref(false);
    const reactiveTemplate = computed(() => toRef(props.autoMount));
    watch(reactiveTemplate, (newVal) => {
      selectTemplate.value = !selectTemplate.value;
    });

    const stylusSize = ref(5);
    const eraser = ref(false);

    const innerHeight = computed(() => window.innerHeight);
    const innerWidth = computed(() => window.innerWidth);

    const originalImgWidth = ref(null);
    const originalImgHeight = ref(null);
    const canvasImg = ref(null);
    const VueCanvasDrawing = ref(null);
    onChange(async (files) => {
      VueCanvasDrawing.value.reset();
      const resizedImages = await Promise.all(
        Array.from(files).map((file) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            // originalImg.value = clone(img.src);
            img.onload = () => {
              originalImgWidth.value = img.width;
              originalImgHeight.value = img.height;

              const containerWidth = innerHeight.value / 2;
              const containerHeight = innerHeight.value / 2;

              const aspectRatio = img.width / img.height;

              let width = containerWidth;
              let height = containerWidth / aspectRatio;

              if (height > containerHeight) {
                height = containerHeight;
                width = containerHeight * aspectRatio;
              }

              const canvas = document.createElement('canvas');
              canvas.width = containerWidth;
              canvas.height = containerHeight;
              const ctx = canvas.getContext('2d');
              ctx.clearRect(0, 0, containerWidth, containerHeight);
              ctx.globalCompositeOperation = 'destination-over';
              ctx.globalAlpha = 0;

              // Composite operation 'copy' to replace the destination with the source
              ctx.globalCompositeOperation = 'copy';

              // Reset the global alpha
              ctx.globalAlpha = 1;

              // Center the image within the canvas
              const x = (containerWidth - width) / 2;
              const y = (containerHeight - height) / 2;
              ctx.drawImage(img, x, y, width, height);
              const resizedDataURL = canvas.toDataURL('image/png');
              resolve(resizedDataURL);
            };

            img.onerror = (error) => {
              reject(new Error(error));
            };
          });
        }),
      );
      const firstResizedImage = resizedImages?.[0];
      if (!firstResizedImage) return;
      nextTick(() => {
        canvasImg.value = firstResizedImage;
        VueCanvasDrawing.value.reset();
        selectTemplate.value = false;
      });
    });

    const openSelectTemplate = () => {
      selectTemplate.value = true;
    };

    // originalImg
    const save = () => {
      // Assuming model.value is a Base64-encoded string
      const img = new Image();
      img.src = model.value;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Calculate the square size
        const size = Math.min(img.width, img.height);

        // Calculate the cropping coordinates
        const x = (img.width - size) / 2;
        const y = (img.height - size) / 2;

        // Set canvas size to the desired square size
        canvas.width = size;
        canvas.height = size;

        // Draw the centered portion of the image on the canvas
        ctx.drawImage(img, x, y, size, size, 0, 0, size, size);

        // Convert the canvas content to a new Base64-encoded string
        const resizedDataURL = canvas.toDataURL('image/jpeg');

        // Emit the 'finish' event with the new Base64-encoded string
        emit('finish', resizedDataURL);

        // Reset and clear as needed
        VueCanvasDrawing.value.reset();
        canvasImg.value = '';
      };
    };

    const reset = () => {
      canvasImg.value = '';
      VueCanvasDrawing.value.reset();
    };

    const mappedTemplateLinks = ref(groupBy(TEMPLATE_LINKS, (o) => o?.header));
    function undo () {
      VueCanvasDrawing.value.undo();
    };
    function redo () {
      VueCanvasDrawing.value.redo();
    }

    const selectImg = async (data) => {
      try {
        const resizedImage = await loadImageAndResize(data);
        canvasImg.value = resizedImage;
        VueCanvasDrawing.value.reset();
        selectTemplate.value = false;
      } catch (error) {
        console.error('Error loading or resizing image:', error);
      }
    };

    const loadImageAndResize = async (rawFile) => {
      const convertedFile = ref(null);
      fetchImage(rawFile.src).then(arrayBuffer => {
        const blob = new Blob([arrayBuffer], { type: 'image/png' });
        // Create a File object from the Blob
        convertedFile.value = new File([blob], rawFile.title, { type: 'image/png' });
      });
      try {
        await until(convertedFile.value).toBe(true, { timeout: 500 });
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        show('Timeout error!', { type: 'error' });
        return;
      }
      const resizedImage = await new Promise((resolve, reject) => {
        const img = new Image();
        URL.revokeObjectURL(img.src);
        img.src = URL.createObjectURL(convertedFile.value);
        img.onload = () => {
          const containerWidth = innerHeight.value / 2;
          const containerHeight = innerHeight.value / 2;

          const aspectRatio = img.width / img.height;

          let width = containerWidth;
          let height = containerWidth / aspectRatio;

          if (height > containerHeight) {
            height = containerHeight;
            width = height * aspectRatio;
          }

          const canvas = document.createElement('canvas');
          canvas.width = containerWidth;
          canvas.height = containerHeight;
          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, containerWidth, containerHeight);

          // Center the image within the canvas
          const x = (containerWidth - width) / 2;
          const y = (containerHeight - height) / 2;
          ctx.drawImage(img, x, y, width, height);
          const resizedDataURL = canvas.toDataURL('image/png');
          resolve(resizedDataURL);
        };

        img.onerror = (error) => {
          reject(new Error(error));
        };
      });
      if (!resizedImage) return;
      VueCanvasDrawing.value.reset();
      return resizedImage;
    };

    return {
      // loading,
      mappedTemplateLinks,
      model,
      canvasImg,
      stylusColor,
      stylusSize,
      eraser,
      VueCanvasDrawing,
      undo,
      redo,
      reset,
      save,
      openDialog,
      selectTemplate,
      openSelectTemplate,
      selectImg,
      innerHeight,
      innerWidth,
    };
  },
};
</script>
