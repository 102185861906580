export const RECORD_TYPE = 'ob-note';

export const FORM_FIELDS = [
  {
    field: 'overallFeeling',
    label: 'Overall Feeling?',
    model: '',
    placeholder: 'e.g. Energetic and healthy',
    update: true,
    type: 'text',
    rules: {
      type: 'string',
      required: true,
    },
  },
  {
    field: 'isBleeding',
    label: 'Any bleeding?',
    model: '',
    update: true,
    type: 'boolean',
    rules: {
      type: 'boolean',
      required: false,
    },
  },
  {
    field: 'isSpotting',
    label: 'Any spotting?',
    model: '',
    update: true,
    type: 'boolean',
    rules: {
      type: 'boolean',
      required: false,
    },
  },
  {
    field: 'isCramping',
    label: 'Any cramping?',
    model: '',
    update: true,
    type: 'boolean',
    rules: {
      type: 'boolean',
      required: false,
    },
  },
  {
    field: 'isNauseus',
    label: 'Feeling nauseous?',
    model: '',
    update: true,
    type: 'boolean',
    rules: {
      type: 'boolean',
      required: false,
    },
  },
  {
    field: 'hasVaginalDischarge',
    label: 'Noticed any changes in vaginal discharge?',
    model: '',
    update: true,
    type: 'boolean',
    rules: {
      type: 'boolean',
      required: false,
    },
  },
  {
    field: 'hasAbdominalPain',
    label: 'Observed any abdominal pain?',
    model: '',
    update: true,
    type: 'boolean',
    rules: {
      type: 'boolean',
      required: false,
    },
  },
  {
    field: 'hasSwelling',
    label: 'Any swelling in hands, feet, or face?',
    model: '',
    update: true,
    type: 'boolean',
    rules: {
      type: 'boolean',
      required: false,
    },
  },
  {
    field: 'hasHeadache',
    label: 'Any headaches?',
    model: '',
    update: true,
    type: 'boolean',
    rules: {
      type: 'boolean',
      required: false,
    },
  },
  {
    field: 'hasBlurredVision',
    label: 'Experiencing blurred vision?',
    model: '',
    update: true,
    type: 'boolean',
    rules: {
      type: 'boolean',
      required: false,
    },
  },
  {
    field: 'hasDizziness',
    label: 'Experienced dizziness?',
    model: '',
    update: true,
    type: 'boolean',
    rules: {
      type: 'boolean',
      required: false,
    },
  },
  {
    field: 'gestationalAgeInWeeks',
    label: 'Gestational Age',
    model: '',
    update: true,
    // type: 'text',
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    field: 'leopoldManeuver',
    label: "Leopold's Maneuvers",
    model: '',
    update: true,
    // type: 'text',
    rules: {
      type: 'string',
      required: false,
    },
  },
  {
    field: 'fetalMovement',
    label: 'Fetal Movement',
    model: {
      frequency: '',
      interval: '',
    },
    update: true,
    rules: {
      type: 'object',
      required: false,
    },
  },
  {
    field: 'lmp',
    label: 'Last Menstrual Period (LMP)',
    model: '',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    field: 'fundalHeight',
    label: 'Fundal Height',
    model: '',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  {
    field: 'fetalHeartRate',
    label: 'Fetal Heart Rate',
    model: '',
    update: true,
    rules: {
      type: 'number',
      required: false,
    },
  },
  // }
  // {
  //   field: 'fetalHeartTone',
  //   label: 'Fetal Heart Tone',
  //   model: '',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   field: 'ultrasoundDate',
  //   label: 'Ultrasound Date',
  //   model: '',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  // {
  //   field: 'ultrasoundAog',
  //   label: 'Ultrasound AOG',
  //   model: '',
  //   update: true,
  //   rules: {
  //     type: 'number',
  //     required: false,
  //   },
  // },
  {
    field: 'examFindings',
    label: 'Other Obstetrics-specific Findings',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: false,
    },
  },
];

export const UPDATE_FIELDS = FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field);

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.model;
  return acc;
}, {});

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
