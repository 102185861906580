export const RECORD_TYPE = 'nutritional-assessment';

export const FORM_FIELDS = [
  // {
  //   field: 'foodLikes',
  //   label: 'Food Likes',
  //   model: '',
  //   update: true,
  //   rules: {
  //     type: 'string',
  //     required: false,
  //   },
  // },
  // {
  //   field: 'foodDislikes',
  //   label: 'Food Dislikes',
  //   model: '',
  //   update: true,
  //   rules: {
  //     type: 'string',
  //     required: false,
  //   },
  // },
  {
    field: 'foodFrequencyMap',
    label: 'Food Frequency Map',
    update: true,
    model: {
      redMeat: {
        label: 'Red Meat',
        unit: '',
        min: '',
        max: '',
        example: '2-3 servings per week',
      },
      whiteMeat: {
        label: 'White Meat',
        unit: '',
        min: '',
        max: '',
        example: '3-4 times a week',
      },
      processedMeat: {
        label: 'Processed Meat',
        unit: '',
        min: '',
        max: '',
        example: '1-2 servings per day',

      },
      beansAndPeasOrLentils: {
        label: 'Beans and Peas or Lentils',
        unit: '',
        min: '',
        max: '',
        example: '5-7 times a week',
      },
      egg: {
        label: 'Egg',
        unit: '',
        min: '',
        max: '',
        example: '2-3 eggs per day',
      },
      milk: {
        label: 'Milk',
        unit: '',
        min: '',
        max: '',
        example: '1-2 cups per day',
      },
      cheeseAndYoghurt: {
        label: 'Cheese and Yoghurt',
        unit: '',
        min: '',
        max: '',
        example: '3-4 servings per week',
      },
      ricePastaAndBread: {
        label: 'Rice, Pasta, and Bread',
        unit: '',
        min: '',
        max: '',
        example: '4-6 servings per day',
      },
      ricePastaAndBreadWholeGrain: {
        label: 'Rice, Pasta, and Bread - Whole Grain',
        unit: '',
        min: '',
        max: '',
        example: '2-3 servings per day',
      },
      fruit: {
        label: 'Fruit',
        unit: '',
        min: '',
        max: '',
        example: '3-4 servings per day',
      },
      vegetable: {
        label: 'Vegetable',
        unit: '',
        min: '',
        max: '',
        example: '5-7 servings per day',
      },
      water: {
        label: 'Water',
        unit: '',
        min: '',
        max: '',
        example: '8-9 glasses per day',
      },
      alcoholicBeverages: {
        label: 'Alcoholic Beverages',
        unit: '',
        min: '',
        max: '',
        example: '1-2 drinks per week',
      },
      coffeeAndTea: {
        label: 'Coffee and Tea',
        unit: '',
        min: '',
        max: '',
        example: '2-3 cups per day',
      },
      fastFood: {
        label: 'Fast Food',
        unit: '',
        min: '',
        max: '',
        example: '1-2 meals per week',
      },
    },
    rules: {
      type: 'object',
      required: true,
    },
  },
  // {
  //   field: 'foodRecallMap',
  //   label: 'Food Recall Map',
  //   model: {
  //     breakfast: {
  //       label: 'Breakfast',
  //       time: '',
  //       foods: '',
  //     },
  //     snackAM: {
  //       label: 'Snack AM',
  //       time: '',
  //       foods: '',
  //     },
  //     lunch: {
  //       label: 'Lunch',
  //       time: '',
  //       foods: '',
  //     },
  //     snackPM: {
  //       label: 'Snack PM',
  //       time: '',
  //       foods: '',
  //     },
  //     dinner: {
  //       label: 'Dinner',
  //       time: '',
  //       foods: '',
  //     },
  //     snackBeforeBed: {
  //       label: 'Snack before Bed',
  //       time: '',
  //       foods: '',
  //     },
  //   },
  //   update: true,
  //   rules: {
  //     type: 'object',
  //     required: false,
  //   },
  // },
  {
    field: 'nutritionalSupplements',
    label: 'Nutritional Supplements',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: true,
    },
  },
  {
    field: 'appetite',
    label: 'Appetite',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: true,
    },
  },
  {
    field: 'bowelFunction',
    label: 'Bowel Function',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: true,
    },
  },
  {
    field: 'dietRX',
    label: 'Diet RX',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: true,
    },
  },
  {
    field: 'nutritionalAssessment',
    label: 'Overall Nutritional Assessment',
    model: '',
    update: true,
    rules: {
      type: 'string',
      required: true,
    },
  },
];

export const UPDATE_FIELDS = FORM_FIELDS
  .filter((f) => f.update)
  .map((f) => f.field);

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.model;
  return acc;
}, {});

// BASE_FORM_MODEL is used to reset FORM_MODEL, since it is a hassle to reset FORM_MODEL because of nested items
export const BASE_FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.model;
  return acc;
}, {});

export const FORM_RULES = FORM_FIELDS.reduce((acc, field) => {
  acc[field.field] = field.rules;
  return acc;
}, {});
