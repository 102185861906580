<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
        <div class="flex flex-col gap-4">
          <p class="text-sm font-medium">{{ attachmentTitle }}</p>
          <Viewer :images="attachmentURLs" @inited="inited" class="viewer flex flex-row flex-wrap gap-4" ref="viewer">
            <template #default="scope">
              <div v-for="(src) in scope.images" :key="src">
                <img :src="src" :key="src" class="max-w-[100px] max-h-[100px] hover:cursor-pointer hover:shadow-sm" />
              </div>
              {{ scope.options }}
            </template>
          </Viewer>
          <div class="flex flex-col gap-2">
            <span class="text-sm font-semibold">Description:</span>
            <span class="text-sm text-neutral-500">{{ description }}</span>
          </div>
        </div>
      </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import { useAttachments } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';
import 'viewerjs/dist/viewer.css';
import { component as Viewer } from 'v-viewer';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
    Viewer,
  },
  props: {
    record: {
      type: Object,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removeAttachments } = useAttachments();

    const attachmentTitle = computed(() => {
      const text = props.record.title;
      return text;
    });
    const createdAt = computed(() => format(new Date(props.record.createdAt), 'MM/dd/yyyy hh:mm a'));
    const attachmentURLs = computed(() => props.record.attachmentURLs);
    const description = computed(() => props.record.description);

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removeAttachments({ id: props.record.id });
    }

    const viewer = ref(null);
    const inited = (viewer) => {
      viewer.value = viewer;
    };

    return {
      createdAt,
      attachmentTitle,
      attachmentURLs,
      description,
      edit,
      remove,

      viewer,
      inited,
    };
  },
};
</script>
