<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="flex flex-col gap-4">
      <div v-for="(fields, index) in model" :key="index">
        <div class="flex justify-between items-center mb-4">
          <span class="text-neutral-500 text-md">Medicine {{ index + 1 }}</span>
          <button v-if="index > 0" class="btn btn-sm btn-circle" @click="removeMedicine(index)">
            <i class="las la-trash text-2xl text-error"></i>
          </button>
        </div>
        <div class="grid grid-cols-2 gap-4 bg-neutral-100 rounded-xl p-4">
          <div
            v-for="(formField, i) in formFields" :key="i"
            class="form-control w-full"
            :class="{ 'col-span-2': formField.field === 'note' || formField.field === 'genericName', 'hidden': formField.field !== 'genericName' && fields['genericName'].length === 0 }"
          >
            <label class="label">
              <span class="label-text">{{ formField.label }}</span>
            </label>

            <select
              v-if="formField.field === 'genericName'"
              v-model="fields[formField.field]"
              required
              class="select select-bordered w-full focus:select-neutral"
            >
              <option value="" selected disabled>{{ formField.placeholder }}</option>
              <option :value="med.genericName" v-for="(med, index) in medicines.data" :key="index">{{med.genericName}}</option>
            </select>

            <template v-if="formField.type === 'text'">
              <input
                v-model="fields[formField.field]"
                type="text"
                class="input input-bordered w-full focus:input-neutral"
                :placeholder="formField.placeholder"
              />
            </template>

            <template v-if="formField.type === 'number'">
              <input
                v-model="fields[formField.field]"
                type="number"
                min="0"
                class="input input-bordered w-full focus:input-neutral"
                :placeholder="formField.placeholder"
              />
            </template>

            <template v-if="formField.type === 'select' && formField.field !== 'genericName'">
              <select
                v-model="fields[formField.field]"
                class="select select-bordered w-full focus:select-neutral"
              >
                <option value="" selected disabled>{{ formField.placeholder }}</option>
                <option :value="form.value" v-for="(form, i) in formField.options" :key="i">{{ form.text }}</option>
              </select>
            </template>

            <template v-if="formField.type === 'date'">
              <input
                v-model="fields[formField.field]"
                type="date"
                class="input input-bordered w-full focus:input-neutral"
                :placeholder="formField.placeholder"
              />
            </template>

            <template v-if="formField.type === 'textarea'">
              <textarea
                v-model="fields[formField.field]"
                class="textarea textarea-bordered col-span-2 focus:textarea-neutral"
                :placeholder="formField.placeholder"
              />
            </template>
          </div>
        </div>
      </div>
      <button type="button" class="btn" @click="addNewPrescription">Add Medicine</button>
   </div>
   <div class="flex gap-4 justify-end">
      <button
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
      >
        Submit
      </button>
    </div>
</form>
</template>

<script>
import { FORM_FIELDS, FORM_MODEL, PRESCRIPTION, FREQUENCY_OPTS, MEDICINE_LIST } from './constants';
import { computed, ref, onMounted } from 'vue';
import { useCloned } from '@vueuse/core';
import { useMedicines } from '@/composables/use-medicines';
import { usePrescription } from './composables';
import { pickBy, identity } from 'lodash';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitPrescription } = usePrescription();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const { cloned: model, sync } = useCloned(FORM_MODEL);
    const { medicines, init: initMedicines } = useMedicines();

    onMounted(async () => {
      model.value = [JSON.parse(JSON.stringify(PRESCRIPTION))];
      await initMedicines();
      if (medicines.value.data.length === 0) {
        medicines.value.data = MEDICINE_LIST;
      }
    });

    // The API doesn't like when you pass it fields containing nothing, so we delete empty fields
    function removeEmptyProperties (arr) {
      // Create a new array with objects that have empty string properties removed
      return arr.map((obj) => {
        const newObj = JSON.parse(JSON.stringify(obj)); // Create a copy of the original object

        if (obj.startedAt) newObj.startedAt = new Date(obj.startedAt).getTime();
        if (obj.endedAt) newObj.endedAt = new Date(obj.endedAt).getTime();

        for (const key in newObj) {
          if (Object.hasOwn(newObj, key) && newObj[key] === '') {
            delete newObj[key]; // Delete properties with empty strings
          }
        }

        return newObj;
      });
    }

    const submit = async () => {
      try {
        const payload = {
          items: removeEmptyProperties(model.value),
        };

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitPrescription(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      sync();
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.value = data.items.map(item => {
        const newItem = {
          ...item,
          startedAt: item.startedAt ? new Date(item.startedAt).toISOString().split('T')[0] : undefined,
          endedAt: item.endedAt ? new Date(item.endedAt).toISOString().split('T')[0] : undefined,
        };
        return pickBy(newItem, identity);
      });
    }

    function addNewPrescription () {
      const newPrescription = JSON.parse(JSON.stringify(PRESCRIPTION));
      model.value.push(newPrescription);
    }

    function removeMedicine (index) {
      model.value.splice(index, 1);
    }

    return {
      loading,
      model,
      recordId,
      isEditing,
      formFields: FORM_FIELDS,
      frequencyOpts: FREQUENCY_OPTS,
      medicines,
      addNewPrescription,
      resetForm,
      submit,
      setForm,
      removeMedicine,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
