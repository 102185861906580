<template>
  <form @submit.prevent="submit">
    <div class="grid grid-cols-3 gap-4">
      <div class="col-span-full grid grid-cols-3 gap-4">
        <template v-for="(field, index) in formFields" :key="index">
          <template v-if="field.type === 'boolean'">
            <div class="flex flex-col col-span-1 gap-2">
              <label class="label flex flex-col gap-2 items-start">
                <span class="label-text">{{ field.label }} <span v-if="field?.rules?.required" class="text-error">*</span></span>
                <select
                  v-model="model[field.field]"
                  class="select select-bordered w-full focus:select-neutral"
                  :disabled="loading"
                >
                  <option value="" selected disabled>e.g. No</option>
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </label>
            </div>
          </template>
          <template v-if="field.type === 'text'">
            <div class="flex flex-col col-span-1 gap-2">
              <label class="label flex flex-col gap-2 items-start">
                <span class="label-text">{{ field.label }} <span v-if="field?.rules?.required" class="text-error">*</span></span>
                <input
                  v-model="model[field.field]"
                  class="input input-bordered w-full focus:input-neutral"
                  :type="field.type"
                  :placeholder="field?.placeholder || 'Type here'"
                  :disabled="loading"
                >
              </label>
            </div>
          </template>
        </template>
      </div>
      <!-- LMP -->
      <div class="form-control col-span-2">
        <label class="label">
          <span class="label-text">Last Menstrual Period (LMP) <span v-if="model?.lmp?.rules?.required" class="text-error">*</span></span>
        </label>
        <ClientOnly>
          <VDatePicker v-model.string="model.lmp" mode="date" :max-date="new Date()" s:masks="{ modelValue: 'YYYY-MM-DD' }">
            <template #default="{ togglePopover }">
              <input type="text" placeholder="e.g. 2023-08-07" class="input input-bordered w-full focus:input-neutral"
                v-model="model.lmp" @click="togglePopover" />
            </template>
          </VDatePicker>
        </ClientOnly>
      </div>

      <!-- Gestational Age -->
      <div class="form-control col-span-2">
        <label class="label">
          <span class="label-text">Gestational Age (in weeks) <span v-if="model?.gestationalAgeInWeeks?.rules?.required" class="text-error">*</span></span>
        </label>
        <input type="number" placeholder="e.g. 32 weeks" class="input input-bordered focus:input-neutral"
          v-model="model.gestationalAgeInWeeks" />
      </div>

      <!-- Fundal Height -->
      <div class="form-control col-span-2">
        <label class="label">
          <span class="label-text">Fundal Height (in cm) <span v-if="model?.fundalHeight?.rules?.required" class="text-error">*</span></span>
        </label>
        <input type="number" placeholder="e.g. 32 centimeters" class="input input-bordered focus:input-neutral"
          v-model="model.fundalHeight" />
      </div>

      <!-- Leopold's Manuevers -->
      <div class="form-control col-span-2">
        <label class="label">
          <span class="label-text">Leopold's Manuevers <span v-if="model?.leopoldManeuver?.rules?.required" class="text-error">*</span></span>
        </label>
        <textarea class="textarea textarea-bordered w-full" placeholder="e.g. Fetus in cephalic presentation, engaged in the pelvis"
          v-model="model.leopoldManeuver" />
      </div>

      <!-- FHR -->
      <div class="form-control col-span-2">
        <label class="label">
          <span class="label-text">FHR (Fetal Heart Rate) <span v-if="model?.fetalHeartRate?.rules?.required" class="text-error">*</span></span>
          <span class="label-text-alt">e.g. 130 beats per minute</span>
        </label>
        <input v-model="model.fetalHeartRate" type="number" min="1" class="input input-bordered focus:input-neutral" />
      </div>

      <!-- Fetal Movement -->
      <div class="form-control col-span-2">
        <label class="label">
          <span class="label-text">Fetal Movement</span>
          <span class="label-text-alt">e.g. 10 fetal movements in one hour</span>
        </label>
        <div class="join w-full">
          <input v-model="model.fetalMovement.frequency" type="number" min="1" placeholder="e.g. 10" class="input input-bordered focus:input-neutral join-item" />
          <select v-model="model.fetalMovement.interval" class="select select-bordered focus:select-neutral w-full join-item">
            <option selected disabled>e.g. in one hour</option>
            <option :value="'hourly'">in one hour</option>
            <option :value="'daily'">in one day</option>
            <option :value="'weekly'">in one week</option>
            <option :value="'monthly'">in one month</option>
          </select>
        </div>
      </div>

      <!-- Exam Findings -->
      <div class="form-control col-span-full">
        <label class="label">
          <span class="label-text">Other Obstetrics-specific Findings <span v-if="model?.examFindings?.rules?.required" class="text-error">*</span></span>
        </label>
        <textarea class="textarea textarea-bordered w-full" placeholder="e.g. No vaginal discharge, bleeding, or pain"
          v-model="model.examFindings" />
      </div>
    </div>
    <div class="flex gap-4 justify-end pt-8">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
        >
        Submit
      </button>
    </div>
  </form>
</template>

<script>
import { FORM_FIELDS, FORM_MODEL, FORM_RULES, UPDATE_FIELDS } from './constants';
import { computed, reactive, ref } from 'vue';
import { useObNote } from './composables';

export default {
  props: {
    showLabel: Boolean,
    loading: Boolean,
  },
  setup (props, { emit }) {
    const {
      submit: submitObNote,
    } = useObNote();
    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);
    model.fetalMovement = {};

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key]?.required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      if ((model?.fetalMovement?.frequency && (!model?.fetalMovement?.interval)) ||
        ((!model?.fetalMovement?.frequency) && (model?.fetalMovement?.interval))) return false;
      return true;
    });

    const submit = async () => {
      try {
        loading.value = true;

        if (!model?.fetalMovement?.frequency || !model?.fetalMovement?.interval) {
          delete model?.fetalMovement;
        }

        const payload = Object.fromEntries(
          Object.entries(model).filter(([key, value]) => value !== null && value !== undefined && value !== ''),
        );

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        // Create
        await submitObNote(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        if (typeof model[field] === 'object') {
          model[field] = {};
        } else model[field] = '';
      });
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      model.fetalMovement = {};
      const modelFields = Object.keys(FORM_MODEL);
      const fields = Object.keys(data).filter(i => modelFields.includes(i));
      fields.forEach(field => {
        if (field === 'lmp') {
          const dateVal = new Date(data[field]);
          data[field] = dateVal.toISOString().split('T')[0];
        }
        model[field] = data[field];
      });
    }

    return {
      model,
      recordId,
      formSubmittable,
      isEditing,
      formFields: FORM_FIELDS,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
</style>
