<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="form-control">
      <div class="flex justify-between py-2">
        <span class="label-text">
          <span v-if="showLabel">Record Form</span>
        </span>
      </div>
      <!-- <textarea
        v-model="text"
        placeholder="Type here"
        class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
        required
        :disabled="loading"
      ></textarea> -->
    </div>
    <div class="flex gap-4 justify-end">
      <button
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
      >
        Submit
      </button>
    </div>
  </form>
</template>

<script>
import { computed, ref } from 'vue';
import { useCarePlan } from './composables';

export default {
  props: {
    showLabel: Boolean,
  },
  setup (props, { emit }) {
    const { submit: submitCarePlan } = useCarePlan();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const text = ref('');

    const submit = async () => {
      try {
        loading.value = true;

        const payload = {
          text: text.value,
        };

        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }

        await submitCarePlan(payload);

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    function resetForm () {
      record.value = {};
      text.value = '';
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      text.value = data.text;
    }

    return {
      loading,
      recordId,
      isEditing,
      text,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>
