<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';
import { useCarePlan } from './composables';

const props = defineProps({
  record: {
    type: Object,
    required: true,
    defafault: () => ({}),
  },
  hideOptions: {
    type: Boolean,
    default: false,
  },
  showRecordLabel: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['edit', 'remove']);

const { remove: removeCarePlan } = useCarePlan();

const text = computed(() => {
  const text = props.record.text;
  return text.charAt(0).toUpperCase() + text.slice(1);
});
const recordId = computed(() => props.record.id);

function edit () {
  emit('edit', props.record);
}

function remove () {
  // const result = globalThis.confirm('Are you sure you want to delete this care plan?');
  // if (!result) return;
  removeCarePlan({ id: recordId.value });
}
</script>


<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <span class="text-sm font-medium">Care Plan Notes</span>
      <span class="text-sm text-neutral-500">{{text}}</span>
    </div>
  </div>
</template>

