<template>
  <generic-preview :record="record" :hide-options="hideOptions" @edit="edit" @remove="remove">
    <preview-wrapper :record="record" :hide-options="hideOptions">
      <div class="flex flex-col gap-4">
        <div class="grid grid-cols-2 gap-2">
          <div class="grid grid-cols-2 col-span-full gap-2">
            <span class="text-sm font-medium">Topic</span>
            <span class="text-sm text-neutral-500">{{ topic || '-' }}</span>
          </div>
          <div class="grid grid-cols-2 col-span-full gap-2">
            <span class="text-sm font-medium">Date of Seminar</span>
            <span class="text-sm text-neutral-500">{{presentedAt || '-'}}</span>
          </div>
          <div class="grid grid-cols-2 col-span-full gap-2">
            <span class="text-sm font-medium">Goals</span>
            <span class="text-sm text-neutral-500">{{goals || '-'}}</span>
          </div>
          <div class="grid grid-cols-2 col-span-full gap-2">
            <span class="text-sm font-medium">Pre-assesment</span>
            <span class="text-sm text-neutral-500">{{preAssessment?.assessment || '-'}}</span>
          </div>
          <div class="grid grid-cols-2 col-span-full gap-2">
            <span class="text-sm font-medium">Post-assesment</span>
            <span class="text-sm text-neutral-500">{{postAssessment?.assessment || '-'}}</span>
          </div>
          <div class="grid grid-cols-2 col-span-full gap-2">
            <span class="text-sm font-medium">Learning Materials</span>
            <span class="text-sm text-neutral-500">{{learningMaterials || '-'}}</span>
          </div>
          <div class="col-span-2 flex flex-col gap-2">
            <span class="text-sm font-medium">Notes</span>
            <span class="text-sm text-neutral-500">{{notes || '-'}}</span>
          </div>
        </div>
      </div>
    </preview-wrapper>
  </generic-preview>
</template>

<script>
import { computed } from 'vue';
import { format } from 'date-fns';
import { usePatientEducation } from './composables';
import GenericPreview from '@/components/medical-records/generic-preview.vue';
import PreviewWrapper from '@/components/medical-records/preview-wrapper.vue';

export default {
  components: {
    GenericPreview,
    PreviewWrapper,
  },
  props: {
    record: {
      type: Object,
      required: true,
      defafault: () => ({}),
    },
    hideOptions: {
      type: Boolean,
      default: false,
    },
    showRecordLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { remove: removePatientEducation } = usePatientEducation();

    const topic = computed(() => props.record?.topic);
    const presentedAt = computed(() => props.record?.presentedAt ? format(new Date(props.record?.presentedAt), 'MM/dd/yyyy') : null);
    const goals = computed(() => (props.record.goals && props.record.goals?.length > 0)
      ? props.record.goals.join(', ')
      : null);
    const learningMaterials = computed(() => (props.record.learningMaterials && props.record.learningMaterials?.length > 0)
      ? props.record.learningMaterials.map(i => i.title).join(', ')
      : null);
    const notes = computed(() => props.record.notes);

    const preAssessment = computed(() => {
      if (!props.record?.assessments) return null;
      return props.record.assessments.find(i => i.type === 'pre');
    });
    const postAssessment = computed(() => {
      if (!props.record?.assessments) return null;
      return props.record.assessments.find(i => i.type === 'post');
    });
    const satisfaction = computed(() => props.record?.satisfaction);

    const createdAt = computed(() => format(new Date(props.record?.createdAt), 'MM/dd/yyyy hh:mm a'));

    function edit () {
      emit('edit', props.record);
    }

    function remove () {
      removePatientEducation({ id: props.record.id });
    }

    return {
      createdAt,
      topic,
      presentedAt,
      goals,
      learningMaterials,
      preAssessment,
      postAssessment,
      satisfaction,
      notes,
      edit,
      remove,
    };
  },
};
</script>
