<template>
  <form @submit.prevent="submit" class="flex flex-col gap-8">
    <div class="grid grid-cols-3 gap-4">
      <template v-for="(formField, index) in formFields" :key="index">
        <div :class="`form-control col-span-${formField.type === 'textarea' ? 'full' :
          (formField.type === 'array' ? '2' : '1')}`">
          <div class="flex justify-between py-2">
            <span class="label-text">
              <span v-if="showLabel">{{formField.label}} <span v-if="formField.rules?.required" class="text-error">*</span></span>
            </span>
          </div>
          <template v-if="formField.type === 'select'">
            <select
              v-model="model[formField.field]"
              class="select select-bordered w-full focus:select-neutral"
              :name="formField.label"
              :disabled="loading"
              required
            >
              <option value="" selected disabled>{{ formField.placeholder }}</option>
              <template v-for="(option, index) in formField.options" :key="index">
                <option :value="option">{{option}}</option>
              </template>
            </select>
          </template>
          <template v-else-if="formField.type === 'textarea'">
            <textarea
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Type here'"
              class="h-[64px] textarea textarea-bordered focus:textarea-neutral"
              :disabled="loading"
            ></textarea>
          </template>
          <template v-else-if="formField.type === 'array'">
            <div class="flex flex-row gap-2 justify-between">
              <div class="flex flex-col gap-2 flex-1">
                <span class="label-text">Pre-surgery</span>
                <Chips
                  v-model="preSurgeryComplications"
                  addOnBlur
                  placeholder="Type here"
                  class="input input-bordered focus:input-neutral h-[100%] py-2"
                  :pt="{
                    container: { class: 'w-full flex flex-row gap-2' },
                    token: { class: 'flex flex-row items-center align-center gap-2 join rounded rounded-full bg-neutral-300 shadow-sm px-2' },
                    label: { class: 'join-item' },
                    removeTokenIcon: { class: 'hover:bg-neutral-200 rounded-full' }
                  }"
                />
              </div>
              <div class="flex flex-col gap-2 flex-1">
                <span class="label-text">Post-surgery</span>
                <Chips
                  v-model="postSurgeryComplications"
                  addOnBlur
                  placeholder="Type here"
                  class="input input-bordered focus:input-neutral h-[100%] py-2"
                  :pt="{
                    container: { class: 'w-full flex flex-row gap-2' },
                    token: { class: 'flex flex-row items-center align-center gap-2 join rounded rounded-full bg-neutral-300 shadow-sm px-2' },
                    label: { class: 'join-item' },
                    removeTokenIcon: { class: 'hover:bg-neutral-200 rounded-full' }
                  }"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <input
              v-if="formField.type !== 'date'"
              v-model="model[formField.field]"
              :placeholder="formField.placeholder || 'Input Text'"
              class="input input-bordered focus:input-neutral w-full"
              :type="formField.rules.type"
              min="0"
              :disabled="loading"
            />
            <ClientOnly v-else>
              <VDatePicker v-model.string="model[formField.field]" mode="date" :max-date="new Date()" :masks="{ modelValue: 'YYYY-MM-DD' }">
                <template #default="{ togglePopover }">
                  <input type="text" :placeholder="formField.placeholder" class="input input-bordered w-full focus:input-neutral"
                    v-model="model[formField.field]" @click="togglePopover" />
                </template>
              </VDatePicker>
            </ClientOnly>
          </template>
        </div>
      </template>
    </div>
    <div class="flex gap-4 justify-end">
      <button
        v-if="formSubmittable"
        type="button"
        class="btn btn-outline btn-sm normal-case"
        @click="resetForm"
      >
        Clear All
      </button>
      <button
        type="submit"
        class="btn btn-neutral btn-sm normal-case text-white"
        :disabled="!formSubmittable"
      >
        {{ isEditing ? 'Save Changes' : 'Submit' }}
      </button>
    </div>
  </form>
</template>

<script>
import { UPDATE_FIELDS, FORM_MODEL, FORM_FIELDS, FORM_RULES } from './constants';
import { computed, reactive, ref } from 'vue';
import { hoursToMilliseconds, millisecondsToHours } from '@/utils/math';
import { useSurgicalHistory } from './composables';
import Chips from 'primevue/chips';
import { union } from 'lodash';

export default {
  props: {
    showLabel: Boolean,
    patientId: String,
    encounterId: String,
    facilityId: String,
  },
  components: {
    Chips,
  },
  setup (props, { emit }) {
    const { submit: submitSurgicalHistory } = useSurgicalHistory();

    const loading = ref(false);
    const record = ref({});
    const recordId = computed(() => record.value.id);
    const isEditing = computed(() => !!recordId.value);

    const model = reactive(FORM_MODEL);
    const preSurgeryComplications = ref([]);
    const postSurgeryComplications = ref([]);

    const submit = async () => {
      try {
        const payload = Object.fromEntries(
          Object.entries(model).filter(([key, value]) => Boolean(value)),
        );
        payload.operationTimeInMillis = hoursToMilliseconds(payload.operationTimeInMillis);
        if (preSurgeryComplications.value || postSurgeryComplications.value) payload.complications = [];
        if (preSurgeryComplications.value && preSurgeryComplications.value?.length > 0) {
          const complications = JSON.parse(JSON.stringify(preSurgeryComplications.value));
          const preComplications = complications.map((v, i) => ({
            type: 'pre',
            code: String(i),
            name: v,
          }));
          payload.complications = union(payload.complications, preComplications);
        }
        if (postSurgeryComplications.value && postSurgeryComplications.value?.length > 0) {
          const complications = JSON.parse(JSON.stringify(postSurgeryComplications.value));
          const postComplications = complications.map((v, i) => ({
            type: 'post',
            code: String(i),
            name: v,
          }));
          payload.complications = union(payload.complications, postComplications);
        }
        // Update
        if (isEditing.value) {
          payload.id = recordId.value;
        }
        // payload.notes = '';

        // Create
        await submitSurgicalHistory(payload, { patientId: props.patientId, encounterId: props.encounterId, facilityId: props.facilityId });

        resetForm();
        emit('submit');
        emit('success');
      } catch (e) {
        console.error('Something went wrong. Try again later.');
        emit('error', e);
      } finally {
        loading.value = false;
      }
    };

    const formSubmittable = computed(() => {
      for (const [key, val] of Object.entries(model)) {
        if (FORM_RULES[key].required && (val === '' || val === null || val === undefined)) return false;
        continue;
      }
      return true;
    });

    function resetForm () {
      record.value = {};
      UPDATE_FIELDS.forEach(field => {
        model[field] = '';
      });
      preSurgeryComplications.value = [];
      postSurgeryComplications.value = [];
    }

    // Sets value to record for editing purposes
    // as well as set value model.text
    function setForm (data) {
      if (!data) return;
      record.value = data;
      if (data.performedAt) {
        const date = new Date(data.performedAt);
        model.performedAt = date.toISOString().split('T')[0];
      }
      model.hospitalName = data.hospitalName;
      model.procedure = data.procedure;
      model.reason = data.reason;
      model.operationTimeInMillis = millisecondsToHours(data.operationTimeInMillis);
      model.lengthOfStayInDays = data.lengthOfStayInDays;
      preSurgeryComplications.value = data.complications.filter(i => i.type === 'pre')
        .map(v => v.name);
      postSurgeryComplications.value = data.complications.filter(i => i.type === 'post')
        .map(v => v.name);
      model.notes = data.notes;
    }

    return {
      loading,
      model,
      preSurgeryComplications,
      postSurgeryComplications,
      recordId,
      isEditing,
      formFields: FORM_FIELDS,
      formSubmittable,
      resetForm,
      submit,
      setForm,
    };
  },
};
</script>

<style scoped>
select:required:invalid {
  color: gray;
}
option {
  color: black;
}
[value=""] {
  color: grey;
}
</style>
