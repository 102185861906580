export const RECORD_TYPE = 'functional-status';

export const INDEPENDENCE_OPTIONS = [
  {
    name: 'Independent',
    value: 'independent',
  },
  {
    name: 'Partially Independent',
    value: 'partially-independent',
  },
  {
    name: 'Requires Assistance',
    value: 'requires-assistance',
  },
  {
    name: 'Unable',
    value: 'unable',
  },
];

export const RECORD_FIELDS = [
  {
    type: 'header',
    label: 'Katz Index',
    description: '', // describe Katz Index
  },
  {
    label: 'Bathing',
    field: 'katzIndex.bathing',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
    update: true,
  },
  {
    label: 'Dressing',
    field: 'katzIndex.dressing',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    label: 'Feeding',
    field: 'katzIndex.feeding',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    label: 'Toileting',
    field: 'katzIndex.toileting',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    label: 'Transferring',
    field: 'katzIndex.transferring',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    label: 'Continence',
    field: 'katzIndex.continence',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    type: 'header',
    label: 'Lawton Instrumental Activities of Daily Living',
  },
  {
    label: 'Shopping',
    field: 'lawtonInstrumentalActivitiesOfDailyLiving.shopping',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    label: 'Housekeeping',
    field: 'lawtonInstrumentalActivitiesOfDailyLiving.housekeeping',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    label: 'Laundry',
    field: 'lawtonInstrumentalActivitiesOfDailyLiving.laundry',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    label: 'Mode Of Transportation',
    field: 'lawtonInstrumentalActivitiesOfDailyLiving.modeOfTransportation',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    label: 'Responsibility For Own Medications',
    field: 'lawtonInstrumentalActivitiesOfDailyLiving.responsibilityForOwnMedications',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    label: 'Ability To Handle Finances',
    field: 'lawtonInstrumentalActivitiesOfDailyLiving.abilityToHandleFinances',
    inputType: 'select',
    options: INDEPENDENCE_OPTIONS,
  },
  {
    type: 'header',
    label: 'Mobility',
  },
  {
    label: 'Gait',
    field: 'mobility.gait',
    inputType: 'input',
    placeholder: 'Unsteady',
  },
  {
    label: 'Balance',
    field: 'mobility.balance',
    inputType: 'input',
    placeholder: 'Poor',
  },
  {
    label: 'Transfer',
    field: 'mobility.transfer',
    inputType: 'input',
    placeholder: 'Requires assistance',
  },
  {
    type: 'header',
    label: 'Cognitive',
  },
  {
    label: 'Orientation',
    field: 'cognitive.orientation',
    inputType: 'input',
    placeholder: 'Oriented to person, place, time, and situation',
  },
  {
    label: 'Memory',
    field: 'cognitive.memory',
    inputType: 'input',
    placeholder: 'Mild memory impairment',
  },
  {
    label: 'Attention',
    field: 'cognitive.attention',
    inputType: 'input',
    placeholder: 'Mild attention impairment',
  },
  {
    label: 'Executive Function',
    field: 'cognitive.executiveFunction',
    inputType: 'input',
    placeholder: 'Able to make simple decisions',
  },
  {
    type: 'header',
    label: 'Pain',
  },
  {
    label: 'Location',
    field: 'pain.location',
    inputType: 'input',
    placeholder: 'Right knee',
  },
  {
    label: 'Intensity',
    field: 'pain.intensity',
    inputType: 'input',
    type: 'number',
    placeholder: 'Pain intensity from 0 to 10',
  },
  {
    label: 'Duration',
    field: 'pain.duration',
    inputType: 'input',
    placeholder: 'Chronic',
  },
  {
    label: 'Notes',
    field: 'pain.notes',
    inputType: 'textarea',
    placeholder: 'Enter pertinent remarks on pain experienced (e.g. Patient experiences severe difficulty in left joint)',
  },
];
