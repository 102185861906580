export const RECORD_TYPE = 'dental-note';
export const RECORD_SUBTYPE_BASELINE = 'baseline';
export const RECORD_SUBTYPE_ORDER = 'order';
export const RECORD_SUBTYPE_RESULT = 'result';

import { teethLocationFlat } from './teeth-location';

export const TEETH_LOCATIONS = teethLocationFlat;

export const SURFACES = [
  {
    label: 'Buccal',
    value: 'buccal'
  },
  {
    label: 'Mesial',
    value: 'mesial'
  },
  {
    label: 'Palatal',
    value: 'palatal'
  },
  {
    label: 'Lingual',
    value: 'lingual'
  },
  {
    label: 'Distal',
    value: 'distal'
  },
  {
    label: 'Occlusal',
    value: 'occlusal'
  },
  {
    label: 'Cervical Buccal',
    value: 'cervical-buccal'
  },
  {
    label: 'Cervical Lingual',
    value: 'cervical-lingual'
  },
];

export const SURFACES_CHECKBOX_MODEL = SURFACES.map((surface) => {
  return {
    ...surface,
    selected: false,
  };
});

export const FORM_FIELDS = [
  {
    label: 'Teeth',
    model: 'teeth',
    required: true,
  },
  {
    label: 'Service',
    model: 'service',
  },
  {
    label: 'Status',
    model: 'status',
    required: true,
  },
  {
    label: 'Surfaces',
    model: 'surfaces',
  },
  {
    label: 'Diagnosis',
    model: 'icd10',
  },
  {
    label: 'Notes',
    model: 'notes', 
  },
];

export const ICDAS_DEFAULTS = [
  { label: '0 - Sound', value: '0' },
  { label: '1 - First Visual Change in Enamel', value: '1' },
  { label: '2 - Distinct Visual Change in Enamel', value: '2' },
  { label: '3 - Localized Enamel Breakdown', value: '3' },
  { label: '4 - Underlying Dark Shadow from Dentin', value: '4' },
  { label: '5 - Distinct Cavity with Visible Dentin', value: '5' },
  { label: '6 - Extensive Distinct Cavity with Visible Dentin', value: '6' },
];

export const CAST_DEFAULTS = [
  { label: '0 - No visible evidence of a distinct carious lesion is present', value: '0' },
  { label: '1 - Pits and/or fissures are at least partially covered with a sealant material', value: '1' },
  { label: '2 - A cavity is restored with an (in)direct restorative material', value: '2' },
  { label: '3 - Distinct visual change in enamel only. A clear caries related discolouration is visible, with or without localised enamel breakdown', value: '3' },
  { label: '4 - Internal caries-related discolouration in dentine. The discoloured dentine is visible through enamel which may or may not exhibit a visible localised breakdown of enamel', value: '4' },
  { label: '5 - Distinct cavitation into dentine. The pulp chamber is intact', value: '5' },
  { label: '6 - Involvement of the pulp chamber. Distinct cavitation reaching the pulp chamber or only root fragments are present', value: '6' },
  { label: '7 - A pus containing swelling or a pus releasing sinus tract related to a tooth with pulpal involvement', value: '7' },
  { label: '8 - The tooth has been removed because of dental caries', value: '8' },
  { label: '9 - Does not correspond to any of the other descriptions', value: '9' },
];

export const FORM_MODEL = FORM_FIELDS.reduce((acc, field) => {
  acc[field.model] = '';
  return acc;
}, {});