export const RECORD_TYPE = 'hpi';
export const UPDATE_FIELDS = [
  'text',
  'signsAndSymptoms',
];
export const FORM_RULES = {
  text: {
    type: 'string',
    required: true,
  },
};
