export const RECORD_TYPE = 'assessment';
export const RECORD_SUBTYPE = 'impression';
export const UPDATE_FIELDS = [
  'text',
];
export const FORM_RULES = {
  text: {
    type: 'string',
    required: true,
  },
};
