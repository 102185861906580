import { compose } from './fn';
import { roundToTwoDecimalPlaces } from './number';

const MILLISECONDS_PER_MINUTE = 60000;
const MINUTES_PER_HOUR = 60;

const LB_PER_KG = 2.2046;
const FT_PER_CM = 0.032808;

export const minutesToMilliseconds = time => {
  return time * MILLISECONDS_PER_MINUTE;
};

export const millisecondsToMinutes = time => {
  return time / MILLISECONDS_PER_MINUTE;
};

export const minutesToHours = time => time * MINUTES_PER_HOUR;
export const hoursToMinutes = time => time / MINUTES_PER_HOUR;
export const hoursToMilliseconds = compose(minutesToMilliseconds, hoursToMinutes);
export const millisecondsToHours = compose(minutesToHours, millisecondsToMinutes);

export const calcBMIUtil = (w = 0, h = 0) => {
  const weight = parseInt(w);
  const height = parseInt(h);
  let final = 0;
  if (weight && height) {
    const bmi = (weight / (height * height)) * 10000;
    final = Math.round(bmi * 100) / 100;
  }
  return roundToTwoDecimalPlaces(final);
};

export const getLbUtil = (w = 0) => {
  return roundToTwoDecimalPlaces(+w * LB_PER_KG);
};

export const getKgUtil = (w = 0) => {
  return roundToTwoDecimalPlaces(+w / LB_PER_KG);
};

export const getFtUtil = (h = 0) => {
  return roundToTwoDecimalPlaces(+h * FT_PER_CM);
};

export const getCmUtil = (h = 0) => {
  return roundToTwoDecimalPlaces(+h / FT_PER_CM);
};
